import { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { LockOutlined, MailOutlined, PlusOutlined, SendOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import classnamesBind from 'classnames/bind'
import { StudentSystemStatus } from '@/api/groups'
import { IStudents } from '@/api/students'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { useStores } from '@/stores'
import { IStudentProps } from '../types'

import styles from './student-sms.module.scss'

const cn = classnamesBind.bind(styles)

export const StudentSms = observer(({ student }: IStudentProps) => {
  const { studentStore } = useStores()
  const { setStudentSmsSendModal, setStudentSmsHistoryModal, setStudentId } = studentStore
  const [loading, setLoading] = useState(false)
  const [isAvailableAdd] = useCheckPermission({
    module: permissionObjectTypes.allGroupsAllHomeworks,
    option: PermissionOptions.Read,
  })

  const handleSmsHistoryShow = (student: IStudents) => {
    setStudentId(student)
    setStudentSmsHistoryModal(true)
  }

  const [isAvailableSee] = useCheckPermission({
    module: permissionObjectTypes.usersStudentsSmsHistory,
    option: PermissionOptions.Read,
  })

  const [isAvailableSend] = useCheckPermission({
    module: permissionObjectTypes.usersStudentsSmsHistory,
    option: PermissionOptions.Create,
  })

  const handleSmsSend = (student: IStudents) => {
    setStudentId(student)
    setStudentSmsSendModal(true)
  }

  const isStudentActive = useMemo(() => student.systemStatus === StudentSystemStatus.ACTIVE, [student])

  const handleConfirm = () => {
    setLoading(true)

    const info = {
      id: student.id,
      data: {
        status: isStudentActive ? StudentSystemStatus.BLOCKED : StudentSystemStatus.ACTIVE,
      },
    }

    const studentParams = {
      page: studentStore.page,
      perPage: studentStore.perPage,
      keys: studentStore.keys,
      value: studentStore.value,
      orderBy: studentStore.orderBy,
      categoryId: studentStore.categoryId,
      statusId: studentStore.statusId,
      systemStatus: studentStore.systemStatus,
      groupId: studentStore.studentGroup!,
    }

    const responseMsg = isStudentActive ? mainDictionary.blockedSuccessFully : mainDictionary.unblockSuccessFully

    studentStore
      .changeStudentStatus(info)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(responseMsg)

          studentStore.getStudents(studentParams).catch(addCatchNotification)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleGroupAddModalClick = () => {
    studentStore.setAddToGroupModal(true)
    studentStore.setSingleStudent(student)
  }

  return (
    <div className={cn('student-sms__wrapper')}>
      {isAvailableAdd && <Button onClick={handleGroupAddModalClick} type="primary" icon={<PlusOutlined />} />}
      <Popconfirm
        title={isStudentActive ? mainDictionary.confirmBlock : mainDictionary.confirmUnblock}
        onConfirm={handleConfirm}
        okButtonProps={{ loading }}
      >
        <Button
          icon={isStudentActive ? <LockOutlined /> : <UnlockOutlined />}
          type="primary"
          disabled={loading}
          danger={isStudentActive}
        />
      </Popconfirm>

      {isAvailableSee && (
        <Tooltip placement="bottom" title={mainDictionary.smsHistory}>
          <Button
            disabled={!student.hasSmsHistory}
            type="primary"
            icon={<MailOutlined />}
            onClick={handleSmsHistoryShow.bind(null, student)}
          />
        </Tooltip>
      )}
      {isAvailableSend && (
        <Popconfirm
          title={mainDictionary.confirmChangePassword}
          onConfirm={handleSmsSend.bind(null, student)}
          placement="left"
        >
          <Tooltip placement="bottom" title={mainDictionary.smsSend}>
            <Button type="primary" icon={<SendOutlined />} />
          </Tooltip>
        </Popconfirm>
      )}
    </div>
  )
})
