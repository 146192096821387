import { ROUTES } from '@/constants'
import mainDictonary from '@/dictionary'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { GamificationMethod } from '@/stores/gamification/types'

export const connectBreadcrumbs: IBreadcrumb[] = [
  { label: mainDictonary.Gamification },
  { label: mainDictonary.connect },
]

export const connectLinkBreadcrumbs: IBreadcrumb[] = [
  { label: mainDictonary.Gamification },
  { label: mainDictonary.connect, link: ROUTES.gamificationConnect },
]

export const gamificationMethodNames: Record<GamificationMethod, string> = {
  [GamificationMethod.GivenFromAdmin]: 'Admin tomonidan berilgan',
  [GamificationMethod.ParticipateLesson]: 'Darsda qatnashgani uchun',
  [GamificationMethod.DoHomework]: 'Uyga vazifa bajargani uchun',
  [GamificationMethod.ParticipateExam]: 'Imtihonda qatnashgani uchun',
  [GamificationMethod.PassExam]: "Imtihondan o'tgani uchun",
}
