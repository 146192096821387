import { useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Table, Tag } from 'antd'
import classNames from 'classnames/bind'
import { Category, IStudents } from '@/api/students'
import { studentStore } from '@/stores/students/student'
import { getFullName } from '@/utils'
import { studentCategoriesColumns } from '../constants'
import { IStudentProps } from '../types'

import styles from './student-category.module.scss'

const cn = classNames.bind(styles)

export const StudentCategory = observer(({ student }: IStudentProps) => {
  const [categoryModal, setCategoryModal] = useState(false)
  const { setSingleStudentCategory } = studentStore

  const handleCategoryInfoModal = (studentCategory: Category[]) => {
    setSingleStudentCategory(studentCategory)
    setCategoryModal(true)
  }

  const handleClose = () => {
    setCategoryModal(false)
  }

  return (
    <>
      <Tag
        onClick={handleCategoryInfoModal.bind(null, student?.categories as Category[])}
        color="cyan"
        className={cn('category-table__id')}
      >
        {student?.categories?.length}
      </Tag>

      <Modal title={getFullName<IStudents>(student)} open={categoryModal} onOk={handleClose} onCancel={handleClose}>
        <Table columns={studentCategoriesColumns} dataSource={student.categories as Category[]} rowKey="id" bordered />
      </Modal>
    </>
  )
})
