export const regexPhoneNumber = new RegExp(/^998(\d{9})$/)
export const onlyNumbers = /[^0-9]/g
export const regexPhoneEditNumber = new RegExp(/^\+998(\d{9})$/)
export const regexInteger = new RegExp(/^\d+$/)
export const regexPassword = new RegExp(
  '^(?=(.*[0-9]))(?=.*[!@#$%^&*()\\\\[\\]{}\\-_+=~`|:;"\'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,16}$',
)
export const integerOnly = /^\d+$/
export const regexNumber = new RegExp(/^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i)
export const regexWordsOnly = /^[a-zA-Z]{0,2}$/
export const phoneNumber = /^[0-9+]+$/
export const emptyOrNumber = /^\d*$/
export const regexWords = /^[a-zA-Z]+$/
export const birthFormatChecker = /^\d{2}.\d{2}.\d{4}$/
export const mobileAppVersionReg = /^\d+(\.\d+){1,9}$/
