import { observer } from 'mobx-react'
import { DeleteOutlined, PauseOutlined, PlayCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { Popconfirm, Space } from 'antd'
import { Button } from '@/ant'
import { IVideo } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { VideoStatus } from '@/modules/kinescope'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { VIDEO_KEYS } from '@/stores/groups'
import { useCheckMyGroup } from '@/utils/hooks'

interface IProps {
  video: IVideo
  groupId: number
}

export const VideoUploadingActions = observer(({ video, groupId }: IProps) => {
  const { groupsStore, kinescopeStore } = useStores()
  const academic = useCheckMyGroup()

  const handleDelete = async () => {
    try {
      const uploadingFile = kinescopeStore.getUploadingFile(groupId, video.id)

      await uploadingFile?.uploadFile.abort?.().then(() => {
        kinescopeStore.removeFromUploads(groupId, video.id)
      })

      if (groupsStore.isAcademic || academic) {
        await groupsStore.deleteVideoForAcademic(video.id)
      } else {
        await groupsStore.deleteVideoForAdmin(video?.id)
      }

      const videoInfo = {
        value: groupsStore.key,
        keys: VIDEO_KEYS,
        groupId: Number(groupsStore.groupId),
      }

      if (groupsStore.isAcademic || academic) {
        await groupsStore.getStaffVideos(videoInfo)
      } else {
        await groupsStore.getVideos(videoInfo)
      }

      addSuccessNotification(mainDictionary.successDelete)
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    }
  }

  const handleStartButtonClick = () => {
    video.onStart?.()
    kinescopeStore.setUploadParams(groupId, video.id, { currentStatus: VideoStatus.Uploading })
  }

  const handlePauseButtonClick = () => {
    video.onAbort?.()
    kinescopeStore.setUploadParams(groupId, video.id, { currentStatus: VideoStatus.Pause })
  }

  return (
    <Space className="action-wrapper">
      {video.currentStatus === VideoStatus.Error && (
        <Button icon={<RetweetOutlined />} type="primary" onClick={handleStartButtonClick} />
      )}

      {video.currentStatus === VideoStatus.Pause && (
        <Button icon={<PlayCircleOutlined />} type="primary" onClick={handleStartButtonClick} />
      )}

      {video.currentStatus === VideoStatus.Uploading && (
        <Button icon={<PauseOutlined />} onClick={handlePauseButtonClick} />
      )}

      <Popconfirm title={mainDictionary.sureToDeleteVideo} onConfirm={handleDelete}>
        <Button danger icon={<DeleteOutlined />} />
      </Popconfirm>
    </Space>
  )
})
