import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { FileAddOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useBoolean } from 'usehooks-ts'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '../../../modules/permission'
import { BranchOfficeGrid } from './components/BranchOfficeGrid'
import { BranchOfficeAddModal } from './modals/BranchOfficeAddModal'
import { BranchOfficeDeleteModal } from './modals/BranchOfficeDeleteModal'
import { branchOfficeStore } from './shared/branch-office.store'

export const BranchOffices = observer(() => {
  const { breadcrumbStore } = useStores()
  const { setOpenAddModalStatus, isOpenAddModal } = branchOfficeStore
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean()
  const [isAvailableAdd] = useCheckPermission({
    module: permissionObjectTypes.staffControlBranchOffice,
    option: PermissionOptions.Create,
  })

  useEffect(() => {
    setTrueLoading()

    branchOfficeStore.getBranchOffices().finally(setFalseLoading)
    breadcrumbStore.addBreadcrumb({ label: mainDictionary.offices })

    return () => {
      breadcrumbStore.clearBreadcrumbs()
    }
  }, [])

  const handleAddButtonClick = () => {
    setOpenAddModalStatus(true)
  }

  return (
    <>
      {isAvailableAdd && (
        <Space style={{ marginBottom: 25, justifyContent: 'end', width: '100%' }}>
          <Button type="primary" icon={<FileAddOutlined />} onClick={handleAddButtonClick} size="large">
            {mainDictionary.addBranchOffice}
          </Button>
        </Space>
      )}

      <BranchOfficeGrid isLoading={isLoading} />

      {isOpenAddModal && <BranchOfficeAddModal />}
      <BranchOfficeDeleteModal />
    </>
  )
})
