import { ReactNode, Suspense } from 'react'
import { Loading } from '@/components'
import { NotFound } from '@/components/NotFound'
import { permissionObjectTypesValues, ValueOfPermissionObjectTypes } from '@/modules/permission'

type Props = {
  permissionName?: ValueOfPermissionObjectTypes
  children: ReactNode
}

export const AvailableComponent = ({ permissionName, children }: Props): ReactNode => {
  if (permissionName && !permissionObjectTypesValues.includes(permissionName)) {
    return <NotFound />
  }

  return <Suspense fallback={<Loading />}>{children}</Suspense>
}
