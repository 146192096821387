import { lazy } from 'react'

export const MasterClass = lazy(
  () => import('./MasterClass').then(({ MasterClass }) => ({ default: MasterClass })),
  // catch will be after release,
)

export const Events = lazy(
  () => import('./Events').then(({ Events }) => ({ default: Events })),
  // catch will be after release,
)

export const MasterClassMake = lazy(
  () => import('./MasterClass/MakeMasterClass').then(({ MakeMasterClass }) => ({ default: MakeMasterClass })),
  // catch will be after release,
)

export const SingleMasterClass = lazy(() =>
  import('./MasterClass/SingleMasterClass').then(({ SingleMasterClass }) => ({ default: SingleMasterClass })),
)

export const Users = lazy(() => import('./MasterClass/Users').then(({ Users }) => ({ default: Users })))

export const MakeCompetition = lazy(() =>
  import('./Events/MakeCompetition').then(({ MakeCompetition }) => ({ default: MakeCompetition })),
)

export const SingleCompetition = lazy(() =>
  import('./Events/SingleCompetition').then(({ SingleCompetition }) => ({ default: SingleCompetition })),
)
