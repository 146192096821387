import { UserOutlined } from '@ant-design/icons'
import { Avatar, Flex, SelectProps, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { StaffsWorkStatus } from '@/api/students'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { HhOffice } from '@/pages/Controls/BranchOffice/types'
import { GenderTypes } from '@/pages/Controls/types'
import { StaffWorkStatusType } from '@/pages/Users/Academic/types'
import { studentPhotosStatuses, studentPhotoStatusesColor } from '@/pages/Users/Students/constants'
import { AcStatuses } from '@/pages/Users/Students/types'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { getFullName, makeFileUrl } from '@/utils'
import { formatDate } from '@/utils/date'
import { StaffAction } from './StaffAction/StaffAction'
import { StaffIndex } from './StaffIndex'
import { StaffStatus } from './StaffStatus'
import { StaffWorkStatusTab } from './StaffWorkStatus'
import { StaffData } from './types'

export const staffColumns: ColumnsType<StaffData> = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (value, record, index) => <StaffIndex index={index} />,
  },
  {
    title: mainDictionary.userPhoto,
    key: 'photo',
    align: 'center',
    render: (value: StaffData) => (
      <Flex justify="center" align="center" gap={10}>
        <Avatar src={makeFileUrl(value?.photo)} icon={<UserOutlined />} />

        {value.acStatus && (
          <Tag color={studentPhotoStatusesColor[value.acStatus] || 'default'}>
            {studentPhotosStatuses[value?.acStatus]}
          </Tag>
        )}

        {value?.photo && !value.acStatus && (
          <Tag color={studentPhotoStatusesColor[AcStatuses.Waiting]}>{studentPhotosStatuses[AcStatuses.Waiting]}</Tag>
        )}
      </Flex>
    ),
  },
  {
    title: mainDictionary.searchStaff.label,
    dataIndex: 'fullName',
    key: 'fullName',
    align: 'center',
    render: (value, record) => getFullName<StaffData>(record),
  },
  {
    title: mainDictionary.iRole,
    dataIndex: 'role',
    key: 'role',
    align: 'center',
    render: (value) => <span>{value?.name ? value?.name : '-'}</span>,
  },
  {
    title: mainDictionary.branches,
    align: 'center',
    render: (value) => (
      <div>
        {value?.offices?.length === 1 ? (
          <h5>{value?.offices?.[0]?.name}</h5>
        ) : (
          value?.offices?.map((item: HhOffice) => <p key={item.id}>{item?.name},</p>)
        )}
      </div>
    ),
  },
  {
    title: mainDictionary.workStatus,
    dataIndex: 'workStatus',
    key: 'workStatus',
    align: 'center',
    render: (value, record) => <StaffWorkStatusTab staff={record} />,
  },
  {
    title: mainDictionary.status,
    dataIndex: 'isActive',
    key: 'isActive',
    align: 'center',
    render: (value, record) => <StaffStatus staff={record} />,
  },
  {
    title: mainDictionary.createdAt,
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (value) => formatDate(value),
  },
  {
    title: mainDictionary.action,
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    render: (value, record) => <StaffAction staff={record} />,
  },
]

export const StaffWorkStatus: SelectProps['options'] = [
  {
    label: mainDictionary.allTeachers,
    value: '',
  },
  {
    label: mainDictionary.workingTeachers,
    value: StaffsWorkStatus.Working,
  },
  {
    label: mainDictionary.vacantTeachers,
    value: StaffsWorkStatus.NotWorking,
  },
]
export const StaffEditWorkStatus: SelectProps['options'] = [
  {
    label: mainDictionary.workingTeachers,
    value: StaffsWorkStatus.Working,
  },
  {
    label: mainDictionary.vacantTeachers,
    value: StaffsWorkStatus.NotWorking,
  },
]

export const StaffsStatusfilter: SelectProps['options'] = [
  {
    label: mainDictionary.filterRole.defaultValue,
    value: null,
  },
  {
    label: mainDictionary.active,
    value: StaffWorkStatusType.Active,
  },
  {
    label: mainDictionary.disActive,
    value: StaffWorkStatusType.Inactive,
  },
]

export const genderOption: SelectProps['options'] = [
  {
    label: mainDictionary.female,
    value: GenderTypes.FEMALE,
  },
  {
    label: mainDictionary.male,
    value: GenderTypes.MALE,
  },
]

export const StaffWorkStatusText = [mainDictionary.workingTeachers, mainDictionary.vacantTeachers]

export const userStaffBreadcumbs: IBreadcrumb[] = [{ label: 'Foydalanuvchilar' }, { label: 'Xodimlar' }]

export const userStaffLinkBreadcumbs: IBreadcrumb[] = [
  { label: 'Foydalanuvchilar' },
  { label: 'Xodimlar', link: ROUTES.usersStaff },
]

export const allOption = {
  label: mainDictionary.allOfThem,
  value: null,
}

export const SUPER_ADMIN = 'superadmin'
