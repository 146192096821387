export const teachersDictionary = {
  fullnamePlaceholder: 'Qidirish',
  hollihopStatus: 'HolliHop status',
  allTeachers: 'Barchasi',
  workingTeachers: 'Ishlayabdi',
  vacantTeachers: "Bo'shagan",
  rating: 'Reyting',
  createdAt: "Qo'shilgan sanasi",
  profileGenerated: 'Profile generated',
  searchErrorStudent: "Bunday o'qituvchi topilmadi",
  back: 'Qaytish',
  block: 'Bloklash',
  activated: 'Activlashtirish',
  blockStaffConfirm: 'Siz ushbu foydalanuvchini bloklamoqchimisiz?',
  unBlockStaffConfirm: 'Siz ushbu foydalanuvchini blockdan chiqarmoqchimisiz',
  formRequiredMessage: "Maydonni to'ldiring",
  formRequiredWhiteSpace: "Sababi bo'sh bo'lmasligi kerak",
  enteredLogin: 'Login kiriting',
  unblockNotificationMessage: 'Blockdan chiqarildi',
  resetPassword: 'Parolni tiklash',
  resetPasswordConfirm: 'Siz parolni o’zgartirmoqchimisiz?',
  resetPasswordSuccess: "ga o'zgartirildi",
  recordActive: 'Faol',
  recordNotActive: 'Faol emas',
  recordNotPassword: "Profili yo'q",
  profileHave: 'Faol',
  blockNotificationMessage: 'Blocklandi',
  unBlockNotificationMessage: 'Blockdan chiqarildi',
  generateProfile: 'Profilingiz yangilandi!',
  selectRoles: 'Rolini tanlang',
  orderBy: 'Orderlash',
  orderByNew: 'Yangi',
  orderByOld: 'Eski',
  iRole: 'Roli',
  gender: 'Jinsi',
  areYouSureToDelete: 'Rostdan ham o`chirmoqchimisiz?',
  startedTimeOfCalling: 'Qo`ngiroq qilish boshlangan vaqt',
}
