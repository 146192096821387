import {
  GetAllNotificationsParams,
  GetNotificationParams,
  NotificationBody,
  ReadMyNotifications,
  TemplateBody,
  TemplateEditBody,
  TemplateRequest,
} from '@/stores/notifications'
import { NotificationSettingsRequest, NotificationSettingsResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse, ISuccessResponse } from '../types'
import { NotificationsEndpoints } from './endpoints'
import { GetAllMyNotificationRes, Notification, NotificationRes, Template, TemplateRes } from './type'
const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}${NotificationsEndpoints.Notifications}`,
}

class NotificationsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getNotifications = (params: GetNotificationParams): Promise<IResponse<NotificationRes>> =>
    this.get(NotificationsEndpoints.GetAllForAdmin, { params })

  addNotification = (params: NotificationBody): Promise<IResponse<Notification>> =>
    this.post(NotificationsEndpoints.Create, params)

  deleteNotification = (params: number): Promise<IResponse<Notification>> =>
    this.delete(`${NotificationsEndpoints.Delete}/${params}`)

  getSingleNotification = (params: number): Promise<IResponse<Notification>> =>
    this.get(`${NotificationsEndpoints.GetForAdmin}/${params}`)

  getAcademicNotification = (params: number): Promise<IResponse<Notification>> => this.get(`/${params}`)

  getTemplates = (params: TemplateRequest): Promise<IResponse<TemplateRes>> =>
    this.get(`${NotificationsEndpoints.TemplatesGetAll}`, { params })

  addTemplate = (params: TemplateBody): Promise<IResponse<Template>> =>
    this.post(`${NotificationsEndpoints.TemplatesCreate}`, params)

  getSingleTemplate = (params: number): Promise<IResponse<Template>> =>
    this.get(`${NotificationsEndpoints.Templates}/${params}`)

  editTemplate = (params: TemplateEditBody): Promise<IResponse<Template>> =>
    this.put(`${NotificationsEndpoints.Templates}/${NotificationsEndpoints.Update}/${params.id}`, params.info)

  deleteTemplate = (params: number): Promise<IResponse<Template>> =>
    this.delete(`${NotificationsEndpoints.Templates}${NotificationsEndpoints.Delete}/${params}`)

  getMyNotifications = (params: GetAllNotificationsParams): Promise<IResponse<GetAllMyNotificationRes>> =>
    this.get(`${NotificationsEndpoints.GetAll}`, { params })

  readNotification = (params?: number, data?: ReadMyNotifications): Promise<IResponse<GetAllMyNotificationRes>> =>
    this.patch(`/${params}${NotificationsEndpoints.Read}`, data)

  getNotificationSettings = (): Promise<IResponse<NotificationSettingsResponse>> =>
    this.get(`${NotificationsEndpoints.Settings}${NotificationsEndpoints.All}`)

  updateNotificationSettings = (params: NotificationSettingsRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${NotificationsEndpoints.Settings}`, params)
}

export const notificationsApi = new NotificationsApi(config)
