import { lazy } from 'react'
import { handleCatchChunkError } from '@/utils'

export const GamifactonGroupActions = lazy(() =>
  import('./GroupActions').then(({ GroupActions }) => ({ default: GroupActions })).catch(handleCatchChunkError),
)

export const Statistics = lazy(() =>
  import('./Statistics').then(({ Statistics }) => ({ default: Statistics })).catch(handleCatchChunkError),
)

export const GamificationSingleStudent = lazy(() =>
  import('./SingleStudent').then(({ SingleStudent }) => ({ default: SingleStudent })).catch(handleCatchChunkError),
)

export const GamificationGroupActionSingle = lazy(() =>
  import('./GroupActions/ByGroup/ByGroupSingle')
    .then(({ ByGroupSingle }) => ({ default: ByGroupSingle }))
    .catch(handleCatchChunkError),
)

export const PassExamDeed = lazy(() =>
  import('./Deeds/PassExamDeed').then(({ PassExamDeed }) => ({ default: PassExamDeed })).catch(handleCatchChunkError),
)

export const GamificationByActionSingle = lazy(() =>
  import('./GroupActions/ByAction/ByActionSingle')
    .then(({ ByActionSingle }) => ({ default: ByActionSingle }))
    .catch(handleCatchChunkError),
)

export const GamificationSingleLevel = lazy(() =>
  import('./Levels/SingleLevel').then(({ SingleLevel }) => ({ default: SingleLevel })).catch(handleCatchChunkError),
)

export const Deeds = lazy(() =>
  import('./Deeds').then(({ Deeds }) => ({ default: Deeds })).catch(handleCatchChunkError),
)

export const DeedSingle = lazy(() =>
  import('./Deeds/SingleDeed').then(({ SingleDeed }) => ({ default: SingleDeed })).catch(handleCatchChunkError),
)

export const ExamHomeworkDeed = lazy(() =>
  import('./Deeds/ExamHomeworkDeed')
    .then(({ ExamHomeworkDeed }) => ({ default: ExamHomeworkDeed }))
    .catch(handleCatchChunkError),
)

export const CameDeed = lazy(() =>
  import('./Deeds/CameDeed').then(({ CameDeed }) => ({ default: CameDeed })).catch(handleCatchChunkError),
)

export const SingleStudent = lazy(() =>
  import('./SingleStudent').then(({ SingleStudent }) => ({ default: SingleStudent })).catch(handleCatchChunkError),
)

export const StudentStatisticsMore = lazy(() =>
  import('@/pages/StudentStatisticsMore')
    .then(({ StudentStatisticsMore }) => ({ default: StudentStatisticsMore }))
    .catch(handleCatchChunkError),
)

export const HomeworkDeed = lazy(() =>
  import('./Deeds/ExamHomeworkDeed/HomeworkDeed')
    .then(({ HomeworkDeed }) => ({ default: HomeworkDeed }))
    .catch(handleCatchChunkError),
)

export const TheBestOnes = lazy(() =>
  import('./TheBestOnes').then(({ TheBestOnes }) => ({ default: TheBestOnes })).catch(handleCatchChunkError),
)

export const MakeTheBestOne = lazy(() =>
  import('./TheBestOnes/MakeTheBestOne')
    .then(({ MakeTheBestOne }) => ({ default: MakeTheBestOne }))
    .catch(handleCatchChunkError),
)
