import { makeAutoObservable } from 'mobx'
import { assistantsApi } from '@/api'
import { IAssistantProfileRequest, IAssistantRequest, IGetAssistantRequest } from '@/api/assistants'
import { IGetAttendanceRequest } from '@/api/teachers'
import { StaffWorkStatus } from '@/api/users'
import { IAssistant, IAssistantGroup, IAttendance, IGroup, KeyType } from './types'

class AssistantsStore {
  assistants: IAssistant[] = []
  assistantGroups: IAssistantGroup[] = []
  assistantAttendances: IAttendance[] = []
  groupName = ''
  rating = 0
  loginValue = ''
  lessonTime = ''
  isActive = true
  isProfileModalOpen = false
  isAssistantsModalOpen = false
  searchValue = ''
  searchInputValue = ''
  selectedAssistantId = 0
  keys = [KeyType.FirstName, KeyType.LastName]
  total = 0
  perPage = 10
  page = 1
  workStatus = StaffWorkStatus.Working

  constructor() {
    makeAutoObservable(this)
  }

  getAssistants = (params: IGetAssistantRequest) =>
    assistantsApi.getAssistants(params).then((res) => {
      this.setAssistants(res.data.assistants)
      this.setTotal(res.data.total)

      return res
    })

  getAssistantGroups = (params: number) =>
    assistantsApi.getAssistantGroups(params).then((res) => {
      this.setAssistantGroups(res.data.staffGroups)

      return res
    })

  getAssistantAttendances = (params: IGetAttendanceRequest) =>
    assistantsApi.getAssistantAttendances(params).then((res) => {
      this.setGroupName(res.data.assistantAttendancesData.group.name)
      this.setRating(res.data.assistantAttendancesData.rating)
      this.setAssistantAttendances(res.data.assistantAttendancesData.attendances)
      this.setTotal(res.data.total)
      this.setLessonTime(res.data.assistantAttendancesData.group)

      return res
    })

  generateAssistantProfile = (params: IAssistantProfileRequest) =>
    assistantsApi.generateAssistantProfile(params).then((res) => res)

  updateAssistant = (params: IAssistantRequest) => assistantsApi.updateAssistant(params).then((res) => res)

  resetPassword = (params: IAssistantRequest) => assistantsApi.resetPassword(params).then((res) => res)

  setIsProfileModalOpen = (isModalOpen: boolean) => {
    this.isProfileModalOpen = isModalOpen
  }

  setIsAssistantsModalOpen = (isModalOpen: boolean) => {
    this.isAssistantsModalOpen = isModalOpen
  }

  setSelectedAssistantId = (id: number) => {
    this.selectedAssistantId = id
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setSearchInputValue = (value: string) => {
    this.searchInputValue = value
  }

  setLoginValue = (value: string) => {
    this.loginValue = value
  }

  setRating = (rating: number) => {
    this.rating = rating
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setLessonTime = (group: IGroup) => {
    this.lessonTime = `${group.beginTime} - ${group.endTime}`
  }

  setGroupName = (name: string) => {
    this.groupName = name
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setAssistants = (assistants: IAssistant[]) => {
    this.assistants = assistants
  }

  setAssistantGroups = (groups: IAssistantGroup[]) => {
    this.assistantGroups = groups
  }

  setAssistantAttendances = (attendances: IAttendance[]) => {
    this.assistantAttendances = attendances
  }

  setWorkStatus = (status: StaffWorkStatus) => {
    this.workStatus = status
  }

  setIsActive = (active: boolean) => {
    this.isActive = active
  }

  reset = () => {
    this.assistants = []
    this.assistantGroups = []
    this.assistantAttendances = []
    this.groupName = ''
    this.rating = 0
    this.loginValue = ''
    this.lessonTime = ''
    this.isActive = true
    this.isProfileModalOpen = false
    this.isAssistantsModalOpen = false
    this.searchValue = ''
    this.searchInputValue = ''
    this.selectedAssistantId = 0
    this.total = 0
    this.perPage = 10
    this.page = 1
    this.workStatus = StaffWorkStatus.Working
  }
}

export const assistantsStore = new AssistantsStore()
