import { Permission, StructuredPermissions } from './types'

export const normalizedPermissions = (permissions: Permission[]): Permission[] =>
  permissions.map(({ children, ...rest }) => ({
    ...rest,
    ...(children && children.length ? { children: normalizedPermissions(children) } : {}),
  }))

export const getStructuredPermissions = (permissions: Permission[]): StructuredPermissions =>
  permissions.reduce(
    (previousValue, { name, children, ...rest }) => ({
      ...previousValue,
      [name]: rest,
      ...(children ? getStructuredPermissions(children) : {}),
    }),
    {},
  )
