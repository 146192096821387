import { lazy } from 'react'
import { handleCatchChunkError } from '@/utils'

export const MyGroups = lazy(() =>
  import('./MyGroups').then(({ MyGroups }) => ({ default: MyGroups })).catch(handleCatchChunkError),
)

export const MyGroupsSingle = lazy(() =>
  import('./MyGroups/MyGroupSingle')
    .then(({ MyGroupSingleSingle }) => ({ default: MyGroupSingleSingle }))
    .catch(handleCatchChunkError),
)

export const Groups = lazy(() =>
  import('./Groups').then(({ Groups }) => ({ default: Groups })).catch(handleCatchChunkError),
)

export const GroupsSingle = lazy(() =>
  import('./GroupSingle').then(({ GroupsSingle }) => ({ default: GroupsSingle })).catch(handleCatchChunkError),
)

export const ExamMake = lazy(() =>
  import('./GroupSingle/Exams/MakeExam').then(({ MakeExam }) => ({ default: MakeExam })).catch(handleCatchChunkError),
)

export const ExamSingle = lazy(() =>
  import('./GroupSingle/Exams/ExamSingle')
    .then(({ ExamSingle }) => ({ default: ExamSingle }))
    .catch(handleCatchChunkError),
)

export const AddExam = lazy(() =>
  import('./GroupSingle/Exams/Publish').then(({ Publish }) => ({ default: Publish })).catch(handleCatchChunkError),
)

export const ExamResults = lazy(() =>
  import('./GroupSingle/Exams/Result').then(({ Result }) => ({ default: Result })).catch(handleCatchChunkError),
)

export const HomeworkMake = lazy(() =>
  import('./GroupSingle/HomeWorks/HomeworkMake')
    .then(({ HomeworkMake }) => ({ default: HomeworkMake }))
    .catch(handleCatchChunkError),
)

export const HomeworkStatistics = lazy(() =>
  import('./GroupSingle/HomeWorks/HomeworkStatistics')
    .then(({ HomeworkStatistics }) => ({ default: HomeworkStatistics }))
    .catch(handleCatchChunkError),
)

export const HomeworkStatisticsSingle = lazy(() =>
  import('./GroupSingle/HomeWorks/HomeworkStatistics/HomeworkStatisticsSingle')
    .then(({ HomeworkStatisticsSingle }) => ({ default: HomeworkStatisticsSingle }))
    .catch(handleCatchChunkError),
)

export const HomeworkSingle = lazy(() =>
  import('./GroupSingle/HomeWorks/HomeworkSingle')
    .then(({ HomeworkSingle }) => ({ default: HomeworkSingle }))
    .catch(handleCatchChunkError),
)

export const HomeworkView = lazy(() =>
  import('./GroupSingle/HomeWorks/HomeworkSingle/Homeworkview')
    .then(({ HomeworkView }) => ({ default: HomeworkView }))
    .catch(handleCatchChunkError),
)

export const PublicResult = lazy(() =>
  import('./GroupSingle/Exams/PublicResult')
    .then(({ PublicResult }) => ({ default: PublicResult }))
    .catch(handleCatchChunkError),
)

export const AttendanceEdit = lazy(() =>
  import('./Attendance/AttendanceEdit')
    .then(({ AttendanceEdit }) => ({ default: AttendanceEdit }))
    .catch(handleCatchChunkError),
)
