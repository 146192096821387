import { GetAllGroupsByStudentRes, IStudents } from '@/api/students'

export interface IStudentData {
  createdAt: number
  firstName: string
  id: number
  lastName: string
  status: string | null
  hasSmsHistory?: boolean
}

export interface IStudentProps {
  student: IStudents
}

export interface IStudentIndex {
  index: number
}

export interface ICardType {
  title?: string
  handleClick?: () => void
}

export interface ChangeLoginProps {
  login: string
  studentId: number
}

export interface ChangePasswordProps {
  studentId: number
}

export interface ResetPasswordProps {
  password: string
}

export enum StudentStatus {
  Active = 1,
  InActive,
}

export interface IGroupSelectOption {
  label: string
  value: number
  group: GetAllGroupsByStudentRes
  disabled: boolean
}

export const enum AcStatuses {
  Waiting = 1,
  Failed,
  Ready,
  ImgNotUpload,
}

export const enum ControlActionTypes {
  ACTIVE = 'Active',
  FINISH_COURSE = 'Bitirgan',
  WITHOUT_COURSE = 'Darssiz',
  FINISHED_30_DAY_PRIVILEGE = 'Bitirgan 30 kunlik',
  LEFT_COURSE = 'Tashlab ketgan',
  NOT_CALCULATE_STATISTIC = 'Statistika hisoblanmaydigan',
}
