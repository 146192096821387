import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import {
  CameDeed,
  Deeds,
  DeedSingle,
  ExamHomeworkDeed,
  GamifactonGroupActions,
  GamificationByActionSingle,
  GamificationGroupActionSingle,
  GamificationSingleLevel,
  GamificationSingleStudent,
  HomeworkDeed,
  MakeTheBestOne,
  PassExamDeed,
  SingleStudent,
  Statistics,
  StudentStatisticsMore,
  TheBestOnes,
} from './lazy'

export const gamificationRouters = [
  {
    path: ROUTES.gamification,
    children: [
      {
        path: ROUTES.gamificationConnect,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <GamifactonGroupActions />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationTheBestOnes,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamification}>
            <TheBestOnes />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationTheBestOnesAdd,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamification}>
            <MakeTheBestOne />
          </AvailableComponent>
        ),
      },

      {
        path: ROUTES.gamificationStatistics,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamification}>
            <Statistics />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationStudentStatistics,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamification}>
            <GamificationSingleStudent />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationConnectGroupSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <GamificationGroupActionSingle />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationActionByPassExamSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <PassExamDeed />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationConnectActionSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <GamificationByActionSingle />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationLevels,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationLevels}>
            <GamificationSingleLevel />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationActions,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <Deeds />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationActionSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <DeedSingle />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationHomeworkExamSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <ExamHomeworkDeed />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.gamificationCameSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <CameDeed />
          </AvailableComponent>
        ),
      },
    ],
  },
  {
    path: ROUTES.singleStudentResult,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <SingleStudent />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.gamificationStudentStatisticsMore,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <StudentStatisticsMore />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.gamificationHomeworkHomeworkSingleAdd,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
        <HomeworkDeed />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.gamificationHomeworkHomeworkSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
        <HomeworkDeed />
      </AvailableComponent>
    ),
  },
]
