import { HomeworkAssignmentTypes, HomeworkDbLessonStatuses } from '@/api/groups/types'
import { Attachment } from '@/api/settings'
import { GroupStatus, HomeworkCheckingStatus } from '@/api/study-department'
import { StaffWorkStatus } from '@/api/users/types'
import { HomeworkStatistics } from '@/pages/Groups/types'
import { ICourseFormValues, ICourseMonthFormValues, IDraggedItem } from '@/pages/StudyDepartment/types'
import { Orders } from '../gamification'
import { StaffWorkStatusType } from '@/pages/Users/Academic/types'

export interface IGetGroupRequest {
  page?: number
  perPage?: number
  group?: string
  categoryId?: number
  teacherId?: number
  learningTypeId?: number
  startedDateFrom?: string
  startedDateTo?: string
  status?: GroupStatus
  courseId?: number
  endedDateFrom?: string
  endedDateTo?: string
  officeId?: number
}

export interface IGetGroupStudentsRequest {
  groupId?: number
  sortBy?: HomeworkStatistics
  startDate?: string | null
  endDate?: string | null
  status?: HomeworkStatistics | HomeworkCheckingStatus
  page?: number
  perPage?: number
  byDate?: boolean
}

export interface IGetHomeworkStudents {
  groupId: number
}

export interface IGetFilterObjects {
  categoryId: number
}

export interface ISelectOption {
  label: string
  value: number | null
}

export interface IGetCourseRequest {
  page?: number
  perPage: number
  categoryIds?: number[]
}

export interface IEditCourse {
  id: number
  data: ICourseFormValues
}

export interface IEditCourseByMonth {
  id: number
  data: ICourseMonthFormValues
}

export interface IGetCourseCurriculums {
  page?: number
  perPage?: number
  courseId: number
}

export interface IAddCurriculum {
  courseId: number
  name: string
}

export interface IChangePagination {
  page: number
  perPage: number
}

export interface IChangeTopicPositionRequest {
  updated: IDraggedItem[]
}

export type TGetGroupActionsFilterObjects = {
  page: number
  perPage: number
  course?: string | number
  academic?: string | number
}

export type GetCourseChaptersReq = {
  page: number
  perPage: number
  courseIds?: number[]
  key?: string
  curriculumId?: number
  systemCourseId?: number
  homeworkDbLessonStatus?: HomeworkDbLessonStatuses
}

export type AddChapterReq = {
  title: string
  courseId?: number
}

export type EditChapterReq = {
  id: number
  data: {
    title: string
  }
}

export const enum HomeworkDbDifficultyLevels {
  Low = 1,
  Medium,
  High,
}

export type GetChapterHomeworkReq = {
  page: number
  perPage: number
  homeworkDbLessonId?: number
  difficultlyLevel?: HomeworkDbDifficultyLevels
}

export type HomeworkReq = {
  desc: string
  difficultlyLevel: HomeworkDbDifficultyLevels
  attachments?: Attachment[]
}

export type AddChapterHomeworkReq = {
  homeworkDbLessonId: number
  homeworkDbData: HomeworkReq[]
}

export type EditChapterHomeworkReq = {
  id: number
  data: HomeworkReq
}

export type SetLessonReq = {
  highLevelCount: number
  mediumLevelCount: number
  lowLevelCount: number
  curriculumId: number
  deadline: number
  homeworkDbLessonIds: number[]
  courseId: number[]
  assignmentType?: HomeworkAssignmentTypes
}

export type GetHomeworkDbCoursesReq = {
  page: number
  perPage: number
}

export type AddCourseParams = {
  name: string
}

export type EditCourseParams = {
  id: number
  data: AddCourseParams
}

export type GetTeachersStatisTicsReq = {
  page: number
  perPage: number
  keys?: string[]
  value?: string
  sortBy?: string
  orderBy?: Orders
  staffSystemStatus?: StaffWorkStatusType
  workStatus?: StaffWorkStatus
  roleId?: number
  categoryId?: number
  courseId?: number
  officeIds?: number[]
  from?: string | null
  to?: string | null
}

export type EditDraftParams = {
  id: number
  data: {
    title: string
    homeworkDbLessonStatus: HomeworkDbLessonStatuses
    difficultlyLevel: HomeworkDbDifficultyLevels
    courseId: number
  }
}

export type MoveToCompletionParams = {
  id: number
  data: {
    difficultlyLevel: HomeworkDbDifficultyLevels
    homeworkDbLessonId: number
  }
}

type HomeworkTypeCount = {
  highLevelCount: number
  mediumLevelCount: number
  lowLevelCount: number
  homeworkDbLessonId: number[]
}

export type SwitchHomeworkParams = {
  curriculumId: number
  deadline: number
  assignmentType: HomeworkAssignmentTypes
  curriculumHomeworkData: HomeworkTypeCount[]
}
