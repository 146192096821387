import { UniqueIdentifier } from '@dnd-kit/core'

export interface ICourseFormValues {
  disciplineId: number
  name: string
  photo?: string
}

export interface ICourseMonthFormValues {
  lessonsPerMonth: number
  roleIds: number[]
}

export interface IAddTopicForm {
  name: string
}

export interface IDraggedItem {
  to: number
  id: number | UniqueIdentifier
}

export enum CourseTabs {
  LessonMonth = 'lesson-month',
  LessonPlan = 'lesson-plan',
}
