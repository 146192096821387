import { getToken } from '@firebase/messaging'
import { VAPID_KEY } from '@/constants'
import { messaging } from './firebaseConfig'

export async function requestPermission() {
  try {
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      return getToken(messaging, {
        vapidKey: VAPID_KEY,
      })
    }
  } catch (err: any) {}
}
