import { IAcademicData } from '@/api/academic'

export interface AcademicIndexProps {
  index: number
}

export interface AcademicProps {
  academic: IAcademicData
}

export type AcademicPhotoProps = {
  value: string
}

export const enum StaffWorkStatusType {
  Active = 1,
  Inactive,
  NoProfile,
}
