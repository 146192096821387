export type GetCategoriesParams = {
  page: number
  perPage: number
}

export type CategoryAddParams = {
  name: string
}

export type CategoryEditParams = {
  id: number
  data: CategoryAddParams
}

export enum ProductStatuses {
  OnSale = 'available',
  DisContinued = 'discontinued',
}

export type GetProductsParams = {
  page?: number
  perPage?: number
  status?: ProductStatuses | string
  categoryId?: number
  minPrice?: number | null
  maxPrice?: number | null
  name?: string
  id?: string
}

export type ChangeStatusParams = {
  id: number
  data: {
    status: ProductStatuses
  }
}

export type MakeProductParams = {
  name: string
  description: string
  price: number
  quantity: number
  categoryId: number
  photo?: string
}

export type EditProductParams = {
  data: MakeProductParams
  id: string
}

export enum TransactionStates {
  Pending = 'pending',
  Canceled = 'canceled',
  Delivered = 'delivered',
}

export type GetTransactionsParams = {
  page?: number
  perPage?: number
  categoryId?: number
  minAmount?: number | null
  maxAmount?: number | null
  itemName?: string
  state?: TransactionStates | string
  search?: string
}

export type ChangeTransactionStatusParams = {
  id: number
  info: {
    state: TransactionStates | string
    comment?: string
  }
}
