import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import {
  AddExam,
  AttendanceEdit,
  ExamMake,
  ExamResults,
  ExamSingle,
  Groups,
  GroupsSingle,
  HomeworkMake,
  HomeworkSingle,
  HomeworkStatistics,
  HomeworkStatisticsSingle,
  HomeworkView,
  MyGroups,
  MyGroupsSingle,
  PublicResult,
} from './lazy'

export const groupRouter = [
  {
    path: ROUTES.myGroups,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroups}>
        <MyGroups />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myGroupsSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsAttendance}>
        <MyGroupsSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.groups,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <Groups />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.groupSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <GroupsSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.addExam,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myGroupAddExam,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsExam}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.editExam,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myGroupEditExam,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <ExamSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myGroupExamSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsExam}>
        <ExamSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examAnnounce,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <AddExam />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myExamAnnounce,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsExam}>
        <AddExam />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examResult,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <ExamResults />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myExamResult,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsExam}>
        <ExamResults />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkAdd,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
        <HomeworkMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkStatistics,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
        <HomeworkStatistics />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkStatisticsSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
        <HomeworkStatisticsSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkStatistics,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsAllHomeworks}>
        <HomeworkStatistics />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkStatisticsSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsAllHomeworks}>
        <HomeworkStatisticsSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkAdd,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsCheckHomeworks}>
        <HomeworkMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkEdit,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
        <HomeworkMake isEdit />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkEdit,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsCheckHomeworks}>
        <HomeworkMake isEdit />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
        <HomeworkSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.myGroupsAllHomeworks}>
        <HomeworkSingle isAcademic />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examView,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsCheckHomeworks}>
        <HomeworkView isExam />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myExamView,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsCheckHomeworks}>
        <HomeworkView isExam />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkSingleView,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsCheckHomeworks}>
        <HomeworkView />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.myHomeworkSingleView,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsCheckHomeworks}>
        <HomeworkView isAcademic />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.publicResult,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsExam}>
        <PublicResult />,
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.editAttendance,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroupsAttendance}>
        <AttendanceEdit />
      </AvailableComponent>
    ),
  },
]
