import { observer } from 'mobx-react'
import classnamesBind from 'classnames/bind'
import { ContentCenter, Icons } from '@/components'
import { BackgroundAnimate } from './BackgroundAnimate'
import { Form } from './Form'

import styles from './login.module.scss'

const cn = classnamesBind.bind(styles)

export const Login = observer(() => (
  <ContentCenter className={cn('login')}>
    <BackgroundAnimate count={20} />

    <div className={cn('login__wrapper')}>
      <div className={cn('login__title')}>
        <span className={cn('login__title-logo')}>
          <Icons.Logo />
        </span>
        <span className={cn('login__title-text')}>Admin paneli</span>
      </div>

      <Form />
    </div>
  </ContentCenter>
))
