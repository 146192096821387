import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames/bind'

import styles from './button.module.scss'

const cn = classNames.bind(styles)

type Props = {
  onClick?: () => void
  children?: ReactNode
}

export const Button = forwardRef<HTMLButtonElement, Props>(({ children, onClick }: Props, ref) => (
  <button className={cn(`button`)} onClick={onClick} ref={ref}>
    {children}
  </button>
))
