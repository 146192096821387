import { Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { ENDPOINTS } from './constants'
import { Permission } from './types'

class Api extends Instance {
  getMinePermissions = (): Promise<IResponse<Permission[]>> => this.get(ENDPOINTS.permissionObjectsMine)
}

export const api = new Api()
