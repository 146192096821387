import { lazy } from 'react'
import { handleCatchChunkError } from '@/utils'

export const Categories = lazy(() =>
  import('./Categories').then(({ Categories }) => ({ default: Categories })).catch(handleCatchChunkError),
)

export const AccessoriesAdd = lazy(() =>
  import('./Accessories/OnSale/MakeProduct')
    .then(({ MakeProduct }) => ({ default: MakeProduct }))
    .catch(handleCatchChunkError),
)

export const Accessory = lazy(() =>
  import('./Accessories/Accessory').then(({ Accessory }) => ({ default: Accessory })).catch(handleCatchChunkError),
)

export const MarketTransactions = lazy(() =>
  import('./Transactions').then(({ Transactions }) => ({ default: Transactions })).catch(handleCatchChunkError),
)

export const Accessories = lazy(() =>
  import('./Accessories').then(({ Accessories }) => ({ default: Accessories })).catch(handleCatchChunkError),
)
