import { useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Flex, Modal, notification, Progress } from 'antd'
import { AxiosProgressEvent } from 'axios'
import { IGroupStatus } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { addSuccessNotification } from '@/modules/notifications'
import { RoleTypes } from '@/pages/Controls'
import { SynchronizeAvailablePages, useStores } from '@/stores'
import { multiplier, percentCounter } from '@/utils/percentCounter'

import './synch-modal.module.scss'

export const SynchModal = observer(() => {
  const { appStore, groupsStore, studentStore, academicStore } = useStores()
  const [percentage, setPercentage] = useState(0)
  const [isAvailable, setIsAvailable] = useState(true)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const { page, perPage, keys, value, orderBy, categoryId, statusId } = studentStore
  const {
    perPage: academicPerpage,
    page: academicPage,
    keys: academicKeys,
    workStatus,
    isActive,
    value: academicValue,
    orderBy: academicOrderBy,
    roleId,
    officeIds,
    staffSystemStatus,
  } = academicStore

  const handleSynchInterval = () => {
    setInterval(
      () =>
        setPercentage((prev: number): number => {
          setIsAvailable(false)
          clearInterval(intervalRef.current)
          if (prev >= 100) {
            appStore.setSynchModal(false)
            addSuccessNotification(mainDictionary.synchSuccess)
            setPercentage(0)

            return 0
          } else {
            return multiplier(percentage, prev, 20)
          }
        }),
      1000,
    )
  }

  const handleCloseWhenError = (err: any) => {
    if (err?.response?.data?.error?.errId === 245) {
      appStore.setSynchModal(false)
      notification.error({ message: '', description: mainDictionary.doubleSynch, duration: 10 })
    }
  }

  useEffect(() => {
    window.onbeforeunload = () => true

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const options = useMemo(
    () => ({
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent

        const percent = percentCounter(total!, loaded)

        setPercentage(percent)
      },
    }),
    [percentage],
  )

  useEffect(() => {
    if (isAvailable) {
      intervalRef.current = setInterval(
        () =>
          setPercentage((prev): number => {
            if (prev >= 100) {
              appStore.setSynchModal(false)
              addSuccessNotification(mainDictionary.synchSuccess)
              setPercentage(0)

              return 0
            } else if (prev <= 75) {
              return multiplier(percentage, prev, 2)
            } else {
              return prev
            }
          }),
        6000,
      )
    }
  }, [])

  const getTeachers = () => {
    appStore
      .getTeacherSynch(options)
      .then((res) => {
        if (res.success) {
          handleSynchInterval()

          const info = {
            perPage: academicPerpage,
            page: academicPage,
            keys: academicKeys,
            workStatus,
            isActive,
            value: academicValue,
            orderBy: academicOrderBy,
            roleId,
            officeIds: officeIds!,
            staffSystemStatus,
          }

          academicStore.getAcademics(info)
        }
      })
      .catch(handleCloseWhenError)
  }

  const getGroups = () => {
    appStore
      .getGroupsSynch(options)
      .then((res) => {
        if (res.success && appStore.synchronization) {
          handleSynchInterval()
          groupsStore.setSynchronize(true)
          if (appStore?.synchronization.isAcademic || appStore.profile?.role?.type === RoleTypes.Academic) {
            groupsStore.getAcademicGroups({
              id: appStore.profile?.id,
              perPage: 10,
              status: String(IGroupStatus.Active),
            })

            return
          }
          groupsStore.getGroups({ perPage: 10, status: String(IGroupStatus.Active) })
        }
      })
      .catch(handleCloseWhenError)
      .finally(() => groupsStore.setSynchronize(false))
  }

  const getStudents = () => {
    appStore
      .getStudentSynch(options)
      .then(() => {
        groupsStore.setSynchronize(true)
        const info = {
          page,
          perPage,
          keys,
          value,
          orderBy,
          categoryId,
          statusId,
        }

        studentStore.getStudents(info)
        handleSynchInterval()
      })
      .catch(handleCloseWhenError)
      .finally(() => groupsStore.setSynchronize(false))
  }

  useEffect(() => {
    if (appStore.synchronization?.synchronizeType === SynchronizeAvailablePages.Teachers) {
      getTeachers()
      appStore.setSynchronization({
        isVisible: true,
        synchronizeType: SynchronizeAvailablePages.Teachers,
      })
    }

    if (appStore.synchronization?.synchronizeType === SynchronizeAvailablePages.Groups) {
      getGroups()
      appStore.setSynchronization({
        isVisible: true,
        synchronizeType: SynchronizeAvailablePages.Groups,
      })
    }

    if (appStore.synchronization?.synchronizeType === SynchronizeAvailablePages.Students) {
      getStudents()
      appStore.setSynchronization({
        isVisible: true,
        synchronizeType: SynchronizeAvailablePages.Students,
      })
    }
  }, [])

  return (
    <Modal open={appStore.synchModal} footer={null} title={mainDictionary.synching}>
      <Flex justify="center">
        <Progress type="circle" percent={percentage} />
      </Flex>
    </Modal>
  )
})
