import { observer } from 'mobx-react'
import { Modal } from 'antd'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'

export const ConfirmSynchModal = observer(() => {
  const { appStore } = useStores()

  const handleCancel = () => {
    appStore.setConfirmSynchModal(false)
  }

  const handleConfirm = () => {
    appStore.setConfirmSynchModal(false)
    appStore.setSynchModal(true)
  }

  return (
    <Modal
      open={appStore.confirmSynchModal}
      title={mainDictionary.confirm}
      onCancel={handleCancel}
      onOk={handleConfirm}
    />
  )
})
