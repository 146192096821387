export const settingsDictionary = {
  auto: 'Avto',
  block: 'Bloklanadi',
  byHand: "Qo'lda",
  conditionInStatistics: 'Statistikalardagi holati',
  confirmDelete:
    'Ushbu status o’chirilsa, bu statusdagi o’quvchilar ham boshqa statusga o’tib qoladi. O’chirishga aminmisiz?',
  fulfillmentType: 'Bajarilish turi',
  groupOver: 'Guruh tugasa',
  finishThemSelves: 'O’zi tugatsa',
  finishGroupAndThemselves: "Guruh ham o'zi ham tugamasa",
  didNotStudy: "Bir kunam o'qimasa",
  debtor: "Qarzdor bo'lsa",
  settingsDeadline: 'Qancha muddat(kun)gacha (agar kiritilmasa, doimiy sifatida qabul qilinadi)',
  notBlocked: 'Bloklanmaydi',
  notCount: 'Hisoblanmaydi',
  privateAccount: 'Shaxsiy kabinet',
  privateAccountBlock: 'Shaxsiy kabinetda bloklanishi',
  countInStatistics: 'Statistikalarda hisoblanishi',
  studentCount: 'Hisoblanadi',
  designation: 'Belgilanishi',
  faceId: 'Face Id',
  settings: 'Sozlamalar',
  stopStudying: "O'qishni to'xtatish",
  studentsStatisticsInGroup: "O'quvchilarning guruhlardagi statuslari",
  studentStatisticsInGroup: "O'quvchilarning guruhlardagi statuslari",
  until: 'Qachongacha',
  permanent: 'Doimiy',
  contracts: 'Shartnomalar',
  addingContract: 'Shartnoma qo‘shish',
  editingContract: 'Shartnoma o‘zgartirish',
  contractDocxRequirement: 'Faqat DOCX formatdagi file yuklay olasiz',
  contractFile: 'Shartnoma fayli',
  downloader: 'Yuklovchi',
  contractHistory: 'Shartnoma Tarixi',
  contract: 'Shartnoma',
  signedPerson: 'Imzolaltgan shaxs',
  signingContract: 'Shartnoma imzolatish',
  signedBySelf: 'O`ziga imzolatish',
  signedByAgent: 'Vakiliga imzolatish',
  passportSeries: 'Passport seriyasi',
  passportNumber: 'Passport raqami',
  givenPerson: 'Kim tomonidan berilgan',
  address: 'Yashash manzili',
  agent: 'Ma`sul shaxs',
  agentPhoneNumber: 'Ma`sul shaxs telefon raqami',
  signing: 'Imzolatish',
  whoIsAgent: 'O`quvchiga kim bo`ladi',
  singed: 'Imzolangan',
  contractWaiting: 'Imzolashni kutyabti',
  successfullySent: 'Muvaffaqiyatli jo`natildi',
  requiredField: 'To`ldirish shart',
  requiredFileUpload: 'File yuklash majburiy',
  self: 'O`ziniki',
}
