import { useEffect } from 'react'
import { notification } from 'antd'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'

export const useDetectBrowserClose = () => {
  const { kinescopeStore, studentStore } = useStores()

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!kinescopeStore.hasUploadingFiles() || !studentStore.isCreatingStudent) {
      return
    }

    notification.error({
      message: mainDictionary.getNotUploadUrlFromKinescope(mainDictionary.notClose),
      placement: 'topRight',
    })

    const confirmationMessage = mainDictionary.notClose

    event.returnValue = confirmationMessage //Gecko + IE

    return confirmationMessage
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
}
