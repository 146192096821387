import mainDictionary from '@/dictionary'
import { IBreadcrumb } from '@/stores/breadcrumb/types'

export const staffInfo = 'staffInfo'

export enum ChangePasswordEnum {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  ConfirmNewPassword = 'confirmNewPassword',
}

export const profileBreadcrumb: IBreadcrumb[] = [{ label: mainDictionary.profile }]
