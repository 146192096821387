import { makeAutoObservable } from 'mobx'
import {
  Contract,
  ContractHistoryRes,
  ContractRes,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  settingsApi,
  StudentStatus,
  StudentStatusesRes,
} from '@/api/settings'
import { addCatchNotification } from '@/modules/notifications'
import { MobileAppVersionReqParams, MobileAppVersionRes } from '@/types'
import {
  ChangeStudentReq,
  ContractFormValues,
  EditContractReq,
  EditStudentReq,
  GetContractHistoryParams,
  GetContractHistoryReq,
  GetContractsAll,
} from './types'

class SettingsStore {
  isUpdateGamificationOffering = false
  isGamificationModal = false
  gamificationOffer: GamificationOfferingResponseTypes | null = null
  editorContent = ''
  studentStatuses: StudentStatusesRes | null = null
  studentStatus: StudentStatus | null = null
  contracts: ContractRes | null = null
  contractsLoading = false
  makeContractModal = false
  selectedContract: Contract | null = null
  contractHistory: ContractHistoryRes | null = null
  contractHistoryLoading = false
  singleContractHistory: Contract | null = null
  contractsFilter: GetContractsAll = {
    page: 1,
    perPage: 10,
  }

  isNewVersionModal = false
  mobileAppVersions: MobileAppVersionRes | null = null
  totalMobileAppVersions = 0
  mobileVersionsPage = 1
  mobileVersionsPerPage = 10
  mobileAppFilterOptions: Partial<MobileAppVersionReqParams> = {
    page: 1,
    perPage: 10,
  }

  contractHistoryFilter: GetContractHistoryParams = {
    page: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setContractHistory = (params: ContractHistoryRes | null) => {
    this.contractHistory = params
  }

  setContractHistoryFilter = (params: GetContractHistoryParams) => {
    this.contractHistoryFilter = params
  }

  setSingleContractHistory = (params: Contract | null) => {
    this.singleContractHistory = params
  }

  setContractHistoryLoading = (value: boolean) => {
    this.contractHistoryLoading = value
  }

  setSelectedContract = (params: Contract | null) => {
    this.selectedContract = params
  }

  setMakeContractModal = (value: boolean) => {
    this.makeContractModal = value
  }

  setContractsFilter = (params: GetContractsAll) => {
    this.contractsFilter = params
  }

  setContractsLoading = (value: boolean) => {
    this.contractsLoading = value
  }

  setContracts = (params: ContractRes | null) => {
    this.contracts = params
  }

  setStudentStatuses = (params: StudentStatusesRes | null) => {
    this.studentStatuses = params
  }

  setStudentStatus = (params: StudentStatus | null) => {
    this.studentStatus = params
  }

  setGamificationOffer = (gamificationOffer: GamificationOfferingResponseTypes | null) => {
    this.gamificationOffer = gamificationOffer
  }

  addContract = (params: ContractFormValues) => settingsApi.addContract(params)

  editContract = (params: EditContractReq) => settingsApi.editContract(params)

  setNewVersionModal = (value: boolean) => {
    this.isNewVersionModal = value
  }

  setMobileAppVersions = (versions: MobileAppVersionRes) => {
    this.mobileAppVersions = versions
  }

  setTotalMobileVersions = (total: number) => {
    this.totalMobileAppVersions = total
  }

  setMobileVersionsPage = (page: number) => {
    this.mobileVersionsPage = page
  }

  setMobileVersionsPerPage = (perPage: number) => {
    this.mobileVersionsPerPage = perPage
  }

  setMobileVersionFilterOptions = (options: Partial<MobileAppVersionReqParams>) => {
    this.mobileAppFilterOptions = options
  }

  getMobileAppVersions = (params: Partial<MobileAppVersionReqParams>) =>
    settingsApi
      .getMobileAppVersions(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setMobileAppVersions(response.data)
          this.setTotalMobileVersions(response.data.total)
        }
      })
      .catch(addCatchNotification)

  changeStudentStatus = (params: ChangeStudentReq) => settingsApi.changeStudentStatus(params)

  getContract = (params: number) =>
    settingsApi
      .getContract(params)
      .then((res) => {
        if (res.success) {
          this.setSingleContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)

  getContractHistory = (params: GetContractHistoryReq) =>
    settingsApi
      .getContractHistory(params)
      .then((res) => {
        if (res.success) {
          this.setContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setContractHistoryLoading(false))

  getStudentStatuses = () =>
    settingsApi
      .getStudentStatuses()
      .then((res) => {
        if (res.success) {
          this.setStudentStatuses(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentStatus = (id: number) =>
    settingsApi
      .getStudentStatus(id)
      .then((res) => {
        if (res.success) {
          this.setStudentStatus(res.data)
        }
      })
      .catch(addCatchNotification)

  getGamificationOffer = () =>
    settingsApi
      .getGamificationOffering()
      .then((res) => {
        if (res?.success) {
          this.setGamificationOffer(res.data)
          this.setEditorContent(res?.data?.body)
        }
      })
      .catch(addCatchNotification)

  updateGamificationOffering = (params: GamificationOfferingParams) =>
    settingsApi
      .updateGamificationOffering(params)
      .then((res) => {
        if (res?.success) {
          this.isUpdateGamificationOffering = res.success
        }
      })
      .catch(addCatchNotification)

  setEditorContent = (param: string) => {
    this.editorContent = param
  }

  editStudentStatus = (params: EditStudentReq) => settingsApi.editStudentStatus(params)

  setUpdateGamificationOffering = (value: boolean) => {
    this.isUpdateGamificationOffering = value
  }

  getContracts = (params: GetContractsAll) =>
    settingsApi
      .getContracts(params)
      .then((res) => {
        if (res.success) {
          this.setContracts(res.data)
        }
      })
      .catch((err: Error | unknown) => {
        addCatchNotification(err)
      })
      .finally(() => this.setContractsLoading(false))

  reset = () => {
    this.isUpdateGamificationOffering = false
    this.isGamificationModal = false
    this.editorContent = ''
    this.isNewVersionModal = false
    this.totalMobileAppVersions = 0
    this.mobileVersionsPage = 1
    this.mobileVersionsPerPage = 10
  }
}

export const settingsStore = new SettingsStore()
