export enum GamificationEndpoints {
  Actions = '/actions',
  ByAction = '/by-action',
  ActionLevels = '/action-levels',
  Methods = '/methods',
  Categories = '/categories',
  LevelsByCategory = '/levels/all',
  Levels = '/levels',
  Group = '/group',
  GroupActions = '/groups-actions',
  StudentAwards = '/student-awards',
  Courses = '/courses',
  Students = '/students',
  Attendance = 'attendances',
  FilterObjects = '/filter-objects',
  Groups = '/groups',
  ByNumber = '/by-number',
  Detail = '/detail',
  ByGroup = '/by-group',
  Statistics = '/statistics',
  Statistic = '/statistic',
  StudentAwardLevels = '/student-awards/levels',
  All = '/all',
  BestStudents = '/best-students',
}
