import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EditOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Flex, Table } from 'antd'
import { TableProps } from 'antd/lib'
import { Contract } from '@/api/settings/types'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { getPaginationParams } from '@/pages/utils'
import { useStores } from '@/stores'
import { getRowIndex } from '@/utils'

export const ContractTable = observer(() => {
  const { settingsStore } = useStores()
  const navigate = useNavigate()

  const handleMoveHistory = (id: number) => {
    navigate(ROUTES.contractsHistory.replace(':id', String(id)))
  }

  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.contracts,
    option: PermissionOptions.Update,
  })

  const handleEdit = (item: Contract) => {
    settingsStore.setSelectedContract(item)
    settingsStore.setMakeContractModal(true)
  }

  const columns: TableProps<Contract>['columns'] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (item: Contract, record: Contract, index: number) => (
          <span>
            {getRowIndex(settingsStore.contractsFilter?.page, settingsStore?.contractsFilter?.perPage, index)}
          </span>
        ),
      },
      {
        title: mainDictionary.name,
        render: (item: Contract) => <span>{item?.title || ''}</span>,
      },
      {
        title: mainDictionary.course,
        align: 'center',
        render: (item: Contract) => <span>{item?.course?.name || mainDictionary.other}</span>,
      },
      {
        title: mainDictionary.actions,
        align: 'center',
        render: (item: Contract) => (
          <>
            {isAvailableEdit && (
              <Flex justify="center" gap={10}>
                <Button onClick={handleEdit.bind(null, item)} type="primary" icon={<EditOutlined />} />
                <Button onClick={handleMoveHistory.bind(null, item?.id)} icon={<RedoOutlined />} />
              </Flex>
            )}
          </>
        ),
      },
    ],
    [settingsStore.contractsFilter],
  )

  const handleChangePage = (page: number, perPage: number) => {
    settingsStore.setContractsFilter({
      ...settingsStore.contractsFilter,
      page,
      perPage,
    })
  }

  return (
    <Table
      loading={settingsStore.contractsLoading}
      columns={columns}
      dataSource={settingsStore.contracts?.contracts}
      bordered
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        onChange: handleChangePage,
        ...getPaginationParams({
          total: settingsStore.contracts?.total,
        }),
      }}
    />
  )
})
