import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import { downloadApi } from '@/api/download'
import {
  ExamChatRes,
  ExamParticipate,
  ExamResult,
  GetJournalListRes,
  groupsApi,
  Homework,
  HomeworkInfo,
  ICurriculum,
  IExam,
  IExamFailureReason,
  IExamStatus,
  IGetAllExamsRes,
  IGetFormOptionsRes,
  IGetGroupCurriculumsResponse,
  IGetGroupExamsResponse,
  IGetGroupInfoRes,
  IGetGroupStudentsResponse,
  IGetHomeworkResponse,
  IGetHomeworkStatisticsByStudentRes,
  IGetHomeworkStatisticsResponse,
  IGetIncompleteStudentsResponse,
  IGetStudentsRes,
  IGetStudyMonthRes,
  IGroup,
  IGroupAttendanceResponse,
  IGroupsResponse,
  IGroupStatus,
  IHomeworkChat,
  IHomeworkInfoCountRes,
  IHomeworkResponse,
  ILessonsResponse,
  IPrice,
  IStudyMonth,
  IVideo,
  IVideosResponse,
  StudentAttendant,
  StudentExam,
  TeacherAttendanceRes,
} from '@/api/groups'
import { IStudents } from '@/api/students'
import { uploadsApi } from '@/api/upload'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { ERROR_NOTIFICATION_UNIQUE_KEY } from '@/pages/Groups/constants'
import { ExamChatsStatus, GroupPageState, HomeworkChatsStatus, HomeworkStatistics } from '@/pages/Groups/types'
import {
  AddSubmissionReq,
  AnnounceExamReqType,
  ChangeExamDate,
  EditExamInfo,
  ExamBody,
  ExamChatReq,
  ExamResultsReq,
  GetTeacherAttendanceReq,
  GroupDownloadFileType,
  GroupDownloadOptionType,
  IAddHomeworkRequest,
  IAddStudentReq,
  ICreateVideoRequest,
  IEditDeadlineParams,
  IEditHomeworkRequest,
  IEditHomeworkResult,
  IErrorStudent,
  IExamRequest,
  IGetGroupAttendanceRequest,
  IGetGroupCurriculumsRequest,
  IGetGroupExams,
  IGetGroupsRequest,
  IGetGroupStudents,
  IGetHomeworkRequest,
  IGetHomeworks,
  IGetHomeworkStatistics,
  IGetHomeworkUploadUrl,
  IGetIncompleteHomeworks,
  IGetJournalsReq,
  IGetLessonsRequest,
  IGetSingleGroupRequest,
  IGetSingleGroupRequestSchedule,
  IGetStudentHomeworkStatistics,
  IGetStudentsRequest,
  IGetVideosRequest,
  IOptionValues,
  ISchedules,
  ISendMessage,
  ISingleLateStudent,
  IStudentAttendanceRequest,
  IStudentInfos,
  ITeacherPhotoResult,
  ITextAreaState,
  ITotalColumns,
  ITotalData,
  IUpdateStatusRequest,
  IUpdateVideoRequest,
  JournalTableDays,
  MakeExamInfo,
  OnDownloadProgressType,
  SelectedGroup,
  SendPhotoReq,
  TContinuingGroup,
  TFinishGroupFormValuesReq,
  UploadedVideoId,
  UploadType,
} from './types'

class GroupsStore {
  cameraModal = false
  selectedVideo: IVideo | null = null
  confirmModal = true
  addVideo = false
  uploads: UploadType[] = []
  uploadedVideoIds: UploadedVideoId[] = []
  examResultModal = false
  groups: IGroupsResponse | null = null
  assistantsModal = false
  groupInfo: IGroup | null = null
  filterOptions: IGetGroupsRequest = {
    page: 1,
    keys: ['hName'],
    status: IGroupStatus.Active,
  }

  videos: IVideosResponse | null = null
  staffVideos: IVideosResponse | null = null
  lessons: ILessonsResponse | null = null
  statusHistoryModal = false
  isVideoPlayerOpen = false
  isKinescopePlayerOpen = false
  key: string | null = null
  videoPage = 1
  homeworks: IGetHomeworkResponse | null = null
  allHomeworks: IHomeworkResponse | null = null
  drawerHomeworks: IHomeworkResponse | null = null
  homeworkTextAreaState: ITextAreaState | null = null
  incompleteStudents: IGetIncompleteStudentsResponse | null = null
  groupAttendance: IGroupAttendanceResponse | null = null
  teacherPhotoResult: ITeacherPhotoResult | null = null
  groupExams: IGetGroupExamsResponse | null = null
  studentResult: IGetAllExamsRes | null = null
  groupStudents: IGetGroupStudentsResponse | null = null
  singleGroup: IGroup | null = null
  homework: Homework | null = null
  isAttendanceLoading = false
  date: string | null = null
  groupSubpage: string | null = GroupPageState.Attendance
  activeDay: string | null = null
  academicGroups: IGroupsResponse | null = null
  groupSingle: IGroup | null = null
  groupId: string | null = null
  homeworkInfo: HomeworkInfo | null = null
  homeworkModal = false
  acceptedHomeworkLoading = false
  groupsTableLoading = false
  isAcademic = false
  staffModal = false
  groupTeachers: ISchedules | null = null
  staffGroup: IGroup | null = null
  homeworkStatistics: IGetHomeworkStatisticsResponse | null = null
  homeworkStatisticsFilter: IGetHomeworkStatistics | null = null
  homeworkStudentStatistics: IGetHomeworkStatisticsByStudentRes | null = null
  homeworkStudentStatisticsFilter: IGetStudentHomeworkStatistics | null = null
  teacherSchedule = false
  attendanceEditCurriculumModal = false
  groupCurriculums: IGetGroupCurriculumsResponse | null = null
  selectedCurriculum: ICurriculum | null = null
  homeworkStatisticsSort: HomeworkStatistics | null = null
  videosPerPage = 10
  videosCurrentPage = 1
  myGroupFilter: IGetGroupsRequest | null = {}
  groupDetail: IGetGroupInfoRes | null = null
  selectedOption: string | IGroupStatus | null = IGroupStatus.Active
  isSynchronized = false
  homeworkInfoCount: IHomeworkInfoCountRes | null = null
  addStudentModal = false
  editStudyModal = false
  myGroupStatus: string | IGroupStatus = IGroupStatus.Active
  hadOccurredWithStudentModal = false
  successfullyAttendanceNotification = false
  errorOccurredStudents: IErrorStudent[] | null = null
  lateModal = false
  singleDeadlineModal = false
  journals: GetJournalListRes | null = null
  journalTable: ITotalColumns[] | null = null
  journalDays: JournalTableDays[] | null = null
  journalObjectFilters: IGetStudyMonthRes | null = null
  journalTableLoading = false
  students: IGetStudentsRes | null = null
  prices: IPrice[] | null = null
  formOptions: IGetFormOptionsRes | null = null
  reasonOption: IOptionValues[] | null = null
  negativeReasonOption: IOptionValues[] | null = null
  studentInfo: IStudentInfos | null = null
  lateTimeModal = false
  changedStudent: ISingleLateStudent | null = null
  selectedHomework: IHomeworkChat | ExamResult | null = null
  selectedExam: ExamResult | null = null
  studyMonth: IStudyMonth[] | null = null
  studentResults: StudentExam[] | null = null
  selectedStudent: IStudents | null = null
  homeworkStatusIcon = true
  journalLoader = false
  videosPage = 1
  examFailureReasons: IExamFailureReason[] | null = null
  changeStatusModal = false
  changeStudentSelect: IStudents | null = null
  selectedGroup: SelectedGroup | null = null
  singleExam: IExam | null = null
  examInfoCount: IHomeworkInfoCountRes | null = null
  examResults: ExamResult[] | null = null
  examChats: ExamChatRes[] | null = null
  examTab: string | ExamChatsStatus | null = ExamChatsStatus.WAITING
  examParticipants: ExamParticipate[] | null = null
  examLoading = false
  activeExamId: null | number = null
  examDetail = false
  homeworkTabs: string | HomeworkChatsStatus = HomeworkChatsStatus.WAITING
  homeworkDrawer = false
  selectedUserId: number | null = null
  isDrawerEdit = false
  videoLoader = false
  teacherAttendances: TeacherAttendanceRes | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setVideoLoader = (params: boolean) => {
    this.videoLoader = params
  }

  setTeacherAttendances = (params: TeacherAttendanceRes | null) => {
    this.teacherAttendances = params
  }

  setIsDrawerEdit = (params: boolean) => {
    this.isDrawerEdit = params
  }

  setSelectedUserId = (params: number | null) => {
    this.selectedUserId = params
  }

  setHomeworkDrawer = (params: boolean) => {
    this.homeworkDrawer = params
  }

  setHomeworkTabs = (params: string | HomeworkChatsStatus) => {
    this.homeworkTabs = params
  }

  setVideosPage = (params: number) => {
    this.videosPage = params
  }

  setSelectedGroup = (params: SelectedGroup | null) => {
    this.selectedGroup = params
  }

  setChangeStudentSelect = (params: IStudents | null) => {
    this.changeStudentSelect = params
  }

  setChangeStatusModal = (params: boolean) => {
    this.changeStatusModal = params
  }

  setExamDetail = (params: boolean) => {
    this.examDetail = params
  }

  setActiveExamId = (params: null | number) => {
    this.activeExamId = params
  }

  setExamLoading = (params: boolean) => {
    this.examLoading = params
  }

  setExamTab = (params: string | ExamChatsStatus | null) => {
    this.examTab = params
  }

  setExamParticipants = (params: ExamParticipate[] | null) => {
    this.examParticipants = params
  }

  setExamInfoCount = (params: IHomeworkInfoCountRes | null) => {
    this.examInfoCount = params
  }

  setExamFailureReasons = (params: null | IExamFailureReason[]) => {
    this.examFailureReasons = params
  }

  setSingleExam = (params: null | IExam) => {
    this.singleExam = params
  }

  setCurrentVideosPage = (params: number) => {
    this.videosCurrentPage = params
  }

  setJournalLoader = (params: boolean) => {
    this.journalLoader = params
  }

  setHomeworkStatusIcon = (params: boolean) => {
    this.homeworkStatusIcon = params
  }

  setLateModal = (params: boolean) => {
    this.lateModal = params
  }

  setSelectedStudent = (params: IStudents | null) => {
    this.selectedStudent = params
  }

  setStudentResults = (params: StudentExam[] | null) => {
    this.studentResults = params
  }

  setGroupAttendance = (params: StudentAttendant[]) => {
    if (this.groupAttendance?.groupStudentData) {
      this.groupAttendance.groupStudentData.studentAttendances = params
    }
  }

  setChangeStudent = (params: ISingleLateStudent | null) => {
    this.changedStudent = params
  }

  setLateTimeModal = (params: boolean) => {
    this.lateTimeModal = params
  }

  setSelectedHomework = (params: IHomeworkChat | ExamResult | null) => {
    this.selectedHomework = params
  }

  setSelectedExam = (params: ExamResult | null) => {
    this.selectedExam = params
  }

  setSingleDeadlineModal = (params: boolean) => {
    this.singleDeadlineModal = params
  }

  resetJournalObjectFilters = () => {
    this.journalObjectFilters = null
  }

  setJournalTableLoading = (params: boolean) => {
    this.journalTableLoading = params
  }

  setStudentInfo = (params: IStudentInfos | null) => {
    this.studentInfo = params
  }

  setNegativeReasonOption = (params: IOptionValues[]) => {
    this.negativeReasonOption = params
  }

  setReasonOption = (params: IOptionValues[]) => {
    this.reasonOption = params
  }

  setGroupCurriculum = () => {
    this.groupCurriculums = null
  }

  setExamResults = (params: ExamResult[] | null) => {
    this.examResults = params
  }

  setAddStudentModal = (params: boolean) => {
    this.addStudentModal = params
  }

  setEditStudyModal = (params: boolean) => {
    this.editStudyModal = params
  }

  setPrices = (params: IPrice[] | null) => {
    this.prices = params
  }

  setFormOptions = (params: IGetFormOptionsRes | null) => {
    this.formOptions = params
  }

  getSingleExam = (params: number) =>
    groupsApi
      .getSingleExam(params)
      .then((res) => {
        if (res.success) {
          this.setSingleExam(res.data)
        }
      })
      .catch(addCatchNotification)

  getExamResultsCount = (params: number) =>
    groupsApi.getGroupResultCount(params).then((res) => {
      if (res.success) {
        this.setExamInfoCount(res.data)
      }
    })

  checkExam = (params: ExamBody) => groupsApi.checkExam(params)

  getExamResults = (params: ExamResultsReq) =>
    groupsApi
      .getResults(params)
      .then((res) => {
        if (res.success) {
          this.setExamResults(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setExamLoading(false)
      })

  editDeadline = (params: IEditDeadlineParams) => groupsApi.editDeadline(params)

  editExam = (params: EditExamInfo) => groupsApi.editExam(params)

  getFormOptions = () =>
    groupsApi
      .getFormOptions()
      .then((res) => {
        if (res.success) {
          const reasonOption = res?.data?.reasonOptions?.map((item) => ({
            label: item.name,
            value: item.id,
          }))

          const negativeFactorOptions = res?.data?.negativeFactorOptions?.map((item) => ({
            label: item.label!,
            value: item.id,
          }))

          this.setReasonOption(reasonOption)
          this.setNegativeReasonOption(negativeFactorOptions)
        }
      })
      .catch(addCatchNotification)

  addStudent = (data: IAddStudentReq) => groupsApi.addStudent(data)

  getPrices = () =>
    groupsApi
      .getPrices()
      .then((res) => {
        if (res.success) {
          this.setPrices(res.data)
        }
      })
      .catch(addCatchNotification)

  setStudents = (params: IGetStudentsRes | null) => {
    this.students = params
  }

  getHomeworkUrl = (data: IGetHomeworkUploadUrl) => groupsApi.getHomeworkUploadUrl(data)

  getStudents = (params: IGetStudentsRequest) =>
    groupsApi
      .getStudents(params)
      .then((res) => {
        if (res.success) {
          this.setStudents(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  resetStudents = () => {
    this.setStudents(null)
  }

  openSuccessNotification = () => {
    setTimeout(() => {
      notification.open({
        type: 'success',
        message: mainDictionary.attendanceSavedSuccessfully,
        duration: 0,
        key: ERROR_NOTIFICATION_UNIQUE_KEY,
        onClose: () => {
          groupsStore.setSuccessfullyAttendanceNotification(false)
        },
      })
    }, 500)
  }

  finishStudentGroup = (params: TFinishGroupFormValuesReq) => groupsApi.finishStudentGroup(params)

  closeSuccessNotification = () => {
    notification.destroy(ERROR_NOTIFICATION_UNIQUE_KEY)
  }

  setOccurredStudents = (params: IErrorStudent[] | null) => {
    this.errorOccurredStudents = params
  }

  setSuccessfullyAttendanceNotification = (params: boolean) => {
    this.successfullyAttendanceNotification = params
  }

  setHadOccurredWithStudentModal = (params: boolean) => {
    this.hadOccurredWithStudentModal = params
  }

  resetHomeworkCount = () => {
    this.homeworkInfoCount = null
  }

  resetHomeworkStatisticsFilter = () => {
    this.homeworkStatisticsFilter = null
  }

  getHomeworkInfoCountsForAdmin = (id: number) =>
    groupsApi
      .getHomeworkInfoCountsForAdmin(id)
      .then((res) => {
        if (res.success) {
          this.homeworkInfoCount = res.data
        }
      })
      .catch(addCatchNotification)

  setExamChats = (params: ExamChatRes[] | null) => {
    this.examChats = params
  }

  changeStudentDeadline = (params: ChangeExamDate) => groupsApi.changeExamDeadline(params)

  getExamChats = (params: ExamChatReq) =>
    groupsApi
      .getExamChats(params)
      .then((res) => {
        if (res.success) {
          this.setExamChats(res.data?.reverse())
        }
      })
      .catch(addCatchNotification)

  getHomeworkInfoCountsForAcademic = (id: number) =>
    groupsApi
      .getHomeworkInfoCountsForAcademic(id)
      .then((res) => {
        if (res.success) {
          this.homeworkInfoCount = res.data
        }
      })
      .catch(addCatchNotification)

  setMyGroupFilter = (params: IGetGroupsRequest | null) => {
    this.myGroupFilter = params
  }

  setVideosPerPage = (params: number) => {
    this.videosPerPage = params
  }

  setVideos = (videos: IVideosResponse | null) => {
    this.videos = videos
  }

  setSelectedCurriculum = (params: null | ICurriculum) => {
    this.selectedCurriculum = params
  }

  setAttendanceEditCurriculumModal = (params: boolean) => {
    this.attendanceEditCurriculumModal = params
  }

  setHomeworkStatisticsSort = (params: HomeworkStatistics | null) => {
    this.homeworkStatisticsSort = params
  }

  resetStudentHomeworkStatistics = () => {
    this.homeworkStudentStatistics = null
    this.resetHomeworkStatisticsFilter()
  }

  setHomeworkStudentStatisticsFilter = (params: IGetStudentHomeworkStatistics | null) => {
    this.homeworkStudentStatisticsFilter = params
  }

  continueStudying = (params: TContinuingGroup) => groupsApi.continueStudying(params)

  setTeacherSchedule = (params: boolean) => {
    this.teacherSchedule = params
  }

  setStaffGroup = (groupName: IGroup | null) => {
    this.staffGroup = groupName
  }

  setHomeworkStatisticsFilter = (value: IGetHomeworkStatistics | null) => {
    this.homeworkStatisticsFilter = value
  }

  setGroupTeachers = (params: ISchedules | null) => {
    this.groupTeachers = params
  }

  setStaffModal = (params: boolean) => {
    this.staffModal = params
  }

  setIsAcademic = (params: boolean) => {
    this.isAcademic = params
  }

  setGroupsTableLoading = (params: boolean) => {
    this.groupsTableLoading = params
  }

  setAcceptedHomeworkLoading = (params: boolean) => {
    this.acceptedHomeworkLoading = params
  }

  setHomeworkInfo = (params: HomeworkInfo | null) => {
    this.homeworkInfo = params
  }

  setHomeworkStatistics = (statistics: IGetHomeworkStatisticsResponse | null) => {
    this.homeworkStatistics = statistics
  }

  setHomeworkModal = (params: boolean) => {
    this.homeworkModal = params
  }

  getGroupDetailForAdmin = (id: number) =>
    groupsApi
      .getGroupInfoForAdmin(id)
      .then((res) => {
        if (res.success) {
          this.groupDetail = res.data
        }
      })
      .catch(addCatchNotification)

  getGroupDetailForAcademic = (id: number) =>
    groupsApi
      .getGroupInfoForAdmin(id)
      .then((res) => {
        if (res.success) {
          this.groupDetail = res.data
        }
      })
      .catch(addCatchNotification)

  getHomeworkStatistics = (params: IGetHomeworkStatistics) =>
    groupsApi
      .getGroupsStatistics(params)
      .then((res) => {
        if (res.success) {
          this.homeworkStatistics = res.data
        }
      })
      .catch(addCatchNotification)

  getHomeworkStatisticsForAdmin = (params: IGetHomeworkStatistics) =>
    groupsApi
      .getGroupsStatisticsForAdmin(params)
      .then((res) => {
        if (res.success) {
          this.homeworkStatistics = res.data
        }
      })
      .catch(addCatchNotification)

  getHomeworkStatisticsForAcademic = (params: IGetHomeworkStatistics) =>
    groupsApi
      .getGroupsStatisticsForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.setHomeworkStatistics(res.data)
        }
      })
      .catch(addCatchNotification)

  setGroupId = (params: string) => {
    this.groupId = params
  }

  setActiveDay = (params: string | null) => {
    this.activeDay = params
  }

  setGroupSubPage = (state: string | null) => {
    this.groupSubpage = state
  }

  setDate = (value: string | null) => {
    this.date = value
  }

  setTeacherPhotoResult = (params: ITeacherPhotoResult | null) => {
    this.teacherPhotoResult = params
  }

  sendTeacherPhoto = (params: SendPhotoReq) => groupsApi.sendPhotoUrl(params)

  resetAttendance = () => {
    this.groupAttendance = null
  }

  getGroupCurriculums = (params: IGetGroupCurriculumsRequest) =>
    groupsApi
      .getGroupCurriculums(params)
      .then((res) => {
        if (res.success) {
          this.groupCurriculums = res.data
        }
      })
      .catch(addCatchNotification)

  getGroupDownloadFile: GroupDownloadFileType = async (url, setPercent) => {
    const onDownloadProgress: OnDownloadProgressType = (e) => {
      const percent = Math.floor((e.loaded / e.total!) * 100)

      setPercent(percent)
    }
    const options: GroupDownloadOptionType = { responseType: 'blob', onDownloadProgress }

    try {
      const { data } = await downloadApi.getGroupDownloadFile(url, options)

      return data
    } catch (error: any) {
      notification.open({
        type: 'error',
        message: 'Error downloading file',
        description: '',
      })
      throw error
    }
  }

  getGroupInfoForAdmin = (params: IGetSingleGroupRequestSchedule) =>
    groupsApi
      .getGroupSingleForAdmin(params)
      .then((res) => {
        if (res.success) {
          this.groupSingle = res.data
        }
      })
      .catch(addCatchNotification)

  getGroupTeacherAttendance = (params: GetTeacherAttendanceReq) =>
    groupsApi
      .getTeacherAttendance(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherAttendances(res.data)
        }
      })
      .catch(addCatchNotification)

  getGroupInfoForAcademic = (params: IGetSingleGroupRequestSchedule) =>
    groupsApi
      .getGroupSingleForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.groupSingle = res.data
        }
      })
      .catch(addCatchNotification)

  addSubmissions = (params: AddSubmissionReq) => groupsApi.addSubmissions(params)

  getGroupExams = (params: IGetGroupExams) =>
    groupsApi
      .getGroupExams(params)
      .then((res) => {
        if (res.success) {
          this.groupExams = res.data
        }
      })
      .catch(addCatchNotification)

  setStudentResult = (params: IGetAllExamsRes | null) => {
    this.studentResult = params
  }

  getGroups = (params?: IGetGroupsRequest) =>
    groupsApi
      .getGroups(params)
      .then((res) => {
        if (res.success) {
          this.groups = res.data
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.groupsTableLoading = false
      })

  getAcademicGroups = (params?: IGetGroupsRequest) =>
    groupsApi
      .getAcademicGroups(params)
      .then((res) => {
        if (res.success) {
          this.academicGroups = res.data
        }
      })
      .catch(addCatchNotification)

  setStatusHistoryModal = (params: boolean) => {
    this.statusHistoryModal = params
  }

  setHomeworkState = (params: ITextAreaState | null) => {
    this.homeworkTextAreaState = params
  }

  setKey = (params: string) => {
    this.key = params
  }

  resetHomeworks = () => {
    this.allHomeworks = null
    this.examChats = null
  }

  setDrawerHomeworks = (params: IHomeworkResponse | null) => {
    this.drawerHomeworks = params
  }

  setAllHomeworks = (params: IHomeworkResponse | null) => {
    this.allHomeworks = params
  }

  setAttendanceLoading = (params: boolean) => {
    this.isAttendanceLoading = params
  }

  sendMessageForAdmin = (params: ISendMessage) => groupsApi.sendMessageForAdmin(params).catch(addCatchNotification)

  sendMessageForAcademic = (params: ISendMessage) =>
    groupsApi.sendMessageForAcademic(params).catch(addCatchNotification)

  getHomeworkForAdmin = (params: IGetHomeworks, isDrawer?: boolean) =>
    groupsApi
      .getHomeworkForAdmin(params)
      .then((res) => {
        if (res.success) {
          res.data.homeworkChatsData.homeworkChats.reverse()

          if (isDrawer) {
            this.setDrawerHomeworks(res.data)
          } else {
            this.setAllHomeworks(res.data)
          }
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.acceptedHomeworkLoading = false
      })

  getHomeworkForAcademic = (params: IGetHomeworks, isDrawer?: boolean) =>
    groupsApi
      .getHomeworkForAcademic(params)
      .then((res) => {
        if (res.success) {
          res.data.homeworkChatsData.homeworkChats.reverse()

          if (isDrawer) {
            this.setDrawerHomeworks(res.data)
          } else {
            this.setAllHomeworks(res.data)
          }
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.acceptedHomeworkLoading = false
      })

  getGroupForAdminAttendance = (params: IGetGroupAttendanceRequest) =>
    groupsApi
      .getGroupAttendanceForAdmin(params)
      .then((res) => {
        this.setAttendanceLoading(true)

        if (res.success) {
          const filtered: StudentAttendant[] = []

          res.data.groupStudentData.studentAttendances.forEach((item) => {
            if (item.isStudying) {
              filtered.unshift(item)
            } else {
              filtered.push(item)
            }
          })

          res.data.groupStudentData.studentAttendances = filtered
          this.groupAttendance = res.data
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setAttendanceLoading(false)
      })

  getGroupForStaffAttendance = (params: IGetGroupAttendanceRequest) =>
    groupsApi
      .getGroupAttendanceForAcademic(params)
      .then((res) => {
        this.setAttendanceLoading(true)

        return res
      })
      .then((res) => {
        if (res.success) {
          const filtered: StudentAttendant[] = []

          res.data.groupStudentData.studentAttendances.forEach((item) => {
            if (item.isStudying) {
              filtered.unshift(item)
            } else {
              filtered.push(item)
            }
          })

          res.data.groupStudentData.studentAttendances = filtered
          this.groupAttendance = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setAttendanceLoading(false)
      })

  studentAttendance = (params: IStudentAttendanceRequest) => groupsApi.studentAttendance(params)

  studentAttendanceForAcademic = (params: IStudentAttendanceRequest) => groupsApi.studentAttendanceForAcademic(params)

  getIncompleteStudentsForAdmin = (params: IGetIncompleteHomeworks) =>
    groupsApi
      .getIncompleteHomeworksForAdmin(params)
      .then((res) => {
        if (res.success) {
          this.incompleteStudents = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.acceptedHomeworkLoading = false
      })

  getIncompleteStudentsForAcademic = (params: IGetIncompleteHomeworks) =>
    groupsApi
      .getIncompleteHomeworksForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.incompleteStudents = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.acceptedHomeworkLoading = false
      })

  setSingleGroup = (params: IGroup) => {
    this.singleGroup = params
  }

  getSingleGroup = (params: IGetSingleGroupRequest) =>
    groupsApi
      .getGroup(params)
      .then((res) => {
        if (res.success) {
          this.setSingleGroup(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getExamParticipants = (params: number) =>
    groupsApi
      .getExamParticipants(params)
      .then((res) => {
        if (res.success) {
          const data = res.data.map((item) => ({
            ...item,
            isNotDisabled: true,
            status: item?.status ? item?.status : item?.score < 60 ? IExamStatus.Failed : undefined,
          }))

          this.setExamParticipants(data)
        }
      })
      .catch(addCatchNotification)

  getSingleGroupForAcademic = (params: IGetSingleGroupRequest) =>
    groupsApi
      .getGroupForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.setSingleGroup(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getStudyMonth = () =>
    groupsApi
      .getStudyMonth()
      .then((res) => {
        if (res.success) {
          this.studyMonth = res.data
        }
      })
      .catch(addCatchNotification)

  getReasons = () =>
    groupsApi
      .getFailureExamReasons()
      .then((res) => {
        if (res.success) {
          this.setExamFailureReasons(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentResults = (id: number) =>
    groupsApi
      .getGroupStudentResults(id)
      .then((res) => {
        if (res.success) {
          this.setStudentResults(res.data)
        }
      })
      .catch(addCatchNotification)

  getGroupStudents = (params: IGetGroupStudents) =>
    groupsApi
      .getGroupStudents(params)
      .then((res) => {
        if (res.success) {
          this.groupStudents = res.data
        }
      })
      .catch(addCatchNotification)

  getHomeworksForAdmin = (params: IGetHomeworkRequest) =>
    groupsApi
      .getHomeworksForAdmin(params)
      .then((res) => {
        if (res.success) {
          this.homeworks = res.data
        }
      })
      .catch(addCatchNotification)

  editHomework = (params: IEditHomeworkResult) => groupsApi.editHomeworkResult(params)

  getHomeworksForAcademic = (params: IGetHomeworkRequest) =>
    groupsApi
      .getHomeworksForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.homeworks = res.data
        }
      })
      .catch(addCatchNotification)

  uploadTeacherPhoto = (formData: FormData) => uploadsApi.uploadTeacherPhoto(formData)

  setIsVideoPlayerOpen = (params: boolean) => {
    this.isVideoPlayerOpen = params
  }

  setIsKinescopePlayerOpen = (params: boolean) => {
    this.isKinescopePlayerOpen = params
  }

  updateVideoForAdmin = async (params: IUpdateVideoRequest) => groupsApi.updateVideoForAdmin(params)

  updateVideoForAcademic = async (params: IUpdateVideoRequest) => groupsApi.updateVideoForAcademic(params)

  setUploadPaused = (videoId: number, paused: boolean) => {
    const uploadingVideo = this.uploads.find((upload) => upload.video.id === videoId)

    if (uploadingVideo) {
      uploadingVideo.paused = paused
    }
  }

  updateStatusForAdmin = (params: IUpdateStatusRequest) =>
    groupsApi.updateVideoStatusForAdmin(params).catch((error) => {
      addCatchNotification({ message: error })
    })

  updateStatusForAcademic = (params: IUpdateStatusRequest) =>
    groupsApi.updateVideoStatusForAcademic(params).catch((error) => {
      addCatchNotification({ message: error })
    })

  updateStatus = (params: IUpdateStatusRequest) => {
    if (this.isAcademic) {
      return this.updateStatusForAcademic(params)
    }

    return this.updateStatusForAdmin(params)
  }

  deleteVideoForAdmin = (params: number) => groupsApi.deleteVideoForAdmin(params)

  deleteVideoForAcademic = (params: number) => groupsApi.deleteVideoForAcademic(params)

  setUploadError = (videoId: number, error: boolean) => {
    const uploadingVideo = this.uploads.find((upload) => upload.video.id === videoId)

    if (uploadingVideo) {
      uploadingVideo.error = error
    }
  }

  addExamAcademic = (params: IExamRequest) => groupsApi.addExamForAcademic(params)

  addExamAdmin = (params: IExamRequest) => groupsApi.addExamForAdmin(params)

  createVideoForAdmin = async (params: ICreateVideoRequest) => {
    try {
      return await groupsApi.createVideoForAdmin(params)
    } catch (error) {
      console.error(error)

      return Promise.reject(error)
    }
  }

  setJournalTable = (params: ITotalColumns[] | null) => {
    this.journalTable = params
  }

  setJournalObjectFilterObjects = (params: IGetStudyMonthRes) => {
    this.journalObjectFilters = params
  }

  getJournalFilterObjects = (id: number, isAcademic: boolean) => {
    if (isAcademic) {
      return groupsApi
        .getJournalFilterObjectsForAcademic(id)
        .then((res) => {
          if (res.success) {
            this.journalObjectFilters = res.data
          }
        })
        .catch(addCatchNotification)
    }

    return groupsApi.getJournalFilterObjectsForAdmin(id).then((res) => {
      if (res.success) {
        this.setJournalObjectFilterObjects(res.data)
      }
    })
  }

  setJournals = (params: GetJournalListRes | null) => {
    this.journals = params
  }

  getJournalForAcademic = (params: IGetJournalsReq) =>
    groupsApi
      .getJournalsForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.journalDays = [
            ...res.data.days,
            { title: mainDictionary.attendanceTitle },
            { title: mainDictionary.homeworkColumn },
          ]
          this.journals = res.data

          const additionalInfo = [
            {
              firstName: mainDictionary.haveLesson,
              lastName: mainDictionary.countOfStudentWhoHasInLesson,
              attendanceAverage: res?.data?.additionalStats.attendancePercentage,
            },
            {
              firstName: mainDictionary.acceptHomework,
              lastName: mainDictionary.countOfStudentWhoWasAcceptedTheirHomework,
              homeworkAverage: res.data?.additionalStats?.homeworkPercentage,
            },
          ]

          const data: ITotalData[] = [...res.data.students!, ...additionalInfo!]

          const columns: ITotalColumns[] = data?.map((item: ITotalData) => ({
            date: res.data?.days,
            student: {
              ...item,
              stats: item?.stats?.length
                ? [...item.stats, { attendance: item?.attendance }, { homework: item?.homework }]
                : item?.stats,
            },
            stats: res.data?.additionalStats!,
          }))

          this.setJournalTable(columns)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setJournalTableLoading(false)
        this.setJournalLoader(false)
      })

  getJournalsForAdmin = (params: IGetJournalsReq) =>
    groupsApi
      .getJournalsForAdmin(params)
      .then((res) => {
        if (res.success) {
          const updatedDates = res.data.days.map((item) => ({
            ...item,
            lessonId: item.lessonId,
          }))

          this.journalDays = [
            ...updatedDates,
            { title: mainDictionary.attendanceTitle },
            { title: mainDictionary.homeworkColumn },
          ]
          this.journals = res.data

          const additionalInfo = [
            {
              firstName: mainDictionary.haveLesson,
              lastName: mainDictionary.countOfStudentWhoHasInLesson,
              attendanceAverage: res?.data?.additionalStats.attendancePercentage,
            },
            {
              firstName: mainDictionary.acceptHomework,
              lastName: mainDictionary.countOfStudentWhoWasAcceptedTheirHomework,
              homeworkAverage: res.data?.additionalStats?.homeworkPercentage,
            },
          ]

          const data: ITotalData[] = [...res.data.students!, ...additionalInfo!]

          const columns: ITotalColumns[] = data?.map((item: ITotalData) => ({
            date: res.data?.days,
            student: {
              ...item,
              stats: item?.stats?.length
                ? [...item.stats, { attendance: item?.attendance }, { homework: item?.homework }]
                : item.stats,
            },
            stats: res.data?.additionalStats!,
          }))

          this.setJournalTable(columns)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setJournalTableLoading(false)
        this.setJournalLoader(false)
      })

  createVideoForAcademic = async (params: ICreateVideoRequest) => {
    try {
      return await groupsApi.createVideoFormAcademic(params)
    } catch (error) {
      console.error(error)

      return Promise.reject(error)
    }
  }

  getSingleHomeworkForAdmin = (params: number) =>
    groupsApi
      .getSingleHomeworkForAdmin(params)
      .then((res) => {
        if (res.success) {
          this.homework = res.data

          return res.data.homeworkInfo.attachments
        }
      })
      .catch(addCatchNotification)

  getSingleHomeworkForAcademic = (params: number) =>
    groupsApi
      .getSingleHomeworkForAcademic(params)
      .then((res) => {
        if (res.success) {
          this.homework = res.data

          return res.data.homeworkInfo.attachments
        }
      })
      .catch(addCatchNotification)

  setSelectedVideo = (video: IVideo | null) => {
    this.selectedVideo = video
  }

  removeUpload = (videoId: number) => {
    this.uploads = this.uploads.filter((upload) => upload.video.id !== videoId)

    if (!this.uploads.length) {
      window.onbeforeunload = null
    }
  }

  getStudentHomeworkStatistics = (params: IGetStudentHomeworkStatistics) =>
    groupsApi
      .getStudentGroupStatistics(params)
      .then((res) => {
        if (res.success) {
          this.homeworkStudentStatistics = res.data
        }
      })
      .catch(addCatchNotification)

  addUpload = (upload: UploadType) => {
    this.uploads.push(upload)
  }

  addHomeworkForAdmin = (params: IAddHomeworkRequest) => groupsApi.addHomeworkForAdmin(params)

  addHomeworkAcademic = (params: IAddHomeworkRequest) => groupsApi.addHomeworkForAcademic(params)

  resetHomework = () => {
    this.homework = null
  }

  getLessons = (params: IGetLessonsRequest) =>
    groupsApi
      .getLessons(params)
      .then((res) => {
        if (res.success) {
          this.lessons = res.data
        }
      })
      .catch(addCatchNotification)

  getVideos = (params: IGetVideosRequest) =>
    groupsApi
      .getVideos(params)
      .then((res) => {
        if (res.success) {
          this.setVideos(res.data)

          this.setUploadedVideoIds(res.data.videos.map((video) => ({ id: video.id, status: video.status })))
        }
      })
      .catch(addCatchNotification)

  createExam = (params: MakeExamInfo) => groupsApi.addExam(params)

  getStaffVideos = (params: IGetVideosRequest) =>
    groupsApi
      .getStaffVideos(params)
      .then((res) => {
        if (res.success) {
          this.staffVideos = res.data

          this.setUploadedVideoIds(res.data.videos.map((video) => ({ id: video.id, status: video.status })))
        }
      })
      .catch(addCatchNotification)

  announceExam = (params: AnnounceExamReqType) => groupsApi.announceExam(params)

  getAllVideos = (params: IGetVideosRequest, isAcademic?: boolean) =>
    isAcademic
      ? this.getStaffVideos(params)
      : this.getVideos(params)
          .catch(addCatchNotification)
          .finally(() => {
            this.setVideoLoader(false)
          })

  deleteHomeworkForAdmin = (params: number) => groupsApi.deleteHomeworkForAdmin(params)

  deleteHomeworkForAcademic = (params: number) => groupsApi.deleteHomeworkForAcademic(params)

  editHomeworkForAdmin = (params: IEditHomeworkRequest) => groupsApi.editHomeworkForAdmin(params)

  editHomeworkForAcademic = (params: IEditHomeworkRequest) => groupsApi.editHomeworkForAcademic(params)

  setAssistantsModal = (params: boolean) => {
    this.assistantsModal = params
  }

  setFilterOption = (params: IGetGroupsRequest) => {
    this.filterOptions = params
  }

  setAssistants = (params: IGroup | null) => {
    this.groupInfo = params
  }

  setCameraStore = (params: boolean) => {
    this.cameraModal = params
  }

  setAddVideoModal = (params: boolean) => {
    this.addVideo = params
  }

  setConfirmModal = (params: boolean) => {
    this.confirmModal = params
  }

  setSelectedOption = (value: string | IGroupStatus | null) => {
    this.selectedOption = value
  }

  setSynchronize = (value: boolean) => {
    this.isSynchronized = value
  }

  setMyGroupStatus = (value: string | IGroupStatus) => {
    this.myGroupStatus = value
  }

  setUploadedVideoIds = (ids: UploadedVideoId[]) => {
    this.uploadedVideoIds = ids
  }

  resetSingleGroup = () => {
    this.videos = null
    this.staffVideos = null
    this.homework = null
    this.groupAttendance = null
    this.activeDay = null
    this.singleGroup = null
  }

  reset = () => {
    this.groupSubpage = GroupPageState.Attendance
    this.videoPage = 1
    this.key = null
    this.videos = null
    this.uploadedVideoIds = []
    this.date = null
    this.groups = null
    this.groupId = null
    this.lessons = null
    this.homework = null
    this.homeworks = null
    this.groupInfo = null
    this.activeDay = null
    this.staffGroup = null
    this.groupExams = null
    this.staffVideos = null
    this.groupSingle = null
    this.singleGroup = null
    this.allHomeworks = null
    this.homeworkInfo = null
    this.selectedVideo = null
    this.studentResult = null
    this.groupStudents = null
    this.groupTeachers = null
    this.academicGroups = null
    this.groupAttendance = null
    this.incompleteStudents = null
    this.teacherPhotoResult = null
    this.homeworkTextAreaState = null
    this.addVideo = false
    this.isAcademic = false
    this.staffModal = false
    this.cameraModal = false
    this.confirmModal = true
    this.homeworkModal = false
    this.isSynchronized = false
    this.examResultModal = false
    this.assistantsModal = false
    this.isVideoPlayerOpen = false
    this.statusHistoryModal = false
    this.isAttendanceLoading = false
    this.groupsTableLoading = false
    this.acceptedHomeworkLoading = false
    this.uploads = []
    this.homeworkStatistics = null
  }
}

export const groupsStore = new GroupsStore()
