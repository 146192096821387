import classnamesBind from 'classnames/bind'
import { SimpleBreadcrumb, WithButtonBreadcrumb } from './components'
import { BreadcrumbType } from './types'

import styles from './styles.module.scss'

const cn = classnamesBind.bind(styles)

export const Breadcrumb = ({ items }: BreadcrumbType) => {
  if (!items) {
    return null
  }

  return (
    <ul className={cn('breadcrumb__items')}>
      {items.length <= 3 ? <SimpleBreadcrumb items={items} /> : <WithButtonBreadcrumb items={items} />}
    </ul>
  )
}
