import { FC } from 'react'
import { Typography } from 'antd'
import classNames from 'classnames/bind'
import mainDictionary from '@/dictionary'

import style from './ResetPassword.module.scss'
type Props = {
  password: string
}

const cn = classNames.bind(style)

export const ResetPasswordContent: FC<Props> = ({ password }) => (
  <Typography.Title level={5}>
    {mainDictionary.password}&nbsp;
    <Typography.Paragraph className={cn('reset__password')} copyable code mark>
      {password}
    </Typography.Paragraph>
    &nbsp;
    {mainDictionary.resetPasswordSuccess}
  </Typography.Title>
)
