import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames/bind'
import { ROUTES } from '@/constants'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { branchOfficeStore } from '@/pages/Controls/BranchOffice'
import { useStores } from '@/stores'
import { openOnTargetOpenWindow } from '@/utils/openTargetWindow'
import { SUPER_ADMIN } from '../constants'
import { StaffStatusProps } from '../types'
import { StaffBlock } from './StaffBlock'
import { StaffResetPassword } from './StaffResetPassword'

import styles from './staff-action.module.scss'

const cn = classNames.bind(styles)

export const StaffAction = observer(({ staff }: StaffStatusProps) => {
  const navigate = useNavigate()
  const { appStore } = useStores()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.usersStaff,
    option: PermissionOptions.Update,
  })
  const myOffices = useMemo(
    () => branchOfficeStore.branchOffices?.map((item) => item.id),
    [branchOfficeStore.branchOffices],
  )
  const userOfficeIds = staff?.offices?.map((office) => office?.id)

  const isAvailableEditUser = myOffices?.filter((branch) => userOfficeIds?.includes(branch))

  const isAdmin = appStore.profile?.login === SUPER_ADMIN

  const handleEdit = (id: number, event: React.MouseEvent<HTMLElement>) => {
    const route = `${ROUTES.usersStaff}/${id}`

    const isOpened = openOnTargetOpenWindow(route, event)

    if (isOpened) return

    navigate(route)
  }

  if (!isAvailableEdit) return

  const handleInfoClick = () => {
    appStore.setLoginModal({
      isVisible: true,
      login: staff?.login,
    })
  }

  return (
    <div className={cn('staff-actions')}>
      <Button onClick={handleInfoClick} icon={<InfoCircleOutlined />} />
      <StaffBlock staff={staff} />
      {(!!isAvailableEditUser?.length || isAdmin) && (
        <Button onClick={handleEdit.bind(null, staff.id)} type="primary" icon={<EditOutlined />} />
      )}
      <StaffResetPassword staff={staff} />
    </div>
  )
})
