import { AxiosRequestConfig } from 'axios'
import { FilterObjectParams } from '@/stores/notifications'
import {
  ChangeCertificateParams,
  ChangeStudentPrivateInfoReq,
  GetScheduleInfoParams,
  GetStudentCertificateReq,
  IChangeStudentStatus,
  IGetStudentGroups,
  ResendContractParams,
  SelfContractData,
  StudentPrivateInfo,
  TStudentInfo,
} from '@/stores/students/types'
import { StudentAddType } from '@/types'
import { dictionaryWithKeys } from '@/utils'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse, ISuccessResponse } from '../types'
import { StudentEndpoints } from './endpoints'
import {
  CategoriesAll,
  CertificatesRes,
  ChangeStudentLogin,
  ChangeStudentLoginResponse,
  Contracts,
  IGetStudentGroupsRes,
  IGetXlsFileResponse,
  IStudents,
  IStudentSmsHistory,
  IStudentsResponse,
  ResetPassword,
  SingleStudentParams,
  StudentParams,
  StudentSmsSendParams,
  StudentSmsSendResponse,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class StudentApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getStudents = (params: StudentParams): Promise<IResponse<IStudentsResponse>> =>
    this.get(dictionaryWithKeys(StudentEndpoints.Students), { params })

  getSingleStudent = (params: SingleStudentParams): Promise<IResponse<IStudents>> =>
    this.get(`${StudentEndpoints.Student}/${params.id}`)

  getStudentCertificates = (params: GetStudentCertificateReq): Promise<IResponse<CertificatesRes>> =>
    this.get(`${StudentEndpoints.Certificates}`, { params })

  changeLogin = (params: ChangeStudentLogin): Promise<IResponse<ChangeStudentLoginResponse>> =>
    this.patch(
      dictionaryWithKeys(StudentEndpoints.StudentChangeLogin, {
        id: params.id,
      }),
      {
        newLogin: params.newLogin,
      },
    )

  resetPassword = (params: SingleStudentParams): Promise<IResponse<ResetPassword>> =>
    this.patch(
      dictionaryWithKeys(StudentEndpoints.StudentResetPassword, {
        id: params.id,
      }),
    )

  getCategory = (params?: FilterObjectParams): Promise<IResponse<CategoriesAll>> =>
    this.get(StudentEndpoints.StudentCategory, { params })

  getScheduleInfoParams = (params: GetScheduleInfoParams): Promise<IResponse<string>> =>
    this.put(StudentEndpoints.StudentGroupsInfoFile, params)

  getStudentSmsHistory = (id: number): Promise<IResponse<IStudentSmsHistory>> =>
    this.get(dictionaryWithKeys(StudentEndpoints.StudentSmsHistory, { id }))

  getXlsFiles = (params?: StudentParams, options?: AxiosRequestConfig): Promise<IResponse<IGetXlsFileResponse>> =>
    this.post(`${StudentEndpoints.Student}${StudentEndpoints.Export}`, params, options)

  studentSmsSend = (params: StudentSmsSendParams): Promise<IResponse<StudentSmsSendResponse>> =>
    this.post(
      dictionaryWithKeys(StudentEndpoints.StudentSmsSend, {
        id: params.id,
      }),
      params,
    )

  changeStudentCertificate = (params: ChangeCertificateParams): Promise<IResponse<IStudents>> =>
    this.put(`${StudentEndpoints.Certificates}/${params.id}`, params.data)

  changeStudentStatus = (params: IChangeStudentStatus): Promise<IResponse<IStudents>> =>
    this.patch(`${StudentEndpoints.Student}/${params.id}${StudentEndpoints.SystemStatus}`, params.data)

  getStudentGroup = (params: IGetStudentGroups): Promise<IResponse<IGetStudentGroupsRes>> =>
    this.get(`${StudentEndpoints.StudentGroups}${StudentEndpoints.Groups}`, { params })

  updateStudentStatus = (params: TStudentInfo): Promise<IResponse<IStudents>> =>
    this.put(`${StudentEndpoints.Student}/${params.id}${StudentEndpoints.UpdateProfile}`, params.data)

  changeStudentInfo = (params: ChangeStudentPrivateInfoReq): Promise<IResponse<StudentPrivateInfo>> =>
    this.put(`${StudentEndpoints.Student}/${params.id}/${StudentEndpoints.Update}`, params.studentInfo)

  addStudent = (params: StudentAddType): Promise<IResponse<Pick<TStudentInfo, 'id'>>> =>
    this.post(StudentEndpoints.Student, params)

  sendContract = (params: SelfContractData): Promise<IResponse<IStudents>> =>
    this.post(`${StudentEndpoints.StudentsContractsCreate}`, params)

  getContracts = (params: number): Promise<IResponse<Contracts[]>> =>
    this.get(`${StudentEndpoints.StudentsContractsStudent}/${params}`)

  resendContract = (params: ResendContractParams): Promise<IResponse<IStudents>> =>
    this.put(`${StudentEndpoints.StudentContractsResend}`, params)
}

export const studentApi = new StudentApi(config)
