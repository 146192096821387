export const staffDictionary = {
  searchStaff: {
    label: 'Ism-familiya',
    placeholder: 'Qidirish',
  },
  chooseRole: 'Roleni tanlang',
  unblock: 'Blokdan chiqarish',
  requiredImage: 'Rasm tanlanishi shart',
  successfullyBlocked: 'Muvaffaqiyatli bloklandi',
  successfullyUnBlocked: 'Muvaffaqiyatli bloklandan chiqarildi',
}
