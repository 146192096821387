import { observer } from 'mobx-react'
import { Form, Input, Modal } from 'antd'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { IChangePasswordValues } from '@/stores/profile'
import { ChangePasswordEnum } from '../constants'

export const ChangePassword = observer(() => {
  const { profileStore } = useStores()
  const [form] = Form.useForm()

  const handleClose = () => {
    profileStore.setChangePassword(false)
  }

  const handleFinish = () => {
    form.submit()
  }

  const handleSubmit = (values: IChangePasswordValues) => {
    profileStore
      .changePasswordRequest(values)
      .then((res) => {
        if (res.success) {
          handleClose()
          addSuccessNotification(mainDictionary.successfullyChangedPassword)
        }
      })
      .catch(addCatchNotification)
  }

  return (
    <Modal
      open={profileStore.changePassword}
      onCancel={handleClose}
      onOk={handleFinish}
      title={mainDictionary.changePassword}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={mainDictionary.enterCurrentPassword}
          name={ChangePasswordEnum.CurrentPassword}
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={mainDictionary.enterNewPassword}
          name={ChangePasswordEnum.NewPassword}
          rules={[{ required: true, min: 8 }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={mainDictionary.enterPassword}
          name={ChangePasswordEnum.ConfirmNewPassword}
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
})
