import { IHomeWorkAdd } from '@/pages/Groups/types'
import {
  AddSubmissionReq,
  AnnounceExam,
  AnnounceExamReqType,
  ChangeExamDate,
  EditExamInfo,
  ExamBody,
  ExamChatReq,
  ExamResultsReq,
  GetTeacherAttendanceReq,
  IAddHomeworkRequest,
  IAddStudentReq,
  ICreateVideoRequest,
  IEditDeadlineParams,
  IEditHomeworkRequest,
  IEditHomeworkResult,
  IExamRequest,
  IGetGroupAttendanceRequest,
  IGetGroupCurriculumsRequest,
  IGetGroupExams,
  IGetGroupsRequest,
  IGetGroupStudents,
  IGetHomeworkRequest,
  IGetHomeworks,
  IGetHomeworkStatistics,
  IGetHomeworkUploadUrl,
  IGetIncompleteHomeworks,
  IGetJournalsReq,
  IGetLessonsRequest,
  IGetSingleGroupRequest,
  IGetSingleGroupRequestSchedule,
  IGetStudentHomeworkStatistics,
  IGetStudentsRequest,
  IGetVideosRequest,
  ISendMessage,
  IStudentAttendanceRequest,
  IUpdateStatusRequest,
  IUpdateVideoRequest,
  MakeExamInfo,
  SendPhotoReq,
  TContinuingGroup,
  TFinishGroupFormValuesReq,
} from '@/stores/groups'
import { dictionaryWithKeys } from '@/utils'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { GroupsEndpoints } from './endpoints'
import {
  ExamChatRes,
  ExamParticipate,
  ExamResult,
  GetJournalListRes,
  Homework,
  IExam,
  IExamFailureReason,
  IFinishGroupRes,
  IGetFormOptionsRes,
  IGetGroupCurriculumsResponse,
  IGetGroupExamsResponse,
  IGetGroupInfoRes,
  IGetGroupStudentsResponse,
  IGetHomeworkFileUrlRes,
  IGetHomeworkResponse,
  IGetHomeworkStatisticsByStudentRes,
  IGetHomeworkStatisticsResponse,
  IGetIncompleteStudentsResponse,
  IGetStudentsRes,
  IGetStudyMonthRes,
  IGroup,
  IGroupAttendanceResponse,
  IGroupsResponse,
  IHomeworkInfoCountRes,
  IHomeworkResponse,
  ILessonsResponse,
  IPrice,
  IStudentAttendanceResponse,
  IStudyMonth,
  IVideo,
  IVideosResponse,
  StudentExam,
  TeacherAttendanceRes,
} from './types'

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class GroupsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getGroups = (params?: IGetGroupsRequest): Promise<IResponse<IGroupsResponse>> =>
    this.get(GroupsEndpoints.GroupAll, { params })

  getVideos = (params: IGetVideosRequest): Promise<IResponse<IVideosResponse>> => {
    const { groupId, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${groupId}${GroupsEndpoints.Videos}`, {
      params: data,
    })
  }

  getStaffVideos = (params: IGetVideosRequest): Promise<IResponse<IVideosResponse>> => {
    const { groupId, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${groupId}${GroupsEndpoints.Videos}`, {
      params: data,
    })
  }

  getLessons = (params: IGetLessonsRequest): Promise<IResponse<ILessonsResponse>> => {
    const { id, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Lessons}`, { params: data })
  }

  createVideoForAdmin = (params: ICreateVideoRequest): Promise<IResponse<IVideo>> =>
    this.post(`${GroupsEndpoints.CreateVideoForAdmin}`, params)

  createVideoFormAcademic = (params: ICreateVideoRequest): Promise<IResponse<IVideo>> =>
    this.post(GroupsEndpoints.CreateVideoForAcademic, params)

  updateVideoForAdmin = (params: IUpdateVideoRequest): Promise<IResponse<IVideo>> =>
    this.put(`${GroupsEndpoints.Video}${GroupsEndpoints.UpdateForAdmin}/${params.id}`, params.data)

  updateVideoForAcademic = (params: IUpdateVideoRequest): Promise<IResponse<IVideo>> =>
    this.put(`${GroupsEndpoints.Video}${GroupsEndpoints.UpdateForAcademic}/${params.id}`, params.data)

  deleteVideoForAdmin = (params: number): Promise<IResponse<IVideo>> =>
    this.delete(`${GroupsEndpoints.VideoDeleteForAdmin}/${params}`)

  deleteVideoForAcademic = (params: number): Promise<IResponse<IVideo>> =>
    this.delete(`${GroupsEndpoints.VideoDeleteForAcademic}/${params}`)

  updateVideoStatusForAdmin = (params: IUpdateStatusRequest): Promise<IResponse<IVideo>> =>
    this.put(`${GroupsEndpoints.VideoUpdateStatusForAdmin}/${params.id}`, params.data)

  updateVideoStatusForAcademic = (params: IUpdateStatusRequest): Promise<IResponse<IVideo>> =>
    this.put(`${GroupsEndpoints.VideoUpdateStatusForAcademic}/${params.id}`, params.data)

  addHomeworkForAdmin = (params: IAddHomeworkRequest): Promise<IResponse<Homework>> =>
    this.post(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAdmin}`, params)

  addHomeworkForAcademic = (params: IAddHomeworkRequest): Promise<IResponse<Homework>> =>
    this.post(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAcademic}`, params)

  getHomeworksForAdmin = (params: IGetHomeworkRequest): Promise<IResponse<IGetHomeworkResponse>> =>
    this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${params.id}${GroupsEndpoints.Homework}`, { params })

  getHomeworksForAcademic = (params: IGetHomeworkRequest): Promise<IResponse<IGetHomeworkResponse>> =>
    this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${params.id}${GroupsEndpoints.Homework}`, {
      params,
    })

  getHomeworkForAdmin = (params: IGetHomeworks): Promise<IResponse<IHomeworkResponse>> =>
    this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${params.id}${GroupsEndpoints.HomeworkChats}`, {
      params: params.data,
    })

  getHomeworkForAcademic = (params: IGetHomeworks): Promise<IResponse<IHomeworkResponse>> => {
    const { id, ...data } = params.data

    return this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${id}${GroupsEndpoints.HomeworkChats}`, {
      params: data,
    })
  }

  sendMessageForAdmin = (params: ISendMessage): Promise<IResponse<Homework>> =>
    this.post(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.CheckStudentHomeworkForAdmin}`, params)

  sendMessageForAcademic = (params: ISendMessage): Promise<IResponse<Homework>> =>
    this.post(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.CheckStudentHomeworkForAcademic}`, params)

  getIncompleteHomeworksForAdmin = (
    params: IGetIncompleteHomeworks,
  ): Promise<IResponse<IGetIncompleteStudentsResponse>> =>
    this.get(
      // eslint-disable-next-line max-len
      `${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${params.groupId}${GroupsEndpoints.NotSubmittedHomeworkStudents}/${params.homeworkId}`,
    )

  getIncompleteHomeworksForAcademic = (
    params: IGetIncompleteHomeworks,
  ): Promise<IResponse<IGetIncompleteStudentsResponse>> =>
    this.get(
      // eslint-disable-next-line max-len
      `${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${params.groupId}${GroupsEndpoints.NotSubmittedHomeworkStudents}/${params.homeworkId}`,
    )

  getGroupAttendanceForAdmin = (params: IGetGroupAttendanceRequest): Promise<IResponse<IGroupAttendanceResponse>> => {
    const { id, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${params.id}/${GroupsEndpoints.Attendance}`, {
      params: data,
    })
  }

  getGroupAttendanceForAcademic = (
    params: IGetGroupAttendanceRequest,
  ): Promise<IResponse<IGroupAttendanceResponse>> => {
    const { id, ...data } = params

    return this.get(
      `${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${params.id}/${GroupsEndpoints.Attendance}`,
      {
        params: data,
      },
    )
  }

  studentAttendance = (params: IStudentAttendanceRequest): Promise<IResponse<IStudentAttendanceResponse>> =>
    this.put(`${GroupsEndpoints.AttendanceSaveForAdmin}`, params)

  studentAttendanceForAcademic = (params: IStudentAttendanceRequest): Promise<IResponse<IStudentAttendanceResponse>> =>
    this.put(`${GroupsEndpoints.AttendanceSaveForAcademic}`, params)

  studentAttendanceForAdmin = (params: IStudentAttendanceRequest): Promise<IResponse<IStudentAttendanceResponse>> =>
    this.put(`${GroupsEndpoints.AttendanceSaveForAdmin}`, params)

  getGroupExams = (params: IGetGroupExams): Promise<IResponse<IGetGroupExamsResponse>> => {
    const { id, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Exams}`, { params: data })
  }

  addSubmissions = (params: AddSubmissionReq): Promise<IResponse<IGetGroupStudentsResponse>> =>
    this.put(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.Submissions}`, params)

  getGroupStudents = (params: IGetGroupStudents): Promise<IResponse<IGetGroupStudentsResponse>> => {
    const { id, isAcademic, ...data } = params

    return this.get(
      `${GroupsEndpoints.Groups}${isAcademic ? GroupsEndpoints.ForAcademic : GroupsEndpoints.ForAdmin}/${id}${GroupsEndpoints.Students}`,
      { params: data },
    )
  }

  getStudyMonth = (): Promise<IResponse<IStudyMonth[]>> =>
    this.get(`${GroupsEndpoints.Exams}${GroupsEndpoints.StudyMonths}`)

  getGroupStudentResults = (id: number): Promise<IResponse<StudentExam[]>> =>
    this.get(`${GroupsEndpoints.Exams}/${id}${GroupsEndpoints.Students}`)

  getGroupSingleForAdmin = (params: IGetSingleGroupRequestSchedule): Promise<IResponse<IGroup>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}`, { params: params.params })

  getGroupSingleForAcademic = (params: IGetSingleGroupRequestSchedule): Promise<IResponse<IGroup>> =>
    this.get(`${GroupsEndpoints.Academic}${GroupsEndpoints.Groups}/${params.id}`, { params: params.params })

  getGroup = (params: IGetSingleGroupRequest): Promise<IResponse<IGroup>> => {
    const { groupId, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}/${groupId}`, { params: data })
  }

  getGroupForAcademic = (params: IGetSingleGroupRequest): Promise<IResponse<IGroup>> => {
    const { groupId, ...data } = params

    return this.get(`${GroupsEndpoints.Academic}${GroupsEndpoints.Groups}/${groupId}`, { params: data })
  }

  getExamParticipants = (params: number): Promise<IResponse<ExamParticipate[]>> =>
    this.get(`${GroupsEndpoints.Exams}/${params}${GroupsEndpoints.Participants}`)

  addExamForAcademic = (params: IExamRequest): Promise<IResponse<IExam>> =>
    this.post(`${GroupsEndpoints.Exam}${GroupsEndpoints.ForAcademic}`, params)

  addExamForAdmin = (params: IExamRequest): Promise<IResponse<IExam>> =>
    this.post(`${GroupsEndpoints.Exam}${GroupsEndpoints.ForAdmin}`, params)

  deleteHomeworkForAdmin = (params: number): Promise<IResponse<IHomeWorkAdd>> =>
    this.delete(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAdmin}/${params}`)

  deleteHomeworkForAcademic = (params: number): Promise<IResponse<IHomeWorkAdd>> =>
    this.delete(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAcademic}/${params}`)

  editHomeworkForAdmin = (params: IEditHomeworkRequest): Promise<IResponse<Homework>> =>
    this.put(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAdmin}/${params.id}`, params.data)

  editHomeworkForAcademic = (params: IEditHomeworkRequest): Promise<IResponse<Homework>> =>
    this.put(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAcademic}/${params.id}`, params.data)

  getSingleHomeworkForAdmin = (params: number): Promise<IResponse<Homework>> =>
    this.get(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAdmin}/${params}`)

  getSingleHomeworkForAcademic = (params: number): Promise<IResponse<Homework>> =>
    this.get(`${GroupsEndpoints.Homework}${GroupsEndpoints.ForAcademic}/${params}`)

  getAcademicGroups = (params?: IGetGroupsRequest): Promise<IResponse<IGroupsResponse>> => {
    const { id: groupId, ...data } = params!

    return this.get(
      dictionaryWithKeys(GroupsEndpoints.AcademicGroups, {
        id: groupId!,
      }),
      { params: data },
    )
  }

  getGroupsStatistics = (params?: IGetHomeworkStatistics): Promise<IResponse<IGetHomeworkStatisticsResponse>> =>
    this.get(`${GroupsEndpoints.HomeworkStatistics}${GroupsEndpoints.Students}`, { params })

  getGroupsStatisticsForAdmin = (params?: IGetHomeworkStatistics): Promise<IResponse<IGetHomeworkStatisticsResponse>> =>
    this.get(`${GroupsEndpoints.HomeworkStatistics}${GroupsEndpoints.ForStaff}${GroupsEndpoints.Students}`, { params })

  getGroupsStatisticsForAcademic = (
    params?: IGetHomeworkStatistics,
  ): Promise<IResponse<IGetHomeworkStatisticsResponse>> =>
    this.get(`${GroupsEndpoints.HomeworkStatistics}${GroupsEndpoints.ForAcademic}${GroupsEndpoints.Students}`, {
      params,
    })

  getStudentGroupStatistics = (
    params: IGetStudentHomeworkStatistics,
  ): Promise<IResponse<IGetHomeworkStatisticsByStudentRes>> => {
    const { studentId, ...data } = params

    return this.get(`${GroupsEndpoints.HomeworkStatistics}${GroupsEndpoints.ByStudent}/${studentId}`, { params: data })
  }

  getGroupCurriculums = (params: IGetGroupCurriculumsRequest): Promise<IResponse<IGetGroupCurriculumsResponse>> =>
    this.get(`${GroupsEndpoints.AttendanceCurriculum}${GroupsEndpoints.ByGroup}/${params.id}`, {
      params: params.pagination,
    })

  getGroupInfoForAdmin = (id: number): Promise<IResponse<IGetGroupInfoRes>> =>
    this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAdmin}/${id}${GroupsEndpoints.Info}`)

  getGroupInfoForAcademic = (id: number): Promise<IResponse<IGetGroupInfoRes>> =>
    this.get(`${GroupsEndpoints.Groups}${GroupsEndpoints.ForAcademic}/${id}${GroupsEndpoints.Info}`)

  getHomeworkInfoCountsForAdmin = (id: number): Promise<IResponse<IHomeworkInfoCountRes>> =>
    this.get(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.UsersStaff}${GroupsEndpoints.Count}`, {
      params: { homeworkId: id },
    })

  getHomeworkInfoCountsForAcademic = (id: number): Promise<IResponse<IHomeworkInfoCountRes>> =>
    this.get(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.Academic}${GroupsEndpoints.Count}`, {
      params: { homeworkId: id },
    })

  getJournalsForAdmin = (params: IGetJournalsReq): Promise<IResponse<GetJournalListRes>> =>
    this.get(`${GroupsEndpoints.Journal}${GroupsEndpoints.ForAdmin}`, { params })

  getJournalsForAcademic = (params: IGetJournalsReq): Promise<IResponse<GetJournalListRes>> =>
    this.get(`${GroupsEndpoints.Journal}${GroupsEndpoints.ForAcademic}`, { params })

  getJournalFilterObjectsForAdmin = (groupId: number): Promise<IResponse<IGetStudyMonthRes>> =>
    this.get(`${GroupsEndpoints.Journal}${GroupsEndpoints.ForAdmin}${GroupsEndpoints.FilterObjects}`, {
      params: { groupId },
    })

  getJournalFilterObjectsForAcademic = (groupId: number): Promise<IResponse<IGetStudyMonthRes>> =>
    this.get(`${GroupsEndpoints.Journal}${GroupsEndpoints.ForAcademic}${GroupsEndpoints.FilterObjects}`, {
      params: { groupId },
    })

  getStudents = (params: IGetStudentsRequest): Promise<IResponse<IGetStudentsRes>> =>
    this.get(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.Students}`, { params })

  getPrices = (): Promise<IResponse<IPrice[]>> => this.get(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.Prices}`)

  getFormOptions = (): Promise<IResponse<IGetFormOptionsRes>> =>
    this.get(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.FormOptions}`)

  addStudent = (data: IAddStudentReq): Promise<IResponse<IGetStudentsRes>> =>
    this.post(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.Add}`, data)

  finishStudentGroup = (data: TFinishGroupFormValuesReq): Promise<IResponse<IFinishGroupRes>> =>
    this.put(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.Stop}`, data)

  continueStudying = (params: TContinuingGroup): Promise<IResponse<IGetStudentsRes>> =>
    this.put(`${GroupsEndpoints.StudentGroups}${GroupsEndpoints.Continue}`, params)

  editHomeworkResult = (params: IEditHomeworkResult): Promise<IResponse<Homework>> => {
    const { id, data, isAdmin } = params

    return this.put(
      `${GroupsEndpoints.HomeworkChat}/${id}${isAdmin ? GroupsEndpoints.UpdateForAdmin : GroupsEndpoints.UpdateForAcademic}`,
      data,
    )
  }

  editDeadline = (params: IEditDeadlineParams): Promise<IResponse<Homework>> =>
    this.put(
      `${GroupsEndpoints.Homework}${params.isAdmin ? GroupsEndpoints.ForAdmin : GroupsEndpoints.ForAcademic}${GroupsEndpoints.Deadline}`,
      params.data,
    )

  getHomeworkUploadUrl = (data: IGetHomeworkUploadUrl): Promise<IResponse<IGetHomeworkFileUrlRes>> =>
    this.post(GroupsEndpoints.HomeWorkUpload, data)

  getFailureExamReasons = (): Promise<IResponse<IExamFailureReason[]>> => this.get(`${GroupsEndpoints.ExamFailingExam}`)

  addExam = (params: MakeExamInfo): Promise<IResponse<IExam>> => this.post(GroupsEndpoints.Exams, params)

  getSingleExam = (params: number): Promise<IResponse<IExam>> => this.get(`${GroupsEndpoints.Exams}/${params}`)

  editExam = (params: EditExamInfo): Promise<IResponse<IExam>> =>
    this.patch(`${GroupsEndpoints.Exams}/${params.id}`, params.data)

  getGroupResultCount = (params: number): Promise<IResponse<IHomeworkInfoCountRes>> =>
    this.get(`${GroupsEndpoints.Exams}/${params}${GroupsEndpoints.Chats}${GroupsEndpoints.Count}`)

  getResults = (params: ExamResultsReq): Promise<IResponse<ExamResult[]>> =>
    this.get(`${GroupsEndpoints.Exams}/${params.examId}${GroupsEndpoints.Chats}${GroupsEndpoints.Results}`, {
      params: params.info,
    })

  getExamChats = (params: ExamChatReq): Promise<IResponse<ExamChatRes[]>> =>
    this.get(`${GroupsEndpoints.Exams}/${params.id}${GroupsEndpoints.Chats}`, { params: params.data })

  checkExam = (params: ExamBody): Promise<IResponse<IExam>> =>
    this.put(`${GroupsEndpoints.Exams}/${params.id}${GroupsEndpoints.Chats}${GroupsEndpoints.Check}`, params.data)

  changeExamDeadline = (params: ChangeExamDate): Promise<IResponse<IExam>> =>
    this.put(`${GroupsEndpoints.Exams}/${params.id}${GroupsEndpoints.Deadline}`, params.data)

  announceExam = (params: AnnounceExamReqType): Promise<IResponse<AnnounceExam>> =>
    this.post(`${GroupsEndpoints.Exams}/${params.id}${GroupsEndpoints.Announce}`, params.data)

  sendPhotoUrl = (params: SendPhotoReq): Promise<IResponse<ILessonsResponse>> =>
    this.post(`${GroupsEndpoints.AttendanceSaveAcademic}`, params)

  getTeacherAttendance = (params: GetTeacherAttendanceReq): Promise<IResponse<TeacherAttendanceRes>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.StaffAttendances}`, { params })
}

export const groupsApi = new GroupsApi(config)
