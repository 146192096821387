import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Flex, message, Modal, Table, Typography } from 'antd'
import classNames from 'classnames/bind'
import { ScheduleItem } from '@/api/groups'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { fullTimeToFormattedDate, getFormattedTimeFromParams } from '@/utils/date'

import styles from './teacher-schedule.module.scss'

const cn = classNames.bind(styles)

export const TeacherSchedule = observer(() => {
  const { groupsStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()

  const handleCLose = () => {
    groupsStore.setTeacherSchedule(false)
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (_: ScheduleItem, item: ScheduleItem, index: number) => <span>{index + 1}</span>,
      },
      {
        title: mainDictionary.teacherName,
        render: (item: ScheduleItem) => <span>{`${item.staff?.firstName} ${item.staff?.lastName}`}</span>,
      },
      {
        title: mainDictionary.iRole,
        render: (item: ScheduleItem) => <span>{item.staff?.role?.name}</span>,
      },
      {
        title: mainDictionary.dLessonDays,
        render: (item: ScheduleItem) => <span>{item?.daysOfWeek.join('-')}</span>,
      },
      {
        title: mainDictionary.dLessonTime,
        render: (item: ScheduleItem) => (
          <span>{`${getFormattedTimeFromParams(item.beginTime)} - ${getFormattedTimeFromParams(item.endTime)}`}</span>
        ),
      },
      {
        title: mainDictionary.schedulePeriod,
        render: (item: ScheduleItem) => (
          <span>{`${fullTimeToFormattedDate(item?.startDate)} - ${fullTimeToFormattedDate(item.endDate)}`}</span>
        ),
      },
      {
        title: mainDictionary.room,
        render: (item: ScheduleItem) => <span>{item?.classroomName || '-'}</span>,
      },
    ],
    [],
  )

  const handleRowClick = (record: ScheduleItem) => {
    const staffGroupId = groupsStore.staffGroup?.id

    if (!staffGroupId) return

    if (!record.daysOfWeek?.length) {
      message.warning(mainDictionary.teacherPermission)

      return
    }

    handleCLose()

    if (location.pathname === ROUTES.myGroups) {
      const myGroupLink = ROUTES.myGroupsSingle
        .replace(':id', String(staffGroupId))
        .replace(':staffId', String(record.staff?.id))
        .replace(':scheduleId', String(record?.id))

      navigate(myGroupLink)
    } else {
      const groupLink = ROUTES.groupSingle
        .replace(':id', String(staffGroupId))
        .replace(':staffId', String(record.staff?.id))
        .replace(':scheduleId', String(record?.id))

      navigate(groupLink)
    }
  }

  return (
    <Modal
      className={cn('teachers-modal')}
      open={groupsStore.teacherSchedule}
      onCancel={handleCLose}
      onOk={handleCLose}
      title={groupsStore.staffGroup?.name}
      width={1200}
    >
      <Flex justify="center">
        <Typography.Title level={5}>{mainDictionary.schedule}</Typography.Title>
      </Flex>

      <div className={cn('teachers-modal__wrapper')}>
        <div>
          <Typography.Title level={5}>{mainDictionary.activeSchedules}</Typography.Title>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={groupsStore.groupTeachers?.activeScheduleItems!}
            className={cn('teachers-modal__table')}
            onRow={(record: ScheduleItem) => ({
              onClick: handleRowClick.bind(null, record),
            })}
          />
        </div>
        <div className={cn('teachers-modal__inactive')}>
          <Typography.Title level={5}>{mainDictionary.otherSchedules}</Typography.Title>

          <Table
            columns={columns}
            bordered
            dataSource={groupsStore.groupTeachers?.othersScheduleItems!}
            className={cn('teachers-modal__table')}
            pagination={false}
            onRow={(record: ScheduleItem) => ({
              onClick: handleRowClick.bind(null, record),
            })}
          />
        </div>
      </div>
    </Modal>
  )
})
