import { observer } from 'mobx-react'
import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Space } from 'antd'
import { Button } from '@/ant'
import { IVideo } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { useStores } from '@/stores'
import { VIDEO_KEYS } from '@/stores/groups'
import { useCheckMyGroup } from '@/utils/hooks'
import { VideoEdit } from './VideoEdit'

interface IProps {
  video: IVideo
}

export const VideoActions = observer(({ video }: IProps) => {
  const { groupsStore } = useStores()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.allGroupsVideoUpload,
    option: PermissionOptions.Update,
  })
  const [isAvailableEditMyGroup] = useCheckPermission({
    module: permissionObjectTypes.myGroupsVideoUpload,
    option: PermissionOptions.Update,
  })
  const academic = useCheckMyGroup()

  const handleDelete = async () => {
    groupsStore.setVideoLoader(true)
    try {
      if (groupsStore.isAcademic || academic) {
        await groupsStore.deleteVideoForAcademic(video.id)
      } else {
        await groupsStore.deleteVideoForAdmin(video?.id)
      }

      const videoInfo = {
        value: groupsStore.key,
        keys: VIDEO_KEYS,
        groupId: Number(groupsStore.groupId),
      }

      if (groupsStore.isAcademic || academic) {
        await groupsStore.getStaffVideos(videoInfo)
      } else {
        await groupsStore.getVideos(videoInfo)
      }

      addSuccessNotification(mainDictionary.successDelete)
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    } finally {
      groupsStore.setVideoLoader(false)
    }
  }

  const handleConfirmDelete = () => {
    handleDelete()
  }

  if (!isAvailableEdit && !isAvailableEditMyGroup && video.isUploading) {
    return
  }

  return (
    <Space className="action-wrapper">
      <VideoEdit video={video} />

      <Popconfirm title={mainDictionary.sureToDeleteVideo} onConfirm={handleConfirmDelete}>
        <Button danger icon={<DeleteOutlined />} />
      </Popconfirm>
    </Space>
  )
})
