import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Input, Modal } from 'antd'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { IChangeLoginRequest } from '@/stores/profile'

export const ChangeLogin = observer(() => {
  const { profileStore, appStore } = useStores()
  const [form] = Form.useForm()
  const profile = appStore.profile
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    profileStore.setChangeLogin(false)
  }

  const handleFinish = (values: IChangeLoginRequest) => {
    profileStore
      .changeLoginRequest(values)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successfullyChangedLogin)
          handleClose()
          appStore.getProfile()
        }
      })
      .catch(addCatchNotification)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (profile?.login) {
      form.setFieldsValue({ newLogin: profile?.login })
    }
  }, [profile?.login])

  const handleSubmit = () => {
    const { newLogin } = form.getFieldsValue()

    if (newLogin.length) {
      setLoading(true)
      form.submit()
    } else {
      form.setFields([{ errors: ['Required'], name: 'newLogin' }])
    }
  }

  return (
    <Modal
      open={profileStore.changeLogin}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      title={mainDictionary.changeLogin}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item name="newLogin" label={mainDictionary.enterLogin} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
})
