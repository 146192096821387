import dayjs from 'dayjs'
import { IMethod } from '@/api/gamification/types'
import gamificationDictionary from '@/dictionary'
import { GamificationMethod } from './types'

const today = dayjs().toString()

export const METHODS: IMethod[] = [
  {
    id: GamificationMethod.GivenFromAdmin,
    name: 'givenByAdmdin',
    displayName: gamificationDictionary.givenByAdmin,
    createdAt: today,
  },
  {
    id: GamificationMethod.ParticipateLesson,
    name: 'participateLesson',
    displayName: gamificationDictionary.participateLesson,
    createdAt: today,
  },
  {
    id: GamificationMethod.DoHomework,
    name: 'doHomework',
    displayName: gamificationDictionary.doHomework,
    createdAt: today,
  },
  {
    id: GamificationMethod.ParticipateExam,
    name: 'participateExam',
    displayName: gamificationDictionary.participateExam,
    createdAt: today,
  },
  {
    id: GamificationMethod.PassExam,
    name: 'passExam',
    displayName: gamificationDictionary.passExam,
    createdAt: today,
  },
]
