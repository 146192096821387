export const faceIdDictionary = {
  entered: 'Kirdi',
  cameOut: 'Chiqdi',
  nowAvailable: 'Hozirda mavjud',
  byStatus: "Status bo'yicha",
  byRole: "Rol bo'yicha",
  byUser: 'Foydalanuvchi bo’yicha',
  sortByDateAndTime: 'Sana va vaqt bo’yicha',
  direction: "Yo'nalishi",
  iGroups: 'Guruhlari',
  inOffice: 'Hozir mavjud',
  noData: 'Ma`lumot mavjud emas',
  userID: 'User ID',
  userBranch: 'Filiali',
  device: 'Qurilma',
  imageNotFound: 'Rasm topilmadi',
}
