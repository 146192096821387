import { IGroupStatus, ILesson, IStaff } from '@/api/groups'

export type KeyType = [string, string]

export enum RoleTypes {
  STAFF = 1,
  ACADEMIC = 2,
}

export interface StaffGroupDetail {
  title: string
  staff: IStaff[]
}

export interface IAcademicGroupInfo {
  id: number
  name: string
  staffDetail: StaffGroupDetail[]
  studentsCount: number
  startedDate: Date
  endedDate: Date
  status: IGroupStatus
  lessons: ILesson
  beginTime: string
  endTime: string
  staffGroups: IAcademicGroupInfo[]
}

export type TAcademicStatus = {
  id: number
  info: {
    isActive: boolean
  }
}
