import { AxiosProgressEvent } from 'axios'
import { LogTypes } from '@/api/instance'

export type TInitial = {
  version: string
}

export interface IGetSynchParams {
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
}

export enum SynchronizeAvailablePages {
  Students = 1,
  Teachers,
  Groups,
  GamificationStudents,
  GamificationConnection,
}

export interface SynchronizationState {
  isVisible: boolean
  synchronizeType: SynchronizeAvailablePages
  isAcademic?: boolean
}

export interface ILoginModal {
  isVisible: boolean
  login: string | null
}

export interface ILogSendRequestParams {
  logType?: LogTypes
  text: string
}
