import { FC } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import classnamesBind from 'classnames/bind'
import { IStudents } from '@/api/students'
import { ROUTES } from '@/constants'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { getFullName } from '@/utils'
import { IStudentProps } from '../types'

import styles from './student-fullname.module.scss'

const cn = classnamesBind.bind(styles)

export const StudentFullname: FC<IStudentProps> = observer(({ student }: IStudentProps) => {
  const toLink = `${ROUTES.students}/${student.id}`

  const [isAvailableSee] = useCheckPermission({
    module: permissionObjectTypes.studentDetail,
    option: PermissionOptions.Read,
  })

  if (!isAvailableSee) return <span>{getFullName<IStudents>(student)}</span>

  return (
    <Link to={toLink} className={cn('student__fullname')}>
      {getFullName<IStudents>(student)}
    </Link>
  )
})
