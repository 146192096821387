import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import { Profile } from './lazy'

export const profileRouters = [
  {
    path: ROUTES.profile,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.profile}>
        <Profile />
      </AvailableComponent>
    ),
  },
]
