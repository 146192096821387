import classNames from 'classnames/bind'

import styles from './styles.module.scss'
const cn = classNames.bind(styles)

export const DownloadIcon = () => (
  <div className={cn('upload')}>
    <div className={cn('upload__btn-container')}>
      <svg
        className={cn('upload__arrow')}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="28"
        viewBox="0 0 16 16"
        version="1.1"
        xmlSpace="preserve"
      >
        <path id={cn('upload__arrow')} d="M6 16l4 0 0-8 6 0 -8-8 -8 8 6 0 0 8Z" fill="#fff" />
      </svg>
    </div>
  </div>
)
