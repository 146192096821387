const mobileApp = 'Mobil ilova'

export const mobileAppDictionary = {
  mobileApp,
  byUpdateStatus: `Yangilanish holati bo'yicha`,
  appVersion: 'Ilova versiyasi',
  updateStatus: `Yangilanish holati`,
  required: 'Majburiy',
  unRequired: 'Majburiy emas',
  byChange: `O'zgartirilish bo'yicha`,
  byDateRange: `Vaqt oralig'i`,
  versionName: 'Versiya nomi',
  byWhom: 'Kim tomonidan',
  addedDate: `Qo'shilgan vaqti`,
  createNewAppVersion: `Yangi ilova versiya qo'shish`,
  newAppVersion: `Yangi ilova versiya`,
  enterNewVersion: `Yangi versiyani kiriting`,
}
