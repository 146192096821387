import { EditOutlined } from '@ant-design/icons'
import { Button, Card, Typography } from 'antd'
import classNames from 'classnames/bind'
import { generateUniqueKeys } from '@/utils/generate'

import styles from '../profile.module.scss'

const cn = classNames.bind(styles)

const passwordDots = Array(8)
  .fill('foo')
  .map((value, index) => <span key={generateUniqueKeys(index)} className={cn('dots-wrapper__item')} />)

interface ICardType {
  title?: string
  handleClick?: () => void
  isLogin?: boolean
  login?: string
  isNotificationSettings?: boolean
}

export const ActionCard = ({ title, handleClick = () => null, isLogin, login, isNotificationSettings }: ICardType) => (
  <Card className={cn('profile__card')}>
    <div className={cn('profile__wrapper')}>
      <Typography.Title level={5}>{title}</Typography.Title>

      <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={handleClick} />
    </div>

    <div className={cn('dots-wrapper')}>{isLogin ? login : isNotificationSettings ? null : passwordDots}</div>
  </Card>
)
