import { IAcademicGroupInfo, TAcademicStatus } from '@/stores/academic'
import { dictionaryWithKeys } from '@/utils'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { ITeacherPassword } from '../users/types'
import { AcademicEndpoints } from './endpoints'
import {
  IAcademicData,
  IAcademicGroupsResponse,
  IAcademicProfileAdd,
  IAcademicRequiest,
  IAcademicResponse,
  IAcademicRoles,
  IAcademicRolesParams,
  IBlockAcademicRequest,
  ISingleAcademicparams,
  ISuccessResponse,
  ResetPassword,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AcademicApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAcademic = (params: IAcademicRequiest): Promise<IResponse<IAcademicResponse>> =>
    this.get(dictionaryWithKeys(AcademicEndpoints.Academic), { params })

  getSingleAcademic = (params: number): Promise<IResponse<IAcademicData>> =>
    this.get(
      dictionaryWithKeys(AcademicEndpoints.AcademicSingle, {
        id: params,
      }),
    )

  getTeacherRoles = (params: IAcademicRolesParams): Promise<IResponse<IAcademicRoles>> =>
    this.get(dictionaryWithKeys(AcademicEndpoints.TeacherRoles), { params })

  editAcademic = (params: IBlockAcademicRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${AcademicEndpoints.Academic}/${params.id}`, params)

  resetPassword = (params: ISingleAcademicparams): Promise<IResponse<ResetPassword>> =>
    this.put(
      dictionaryWithKeys(AcademicEndpoints.AcademicResetPassword, {
        id: params.id,
      }),
    )

  getAcademicGroups = (params: ISingleAcademicparams): Promise<IResponse<IAcademicGroupsResponse>> =>
    this.get(
      dictionaryWithKeys(AcademicEndpoints.AcademicGroups, {
        id: params.id,
      }),
      { params: params.data },
    )

  getGroupAcademics = (params: number): Promise<IResponse<IAcademicGroupInfo>> =>
    this.get(
      dictionaryWithKeys(AcademicEndpoints.AcademicGroups, {
        id: params,
      }),
    )

  addProfileTeacher = (params: IAcademicProfileAdd): Promise<IResponse<ITeacherPassword>> =>
    this.post(`${AcademicEndpoints.AcademicSignUpAcademics}/${params.id}`, {
      login: params.login,
      roleId: params.roleId,
      genderType: params.genderType,
    })

  changeAcademicStatus = (data: TAcademicStatus): Promise<IResponse<IAcademicData>> =>
    this.put(`${AcademicEndpoints.Academic}/${data.id}${AcademicEndpoints.UpdateStatus}`, data.info)
}

export const academicApi = new AcademicApi(config)
