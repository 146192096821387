import { observer } from 'mobx-react'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { IStaffParams } from '@/api/staff'
import { StaffsWorkStatus } from '@/api/students'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { staffStore } from '@/stores/staff'
import { StaffStatusProps } from '../../types'

export const StaffBlock = observer(({ staff }: StaffStatusProps) => {
  const { page, perPage, keys, isActive, workStatus, staffSystemStatus, value, orderBy, roleId, getStaff } = staffStore

  const getData = async () => {
    try {
      const requestData: IStaffParams = {
        perPage,
        page,
        keys,
        workStatus,
        isActive,
        value,
        orderBy,
        roleId,
        staffSystemStatus,
        officeIds: staffStore.officeIds || undefined,
      }

      await getStaff(requestData)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    }
  }

  const handleChangeStatus = () => {
    staffStore
      .changeStudentStatus({
        id: staff.id,
        isActive: !staff.isActive,
      })
      .then((res) => {
        if (res.success) {
          const successTitle = staff.isActive
            ? mainDictionary.successfullyBlocked
            : mainDictionary.successfullyUnBlocked

          addSuccessNotification(successTitle)
          getData()
        }
      })
      .catch(addCatchNotification)
  }

  const isNotWorking = staff.workStatus === StaffsWorkStatus.NotWorking

  if (isNotWorking) {
    return <Button onClick={handleChangeStatus} icon={<UnlockOutlined />} danger disabled />
  }

  const tooltipTitle = staff.isActive ? mainDictionary.blockStaffConfirm : mainDictionary.unBlockStaffConfirm
  const buttonIcon = staff.isActive ? <LockOutlined /> : <UnlockOutlined />

  return (
    <Popconfirm onConfirm={handleChangeStatus} placement="left" title={tooltipTitle}>
      <Button icon={buttonIcon} danger />
    </Popconfirm>
  )
})
