import { GroupStatus } from '@/api/gamification'
import { SortStatistic } from '@/pages/Gamification/types'
import { StudentStatus } from '@/pages/Users/Students/types'

export interface IAddActionRequest {
  methodId: number
  name: string
}

export interface IMethod {
  id: number
  name: string
  displayName: string
  createdAt: string
}

export interface IGetMethodsRequest {
  page?: number
  perPage?: number
  joinActions?: boolean
}

export interface IAward {
  range: number
  xp: number
  coin: number
  condition?: string
}

export interface IAddActionLevelRequest {
  awards: IAward[]
  actionId: number
}

export type IAddActionLevelForm = Omit<IAddActionLevelRequest, 'actionId'>

export type IEditActionLevel = {
  data: IAddActionLevelRequest
  id: number
}
export interface IEditMethodRequest {
  id: number
  data: {
    displayName: string
  }
}
export interface IAddLevelRequest {
  requiredXp: number
  levelId?: number
  xpProportion: number
}

export type IAddLevelForm = Omit<IAddLevelRequest, 'categoryId'>

export interface IDeleteInfo {
  id: number
  data: {
    id: number
  }
}

export interface IGetGroupActionRequest {
  page?: number
  perPage?: number
  keys?: string[]
  value?: string
  sortBy?: string
  orderBy?: string
  fromByStartedDate?: string
  toByStartedDate?: string
  fromByEndedDate?: string
  toByEndedDate?: string
  status?: GroupStatus
  teacher?: string
  assistant?: string
  studentsCount?: string | null
  categoryId?: string
  staff?: string
  officeIds?: number[]
  courseId?: number
  learningTypeId?: number
  teacherId?: number
}

export interface IGetStaffGroupActions {
  groupId?: string
  actionId?: string
  perPage?: number
  page?: number
}

export interface ISelectOptionBody {
  label: string
  value: number
}

export interface ISelectOptions {
  label: string
  options?: ISelectOptionBody[]
}

export interface IUpdateSelectIds {
  id: number
  data: {
    actionIds: number[]
  }
}

export interface IUpdateActionGroups {
  id: number
  data: {
    groupIds: number[]
  }
}

export const enum Orders {
  Ascend = 'ASC',
  Descend = 'DESC',
}

export enum Categories {
  Programming = 1,
  Design = 2,
  Marketing = 3,
}

export interface IGetStatistics {
  page?: number
  perPage?: number
  sortBy?: SortStatistic | string
  orderBy?: Orders
  key?: string
  groupId?: string
  level?: number
  statusId?: number
  courseId?: number
  categoryId?: Categories | string | null
  startDate?: string
  endDate?: string
  officeId?: number
}

export interface IAddStudentAwards {
  studentId: number
  categoryId: string | Categories
  comment: string
  xp: number
  coin: number
}

export interface IGetStudentAwards {
  page?: number
  perPage?: number
  categoryId?: number | null
  studentId: number
  methodId?: number
}

export interface IEditDeedRequest {
  id: number
  data: {
    name: string
  }
}

export enum MethodIds {
  Lesson = 1,
  Homework = 2,
  // participateExam = 3,
  // passExam = 4,
}

export interface IGetCategoriesRequest {
  page: number
  perPage: number
}

export interface IGetGroupRequest {
  data: {
    categoryId: number | null
    studentId: number
  }
}

export interface IGetCoursesParams {
  categoryId?: string | null
}

export interface IGetActionParams {
  page: number
  perPage: number
}

export enum GamificationMethod {
  GivenFromAdmin = 0,
  ParticipateLesson = 1,
  DoHomework,
  ParticipateExam,
  PassExam,
}

export enum LevelAwardRange {
  ParticipateLessonLate = 200,
  ParticipateLessonOnTime = 300,
  ParticipateExam = 400,
}

export const LEVEL_AWARD_RANGES = [1, 60, 71, 86]

export enum LevelAwardRanges {
  Awful = 1,
  Satisfactory = 60,
  Top = 71,
  High = 86,
  Extra = 95,
}

export interface IAddParticipateLessonDeed {
  awards: IAward[]
  actionId: number
}

export interface IGetStatusesParams {
  categoryId?: string | Categories | null
  group?: string
  officeId?: number
}

export type GetGroupAttendanceReq = {
  studentId: number
  lessonId: number
}

export type GetStudentGroupTypes = {
  studentId: number
}

export type GetTheBestOnesReq = {
  page: number
  perPage: number
  value?: string
  keys?: string[]
}

export type GetAllGroupMembersReq = {
  page: number
  perPage: number
  keys?: string[]
  value?: string
  categoryId?: number
  statusId?: number
  systemStatus?: number | null
  officeIds?: number[]
}

export type AddNominationReq = {
  studentId: number
  officeId: number
  categoryId: number
  nomination: string
}

export type EditNominationReq = {
  id: number
  data: AddNominationReq
}
