import { createRef, useEffect, useMemo, useRef, useState } from 'react'
import classnamesBind from 'classnames/bind'
import { IBreadcrumb } from '@/stores/breadcrumb'
import { Button } from '../Button'
import { SEPARATOR_CHAR } from '../constants'
import { Menu } from '../Menu'
import { Portal } from '../Portal'
import { configMoreBreadcrumbItems } from '../utils'
import { Item } from './Item'

import styles from './breadcrumbs.module.scss'

const cn = classnamesBind.bind(styles)

type Props = { items: IBreadcrumb[] }

export const WithButtonBreadcrumb = ({ items }: Props) => {
  const [isVisibleMenu, setIsVisibleMenu] = useState(false)
  const buttonRef = createRef<HTMLButtonElement>()
  const menuRef = createRef<HTMLDivElement>()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const dotRef = useRef<HTMLElement>(null)
  const { first, lastBefore, last, menuItems } = useMemo(() => configMoreBreadcrumbItems(items), [items])

  const handleClick = () => {
    setIsVisibleMenu(!isVisibleMenu)
  }

  const handleClickBtn = (event: MouseEvent) => {
    if (dotRef.current !== event.target) {
      setIsVisibleMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickBtn)

    return () => {
      window.removeEventListener('click', handleClickBtn)
    }
  }, [])

  return (
    <div ref={wrapperRef}>
      {first && (
        <>
          <Item {...first} />
          <li className={cn('breadcrumb-items__item', 'separator')}>{SEPARATOR_CHAR}</li>
        </>
      )}

      <Item>
        <Button ref={buttonRef}>
          <span ref={dotRef} onClick={handleClick} className={cn('breadcrumb-items__dots')}>
            &middot;&middot;&middot;
          </span>
        </Button>
      </Item>

      <li className={`${styles['breadcrumb-items__item']} ${styles.separator}`}>{SEPARATOR_CHAR}</li>

      {lastBefore && (
        <>
          <Item {...lastBefore} />

          <li className={`${styles['breadcrumb-items__item']} ${styles.separator}`}>{SEPARATOR_CHAR}</li>
        </>
      )}

      {last && <Item {...last} />}

      {isVisibleMenu && menuItems && (
        <Portal elementRef={buttonRef}>
          <div ref={menuRef} className={cn('menu-block')}>
            <Menu items={menuItems} />
          </div>
        </Portal>
      )}
    </div>
  )
}
