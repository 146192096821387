import { ValueOfPermissionObjectTypes } from '@/modules/permission'
import { IMenuItems } from './types'

export const getMenu = (
  menuItemsPermissionsKeys: ValueOfPermissionObjectTypes[],
  menuItems?: IMenuItems[],
): IMenuItems[] =>
  menuItems
    ?.filter((menuItem) => menuItemsPermissionsKeys.includes(menuItem.name))
    .map(({ children, ...itemRest }) => ({
      ...itemRest,
      ...(children && children.length ? { children: getMenu(menuItemsPermissionsKeys, children) } : {}),
    })) || []
