import { useMemo } from 'react'
import { PermissionOptions, ValueOfPermissionObjectTypes } from '@/modules/permission'
import { useStores } from '@/stores'

interface IProps {
  option: PermissionOptions
  module: ValueOfPermissionObjectTypes
}

export const useCheckPermission = ({ option, module }: IProps) => {
  const { permissionsStore } = useStores()

  const isValid = useMemo(
    () => permissionsStore?.structuredPermissions?.[module]?.permissions?.includes(option),
    [permissionsStore.structuredPermissions],
  )

  return [isValid]
}
