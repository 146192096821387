import { HhOffice } from '@/pages/Controls/BranchOffice/types'
import { AcStatuses } from '@/pages/Users/Students/types'
import { StudentSystemStatus } from '../controls/types'
import { IGroupStatus } from '../groups/types'
import { StudentStatus as StudentActionStatus } from '../settings/types'
import { IPagination } from '../types'

export interface StudentParams extends IPagination {
  keys?: string[]
  value?: string
  orderBy?: string
  categoryId?: number
  statusId?: number
  groupId?: number
}

export interface IStatus {
  id: number
  hName: string
  createdAt: string
}

export interface IAgents {
  firstName: string
  id: number
  mobile: string
  phone: string
  whoIs: string
  givenFrom: string
  passportNumber: string
  passportSeria: string
  pinfl: string
  address: string
  lastName: string
  middleName: string
}

export interface IStudents {
  acStatus?: AcStatuses
  [key: string]: any
  categories: Category[] | string[]
  controlAction?: StudentActionStatus
  createdAt: string
  studentId: number
  firstName: string
  isStudying?: boolean
  genderType: number
  groupName: string
  groups: IStudents[]
  hId: number
  id: number
  lastName: string
  level: number
  login: string
  phoneNumber: string
  deadline?: string
  photo: string
  silver: number
  hasPersonalData?: boolean
  status: IStatus | string
  studentGroupId: number
  xp: number
  systemStatus: StudentSystemStatus | null
  hasSmsHistory: boolean
  offices: HhOffice[]
  agents: IAgents[]
  mobile: string
  middleName: string
  phone?: string
  groupStatus: IGroupStatus
  birthCertificateNumber?: string
  birthCertificateSeria?: string
  passportNumber?: string
  passportSeria?: string
  pinfl?: string
  birthday?: string
  userId: number
  givenFrom: string
  address: string
  age?: number
}

export interface IStudentsResponse {
  students: IStudents[]
  total: number
}

export interface SingleStudentParams {
  id: number
}

export interface ChangeStudentLogin {
  newLogin: string
  id: number
}

interface StudentLoginResUser {
  id: number
  userType: number
  status: number
}

export interface ChangeStudentLoginResponse {
  email: string
  firstName: string
  genderType: number
  id: number
  lastName: string
  login: string
  phoneNumber: string
  photo: string
  role: string | number | null
  user: StudentLoginResUser
}

export interface ResetPassword {
  newPassword: string
}

export interface Category {
  createdAt: string
  hId: number
  hName: string
  id: number
  name?: string
  displayName?: string
}

export interface StatusResponse {
  createdAt: string
  hName: string
  id: number
  name?: string
}

export type FilterResponse = {
  id: number
  name: string
}

export interface CategoriesAll {
  categories: Category[]
  statuses: StatusResponse[]
  groups: FilterResponse[]
  offices: FilterResponse[]
  courses: FilterResponse[]
}

export interface IStudentSmsHistoryInitiator {
  id: number
  photo: string
  firstName: string
  lastName: string
  rating: number
  phone: string
  genderType: number
  workStatus: number
  isActive: boolean
  createdAt: string
  login: string
  role: string | null
}

export interface IStudentSmsHistoryAuthor {
  id: number
  initiator: IStudentSmsHistoryInitiator
  phone: string
  text: string
  createdAt: string
}

export interface IStudentSmsHistory {
  history: IStudentSmsHistoryAuthor[]
  total: number
}

export interface StudentSmsSendParams {
  id: number
  login: string
  password: string
  phone: string
}

export interface StudentSmsSendResponse {
  success: boolean
}

export enum StaffsWorkStatus {
  Working = 1,
  NotWorking = 2,
}

export interface GetAllGroupsByStudentRes {
  id: number
  hGroupId: number
  name: string
  office: HhOffice
  startedDate: string
  status: IGroupStatus
  endedDate: string
  hasStudent: boolean
  hName: string
  createdAt: string
}

export interface IGetStudentGroupsRes {
  groups: GetAllGroupsByStudentRes[]
}

export type xlsData = {
  type: string
  data: Buffer
}

export type IGetXlsFileResponse = {
  pinflsData: xlsData
  passportsData: xlsData
  birthCertificatesData: xlsData
}

export type Certificate = {
  courseName: string
  awardedAt: string
  certificateId: string
  isConcealed: boolean
  image: string
}

export type CertificatesRes = {
  certificates: Certificate[]
}

export enum ContractStatus {
  Waiting = 1,
  Done = 2,
}

export type Contract = {
  id: number
  title: string
  url: string
}

export type Initiator = {
  id: number
  firstName: string
  lastName: string
}

export type Contracts = {
  id: number
  contract: Contract
  initiator: Initiator
  phoneNumber: string
  signatory: string
  updatedAt: string
  status: ContractStatus
}
