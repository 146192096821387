export const faceIdDeviceDictionary = {
  additionalBranches: 'Qo’shimcha filiallar:',
  deviceLogin: 'Qurilma admin login',
  deviceName: 'Qurilma nomi',
  devicePassword: 'Qurilma admin paneli paroli',
  deviceType: 'Qurilma turi',
  deviceIpAddress: 'Qurilma ip manzili',
  enterType: 'kiriting',
  example: '111.111.111.111:80',
  faceIdDevice: 'Hikvision qurilmalari',
  faceIdDeviceAdd: 'Hikvision qurilma qo`shish',
  faceIdDeviceEdit: 'Hikvision qurilmani o`zgartirish',
  faceIdScreens: 'Hikvisionda bloklanganlar',
  ipAddress: 'Qurilma ip manzili',
  no: 'Yo`q',
  ok: 'Ha',
  recognizeSeveralBranches: 'Bir nechta filiallar o`quvchilarini taniy olish',
  selectAdditionalBranches: 'Qo`shimcha filiallarni tanlang',
  selectAdditionalBranchesPlaceholder: 'Qurilma o`quvchilarini tanishi kerak bo`lgan qo`shimcha filiallarni tanlang',
  sureDeleteFaceIdDevice:
    // eslint-disable-next-line max-len
    'Agar qurilma o’chirilsa, undagi barcha ma’lumotlar ham o’chiriladi! Ushbu qurilmani tizimdan o’chirishga aminmisiz?',
  type: 'Turi',
}
