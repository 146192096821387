import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { GroupStatus, IGroup } from '@/api/gamification'
import { StoreTitle, Table as AntTable } from '@/components/Table'
import { groupStatus, ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { groupStatusColor } from '@/pages/Gamification/constants'
import { getPaginationParams } from '@/pages/utils'
import { getPerPages } from '@/shared'
import { useStores } from '@/stores'
import { getRowIndex } from '@/utils'
import { formatDate } from '@/utils/date'

export const ByGroupTable = observer(() => {
  const { gamificationStore } = useStores()
  const navigate = useNavigate()
  const perPageFromLocalStorage = getPerPages()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.gamificationGroups,
    option: PermissionOptions.Update,
  })

  const handleOpenModal = (id: number) => {
    gamificationStore.getGroupActionDetail(id)
    gamificationStore.setByGroupInfoModal(true)
  }

  const handleClickEdit = (id: number) => {
    const route = ROUTES.gamificationConnectGroupSingle.replace(':id', String(id))

    navigate(route)
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (item: IGroup, _: any, index: number) => (
          <span>
            {getRowIndex(
              gamificationStore.groupActionFilter?.page! || 1,
              gamificationStore.groupActionFilter?.perPage! || 10,
              index,
            )}
          </span>
        ),
      },
      {
        title: mainDictionary.groupName,
        render: (item: IGroup) => <span>{item.name}</span>,
      },
      {
        title: mainDictionary.status,
        render: (item: IGroup) => <Tag color={groupStatusColor[item.status]}>{groupStatus[item.status]}</Tag>,
      },
      {
        title: mainDictionary.category,
        render: (item: IGroup) => <span>{item.category?.displayName}</span>,
      },
      {
        title: mainDictionary.startDate,
        render: (item: IGroup) => <span>{formatDate(item.startedDate) || '-'}</span>,
      },
      {
        title: mainDictionary.willEndDate,
        render: (item: IGroup) => <span>{formatDate(item.endedDate) || '-'}</span>,
      },
      {
        title: mainDictionary.info,
        render: (item: IGroup) => (
          <Button icon={<InfoCircleOutlined />} onClick={handleOpenModal.bind(null, item.id)} />
        ),
      },
      {
        title: mainDictionary.actionsCount,
        render: (item: IGroup) => <span>{item.actionsCount}</span>,
      },
      ...(isAvailableEdit
        ? [
            {
              title: mainDictionary.settings,
              render: (item: IGroup) => (
                <Button icon={<EditOutlined />} type="primary" onClick={handleClickEdit.bind(null, item.id)} />
              ),
            },
          ]
        : []),
    ],
    [gamificationStore.groupActionsByGroups?.groups],
  )

  const handlePageChange = (page: number, perPage: number) => {
    const info = {
      status: GroupStatus.ACTIVE,
      ...gamificationStore.groupActionFilter,
      page,
      perPage,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleSizeChange = (page: number, perPage: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      page,
      perPage,
    }

    gamificationStore.setGroupActionFilter(info)
  }

  useEffect(() => {
    gamificationStore.setGroupActionByGroupTableLoading(true)
    gamificationStore.setSelectedActionIds([])
    gamificationStore.setGroupActionFilter({
      ...gamificationStore.groupActionFilter,
      perPage: perPageFromLocalStorage?.groupActionPage,
    })
  }, [])

  return (
    <AntTable
      // @ts-ignore
      columns={columns}
      dataSource={gamificationStore.groupActionsByGroups?.groups}
      bordered
      rowKey={'id'}
      storeTitle={StoreTitle.GroupActionPage}
      onStoreChange={handleSizeChange}
      loading={gamificationStore.groupActionByGroupTableLoading}
      pagination={{
        ...getPaginationParams({
          total: gamificationStore.groupActionsByGroups?.total,
          notShowSizeChanger: false,
          onChange: handlePageChange,
          current: gamificationStore?.groupActionFilter?.page,
          pageSize: gamificationStore.groupActionFilter?.perPage || 10,
        }),
      }}
    />
  )
})
