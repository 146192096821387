import { Skeleton } from 'antd'
import classNamesBind from 'classnames/bind'

import styles from './menu.module.scss'

const cn = classNamesBind.bind(styles)

const menuItemSkeleton = <Skeleton.Button className={cn('menu__skeleton')} size="large" block active />

export const menuSkeletons = Array(5).map((key) => ({
  label: menuItemSkeleton,
  key,
}))
