import { Tag } from 'antd'
import mainDictionary from '@/dictionary'
import { StaffStatusProps } from '../types'

export const StaffStatus = ({ staff }: StaffStatusProps) => {
  const status = staff?.isActive
    ? mainDictionary.active.toLocaleLowerCase()
    : mainDictionary.disActive.toLocaleLowerCase()

  return <Tag color={staff?.isActive ? 'green' : 'red'}>{status}</Tag>
}
