import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Typography } from 'antd'
import classNames from 'classnames/bind'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { makeFileUrl } from '@/utils'
import { useBreadcrumbs } from '@/utils/hooks/useBreadcrumbs'
import { ActionCard } from './ActionCard/ActionCard'
import { ChangeLogin } from './ChangeLogin'
import { ChangeNotifications } from './ChangeNotifications'
import { ChangePassword } from './ChangePassword'
import { profileBreadcrumb } from './constants'

import styles from './profile.module.scss'

const cn = classNames.bind(styles)

export const Profile = observer(() => {
  const { profileStore, appStore, notificationStore } = useStores()

  const profile = appStore.profile

  useBreadcrumbs(profileBreadcrumb)

  const handleChangeLogin = () => {
    profileStore.setChangeLogin(true)
  }

  const handleChangePassword = () => {
    profileStore.setChangePassword(true)
  }

  const handleConfigureNotifications = () => {
    notificationStore.setConfigureNotifications(true)
  }

  useEffect(() => {
    notificationStore.getNotificationSettings()
  }, [])

  return (
    <div className={cn('profile')}>
      <div className={cn('profile__top')}>
        <div className={cn('profile__top-item')}>
          <Avatar src={makeFileUrl(profile?.photo as string)} size={128} icon={<UserOutlined />} />

          <Typography.Title level={4}>
            {profile?.firstName || '-'} {profile?.lastName || '-'}
          </Typography.Title>

          <Typography.Title level={5}>+{profile?.phone || '-'}</Typography.Title>

          <Typography.Title level={4}>{appStore.adminRole || '-'}</Typography.Title>
        </div>
      </div>

      <div className={cn('profile__bottom')}>
        <ActionCard login={profile?.login} handleClick={handleChangeLogin} title={mainDictionary.login} isLogin />

        <ActionCard handleClick={handleChangePassword} title={mainDictionary.password} />

        <ActionCard
          handleClick={handleConfigureNotifications}
          title={mainDictionary.notificationSettings}
          isNotificationSettings
        />
      </div>

      {profileStore.changeLogin && <ChangeLogin />}
      {profileStore.changePassword && <ChangePassword />}
      {notificationStore.isNotificationSettings && <ChangeNotifications />}
    </div>
  )
})
