// eslint-disable-next-line simple-import-sort/imports
import 'dayjs/locale/ru'
import timezone from 'dayjs/plugin/timezone'
import 'antd/dist/reset.css'
import '@/styles/global.scss'

import { BrowserRouter } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import { observer, Provider } from 'mobx-react'
import { ConfigProvider } from 'antd'
import updateLocale from 'dayjs/plugin/updateLocale'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Loading } from '@/components'
import { Router } from '@/router'
import { stores, useStores } from '@/stores'
import { theme as antdTheme } from '@/styles/theme'
import { useBootstrap } from './useBootstrap'
import { months } from '@/constants/utils'
import { useDetectBrowserClose } from '@/modules/kinescope'

dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

dayjs.tz.setDefault('Asia/Tashkent')

dayjs.updateLocale('en', {
  weekStart: 1,
  months,
})

export const App = observer(() => {
  const { authStore } = useStores()
  const [isInitiated] = useBootstrap()

  useDetectBrowserClose()

  if (isInitiated) {
    return <Loading />
  }

  return (
    <Provider {...stores}>
      <ConfigProvider csp={{ nonce: 'erp-admin' }} theme={antdTheme}>
        <BrowserRouter>
          <Router isAuth={authStore.isAuth} />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
})
