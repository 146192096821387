import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { BellOutlined, InfoCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Layout as AntdLayout, Row, Tooltip } from 'antd'
import classnamesBind from 'classnames/bind'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { Notification } from '@/Layout/Notification'
import { Breadcrumb } from '@/modules/Breadcrumb'
import { useStores } from '@/stores'
import { breadcrumbStore } from '@/stores/breadcrumb'
import { useDeviceMediaQuery } from '@/utils'
import { getFormattedFullDateFromParams } from '@/utils/date'
import { Drawer } from '../Drawer'
import { LogOut } from '../LogOut'
import { stylesInJs } from './styles'

import styles from './header.module.scss'

const cn = classnamesBind.bind(styles)

const INTERVAL_DELAY = 5_000

type Props = {
  collapsed: boolean
  onCollapsedClick: () => void
}

export const Header = observer(({ collapsed, onCollapsedClick }: Props) => {
  const { appStore, kinescopeStore } = useStores()
  const [hasUploadingFiles, setHasUploadingFiles] = useState(false)
  const { isMobile } = useDeviceMediaQuery()
  const navigate = useNavigate()
  const [uploadingFilesLinks, setUploadingFilesLinks] = useState(kinescopeStore.getUploadingFilesLinks())
  const menuItems = useMemo(
    () =>
      uploadingFilesLinks.map((page) => ({
        key: page.link!,
        label: <Link to={page.link!}>{page.name || page.link}</Link>,
      })),
    [uploadingFilesLinks?.length],
  )

  const handleBadgeClick = () => {
    navigate(ROUTES.myNotification)
  }

  useEffect(() => {
    const id = window.setInterval(() => {
      const hasUploading = kinescopeStore.hasUploadingFiles()

      setHasUploadingFiles(hasUploading)

      if (hasUploading) {
        setUploadingFilesLinks(kinescopeStore.getUploadingFilesLinks())
      }
    }, INTERVAL_DELAY)

    return () => {
      clearInterval(id)
    }
  }, [])

  const handleSynchConfirmModalClick = () => {
    appStore.setConfirmSynchModal(true)
  }

  return (
    <AntdLayout.Header className={cn('header')} style={stylesInJs.header}>
      <div className={cn('header__inner')}>
        <div className={cn('header__inner')}>
          <div>
            {isMobile ? (
              <Drawer />
            ) : (
              <Button type="text" onClick={onCollapsedClick}>
                {collapsed ? (
                  <MenuUnfoldOutlined className={cn('header__icon')} />
                ) : (
                  <MenuFoldOutlined className={cn('header__icon')} />
                )}
              </Button>
            )}
          </div>
          <Breadcrumb items={breadcrumbStore.breadcrumbs} />
        </div>

        <Row gutter={20} align="middle">
          {/* {isAvailableReadQueue && (
            <Col>
              <Tooltip color="black" title={constantsDictionary.haveQueue(appStore?.queueCount || 0)}>
                <Button>{constantsDictionary.inQueue}</Button>
              </Tooltip>
            </Col>
          )} */}
          <Col>
            {appStore.synchronization?.isVisible && (
              <Row gutter={10}>
                <Col>
                  <Tooltip
                    color="black"
                    title={`${mainDictionary.lastSynchTitle}
                      ${getFormattedFullDateFromParams(appStore.synchronizationInfo?.updatedAt!)}`}
                  >
                    <Button onClick={handleSynchConfirmModalClick} icon={<InfoCircleOutlined />}>
                      {mainDictionary.synch}
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Col>

          <Col>
            <Badge overflowCount={9} count={appStore.notificationsCount}>
              <Button onClick={handleBadgeClick} icon={<BellOutlined />} />
            </Badge>
          </Col>

          {hasUploadingFiles && (
            <Col>
              <Notification hasUploadingFiles={hasUploadingFiles} dropdownMenu={{ items: menuItems }} />
            </Col>
          )}
          <Col>
            <LogOut hasUploadingFiles={hasUploadingFiles} />
          </Col>
        </Row>
      </div>
    </AntdLayout.Header>
  )
})
