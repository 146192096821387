import { Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { TagProps } from 'antd/lib'
import classNames from 'classnames/bind'
import { Category, IStudents } from '@/api/students'
import TickIcon from '@/assets/img/tick.png'
import NotErrorIcon from '@/assets/img/x.png'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { HhOffice } from '@/pages/Controls/BranchOffice/types'
import { AcStatuses, ControlActionTypes, StudentStatus } from '@/pages/Users/Students/types'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { formatDate } from '@/utils/date'
import { StudentCategory } from './StudentCategory'
import { StudentFullname } from './StudentFullname'
import { StudentIndex } from './StudentIndex/StudentIndex'
import { StudentSms } from './StudentSms'

import styles from './students.module.scss'

const cn = classNames.bind(styles)

export const studentColumns: ColumnsType<IStudents> = [
  {
    title: 'N',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_value, _record, index) => <StudentIndex index={index} />,
  },
  {
    title: mainDictionary.tableId,
    dataIndex: 'hId',
    key: 'hId',
    align: 'center',
    render: (value) => <span>{value}</span>,
  },
  {
    title: mainDictionary.photo,
    dataIndex: 'photo',
    key: 'photo',
    align: 'center',
    render: (record) =>
      record?.length ? (
        <img className={cn('student-photo-status')} src={TickIcon} alt="tick" />
      ) : (
        <img className={cn('student-photo-status')} src={NotErrorIcon} alt="notError" />
      ),
  },
  {
    title: mainDictionary.privateInfo,
    align: 'center',
    render: (record) =>
      record?.hasPersonalData ? (
        <img className={cn('student-photo-status')} src={TickIcon} alt="tick" />
      ) : (
        <img className={cn('student-photo-status')} src={NotErrorIcon} alt="notError" />
      ),
  },
  {
    title: mainDictionary.tableFullname,
    dataIndex: 'fullName',
    key: 'fullName',
    align: 'center',
    render: (_, record) => <StudentFullname student={record} />,
  },
  {
    title: mainDictionary.branches,
    align: 'center',
    render: (value) => (
      <div>
        {value?.offices?.length === 1 ? (
          <h5>{value?.offices?.[0]?.name}</h5>
        ) : (
          value?.offices?.map((item: HhOffice) => <p key={item.id}>{item?.name},</p>)
        )}
      </div>
    ),
  },
  {
    title: mainDictionary.searchSelectLabel,
    dataIndex: 'categories',
    key: 'categories',
    align: 'center',
    render: (_, record) => <StudentCategory student={record} />,
  },
  {
    title: mainDictionary.erpStatus,
    key: 'erp-status',
    align: 'center',
    render: (value) => <>{value?.status?.name}</>,
  },
  {
    title: mainDictionary.status,
    align: 'center',
    render: (value) => {
      const isActive = value.systemStatus === StudentStatus.Active

      return (
        <Tag color={isActive ? 'green' : 'red'}>{isActive ? mainDictionary.didNotBlock : mainDictionary.blocked}</Tag>
      )
    },
  },
  {
    title: mainDictionary.createdAt,
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (value) => formatDate(value, undefined, true),
  },
  {
    title: mainDictionary.actions,
    dataIndex: 'more',
    key: 'more',
    align: 'center',
    render: (value, record) => <StudentSms student={record} />,
  },
]

export const studentCategoriesColumns: ColumnsType<Category> = [
  {
    title: 'N',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (value, record, index) => <span>{index + 1}</span>,
  },
  {
    title: mainDictionary.searchSelectLabel,
    dataIndex: 'hName',
    key: 'hName',
    align: 'center',
    render: (value) => <span>{value}</span>,
  },
]

export const studentBreadcrumbs: IBreadcrumb[] = [{ label: 'Foydalanuvchilar' }, { label: 'O`quvchilar' }]

export const studentLinkBreadcrumbs: IBreadcrumb[] = [
  { label: 'Foydalanuvchilar' },
  { label: 'O`quvchilar', link: ROUTES.students },
]

export const studentStatuses = [
  { label: mainDictionary.all, value: null },
  { label: mainDictionary.blocked, value: StudentStatus.InActive },
  { label: mainDictionary.didNotBlock, value: StudentStatus.Active },
]

export const studentPhotos = [
  { label: mainDictionary.all, value: null },
  { label: mainDictionary.available, value: true },
  { label: mainDictionary.unavailable, value: false },
]

export const allOption = {
  label: mainDictionary.all,
  value: null,
}

export const studentPhotosStatuses: Record<AcStatuses, string> = {
  [AcStatuses.Waiting]: mainDictionary.checking,
  [AcStatuses.Failed]: mainDictionary.notSuitable,
  [AcStatuses.Ready]: mainDictionary.suitable,
  [AcStatuses.ImgNotUpload]: mainDictionary.imgDidNotUpload,
}

export const studentPhotoStatusesColor: Record<AcStatuses, TagProps['color']> = {
  [AcStatuses.Waiting]: 'yellow',
  [AcStatuses.Failed]: 'red',
  [AcStatuses.Ready]: 'green',
  [AcStatuses.ImgNotUpload]: 'default',
}

export const studentStatus = [ControlActionTypes.ACTIVE, ControlActionTypes.NOT_CALCULATE_STATISTIC].map((item) =>
  item.toString(),
)
