import { Alert } from 'antd'
import classNames from 'classnames/bind'
import mainDictionary from '@/dictionary'

import styles from './no-internet.module.scss'

const cn = classNames.bind(styles)

export const NoInternet = () => (
  <Alert className={cn('no-internet')} closable showIcon type="error" message={mainDictionary.problemWithInternet} />
)
