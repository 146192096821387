import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { useDebounceValue } from 'usehooks-ts'
import { GroupStatus } from '@/api/gamification'
import mainDictionary from '@/dictionary'
import { groupActionsStatus } from '@/pages/Gamification/constants'
import { allOption } from '@/pages/Groups/constants'
import { getPerPages } from '@/shared'
import { useStores } from '@/stores'
import { YEAR_FORMAT } from '@/utils'

import styles from './filter.module.scss'

const cn = classNames.bind(styles)

export const Filter = observer(() => {
  const [form] = Form.useForm()
  const { gamificationStore, studyDepartment } = useStores()
  const [debouncedValue, setDebounceValue] = useDebounceValue('', 1000)
  const perPageFromLocalStorage = getPerPages()

  const categories = useMemo(
    () =>
      gamificationStore.groupActionCategories?.categories.map((item) => ({
        label: item?.displayName || mainDictionary.all,
        value: item?.displayName ? item?.id : null,
      })),
    [gamificationStore.groupActionCategories],
  )

  const handleSearchAcademics = (e: string) => {
    studyDepartment.getGroupActionsFilterObjects({
      page: 1,
      perPage: 10,
      academic: e,
    })
  }

  const handleChangeAcademics = (id: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      teacherId: id,
    }

    gamificationStore.setGroupActionFilter(info)
    gamificationStore.setGroupActionByGroupTableLoading(true)

    gamificationStore.getGroupActions(info)
  }

  const handleSelectCourse = (id: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      courseId: id,
      page: 1,
    }

    gamificationStore.setGroupActionFilter(info)
    gamificationStore.setGroupActionByGroupTableLoading(true)

    gamificationStore.getGroupActions(info)
  }

  useEffect(() => {
    void gamificationStore.getGroupActionCategories()
  }, [])

  const handleStatusChange = (value: GroupStatus) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      status: value!,
      page: 1,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDebounceValue(e.currentTarget.value)

    gamificationStore.setGroupActionFilter({
      ...gamificationStore.groupActionFilter!,
      value: e.currentTarget.value,
      keys: ['hName'],
      page: 1,
    })
  }

  const handleCourseSearch = (e: string) => {
    studyDepartment.getGroupActionsFilterObjects({
      page: 1,
      perPage: 10,
      course: e,
    })
  }

  const handleCategoryChange = (value: string) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      categoryId: value,
      page: 1,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleClear = () => {
    const info = {
      status: GroupStatus.ACTIVE,
      page: 1,
      perPage: gamificationStore.groupActionFilter?.perPage || 10,
    }

    gamificationStore.setGroupActionFilter(info)
    gamificationStore.setGroupActionByGroupTableLoading(true)

    form.resetFields()

    form.setFieldValue('status', GroupStatus.ACTIVE)

    gamificationStore.getGroupActions(info)

    studyDepartment.getGroupActionsFilterObjects({
      page: 1,
      perPage: 10,
    })
  }

  useEffect(() => {
    studyDepartment.getGroupActionsFilterObjects({
      page: 1,
      perPage: 10,
    })
  }, [])

  const handleChangeStartDate = (rangeValue: any) => {
    const startDate = rangeValue?.[0]?.format(YEAR_FORMAT)
    const endDate = rangeValue?.[1]?.format(YEAR_FORMAT)

    const info = {
      ...gamificationStore.groupActionFilter,
      fromByStartedDate: startDate,
      toByStartedDate: endDate,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleChangeEndDate = (rangeValue: any) => {
    const startDate = rangeValue?.[0]?.format(YEAR_FORMAT)
    const endDate = rangeValue?.[1]?.format(YEAR_FORMAT)

    const info = {
      ...gamificationStore.groupActionFilter,
      fromByEndedDate: startDate,
      toByEndedDate: endDate,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleChangeOffice = (value: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      officeIds: [value],
      page: 1,
    }

    gamificationStore.setGroupActionFilter(info)
    gamificationStore.setGroupActionByGroupTableLoading(true)

    gamificationStore.getGroupActions(info)
  }

  const handleChangeLearningType = (value: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      learningTypeId: value,
      page: 1,
    }

    gamificationStore.setGroupActionFilter(info)
    gamificationStore.setGroupActionByGroupTableLoading(true)

    gamificationStore.getGroupActions(info)
  }

  useEffect(() => {
    gamificationStore.setGroupActionFilter({
      status: GroupStatus.ACTIVE,
      ...gamificationStore.groupActionFilter,
      perPage: 10,
    })
  }, [])

  useEffect(() => {
    const info = {
      ...gamificationStore.groupActionFilter,
      status: gamificationStore.groupActionFilter?.status || undefined,
      studentsCount: gamificationStore.groupActionFilter?.studentsCount || null,
      perPage: perPageFromLocalStorage?.groupActionPage! || 10,
      page: 1,
    }

    gamificationStore.getGroupActions(info)
  }, [debouncedValue])

  useEffect(() => {
    const filter = gamificationStore.groupActionFilter

    if (!filter) return

    if (Object.keys(filter).length) {
      setDebounceValue(filter.value || '')
      form.setFieldsValue({
        groupName: filter.value,
        categoryId: filter.categoryId,
        status: filter.status || GroupStatus.ACTIVE,
        teacher: filter.teacherId,
        startDate: filter?.fromByStartedDate
          ? [dayjs(filter.fromByStartedDate), dayjs(filter.fromByEndedDate)]
          : undefined,
        endDate: filter?.toByStartedDate ? [dayjs(filter.toByStartedDate), dayjs(filter.toByEndedDate)] : undefined,
        officeId: filter?.officeIds?.[0],
        course: filter?.courseId,
        type: filter?.learningTypeId,
      })
    }
  }, [])

  return (
    <Form layout="vertical" className={cn('group-filter')} form={form}>
      <Form.Item label={mainDictionary.groupName} name="groupName">
        <Input.Search placeholder={mainDictionary.search} value={debouncedValue} onChange={handleChange} />
      </Form.Item>

      <Form.Item name="categoryId" label={mainDictionary.category}>
        <Select
          className={cn('group-filter__select')}
          options={categories}
          placeholder={mainDictionary.all}
          onChange={handleCategoryChange}
        />
      </Form.Item>

      <Form.Item name="status" label={mainDictionary.status}>
        <Select
          className={cn('group-filter__select')}
          options={groupActionsStatus}
          placeholder={mainDictionary.all}
          onChange={handleStatusChange}
        />
      </Form.Item>

      <Form.Item name="teacher" label={mainDictionary.academic}>
        <Select
          options={studyDepartment.actionAcademics}
          className={cn('group-filter__select')}
          placeholder={mainDictionary.choose}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          showSearch
          onSearch={handleSearchAcademics}
          filterSort={(optionA, optionB) =>
            (optionA?.label?.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={handleChangeAcademics}
        />
      </Form.Item>

      <Form.Item name="course" label={mainDictionary.course}>
        <Select
          options={studyDepartment.actionCourses}
          onChange={handleSelectCourse}
          onSearch={handleCourseSearch}
          placeholder={mainDictionary.choose}
          className={cn('group-filter__select')}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          showSearch
          filterSort={(optionA, optionB) =>
            (optionA?.label?.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item name="type" label={mainDictionary.typeOfStudy}>
        <Select
          options={studyDepartment.actionLearningTypes!}
          defaultValue={allOption.value}
          className={cn('group-filter__select')}
          onChange={handleChangeLearningType}
        />
      </Form.Item>

      <Form.Item label={mainDictionary.startDate} name="startDate">
        <DatePicker.RangePicker onChange={handleChangeStartDate} className={cn('group-filter__date')} />
      </Form.Item>

      <Form.Item label={mainDictionary.endDate} name="endDate">
        <DatePicker.RangePicker className={cn('group-filter__date')} onChange={handleChangeEndDate} />
      </Form.Item>

      <Form.Item name="officeId" label={mainDictionary.branches}>
        <Select
          defaultValue={allOption.value}
          placeholder={mainDictionary.chooseBranch}
          className={cn('group-filter__select')}
          options={studyDepartment.actionOffices}
          onChange={handleChangeOffice}
        />
      </Form.Item>

      <Button onClick={handleClear}>{mainDictionary.clear}</Button>
    </Form>
  )
})
