import { EnterTypes } from '@/api/staff'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { GenderTypes, RoleTypes, StaffWorkStatus } from './types'

export const isEditRole = 'edit'

export interface SingleRoleProps {
  isEdit?: boolean
}

export const genderTypes = [
  {
    label: mainDictionary.male,
    value: GenderTypes.MALE,
  },
  {
    label: mainDictionary.female,
    value: GenderTypes.FEMALE,
  },
]

export const phoneFormatChecker = /^998(\d{9})$/

export const workStatuses = [
  {
    label: mainDictionary.working,
    value: StaffWorkStatus.Working,
  },
  {
    label: mainDictionary.notWorking,
    value: StaffWorkStatus.NotWorking,
  },
]

export enum StaffStatuses {
  Active = 1,
  DeActive = 2,
}

export const filterWorkStatuses = [
  {
    label: mainDictionary.working,
    value: StaffStatuses.Active,
  },
  {
    label: mainDictionary.notWorking,
    value: StaffStatuses.DeActive,
  },
]

export const staffStatus = [
  {
    label: mainDictionary.active,
    value: true,
  },
  {
    label: mainDictionary.inActive,
    value: false,
  },
]

export enum KeyTypes {
  FirstName = 'firstName',
  LastName = 'lastName',
}

export const keys = [KeyTypes.FirstName, KeyTypes.LastName]

export const roles = [
  {
    label: mainDictionary.academic,
    value: RoleTypes.Academic,
  },
  {
    label: mainDictionary.staff,
    value: RoleTypes.Staff,
  },
]

export const actions = []

export const staffControlBreadCrummb: IBreadcrumb[] = [{ label: 'Xodimlar boshqaruvi' }]

export const staffControlLinkBreadCrummb: IBreadcrumb[] = [{ label: 'Xodimlar boshqaruvi', link: ROUTES.controls }]

export const usersBreadCrummb: IBreadcrumb[] = [{ label: 'Foydalanuvchilar' }]

export const controlUserAddBreadCrummb: IBreadcrumb[] = [
  { label: 'Xodimlar boshqaruvi', link: ROUTES.controls },
  { label: 'Foydalanuvchi qo`shish' },
]

export const userEditBreadCrummb: IBreadcrumb[] = [{ label: 'Foydalanuvchini o`zgartirish' }]

export const controlRolesAddBreadCrummb: IBreadcrumb[] = [
  { label: 'Xodimlar boshqaruvi', link: ROUTES.controls },
  { label: 'Rol qo`shish' },
]

export const controlRolesEditBreadCrummb: IBreadcrumb[] = [
  { label: 'Xodimlar boshqaruvi', link: ROUTES.controls },
  { label: 'Rol o`zgartirish' },
]

export enum UserType {
  Staff = 'staff',
  Academic = 'academic',
}

export const DeviceTypes = ['', mainDictionary.enter, mainDictionary.quit]

export const deviceTypeOptions = [
  {
    label: mainDictionary.enter,
    value: EnterTypes.ENTER,
  },
  {
    label: mainDictionary.quit,
    value: EnterTypes.EXIT,
  },
]

export const LINK_CHECKER =
  /^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2}):\d{1,5}$/

export const initialValues = { urls: [{}] }
