import { ReactNode } from 'react'
import { Layout as AntdLayout } from 'antd'
import classnamesBind from 'classnames/bind'

import styles from './content.module.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  children: ReactNode
}

export const Content = ({ children }: Props) => (
  <AntdLayout.Content className={cn('content')}>{children}</AntdLayout.Content>
)
