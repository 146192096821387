import { observer } from 'mobx-react'
import { Button, Flex, Modal } from 'antd'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { downloader } from '@/utils/downloadFile'

const modalDictionary = {
  pinflsData: 'JSHSHIR ma`lumotlari',
  passportsData: 'Passport ma`lumotlari',
  birthCertificatesData: 'Guvohnoma ma`lumotlari',
}

type Props = {
  groupName?: string
}

export const DownloadModal = observer(({ groupName }: Props) => {
  const { studentStore } = useStores()

  const handleCancel = () => {
    studentStore.setDownload(false)
    studentStore.setDownloadableFiles(null)
  }

  const handleDownload = (name: string) => {
    const foundName = studentStore.downloadableFiles?.[name as keyof typeof studentStore.downloadableFiles]

    if (foundName?.data) {
      const bufferData = new Uint8Array(foundName.data)
      const fileTypeName = modalDictionary[name as keyof typeof modalDictionary]
      const fileName = groupName ? `${groupName}/${fileTypeName}` : fileTypeName

      downloader(bufferData, fileName)
    }
  }

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleCancel}
      open={studentStore.downloadModal}
      title={mainDictionary.download}
    >
      <Flex vertical gap={10}>
        {studentStore?.downloadableFiles &&
          Object.keys(studentStore?.downloadableFiles)?.map((item) => (
            <Button onClick={handleDownload?.bind(null, item)} key={item}>
              {modalDictionary[item as keyof typeof modalDictionary]}
            </Button>
          ))}
      </Flex>
    </Modal>
  )
})
