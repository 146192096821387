import { Fragment } from 'react'
import classNames from 'classnames/bind'
import { IBreadcrumb } from '@/stores/breadcrumb'
import { SEPARATOR_CHAR } from '../constants'
import { Item } from './Item'

import styles from './breadcrumbs.module.scss'

const cn = classNames.bind(styles)

type Props = { items: IBreadcrumb[] }

export const SimpleBreadcrumb = ({ items }: Props) => (
  <>
    {items.map((item, index) => (
      <Fragment key={`${item}-${index}`}>
        {index !== 0 && <li className={cn('simple-breadcrumb__items', 'separator')}>{SEPARATOR_CHAR}</li>}
        <Item {...item} />
      </Fragment>
    ))}
  </>
)
