import classnamesBind from 'classnames/bind'
import { Icons } from '@/components/icons'

import styles from './loader.module.scss'

const cn = classnamesBind.bind(styles)

export const Loading = () => (
  <div className={cn('loader')}>
    <div className={cn('loader__logo')}>
      <span className={cn('loader__img')}>
        <Icons.Logo />
      </span>
    </div>
  </div>
)
