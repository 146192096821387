import { FC } from 'react'
import { Typography } from 'antd'
import classnamesBind from 'classnames/bind'
import mainDictionary from '@/dictionary'
import { ResetPasswordProps } from '../types'

import styles from './reset-password.module.scss'

const cn = classnamesBind.bind(styles)

export const ResetPasswordContent: FC<ResetPasswordProps> = ({ password }) => (
  <Typography.Title level={5}>
    {mainDictionary.password}&nbsp;
    <Typography.Paragraph className={cn('reset-password')} copyable code mark>
      {password}
    </Typography.Paragraph>
    &nbsp;
    {mainDictionary.resetPasswordSuccess}
  </Typography.Title>
)
