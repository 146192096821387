export const openOnTargetOpenWindow = (route: string, event: React.MouseEvent<HTMLElement>) => {
  if (!route) return

  if (event.ctrlKey || event.metaKey) {
    window.open(`${location.origin}${route}`)

    return true
  }

  return false
}
