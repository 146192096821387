import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Button, DatePicker, Flex, Form, Input, Select } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { useDebounceValue } from 'usehooks-ts'
import mainDictionary from '@/dictionary'
import { allOption } from '@/pages/Users/Staff/constants'
import { useStores } from '@/stores'
import { YEAR_FORMAT } from '@/utils'
import { ALL_ID } from '../../constants'

import styles from './filter.module.scss'

const cx = classNames.bind(styles)

const DEBOUNCE_TIME = 1000

export const Filter = observer(() => {
  const { gamificationStore } = useStores()
  const {
    statisticsFilter,
    setStatisticsFilter,
    setStatisticsTableLoading,
    courses,
    getLevelsCategoryById,
    categoryLevels,
    statuses: allStatus,
    resetStatisticsFilter,
    methods,
  } = gamificationStore

  const [debouncedValue, setDebounceValue] = useDebounceValue('', DEBOUNCE_TIME)
  const [debouncedGroupValue, setDebounceGroupValue] = useDebounceValue('', DEBOUNCE_TIME)
  const [form] = Form.useForm()

  const handleUserNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDebounceValue(e.currentTarget.value)
  }

  const methodOption = useMemo(
    () =>
      methods?.map((item) => ({
        label: item.displayName,
        value: item.id,
      })),
    [methods],
  )

  const handleChangeGroup = (e: string) => {
    gamificationStore.getStudentAwardFilterObjects({
      categoryId: gamificationStore.statisticTab === ALL_ID ? null : gamificationStore.statisticTab,
      group: e,
    })
  }

  const handleSelect = (groupId: string) => {
    const info = {
      ...statisticsFilter,
      groupId,
      page: 1,
    }

    setStatisticsFilter(info)
  }

  const groups = useMemo(
    () =>
      gamificationStore.statuses?.groups.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [gamificationStore.statuses],
  )

  const offices = useMemo(() => {
    const allOffices = gamificationStore.statuses?.offices.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    if (allOffices?.length) {
      return [allOption, ...allOffices]
    }

    return [allOption]
  }, [gamificationStore.statuses])

  const handleLevelChange = (value: number) => {
    const info = {
      ...statisticsFilter,
      level: value,
      page: 1,
    }

    setStatisticsFilter(info)
  }

  const statuses = useMemo(() => {
    const statuses = allStatus?.statuses?.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    return [allOption, ...(statuses || [])]
  }, [allStatus])

  const allCourses = useMemo(
    () =>
      courses?.courses.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [courses?.courses],
  )

  const handleCourseChange = (value: number) => {
    const info = {
      ...statisticsFilter,
      courseId: value,
      page: 1,
    }

    setStatisticsTableLoading(true)

    setStatisticsFilter(info)
  }

  const handleOfficeChange = (value: number) => {
    const info = {
      ...statisticsFilter,
      officeId: value,
      groups: null,
      page: 1,
    }

    setStatisticsTableLoading(true)

    gamificationStore.getStudentAwardFilterObjects({
      officeId: value,
    })

    setStatisticsFilter(info)

    gamificationStore.getStudentAwardFilterObjects({
      officeId: value,
    })

    form.setFieldValue('group', null)
  }

  const levels = useMemo(
    () =>
      categoryLevels?.levels.map((item) => ({
        label: `${mainDictionary.level} ${item._number}`,
        value: item._number,
      })),
    [categoryLevels],
  )

  const handleStatusChange = (value: number) => {
    const info = {
      ...statisticsFilter,
      statusId: value,
      page: 1,
    }

    setStatisticsTableLoading(true)

    setStatisticsFilter(info)
  }

  const handleMethodChange = (value: number[]) => {
    const info = {
      ...statisticsFilter,
      methods: value || null,
      page: 1,
    }

    setStatisticsTableLoading(true)

    setStatisticsFilter(info)
  }

  const handleClear = () => {
    resetStatisticsFilter()

    const methodIds = methods?.map((item) => item.id)

    const info = {
      methods: methodIds || null,
      page: 1,
    }

    setStatisticsTableLoading(true)

    form.resetFields()
    form.setFieldValue('methods', methodIds)

    setStatisticsFilter(info)

    gamificationStore.getStudentAwardFilterObjects({
      categoryId: gamificationStore.statisticTab === ALL_ID ? null : gamificationStore.statisticTab,
    })
  }

  useEffect(() => {
    if (gamificationStore.statisticTab) {
      const info = {
        ...statisticsFilter,
        key: debouncedValue || statisticsFilter?.key,
        page: 1,
        group: debouncedGroupValue,
      }

      setStatisticsTableLoading(true)

      setStatisticsFilter(info)
    }
  }, [debouncedValue, debouncedGroupValue])

  useEffect(() => {
    getLevelsCategoryById()
  }, [])

  const handleChangeDate = (rangeValue: any) => {
    const startDate = rangeValue?.[0]?.format(YEAR_FORMAT)
    const endDate = rangeValue?.[1]?.format(YEAR_FORMAT)

    const info = {
      ...statisticsFilter,
      startDate,
      endDate,
      page: 1,
    }

    setStatisticsFilter(info)
  }

  useEffect(() => {
    gamificationStore.getMethods()
  }, [])

  useEffect(() => {
    const methodIds = methods?.map((item) => item.id)

    const info = {
      ...statisticsFilter,
      methods: methodIds || null,
      page: 1,
    }

    setStatisticsTableLoading(true)

    setStatisticsFilter(info)

    form.setFieldValue('methods', methodIds)
  }, [methods])

  useEffect(() => {
    setDebounceValue(statisticsFilter?.key || '')
    setDebounceGroupValue(statisticsFilter?.groupId || '')
    form.setFieldsValue({
      name: statisticsFilter?.key,
      courseId: statisticsFilter?.courseId,
      level: statisticsFilter?.level,
      officeId: statisticsFilter?.officeId,
      group: statisticsFilter?.groupId,
      status: statisticsFilter?.statusId,
      endDate: statisticsFilter?.startDate
        ? [dayjs(statisticsFilter.startDate), dayjs(statisticsFilter.endDate)]
        : undefined,
    })

    return () => {
      setDebounceValue('')
      setDebounceGroupValue('')
      form.resetFields()
    }
  }, [gamificationStore.statisticTab])

  return (
    <Form
      className={cx('statistics-filter')}
      disabled={gamificationStore.statisticsTableLoading}
      form={form}
      layout="vertical"
    >
      <Flex gap={15} wrap="wrap" align="center">
        <Form.Item label={mainDictionary.nameOrId} name="name">
          <Input.Search onChange={handleUserNameChange} placeholder={mainDictionary.search} />
        </Form.Item>

        <Form.Item label={mainDictionary.group} name="group">
          <Select
            className={cx('statistics-filter__select', { groupSelect: true })}
            showSearch
            placeholder={mainDictionary.search}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleChangeGroup}
            onSelect={handleSelect}
            notFoundContent={null}
            options={(groups || []).map((item) => ({
              value: item.value,
              label: item.label,
            }))}
          />
        </Form.Item>

        <Form.Item name="courseId" label={mainDictionary.course}>
          <Select
            className={cx('statistics-filter__select')}
            placeholder={mainDictionary.all}
            options={allCourses}
            onChange={handleCourseChange}
          />
        </Form.Item>

        <Form.Item name="officeId" label={mainDictionary.branch}>
          <Select placeholder={mainDictionary.all} options={offices} onChange={handleOfficeChange} />
        </Form.Item>

        <Form.Item name="level" label={mainDictionary.level}>
          <Select placeholder={mainDictionary.all} options={levels} onChange={handleLevelChange} />
        </Form.Item>

        <Form.Item name="status" label={mainDictionary.status}>
          <Select
            defaultValue={1}
            className={cx('statistics-filter__select')}
            placeholder={mainDictionary.all}
            onChange={handleStatusChange}
            options={statuses}
          />
        </Form.Item>

        <Form.Item name="methods" label={mainDictionary.methods}>
          <Select
            onChange={handleMethodChange}
            className={cx('statistics-filter__select')}
            placeholder={mainDictionary.all}
            options={methodOption}
            mode="multiple"
          />
        </Form.Item>

        <Form.Item label={mainDictionary.datePicker} name="endDate">
          <DatePicker.RangePicker onChange={handleChangeDate} />
        </Form.Item>
        <Button onClick={handleClear} htmlType="reset">
          {mainDictionary.clear}
        </Button>
      </Flex>
    </Form>
  )
})
