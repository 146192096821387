import { IResponse } from '@/api/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { AuthEndpoints } from './endpoints'
import { IGetRefreshTokenResponse, ISignInResponse, SignInParamsType } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AuthApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getSignIn = ({ login, password, fcmToken }: SignInParamsType): Promise<IResponse<ISignInResponse>> =>
    this.post(AuthEndpoints.SignIn, { login, password, fcmToken })

  getRefreshToken = (): Promise<IResponse<IGetRefreshTokenResponse>> => this.get(AuthEndpoints.RefreshToken)

  logOut = (): Promise<IResponse<string>> => this.post(AuthEndpoints.AuthLogout)
}

export const authApi = new AuthApi(config)
