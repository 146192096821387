export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/webm',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/x-matroska',
  'video/matroska',
  'video/mkv',
  'video/mpeg',
  'video/avi',
  'video/mAv',
  'video/m4v',
  'video/ogm',
  'video/wmw',
  'video/mpg',
  'video/webm.og',
  'video/mov',
  'video/asx',
  'video/mxf',
  '.mkv',
]

export const VIDEO_MIME_TYPES_TO_ACCEPT = VIDEO_MIME_TYPES.join(',')

export const enum VideoStatus {
  Processing = 1,
  Done,
  Error,
  Pause,

  Uploading, // only frontend status
  Waiting,
}

export const loader = `
  <style>
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #000;
    }
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #e39f55;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
      } 
  </style>

  <div class="wrapper">
    <span class="loader"></span>
  </div>
`
