import { StopOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import classNames from 'classnames/bind'
import mainDictionary from '@/dictionary'

import styles from './not-found.module.scss'

const cn = classNames.bind(styles)

export const NotFound = () => (
  <div className={cn('not-found')}>
    <Result title={mainDictionary.notfoundTitle} subTitle={mainDictionary.notfoundSubtitle} icon={<StopOutlined />} />
  </div>
)
