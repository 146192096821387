import classNames from 'classnames/bind'
import { motion } from 'framer-motion'
import MiniLogoIcon from '@/assets/icons/mini-logo.svg?react'

import styles from './logo.module.scss'

const cn = classNames.bind(styles)

export const MiniLogo = () => (
  <div className={cn('logo')}>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'linear', duration: 1, x: { duration: 1 } }}
    >
      <MiniLogoIcon />
    </motion.div>
  </div>
)
