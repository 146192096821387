import { SyntheticEvent, useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import { appStore } from '@/stores/app'
import { loader } from '../../constants'
import { PLAYER_HEIGHT, PLAYER_WIDTH, VIDEO_PLAYER_LINK } from './constants'

import styles from './Player.module.scss'

const cn = classNames.bind(styles)

const getVideoLink = (videoId: string) => `${VIDEO_PLAYER_LINK}${videoId}`

export type PlayerProps = {
  videoId: string
  width?: number
  height?: number
  allow?: string
}

export const Player = ({
  videoId,
  width = PLAYER_WIDTH,
  height = PLAYER_HEIGHT,
  allow = 'autoplay; fullscreen; encrypted-media;',
}: PlayerProps) => {
  const src = getVideoLink(videoId)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe && iframe.contentWindow) {
      const iframeWindow = iframe.contentWindow

      const iframeDocument = iframeWindow.document

      const elementInsideIframe = iframeDocument.querySelector('body')

      if (elementInsideIframe) {
        elementInsideIframe.innerHTML = loader
      }
    }
  }, [])

  const handleError = (error: SyntheticEvent<HTMLIFrameElement, Event>) => {
    const logInfoText = `
  *url*: ${error.currentTarget.baseURI}  
    `

    appStore.sendLog({
      text: logInfoText,
    })
  }

  return (
    <div className={cn('wrapper-loader')}>
      <span className={cn('wrapper-loader__loader')} />
      <iframe
        onError={handleError}
        ref={iframeRef}
        src={src}
        width={width}
        height={height}
        allow={allow}
        className={cn('player__iframe')}
      />
    </div>
  )
}
