import { isValidElement } from 'react'
import { notification } from 'antd'
import { isAxiosError } from 'axios'
import mainDictionary from '@/dictionary'
import { ErrorMessage } from './ErrorMessage'
import { NotificationMessageType, NotificationParams } from './types'

notification.config({ placement: 'topRight' })

const TYPE_ERROR = 'TypeError'

export const addNotification = ({ messageType, message, ...rest }: NotificationParams) => {
  const isError = message instanceof Error

  if (isError && message.name === TYPE_ERROR) {
    return
  }

  const isAxiosErrors = isAxiosError(message)
  const hasError = isError || isAxiosErrors
  const notificationType: NotificationMessageType = hasError
    ? NotificationMessageType.Error
    : messageType || NotificationMessageType.Error

  if (hasError) {
    notification[notificationType]({ ...rest, message: <ErrorMessage error={message} /> })

    return
  }

  if (isValidElement(message)) {
    notification[notificationType]({ ...rest, message })

    return
  }

  const anyMessage = message ? <code>{message.toString()}</code> : mainDictionary.unknownError

  notification[notificationType]({ ...rest, message: anyMessage })
}
