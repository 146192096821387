import { makeAutoObservable, runInAction } from 'mobx'
import { MenuProps } from 'antd/es/menu/menu'
import { appApi } from '@/api'
import { IGetSynchResponse } from '@/api/app'
import { IPermissionResponse } from '@/api/controls'
import { IRole, IStaff } from '@/api/groups'
import { LogTypes } from '@/api/instance'
import { controlDictionary } from '@/dictionary/uz/control'
import { addCatchNotification, addNotification, NotificationMessageType } from '@/modules/notifications'
import {
  IGetSynchParams,
  ILoginModal,
  ILogSendRequestParams,
  SynchronizationState,
  SynchronizeAvailablePages,
  TInitial,
} from './types'

const ADMIN_ROLE_DEFAULT = 'Admin'

export class AppStore {
  adminRole: IRole['name'] = ADMIN_ROLE_DEFAULT
  confirmSynchModal = false
  initialParams: Partial<TInitial> | null = null
  mainMenuItems: MenuProps['items'] | null = null
  minePermissions: IPermissionResponse | null = null
  profile: IStaff | null = null
  synchModal = false
  synchronization: SynchronizationState | null = null
  synchronizationInfo: IGetSynchResponse | null = null
  loginModal: ILoginModal | null = {
    isVisible: false,
    login: '',
  }

  menuItems = undefined
  selectedKeys: string[] = []
  queueCount = 0
  notificationsCount = 0

  reportsCount = 0

  setLoginModal = (params: ILoginModal | null) => {
    this.loginModal = params
  }

  setNotificationsCount = (params: number) => {
    this.notificationsCount = params
  }

  setReportsCount = (reportsCount: number) => {
    this.reportsCount = reportsCount
  }

  getNotificationsCount = () =>
    appApi
      .getNotifications()
      .then((res) => {
        if (res.success) {
          this.setNotificationsCount(res.data || 0)
        }
      })
      .catch(addCatchNotification)

  getReportsCount = () =>
    appApi
      .getReportsCount()
      .then((res) => {
        if (res.success) {
          this.setReportsCount(res.data?.reportsCount || 0)
        }
      })
      .catch(addCatchNotification)

  setSynchronization = (params: SynchronizationState | null) => {
    this.synchronization = params
  }

  resetSynchronizationInfo = () => {
    this.synchronizationInfo = null
  }

  setConfirmSynchModal = (params: boolean) => {
    this.confirmSynchModal = params
  }

  setSynchModal = (status: boolean) => {
    runInAction(() => {
      this.synchModal = status
    })
  }

  getProfile = () =>
    appApi
      .getProfile()
      .then((res) => {
        if (res.success) {
          this.profile = res.data
          this.adminRole = res.data.role?.name || ADMIN_ROLE_DEFAULT
        }
      })
      .catch(() => {
        addNotification({ messageType: NotificationMessageType.Error, message: controlDictionary.wentWrong })
      })

  constructor() {
    makeAutoObservable(this)
  }

  setQueueCount = (params: number) => {
    this.queueCount = params
  }

  getQueueCount = () =>
    appApi
      .getQueueCount()
      .then((res) => {
        this.setQueueCount(res.data)
      })
      .catch(addCatchNotification)

  sendLog = ({ logType = LogTypes.Error, text }: ILogSendRequestParams) => appApi.sendLog({ logType, text })

  getTeacherSynch = (params: IGetSynchParams) => appApi.synchronizationTeachers(params)

  getStudentSynch = (params: IGetSynchParams) => appApi.synchronizationStudents(params)

  getGroupsSynch = (params: IGetSynchParams) => appApi.synchronizationGroups(params)

  getSynchInfo = (id: SynchronizeAvailablePages) =>
    appApi
      .getSynchronizationInfo(id)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.synchronizationInfo = res.data
          })
        }
      })
      .catch(addCatchNotification)

  setMenuItems = (value: any) => {
    this.menuItems = value
  }

  setSelectedKeys = (value: string[]) => {
    this.selectedKeys = value
  }

  reset = () => {
    this.adminRole = ADMIN_ROLE_DEFAULT
    this.confirmSynchModal = false
    this.initialParams = null
    this.mainMenuItems = null
    this.minePermissions = null
    this.profile = null
    this.synchModal = false
    this.synchronization = null
    this.synchronizationInfo = null
  }
}

export const appStore = new AppStore()
