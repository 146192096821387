import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import { DetailedError } from 'tus-js-client'
import mainDictionary from '@/dictionary'
import { statusHistoryMessage } from '@/dictionary/uz/kinescope'
import { VideoStatus } from '@/modules/kinescope/constants'
import { appStore } from '@/stores/app'
import { getFormattedCurrentDateWithHour } from '@/utils/date'
import { api } from './api'
import { UploadedVideoId, UploadFile, UploadFileAuxiliary, Uploads, VideoCreateRequestParams } from './types'
import { uploadToKinescopeFile } from './utils'

class KinescopeStore {
  uploads: Record<number, Uploads[]> = {}

  constructor() {
    makeAutoObservable(this)
  }

  createUploadChapter = (chapterId: number): void => {
    if (!this.uploads[chapterId]) {
      this.uploads[chapterId] = []
    }
  }

  getUploadLinks = (apiEndpoint: string, params: VideoCreateRequestParams) => api.createUploadVideo(apiEndpoint, params)

  setUpload = ({
    chapterId,
    id,
    page,
    uploadFile,
  }: {
    chapterId: number
    id: number
    page: Uploads['page']
    uploadFile: UploadFile
  }) => {
    this.createUploadChapter(chapterId)
    this.uploads[chapterId].push({
      id,
      page: { link: page?.link, name: page?.name },
      uploadFile: { ...uploadFile, currentStatus: VideoStatus.Uploading },
      statusHistories: [
        {
          createdAt: getFormattedCurrentDateWithHour(),
          id: dayjs().get('millisecond'),
          message: statusHistoryMessage[VideoStatus.Uploading],
          status: VideoStatus.Uploading,
        },
      ],
    })
  }

  removeFromUploads = (chapterId: number, id: number) => {
    if (!this.uploads[chapterId]) {
      return
    }

    this.uploads[chapterId] = this.uploads[chapterId].filter((upload) => upload.id !== id)
  }

  getUploadingFile = (chapterId: number, id: number) => {
    if (!this.uploads[chapterId]) {
      return null
    }

    return this.uploads[chapterId].filter((upload) => upload.id === id)?.[0] || null
  }

  removeWaitProcessingFiles = (uploadedVideoIds: UploadedVideoId[], chapterId: number) => {
    uploadedVideoIds.forEach(({ id, status }) => {
      if (status === VideoStatus.Done) {
        kinescopeStore.removeFromUploads(chapterId, id)
      } else {
        kinescopeStore.setUploadParams(chapterId, id, { isVisible: false })
      }
    })
  }

  setUploadParams = (chapterId: number, id: number, params: Pick<UploadFile, keyof UploadFileAuxiliary>) => {
    if (this.uploads?.[chapterId]) {
      this.uploads[chapterId] = this.uploads[chapterId].map((upload) =>
        upload.id === id ? { ...upload, uploadFile: { ...upload.uploadFile, ...params } } : upload,
      )
    }
  }

  setUploadHistory = (chapterId: number, id: number, status: VideoStatus) => {
    if (this.uploads?.[chapterId]) {
      this.uploads?.[chapterId]
        .filter((upload) => upload.id === id)[0]
        .statusHistories.push({
          id: dayjs().get('millisecond'),
          createdAt: getFormattedCurrentDateWithHour(),
          message: statusHistoryMessage[status],
          status,
        })
    }
  }

  hasUploadingFiles = (): boolean =>
    Object.entries(this.uploads).some(([, uploads]) => uploads?.filter((item) => item.uploadFile?.isVisible)?.length)

  getUploadingFilesLinks = (): Uploads['page'][] =>
    Object.entries(this.uploads)
      .map(([, uploads]) => (uploads && uploads?.length ? uploads[0].page : {}))
      .filter((item) => item && item.link)

  abortAllUploadingFiles = () => {
    Object.entries(this.uploads).forEach(
      ([, uploads]) =>
        uploads &&
        uploads
          .filter((upload) => upload.uploadFile.currentStatus === VideoStatus.Uploading)
          .forEach((upload) => {
            upload.uploadFile.isVisible && upload.uploadFile.abort?.()
          }),
    )
  }

  deleteUploadingFiles = async (isAcademic?: boolean) => {
    const uploadingFiles: { groupId: string; id: number }[] = []

    Object.entries(this.uploads).forEach(([groupId, uploads]) => {
      uploads
        .filter(({ uploadFile }) => uploadFile.currentStatus === VideoStatus.Uploading)
        .forEach(({ id }) => {
          uploadingFiles.push({ groupId, id })
        })
    })

    return Promise.allSettled(uploadingFiles.map(async ({ id }) => api.deleteUploadVideo(id, isAcademic)))
  }

  private getHandleUploadProgress =
    (chapterId: number, id: number) => (bytesUploaded: number, bytesTotal: number, percentage: number) => {
      this.setUploadParams(chapterId, id, { progress: percentage })
    }

  private getHandleUploadError = (chapterId: number, id: number) => (error: DetailedError | Error) => {
    this.setUploadParams(chapterId, id, { currentStatus: VideoStatus.Error })
    this.setUploadHistory(chapterId, id, VideoStatus.Error)

    appStore.sendLog({
      text: `
          *error*: ${error?.message || error?.stack || ''}
        `,
    })
  }

  private getHandleUploadSuccess =
    (chapterId: number, id: number, endpoint: string, onSuccess?: () => void, isAcademic?: boolean) => () => {
      onSuccess?.()
      this.setUploadParams(chapterId, id, { currentStatus: VideoStatus.Waiting })
      api.updateVideoStatus({ id, status: VideoStatus.Waiting, message: mainDictionary.waitingMessage }, isAcademic)
    }

  runUploadFile = ({
    chapterId,
    id,
    updateApiEndpoint,
    uploadFile,
    onSuccess,
    isAcademic,
  }: {
    chapterId: number
    id: number
    updateApiEndpoint: string
    uploadFile: UploadFile
    onSuccess?: () => void
    isAcademic?: boolean
  }) => {
    const callbacksObject = uploadToKinescopeFile({
      file: uploadFile.file,
      uploadUrl: uploadFile.uploadUrl,
      onProgress: this.getHandleUploadProgress(chapterId, id),
      onError: this.getHandleUploadError(chapterId, id),
      onSuccess: this.getHandleUploadSuccess(chapterId, id, updateApiEndpoint, onSuccess, isAcademic),
      origName: uploadFile.origName,
      chapterId,
      id,
    })

    const handleAbort = () => {
      this.setUploadHistory(chapterId, id, VideoStatus.Pause)

      return callbacksObject.abort()
    }

    const handleStart = () => {
      this.setUploadHistory(chapterId, id, VideoStatus.Uploading)

      return callbacksObject.start()
    }

    this.setUploadParams(chapterId, id, { abort: handleAbort, start: handleStart })
  }

  reset = () => {
    this.uploads = []
  }
}

export const kinescopeStore = new KinescopeStore()
