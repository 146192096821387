export const enum StoreTitle {
  MyGroupPerPage = 'myGroupsPerPage',
  GroupsPerPage = 'groupsPerPage',
  HomeworksPerPage = 'homeworksPerPage',
  GroupVideos = 'groupVideos',
  GroupExams = 'groupExams',
  AcademicPerPage = 'academicPerPage',
  StaffPerPage = 'staffPerPage',
  StudentPerPage = 'studentPerPage',
  StatisticsPerPage = 'statisticsPerPage',
  LevelsPerPage = 'levelsPerPage',
  MethodsPerPage = 'methodsPerPage',
  DeedPerPage = 'deedPerPage',
  ByActionPage = 'byActionPage',
  GroupActionPage = 'groupActionPage',
  AuditPerPage = 'auditPerPage',
  StaffControlRolesPerPage = 'staffControlRolesPerPage',
  StaffControlUsersPerPage = 'staffControlUsersPerPage',
  FaceIdLogsPerPage = 'faceIdLogsPerPage',
  FaceIdDevices = 'faceIdDevices',
  StudyDepartmentHomework = 'studyDepartmentHomework',
  TeachersKpiPerPage = 'teachersKpiPerPage',
}
