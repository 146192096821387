import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import {
  MobileAppVersions,
  SettingsGamificationOffering,
  SettingsGamificationOfferingUpdate,
  StudentStatuses,
  StudentStatusesMake,
} from './lazy'

export const settingsRouters = [
  {
    path: ROUTES.settings,
    children: [
      {
        path: ROUTES.settingsPublicOffering,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsGamificationOffer}>
            <SettingsGamificationOffering />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsPublicOfferingUpdate,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsGamificationOffer}>
            <SettingsGamificationOfferingUpdate />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsStudentStatuses,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsStudentStatuses}>
            <StudentStatuses />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsStudentStatusesEdit,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsStudentStatuses}>
            <StudentStatusesMake />
          </AvailableComponent>
        ),
      },

      {
        path: ROUTES.settingsMobileApp,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsMobileVersions}>
            <MobileAppVersions />
          </AvailableComponent>
        ),
      },
    ],
  },
]
