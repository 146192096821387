import { observer } from 'mobx-react'
import { Button, Descriptions, message, Modal, Space } from 'antd'
import { useBoolean } from 'usehooks-ts'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { branchOfficeApi } from '@/pages/Controls/BranchOffice/shared/branch-office.api'
import { branchOfficeStore } from '../shared/branch-office.store'

export const BranchOfficeDeleteModal = observer(() => {
  const {
    inActionBranchOffice,
    setOpenDeleteModalStatus,
    isOpenDeleteModal,
    getBranchOffices,
    setInActionBranchOffice,
  } = branchOfficeStore
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean()

  const handleModalClose = () => {
    if (isLoading) {
      return
    }

    setOpenDeleteModalStatus(false)
  }

  if (!inActionBranchOffice) {
    if (isOpenDeleteModal) {
      message.info(mainDictionary.openDeleteModalMessage)
    }

    return null
  }

  const handleOkButtonClick = async () => {
    setTrueLoading()

    try {
      await branchOfficeApi.deleteOffices(inActionBranchOffice.id)
      await getBranchOffices()

      setInActionBranchOffice(null)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setFalseLoading()
      setOpenDeleteModalStatus(false)
    }
  }

  return (
    <Modal
      open={isOpenDeleteModal}
      title={mainDictionary.deleteBranchOffice(inActionBranchOffice.name)}
      footer={null}
      onCancel={handleModalClose}
    >
      <Descriptions
        bordered
        size="middle"
        title={mainDictionary.branchOfficeName}
        items={[
          {
            key: inActionBranchOffice.id,
            label: 'ID',
            children: inActionBranchOffice.id,
          },
          {
            key: inActionBranchOffice.name,
            label: 'name',
            children: inActionBranchOffice.name,
          },
        ]}
        style={{ margin: '25px 0' }}
      />
      <Descriptions
        bordered
        size="middle"
        title={mainDictionary.holyHopesBranchOfficeName}
        items={[
          {
            key: inActionBranchOffice.hOffice.id,
            label: 'ID',
            children: inActionBranchOffice.hOffice.id,
          },
          {
            key: inActionBranchOffice.hOffice.name,
            label: 'name',
            children: inActionBranchOffice.hOffice.name,
          },
        ]}
        style={{ margin: '40px 0' }}
      />

      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={handleModalClose} disabled={isLoading} size="large">
          {mainDictionary.nope}
        </Button>

        <Button type="primary" onClick={handleOkButtonClick} disabled={isLoading} size="large" loading={isLoading}>
          {mainDictionary.yes}
        </Button>
      </Space>
    </Modal>
  )
})
