import { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Col, Form, Modal } from 'antd'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import { useBoolean } from 'usehooks-ts'
import { notificationsApi } from '@/api/notifications'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'

export const ChangeNotifications = observer(() => {
  const [form] = Form.useForm()
  const { notificationStore } = useStores()
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([])
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean(false)

  const handleCloseModal = () => {
    notificationStore.setConfigureNotifications(false)
  }

  const handleSubmit = () => {
    form.submit()
  }
  const handleCheckboxChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    setSelectedNotifications(checkedValue)
  }

  const handleFinish = async () => {
    setTrueLoading()
    try {
      const transformedData = notificationStore.notificationSettings.map((settings) => ({
        notificationTypeId: settings.id,
        isEnable: selectedNotifications.includes(settings.id),
      }))

      await notificationsApi.updateNotificationSettings({ settings: transformedData })
      await notificationStore.getNotificationSettings()
      addSuccessNotification(mainDictionary.successfullyEdited)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setFalseLoading()
      notificationStore.setConfigureNotifications(false)
    }
  }

  const notificationTypesOptions = useMemo(
    () =>
      notificationStore.notificationSettings?.map((item) => ({
        label: item.displayName,
        value: item?.id,
        isEnable: item.isEnable,
      })),
    [notificationStore.notificationSettings],
  )

  return (
    <Modal
      open={notificationStore.isNotificationSettings}
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      title={mainDictionary.notificationSettings}
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Col span={15}>
          <Checkbox.Group
            name="settings"
            options={notificationTypesOptions}
            defaultValue={notificationStore.checkedNotificationSettings}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Form>
    </Modal>
  )
})
