export enum SortStatistic {
  COIN = 'coin',
  LEVEL = 'level',
  XP = 'xp',
}

export enum MethodIds {
  ParticipateLesson = 1,
  DoHomework = 2,
  ParticipateExam = 3,
  PassExam = 4,
  ByAdmin = 5,
}
