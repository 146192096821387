import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import { Endpoints, stage } from '@/api/endpoints'
import { IResponse } from '@/api/types'
import { STAFF_INFO, TOKEN_NAME } from '@/constants/auth'
import { addCatchNotification } from '@/modules/notifications'
import { resetStores, stores } from '@/stores'
import { storage } from '@/utils/storage'
import { INetworkConfig, TMethod } from './types'

export class Instance {
  protected readonly instance: AxiosInstance
  protected baseURL = ''

  public constructor(params?: INetworkConfig) {
    const { baseURL, headers, timeout } = params || { baseURL: Endpoints.Base, headers: {}, timeout: 1200000 }

    this.instance = axios.create({
      baseURL,
      timeout,
      headers,
    })
    // @ts-ignore
    this.instance.interceptors.request.use(this.handleRequest)
    this.instance.interceptors.response.use(this.handleResponse, this.handleResponseError)
    this.baseURL = baseURL!
  }

  handleResponse = <T>(response: AxiosResponse<IResponse<T>>) => response

  private handleResponseError = async (error: AxiosError<IResponse<any>>) => {
    if (error.response?.status === 403) {
      addCatchNotification(error)

      return
    }

    if (error.response?.status === 401) {
      addCatchNotification(error)

      resetStores()

      storage.removeItems([STAFF_INFO, TOKEN_NAME.refreshToken, TOKEN_NAME.accessToken])
      window.location.reload()

      return
    }

    throw error
  }

  private handleRequest = async ({ headers, ...restConfig }: AxiosRequestConfig) => {
    const { authStore } = stores
    const accessToken = authStore.token?.accessToken

    return {
      headers: {
        ...headers,
        ...(accessToken && { Authorization: `Bearer ${authStore.token?.accessToken}` }),
        'Cache-Control': 'no-cache',
      },
      ...restConfig,
    }
  }

  public async send(method: TMethod, url: string, params?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance[method](url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async get<T>(url: string, params?: unknown, cancelTokenSource?: CancelTokenSource) {
    const { data } = await this.instance.get<T>(url, {
      ...(params || {}),
      baseURL: `${stage.apiUrl}${this.baseURL}`,
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
    })

    return data
  }

  public async post<T>(url: string, params?: unknown, config?: AxiosRequestConfig) {
    const { data } = await this.instance.post<T>(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.instance.delete<T>(url, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async put<T>(url: string, params?: unknown, config?: AxiosRequestConfig) {
    const { data } = await this.instance.put<T>(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async patch(url: string, params?: unknown, config?: AxiosRequestConfig) {
    const { data } = await this.instance.patch(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }
}
