import { settingsDictionary } from '@/dictionary/uz/settings'
import { academicDictionary } from './academic'
import { assistantsDictionary } from './assistants'
import { assistantSingleDictionary } from './assistantSingle'
import { attendanceDictionary } from './attendance'
import { auditDictionary } from './audit'
import { branchOfficeDictionary } from './branchOffice'
import { constantsDictionary } from './constants'
import { controlDictionary } from './control'
import { errorsDictionary } from './errors'
import { eventsDictionary } from './events'
import { faceIdDictionary } from './faceId'
import { faceIdDeviceDictionary } from './faceIdDevice'
import { gamificationDictionary } from './gamification'
import { gamificationOfferDictionary } from './gamificationOffer'
import { generalDictionary } from './general'
import { groupDictionary } from './group'
import { kinescopeDictionary } from './kinescope'
import { loginDictionary } from './login'
import { logOutDictionary } from './logOut'
import { managementDictionary } from './management'
import { marketDictionary } from './market'
import { methodsDictionary } from './methods'
import { mobileAppDictionary } from './mobileApp'
import { notificationsDictionary } from './notifications'
import { profileDictionary } from './profile'
import { staffDictionary } from './staff'
import { statisticsDictionary } from './statistics'
import { studentDictionary } from './student'
import { studyDepartmentDictionary } from './studyDepartment'
import { teachersDictionary } from './teachers'
import { teacherSingleDictionary } from './teacherSingle'
import { usersSingleTeacherDictionary } from './usersSingleTeacher'

export default {
  ...academicDictionary,
  ...assistantsDictionary,
  ...assistantSingleDictionary,
  ...attendanceDictionary,
  ...auditDictionary,
  ...branchOfficeDictionary,
  ...constantsDictionary,
  ...controlDictionary,
  ...errorsDictionary,
  ...faceIdDeviceDictionary,
  ...faceIdDictionary,
  ...gamificationDictionary,
  ...generalDictionary,
  ...groupDictionary,
  ...kinescopeDictionary,
  ...loginDictionary,
  ...logOutDictionary,
  ...managementDictionary,
  ...methodsDictionary,
  ...profileDictionary,
  ...staffDictionary,
  ...statisticsDictionary,
  ...studentDictionary,
  ...studyDepartmentDictionary,
  ...teachersDictionary,
  ...teacherSingleDictionary,
  ...usersSingleTeacherDictionary,
  ...staffDictionary,
  ...generalDictionary,
  ...settingsDictionary,
  ...gamificationOfferDictionary,
  ...marketDictionary,
  ...notificationsDictionary,
  ...eventsDictionary,
  ...mobileAppDictionary,
}
