import { useEffect } from 'react'
import { appStore, SynchronizeAvailablePages } from '@/stores/app'

interface IProps {
  synchronizeType: SynchronizeAvailablePages
  isAcademic?: boolean
}

export const useSynchronization = ({ synchronizeType, isAcademic }: IProps) => {
  useEffect(() => {
    appStore.setSynchronization({
      isVisible: true,
      synchronizeType,
      isAcademic,
    })

    appStore.getSynchInfo(synchronizeType)

    return () => {
      appStore.setSynchronization(null)
      appStore.resetSynchronizationInfo()
    }
  }, [])
}
