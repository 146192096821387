export enum AppEndpoints {
  Roles = '/roles',
  PermissionObjectsMine = '/permission-objects/mine',
  Initial = '/initial',
  Profile = '/profile',
  Synchronization = '/synchronization',
  Students = '/students',
  Groups = '/groups',
  GroupStudents = '/group-students',
  Reports = '/reports',
  Count = '/count',
  SenderLog = '/sender/log',
  MessageCount = '/message-count',
  Notifications = '/notifications',
  Teachers = '/teachers',
}
