import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Menu as AntdMenu } from 'antd'
import { MenuProps } from 'antd/es/menu/menu'
import { appStore } from '@/stores/app'
import { menuWithOutChildren } from './constants'

type Props = {
  setVisibleDrawer?: Dispatch<SetStateAction<boolean>>
}

export const Menu = observer(({ setVisibleDrawer }: Props) => {
  const { selectedKeys, menuItems } = appStore

  const navigate = useNavigate()

  const handleClick: MenuProps['onClick'] = ({ key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()

    if (domEvent.ctrlKey || domEvent.metaKey) {
      window.open(`${location.origin}${key}`)

      return
    }
    navigate(key)

    if (menuWithOutChildren.includes(key) && setVisibleDrawer) {
      setVisibleDrawer(false)
    }
  }

  return (
    <AntdMenu
      defaultOpenKeys={selectedKeys}
      defaultSelectedKeys={selectedKeys}
      items={menuItems}
      onClick={handleClick}
      selectedKeys={selectedKeys}
      mode="inline"
      theme="dark"
      style={{ border: 'none' }}
    />
  )
})
