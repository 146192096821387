import { Endpoints } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { IGetSynchParams, ILogSendRequestParams, SynchronizeAvailablePages } from '@/stores'
import { IPermissionResponse } from '../controls/types'
import { IStaff } from '../groups/types'
import { AppEndpoints } from './endpoints'
import { IGetReportsCount, IGetSynchResponse, TInitial } from './types'

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class AppApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getInitial = (): Promise<IResponse<TInitial>> => this.send('post', `${AppEndpoints.Roles}${AppEndpoints.Initial}`)

  getMinePermissions = (): Promise<IResponse<IPermissionResponse>> =>
    this.get(`${AppEndpoints.Roles}${AppEndpoints.PermissionObjectsMine}`)

  logout = (link: string): Promise<IResponse<any>> => this.post(link)

  getProfile = (): Promise<IResponse<IStaff>> => this.get(AppEndpoints.Profile)

  synchronizationTeachers = (params: IGetSynchParams): Promise<IResponse<boolean>> =>
    this.put(`${AppEndpoints.Synchronization}${AppEndpoints.Teachers}`, undefined, params)

  synchronizationStudents = (params: IGetSynchParams): Promise<IResponse<boolean>> =>
    this.put(`${AppEndpoints.Synchronization}${AppEndpoints.Students}`, undefined, params)

  synchronizationGroups = (params: IGetSynchParams): Promise<IResponse<boolean>> =>
    this.put(`${AppEndpoints.Synchronization}${AppEndpoints.Groups}`, undefined, params)

  synchronizationStudentGroups = (params: IGetSynchParams): Promise<IResponse<boolean>> =>
    this.put(`${AppEndpoints.Synchronization}${AppEndpoints.GroupStudents}`, undefined, params)

  getSynchronizationInfo = (params: SynchronizeAvailablePages): Promise<IResponse<IGetSynchResponse>> =>
    this.get(`${AppEndpoints.Synchronization}/${params}`)

  getReportsCount = (): Promise<IResponse<IGetReportsCount>> => this.get(`${AppEndpoints.Reports}${AppEndpoints.Count}`)

  getNotifications = (): Promise<IResponse<number>> => this.get(`${AppEndpoints.Notifications}${AppEndpoints.Count}`)

  sendLog = (params: ILogSendRequestParams): Promise<IResponse<boolean>> => this.post(AppEndpoints.SenderLog, params)

  getQueueCount = (): Promise<IResponse<number>> =>
    this.get(`${AppEndpoints.Synchronization}${AppEndpoints.MessageCount}`)
}

export const appApi = new AppApi(config)
