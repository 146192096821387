import {
  AppleOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BarChartOutlined,
  BellOutlined,
  BookOutlined,
  BranchesOutlined,
  CameraOutlined,
  CoffeeOutlined,
  CommentOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DribbbleOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  FundProjectionScreenOutlined,
  GroupOutlined,
  MailOutlined,
  MobileOutlined,
  MoneyCollectOutlined,
  PhoneOutlined,
  PrinterOutlined,
  RedditOutlined,
  RiseOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SnippetsOutlined,
  StarOutlined,
  TwitchOutlined,
  UngroupOutlined,
  UserOutlined,
  VerifiedOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { Badge } from 'antd'
import classNames from 'classnames/bind'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { permissionObjectTypes } from '@/modules/permission'

import styles from './menu.module.scss'

const cn = classNames.bind(styles)

export const staffControlSettings = {
  [permissionObjectTypes.staffControl]: {
    icon: <ControlOutlined />,
    link: ROUTES.controls,
  },
  [permissionObjectTypes.staffControlRoles]: {
    link: ROUTES.singleRole,
  },
}

export const mainMenuLists = (count: number) => [
  {
    label: mainDictionary.myGroups,
    key: ROUTES.myGroups,
    name: permissionObjectTypes.myGroups,
    icon: <UngroupOutlined />,
  },
  {
    label: mainDictionary.teachers,
    key: ROUTES.teachers,
    icon: <UserOutlined />,
    name: permissionObjectTypes.usersTeachers,
    children: [
      {
        label: mainDictionary.teachers,
        icon: <UserOutlined />,
        key: ROUTES.teachers,
        name: permissionObjectTypes.usersTeachers,
      },
    ],
  },
  {
    label: mainDictionary.groups,
    key: ROUTES.groups,
    icon: <GroupOutlined />,
    name: permissionObjectTypes.allGroups,
  },
  {
    label: mainDictionary.users,
    icon: <UserOutlined />,
    key: ROUTES.users,
    name: permissionObjectTypes.users,
    children: [
      {
        label: mainDictionary.staffs,
        icon: <UserOutlined />,
        key: ROUTES.usersStaff,
        name: permissionObjectTypes.usersStaff,
      },
      {
        label: mainDictionary.academics,
        icon: <UserOutlined />,
        key: ROUTES.usersAcademic,
        name: permissionObjectTypes.usersAcademic,
      },
      {
        label: mainDictionary.students,
        key: ROUTES.students,
        icon: <UserOutlined />,
        name: permissionObjectTypes.usersStudents,
      },
    ],
  },
  {
    label: mainDictionary.studyDepartment,
    key: ROUTES.studyDepartment,
    icon: <StarOutlined />,
    name: permissionObjectTypes.study,
    children: [
      {
        label: mainDictionary.courses,
        key: ROUTES.studyDepartmentCourses,
        icon: <SnippetsOutlined />,
        name: permissionObjectTypes.studyCourses,
      },
      {
        label: mainDictionary.homework,
        key: ROUTES.studyDepartmentHomeworks,
        icon: <BookOutlined />,
        name: permissionObjectTypes.studyHomeworkStatistics,
      },
      {
        label: mainDictionary.teachersKPI,
        key: ROUTES.studyDepartmentTeachersKPI,
        icon: <BarChartOutlined />,
        name: permissionObjectTypes.studyHomeworkStatistics,
      },
      {
        label: mainDictionary.teachersStatistics,
        key: ROUTES.studyDepartmentTeacherStatistics,
        icon: <BarChartOutlined />,
        name: permissionObjectTypes.studyHomeworkStatistics,
      },
      {
        label: mainDictionary.homeworkBase,
        key: `${ROUTES.studyDepartmentHomeworks}-1`,
        icon: <DatabaseOutlined />,
        name: permissionObjectTypes.studyHomeworkDb,
        children: [
          {
            label: mainDictionary.homework,
            key: ROUTES.studyDepartmentHomeworkDataBase,
            icon: <PrinterOutlined />,
            name: permissionObjectTypes.studyHomeworkDb,
          },
          {
            label: mainDictionary.draft,
            key: ROUTES.studyDepartmentHomeworkDataBaseDraft,
            icon: <FontSizeOutlined />,
            name: permissionObjectTypes.studyHomeworkDb,
          },
        ],
      },
    ],
  },
  {
    label: mainDictionary.Gamification,
    key: ROUTES.gamification,
    icon: <RedditOutlined />,
    name: permissionObjectTypes.gamification,
    children: [
      {
        label: mainDictionary.statistics,
        key: ROUTES.gamificationStatistics,
        icon: <AreaChartOutlined />,
        name: permissionObjectTypes.gamificationUnits,
      },
      {
        label: mainDictionary.levels,
        key: ROUTES.gamificationLevels,
        icon: <RiseOutlined />,
        name: permissionObjectTypes.gamificationLevels,
      },
      {
        label: mainDictionary.actions,
        key: ROUTES.gamificationActions,
        icon: <DribbbleOutlined />,
        name: permissionObjectTypes.gamificationActions,
      },
      {
        label: mainDictionary.connect,
        key: ROUTES.gamificationConnect,
        icon: <BranchesOutlined />,
        name: permissionObjectTypes.gamificationGroups,
      },
      {
        label: mainDictionary.theBestOnes,
        key: ROUTES.gamificationTheBestOnes,
        icon: <StarOutlined />,
        name: permissionObjectTypes.gamification,
      },
    ],
  },
  {
    label: mainDictionary.management,
    key: `root${ROUTES.management}`,
    icon: <ControlOutlined />,
    name: permissionObjectTypes.management,
    children: [
      {
        className: 'report-wrapper',
        label: (
          <Badge
            showZero
            count={count}
            overflowCount={99}
            color="danger"
            className={cn('badge')}
            style={{
              right: '-20px',
            }}
          >
            <span className={cn('report-text')}>{mainDictionary.reports}</span>
          </Badge>
        ),
        key: ROUTES.management,
        icon: <CommentOutlined />,
        name: permissionObjectTypes.managementReports,
        index: true,
      },
      {
        label: mainDictionary.autoCall,
        key: ROUTES.managementAutoCall,
        name: permissionObjectTypes.profile,
        icon: <PhoneOutlined />,
      },
    ],
  },
  {
    label: mainDictionary.controls,
    key: `${ROUTES.controls}${permissionObjectTypes.staffControl}`,
    icon: <ControlOutlined />,
    name: permissionObjectTypes.staffControl,
    children: [
      {
        label: mainDictionary.audit,
        key: ROUTES.audits,
        icon: <VerifiedOutlined />,
        name: permissionObjectTypes.audits,
      },
      {
        label: mainDictionary.controls,
        key: ROUTES.controls,
        icon: <ControlOutlined />,
        name: permissionObjectTypes.staffControlRoles,
      },
      {
        label: mainDictionary.faceIdLogs,
        key: ROUTES.faceId,
        icon: <CameraOutlined />,
        name: permissionObjectTypes.staffControlFaceIdLogs,
      },
      {
        label: mainDictionary.offices,
        key: ROUTES.controlBranchOffice,
        icon: <CoffeeOutlined />,
        name: permissionObjectTypes.staffControlBranchOffice,
      },
      {
        label: mainDictionary.faceIdDevices,
        key: ROUTES.faceIdDevices,
        icon: <VideoCameraOutlined />,
        name: permissionObjectTypes.staffControlDevices,
      },
    ],
  },
  {
    label: mainDictionary.market,
    key: ROUTES.market,
    icon: <ShoppingCartOutlined />,
    name: permissionObjectTypes.store,
    children: [
      {
        label: mainDictionary.transactions,
        key: ROUTES.marketTransactions,
        icon: <MoneyCollectOutlined />,
        name: permissionObjectTypes.storeTransactions,
      },
      {
        label: mainDictionary.categories,
        key: ROUTES.marketCategories,
        icon: <ShoppingOutlined />,
        name: permissionObjectTypes.storeCategories,
      },
      {
        label: mainDictionary.accessories,
        key: ROUTES.marketAccessories,
        icon: <AppleOutlined />,
        name: permissionObjectTypes.storeItems,
      },
    ],
  },
  {
    label: mainDictionary.notifications,
    key: ROUTES.notifications,
    icon: <BellOutlined />,
    name: permissionObjectTypes.notifications,
    children: [
      {
        label: mainDictionary.sended,
        key: ROUTES.notificationsSended,
        icon: <MailOutlined />,
        name: permissionObjectTypes.notificationsSent,
      },
      {
        label: mainDictionary.templates,
        key: ROUTES.templates,
        icon: <AuditOutlined />,
        name: permissionObjectTypes.notifications,
      },
    ],
  },
  {
    label: mainDictionary.settings,
    key: ROUTES.settings,
    icon: <SettingOutlined />,
    name: permissionObjectTypes.settings,
    children: [
      {
        label: mainDictionary.gamificationOffer,
        key: ROUTES.settingsPublicOffering,
        icon: <FileTextOutlined />,
        name: permissionObjectTypes.settingsGamificationOffer,
      },
      {
        label: mainDictionary.studentStatuses,
        key: ROUTES.settingsStudentStatuses,
        icon: <ScheduleOutlined />,
        name: permissionObjectTypes.settingsStudentStatuses,
      },
      {
        label: mainDictionary.mobileApp,
        key: ROUTES.settingsMobileApp,
        icon: <MobileOutlined />,
        name: permissionObjectTypes.settingsMobileVersions,
      },
      {
        label: mainDictionary.contracts,
        key: ROUTES.contracts,
        icon: <FileTextOutlined />,
        name: permissionObjectTypes.contracts,
      },
      {
        label: mainDictionary.mobileApp,
        key: ROUTES.settingsMobileApp,
        icon: <MobileOutlined />,
        name: permissionObjectTypes.settingsMobileVersions,
      },
    ],
  },
  {
    label: mainDictionary.events,
    key: `${ROUTES.competitions}-1`,
    icon: <FundProjectionScreenOutlined />,
    name: permissionObjectTypes.events,
    children: [
      {
        label: mainDictionary.masterClasses,
        key: ROUTES.masterClass,
        icon: <TwitchOutlined />,
        name: permissionObjectTypes.eventsMasterClasses,
      },
      {
        label: mainDictionary.competitions,
        key: ROUTES.competitions,
        icon: <RedditOutlined />,
        name: permissionObjectTypes.eventsCompetitions,
      },
    ],
  },
  {
    label: mainDictionary.profile,
    key: ROUTES.profile,
    icon: <UserOutlined />,
    name: permissionObjectTypes.profile,
  },
]

export const menuWithOutChildren = ['/groups', '/my-groups', '/profile']
