import classnamesBind from 'classnames/bind'
import { Icons } from '@/components'

import styles from './background-animate.module.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  count?: number
}

export const BackgroundAnimate = ({ count = 5 }: Props) => {
  const countArr = [...new Array(count)]

  return (
    <ul className={cn('background-animate')}>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {countArr.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`najod-${index}`} className={cn('background-animate__li')}>
          <Icons.Logo />
        </li>
      ))}
    </ul>
  )
}
