import { makeAutoObservable } from 'mobx'
import {
  IBlockStaffRequest,
  ITeacherProfileAdd,
  ITeacherRolesData,
  ITeacherRolesParams,
  ITeachersParams,
  ITeachersSignUp,
  StaffAccountStatus,
  teacherApi,
} from '@/api/users'
import { ITeacherData } from '@/pages/Users/Teachers/types'
import { OrderBy } from '@/utils'
import { KeyType } from './types'

class UsersStore {
  teacherProfileModal = false
  editTeacherProfile = ''
  teachers: ITeacherData[] = []
  teacherRoles: ITeacherRolesData[] = []
  perPage = 10
  page = 1
  value = ''
  orderBy: OrderBy = OrderBy.DESC
  keys: KeyType = ['firstName', 'lastName']
  workStatusFilters: StaffAccountStatus = StaffAccountStatus.Active
  isActive = true
  statusOption: string[] = []
  teacherId = 0
  addTeacherPassword = ''
  total = 0

  constructor() {
    makeAutoObservable(this)
  }

  setTeacherProfile(isOpen: boolean) {
    this.teacherProfileModal = isOpen
  }

  setEditTeacherProfile(login: string) {
    this.editTeacherProfile = login
  }

  getTeachers = (params: ITeachersParams) =>
    teacherApi
      .getTeachers(params)
      .then((res) => {
        if (res.success) {
          this.setTeachers(res.data.teachers)
          this.setTotal(res.data.total)
        }

        return res
      })
      .catch((err) => {
        console.error(err)
      })

  getTeacherRoles = (params: ITeacherRolesParams) =>
    teacherApi
      .getTeacherRoles(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherRoles(res.data.roles)
        }

        return res
      })
      .catch((err) => {
        console.error(err)
      })

  signUpTeachers = (params: ITeachersSignUp) =>
    teacherApi
      .signUpTeachers(params)
      .then((res) => {
        if (res.success) {
          return res
        }
      })
      .catch((err) => {
        console.error(err)
      })

  editTeacher = (params: IBlockStaffRequest) => teacherApi.editTeacher(params)

  loginEdit = (params: IBlockStaffRequest) => {
    teacherApi.loginGenerated(params)
  }

  addLoginTeacher = (params: ITeacherProfileAdd) => {
    teacherApi
      .addProfileTeacher(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherPassword(res.data.password)
        }

        return res
      })
      .catch((err) => {
        console.error(err)
      })
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setTeachers = (teachers: ITeacherData[]) => {
    this.teachers = teachers
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setTeacherRoles = (roles: ITeacherRolesData[]) => {
    this.teacherRoles = roles
  }

  setSearch = (searchValue: string) => {
    this.value = searchValue
  }

  setOrderBy = (order: OrderBy) => {
    this.orderBy = order
  }

  setWorkStatusFilter = (filterWorkStatus: StaffAccountStatus) => {
    this.workStatusFilters = filterWorkStatus
  }

  setIsActive = (active: boolean) => {
    this.isActive = active
  }

  setStatusOption = (value: []) => {
    this.statusOption = value
  }

  setTeacherId = (id: number) => {
    this.teacherId = id
  }

  setTeacherPassword = (password: string) => {
    this.addTeacherPassword = password
  }

  reset = () => {
    this.value = ''
    this.editTeacherProfile = ''
    this.addTeacherPassword = ''
    this.orderBy = OrderBy.DESC
    this.workStatusFilters = StaffAccountStatus.Active
    this.perPage = 10
    this.page = 1
    this.total = 0
    this.teacherId = 0
    this.isActive = true
    this.teacherProfileModal = false
    this.teachers = []
    this.statusOption = []
    this.teacherRoles = []
    this.keys = ['firstName', 'lastName']
  }
}

export const usersStore = new UsersStore()
