const { localStorage } = window

export const setStorageItem = (key: string, value: string): void => localStorage.setItem(key, JSON.stringify(value))

export const getStorageItem = (key: string): string => JSON.parse(localStorage.getItem(key) || '')

class Storage {
  set(name: string, value: string): void {
    localStorage.setItem(name, value)
  }

  get(name: string): string | null {
    return localStorage.getItem(name)
  }

  getObject<T>(name: string): T | null {
    return JSON.parse(localStorage.getItem(name)!) as T
  }

  setObject<T extends NonNullable<unknown>>(name: string, value: T): void {
    localStorage.setItem(name, JSON.stringify(value))
  }

  remove(name: string): void {
    localStorage.removeItem(name)
  }

  removeItems(names: string[]): void {
    if (!names.length) return
    names.forEach((name) => localStorage.removeItem(name))
  }

  clearAll(): void {
    localStorage.clear()
  }
}

export const storage = new Storage()
