export const marketDictionary = {
  add: 'Qo`shish',
  hash: '#',
  name: 'Nomi',
  actions: 'Harakatlar',
  category: 'Katagoriya',
  edit: 'O`zgartirish',
  onSale: 'Sotuvda',
  image: 'Rasm',
  value: 'Qiymati',
  sold: 'Sotilgan',
  productsCount: 'Maxsulotlar soni',
  count: 'Soni',
  from: 'dan',
  team: 'Jamoa',
  onSaleCount: 'Sotuvdagi soni',
  soldCount: 'Sotilgan soni',
  to: 'gacha',
  valueOfAccessories: 'Akksesuar qiymati',
  nameOfAccessories: 'Akksesuar nomi',
  addAccessory: 'Aksessuar qo`shish',
  selectCategory: 'Kategoryani tanlang',
  enterAccessoryName: 'Aksessuar nomini kiriting',
  enterDescription: 'Akksesuar tavsifini kiriting',
  enterAccessoryCount: 'Akksesuar sonini kiriting',
  enterAccessoryPrice: 'Aksessuar qiymati(narx)ini kiriting (Kumushda)',
  cancel: 'Bekor qilish',
  sell: 'Sotuvga qo`yish',
  givenCount: 'Berilgan soni',
  student: 'Student',
  hhId: 'HH ID',
  photo: 'Rasm',
  time: 'Vaqti',
  returnedAccessory: 'Aksessuarni qaytarish',
  reasonWhyReturnedThatProduct: 'Aksessuar qaytarilayotgani sababini yozing',
  given: 'Topshirilgan',
  stopped: 'To`xtatilgan',
  returned: 'Qaytarilgan',
  returnedProductTitle: 'Sotuvi bekor qilinib qaytarilgan aksessuarlar',
  description: 'Izoh',
  categoryWarningTitle: "Bu kategoriyaga maxsulot biriktirilganligi uchun, o'chirib bo'lmaydi",
  sureToArchiveProduct: 'Ushbu aksessuar sotuvini to’xtatmoqchimisiz?',
  sureToRestoreProduct: 'Ushbu aksessuar sotuvga qaytarmoqchimisiz?',
  productImage: 'Maxsulot rasmi',
  addedSuccessfully: 'Muvaffaqiyatli qo`shildi',
  editAccessory: 'Aksessuar o`zgartirish',
  areYouSureToGive: 'Ushbu aksessuarni sotib olgan studentga topshirmoqchimisiz?',
  categoriesPage: 'Aksessuarlar kategoriyalari',
  categoryRequiredTitle: 'Kategoriya uchun nom kiriting',
  accessoriesWhichStudentsBought: 'Studentlar shaxsiy kabinetidan sotib olingan aksessuarlar',
  accessoriesWhichStudentsReceived: 'Sotib olgan studentlarga topshirilgan aksessuarlar',
  accessoriesWhichOnSale: 'Sotuvda bo`lgan aksessuarlar',
  accessoriesWhichIsStopped: 'Sotuvdan to`xtatilgan aksessuarlar',
}
