import { Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
  GroupStatus,
  HomeworkCheckingStatus,
  LessonCheckingStatistic,
  StudentHomeworkStatistic,
} from '@/api/study-department'
import { fixedKpi, groupStatus, groupStatusColor } from '@/constants'
import mainDictionary from '@/dictionary'
import { HomeworkDbDifficultyLevels } from '@/stores/study-department/types'
import { KpiStaffType, TeachersKpiGroupsType } from '@/types'
import { getFullName } from '@/utils'
import { allOption } from '../Groups'

import styles from './Homeworks/study-department.module.scss'

const pointerStyle = {
  cursor: 'pointer',
}

export const conditionalRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => {
      const percentage = Math.round((row.acceptedHomeworkCount * 100) / row.totalHomeworkCount)

      return percentage >= 50
    },
    style: {
      backgroundColor: '#99FF99',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalNotDoneRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => {
      const percentage = Math.round((row.uncompletedHomeworkCount * 100) / row.totalHomeworkCount)

      return percentage >= 50
    },
    style: {
      backgroundColor: '#FF0000',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalWaitingRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => {
      const percentage = Math.round((row.waitingHomeworkCount! * 100) / row.totalHomeworkCount)

      return percentage >= 50
    },
    style: {
      backgroundColor: '#465fdc',
      color: '#fff',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalRejectedRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => {
      const percentage = Math.round((row.rejectedHomeworkCount * 100) / row.totalHomeworkCount)

      return percentage >= 50
    },
    style: {
      backgroundColor: '#FEFF33',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalRowStylesHomeworkStudent = [
  {
    when: (row: LessonCheckingStatistic) => row.status === HomeworkCheckingStatus.NotGiven,
    style: {
      backgroundColor: '#FEFF33',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: LessonCheckingStatistic) => row.status === HomeworkCheckingStatus.Checked,
    style: {
      backgroundColor: '#00FF00',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: LessonCheckingStatistic) => row.status === HomeworkCheckingStatus.Unchecked,
    style: {
      backgroundColor: '#FF0000',
      color: '#fff',
      '&:hover': pointerStyle,
    },
  },
]

export const homeworkByCheckingSort = [
  {
    label: <span className={`${styles.default} ${styles['normalize-option']}`}>{mainDictionary.allOfThem}</span>,
    value: null,
  },
  {
    value: HomeworkCheckingStatus.Checked,
    label: <span className={`${styles.checked} ${styles['normalize-option']}`}>{mainDictionary.checked}</span>,
    className: 'option-st',
  },
  {
    value: HomeworkCheckingStatus.NotGiven,
    className: 'option-st',
    label: <span className={`${styles['normalize-option']} ${styles['un-checked']}`}>{mainDictionary.notGiven}</span>,
  },
  {
    value: HomeworkCheckingStatus.Unchecked,
    label: <span className={`${styles['normalize-option']} ${styles['not-given']}`}>{mainDictionary.notChecked}</span>,
    className: 'option-st',
  },
]

export const groupStatuses = [
  allOption,
  {
    label: mainDictionary.active,
    value: GroupStatus.ACTIVE,
  },
  {
    label: mainDictionary.notStarted,
    value: GroupStatus.NotStarted,
  },
  {
    label: mainDictionary.ended,
    value: GroupStatus.ENDED,
  },
]

export const conditionalAcceptedHomeworkRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => (row?.acceptedHomeworkCount * 100) / row.totalHomeworkCount >= 50,
    style: {
      backgroundColor: '#99FF99',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalRejectedHomeworkRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => (row?.rejectedHomeworkCount * 100) / row.totalHomeworkCount >= 50,
    style: {
      backgroundColor: '#FEFF33',
      '&:hover': pointerStyle,
    },
  },
]

export const conditionalNotDoneHomeworkRowStyles = [
  {
    when: (row: StudentHomeworkStatistic) => (row?.uncompletedHomeworkCount * 100) / row.totalHomeworkCount >= 50,
    style: {
      backgroundColor: '#FF0000',
      '&:hover': pointerStyle,
    },
  },
]

export const ACCEPTED = 'accepted'
export const REJECTED = 'rejected'

export const teachersKPIColumns: ColumnsType<TeachersKpiGroupsType> = [
  {
    title: mainDictionary.groupName,
    render: (item: TeachersKpiGroupsType) => <Typography.Text>{item.name || '-'}</Typography.Text>,
    fixed: 'left',
  },
  {
    title: mainDictionary.status,
    dataIndex: 'status',
    render: (value, record) => <Tag color={groupStatusColor[record.status]}>{groupStatus[record.status]}</Tag>,
  },
  {
    title: mainDictionary.course,
    render: (item: TeachersKpiGroupsType) => <Typography.Text>{item?.course?.name || '-'}</Typography.Text>,
  },
  {
    title: mainDictionary.studyMonth,
    align: 'center',
    render: (item: TeachersKpiGroupsType) => <Typography.Text>{item?.studyMonth || '-'}</Typography.Text>,
  },
  {
    title: mainDictionary.teacher,
    render: (item: TeachersKpiGroupsType) => {
      const teachers = item.staff

      return teachers.map((teacher: KpiStaffType) => (
        <Typography.Paragraph key={teacher.id}>{getFullName<KpiStaffType>(teacher) || '-'}</Typography.Paragraph>
      ))
    },
  },
  {
    title: mainDictionary.rating,
    align: 'center',
    render: (item: TeachersKpiGroupsType) => <>{item?.rating}</>,
  },
  {
    title: mainDictionary.homework,
    align: 'center',
    render: (item: TeachersKpiGroupsType) => <>{fixedKpi(item?.homeworkKpi)} %</>,
  },
  {
    title: mainDictionary.attendanceTitle,
    align: 'center',
    render: (item: TeachersKpiGroupsType) => <>{fixedKpi(item?.attendanceKpi)} %</>,
  },
  {
    title: mainDictionary.exam,
    align: 'center',
    render: (item: TeachersKpiGroupsType) => <>{fixedKpi(item?.examKpi)} %</>,
  },
  {
    title: mainDictionary.branch,
    render: (item: TeachersKpiGroupsType) => <Typography.Text>{item?.office?.name || '-'}</Typography.Text>,
  },
]

export const homeworkRequirements = [
  {
    label: mainDictionary.requiredHomework,
    value: true,
  },
  {
    label: mainDictionary.notRequired,
    value: false,
  },
]

export const HomeworkStatuses = [
  {},
  {
    label: mainDictionary.easy,
    color: 'green',
    value: HomeworkDbDifficultyLevels.Low,
  },
  {
    label: mainDictionary.medium,
    color: 'gold',
    value: HomeworkDbDifficultyLevels.Medium,
  },
  {
    label: mainDictionary.hard,
    color: 'red',
    value: HomeworkDbDifficultyLevels.High,
  },
]
