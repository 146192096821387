import { FC, useState } from 'react'
import { Button, Flex, Progress, Typography } from 'antd'
import classNames from 'classnames'
import saveAs from 'file-saver'
import { HomeworkAttachment } from '@/api/groups'
import { isSuccessPercent } from '@/constants/utils'
import { addCatchNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { downloadUrl } from '@/utils/getHlsVideoLink'

import styles from './group-download-file.module.scss'

const cn = classNames.bind(styles)

type Props = {
  item: HomeworkAttachment
  sender: string
}

export const GroupDownloadFile: FC<Props> = ({ item, sender }) => {
  const [percent, setPercent] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { groupsStore } = useStores()

  const handleSaveFile = async (file: HomeworkAttachment) => {
    try {
      if (!file.url) return
      setIsLoading(true)

      const blob = await groupsStore.getGroupDownloadFile(downloadUrl(file.url)!, setPercent)

      saveAs(blob, `${sender}-${file.origName}`)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setIsLoading(false)
      setPercent(0)
    }
  }

  return (
    <Flex vertical>
      <Button
        disabled={isLoading}
        key={item.url}
        onClick={handleSaveFile.bind(null, item)}
        className={cn(`${isLoading ? 'group-download__btn' : ''}`)}
      >
        <Typography.Text className={cn('group-download__text')}>
          {item.origName?.slice(0, 12)}...{item.origName.split('.')?.at(-1)}
        </Typography.Text>
      </Button>

      {isLoading && (
        <Progress
          percent={percent}
          status={percent === isSuccessPercent ? 'success' : 'active'}
          showInfo={false}
          key={percent}
          size={'small'}
          className={cn('group-download__progress')}
        />
      )}
    </Flex>
  )
}
