export const eventsDictionary = {
  planned: 'Rejalashtirilgan',
  conducted: 'O`tkazilgan',
  addMasterClass: 'Yangi master-klass',
  byEventName: 'Nomi bo`yicha',
  discipline: 'Disiplina',
  requiredXp: 'Ishtirok Talab qilingan XP',
  registeredUsersCount: 'Ro`yxatdan o`tganlar soni',
  participantsCount: 'Ishtirokchilar soni',
  addNewMasterClass: 'Yangi masterklass qo`shish',
  requiredField: 'Bu maydonni to`ldirish shart',
  selectDateAndTime: 'Kun va vaqtni tanlang',
  awardedXp: 'Ishtirok Mukofot XP miqdori',
  awardedSilver: 'Ishtirok uchun mukofot Kumush',
  participationLimit: 'Maksimal a`zolar soni',
  faceIdDevice: 'Face-id qurilma',
  faceIdPeriod: 'Face-id hisoblash muddati',
  requiredFaceIdDevice: 'Face-id qurilma kiritish shart!',
  sureToDeleteDeleteMasterClass: 'Ushbu master-klassni o’chirishga aminmisiz?',
  exist: 'Mavjud',
  notExist: 'Mavjud emas',
  aboutMasterClass: 'Master-klass haqida(description):',
  registered: 'Ro`yxatdan o`tganlar',
  contributors: 'Kelganlar',
  searchByStudentNameOrId: 'Nomi yoki ID bo`yicha',
  givenXp: 'Berilgan XP',
  givenSilver: 'Berilgan Kumush',
  exclusion: 'Chetlatish',
  isRegisteredBefore: 'Oldin Ro`yxatdan o`tgan',
  addNewCompetition: 'Yangi musobaqa qo`shish',
  participationType: 'Ishtirok turi',
  teamMemberCount: 'Jamoadagi ishtirokchilar soni',
  withTeam: 'Jamoaviy',
  single: 'Yakka',
  awardingParticipants: 'Sovrindorlarni belgilash',
  place: 'O`rin',
  finishEvent: 'Yakunlash',
  sureToDeleteDeleteCompetition: 'Ushbu musobaqani o`chirishga aminmisiz?',
}
