import { observer } from 'mobx-react'
import { LogoutOutlined } from '@ant-design/icons'
import { Alert, Modal, Typography } from 'antd'
import classnamesBind from 'classnames/bind'
import { useBoolean } from 'usehooks-ts'
import { STAFF_INFO, TOKEN_NAME } from '@/constants/auth'
import mainDictionary from '@/dictionary'
import { resetStores, useStores } from '@/stores'
import { useCheckMyGroup } from '@/utils/hooks/useCheckMyGroup'
import { storage } from '@/utils/storage'

import styles from './logout.module.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  hasUploadingFiles?: boolean
}

export const LogOut = observer(({ hasUploadingFiles }: Props) => {
  const { kinescopeStore, authStore } = useStores()
  const isAcademic = useCheckMyGroup()
  const { value: isLoading, setTrue: setIsLoadingTrue } = useBoolean(false)
  const { value: isModalOpen, setTrue: setIsModalOpenTrue, setFalse: setIsModalOpenFalse } = useBoolean(false)

  const handleOk = async () => {
    setIsLoadingTrue()
    kinescopeStore.abortAllUploadingFiles()

    const token = storage.get('fcmToken')

    if (token) {
      await authStore.logOut()
    }

    await kinescopeStore
      .deleteUploadingFiles(isAcademic)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        resetStores()
        storage.removeItems([STAFF_INFO, TOKEN_NAME.refreshToken, TOKEN_NAME.accessToken])
        setIsModalOpenFalse()
      })
  }

  return (
    <div>
      <span onClick={setIsModalOpenTrue} className={cn('logout')}>
        <Typography.Text className={cn('logout__title')}>{mainDictionary.exit}</Typography.Text>
        <LogoutOutlined className={cn('logout__exit')} />
      </span>

      <Modal
        title={mainDictionary.exit}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={setIsModalOpenFalse}
        okText={mainDictionary.yes}
        cancelText={mainDictionary.cancel}
        confirmLoading={isLoading}
      >
        <div className={cn('logout__modal-test')}>{mainDictionary.logOut}</div>
        {hasUploadingFiles && <Alert message={mainDictionary.hasUploadingFiles} type="error" showIcon />}
      </Modal>
    </div>
  )
})
