import { observer } from 'mobx-react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import mainDictionary from '@/dictionary'
import { BranchOfficeGridActionButtons } from '@/pages/Controls/BranchOffice'
import { BranchOffice } from '@/pages/Controls/BranchOffice/types'
import { getRowIndex } from '@/utils'
import { branchOfficeStore } from '../shared/branch-office.store'

type Props = {
  isLoading: boolean
}

export const BranchOfficeGrid = observer(({ isLoading }: Props) => {
  const { branchOffices } = branchOfficeStore
  const branchOfficesTableColumnWithActions: ColumnsType<BranchOffice> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (value, branchOffice, index) => <span>{getRowIndex(1, 10, index)}</span>,
    },
    {
      title: mainDictionary.branchOfficeName,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (value, branchOffice) => <span>{branchOffice.name}</span>,
    },
    {
      title: mainDictionary.holyHopesBranchOfficeName,
      dataIndex: ['hOffice', 'name'],
      key: 'hOfficeName',
      align: 'center',
      render: (value, branchOffice) => <span>{branchOffice.hOffice.name}</span>,
    },
    {
      title: mainDictionary.actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value, branchOffice) => <BranchOfficeGridActionButtons branchOffice={branchOffice} />,
    },
  ]

  return (
    <Table<BranchOffice>
      size="large"
      columns={branchOfficesTableColumnWithActions}
      dataSource={branchOffices || []}
      bordered
      loading={!branchOffices || isLoading}
      pagination={false}
    />
  )
})
