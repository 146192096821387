import { ReactNode } from 'react'
import dayjs from 'dayjs'
import { GroupStatus } from '@/api/gamification'
import { EnterTypes } from '@/api/staff'
import mainDictionary from '@/dictionary'
import { HomeworkChatsStatus, HomeworkStatistics } from '@/pages/Groups/types'

export const months = ['Yan', 'Fev', 'Mart', 'Apr', 'May', 'Iyun', 'Iyul', 'Avg', 'Sen', 'Okt', 'Noy', 'Dek']
export const isSuccessPercent = 100
export const PER_PAGES = 'per_pages'

export const groupStatusColor = ['none', 'gray', 'blue-inverse', '#eb4034']

export const VALID_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
export const PHOTO_SIZE_LIMIT = 2 * 1024 * 1024

export const groupStatus = {
  [GroupStatus.NotStarted]: mainDictionary.notStarted,
  [GroupStatus.ACTIVE]: mainDictionary.active,
  [GroupStatus.ENDED]: mainDictionary.ended,
}

export const fixedKpi = (kpi?: number) => {
  if (!kpi) return 0

  return Math.round(kpi)
}

export const disabledKpiDate = (current: dayjs.Dayjs | null) => {
  if (!current) {
    return false
  }

  const today = dayjs().startOf('day')

  return current.isAfter(today, 'day') || current.isSame(today, 'day')
}

export interface IOption {
  value: HomeworkStatistics | HomeworkChatsStatus | null
  label: string | ReactNode
  className?: string
  enterType?: EnterTypes
}

export const VAPID_KEY = 'BOEo5OmOYvrEFSJJF1Dxd1kXEqUE83AKVnIkoI7WhSsdj8HYkKJ54xn17TzJIyL8PGi7MY7nanfp2IMVK56AO8c'
