import { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { studentStore } from '@/stores/students/student'
import { getRowIndex } from '@/utils'
import { IStudentIndex } from '../types'

export const StudentIndex: FC<IStudentIndex> = observer(({ index }: IStudentIndex) => {
  const number = useMemo(() => getRowIndex(studentStore.page, studentStore.perPage, index), [index])

  return <span>{number}</span>
})
