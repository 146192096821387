export enum GroupStatus {
  NotStarted = 1,
  Active,
  Ended,
}

type KpiCategoryType = {
  id: number | string
  hName: string
  createdAt: string
  displayName: string
}

type KpiOfficeType = {
  id: number
  name: string
  hOffice: HhOffice | null
}

type HhOffice = {
  id: number
  name: string
  isRelated: boolean
}

type KpiCourseType = {
  name: string
  imageIllustration?: string
}

export type KpiStaffType = {
  id: number
  firstName: string
  lastName: string
  role: string | null
}

export type TeachersKpiGroupsType = {
  id: number
  name: string
  staff: KpiStaffType[]
  startedDate: string
  endedDate: string
  studentsCount: number
  status: GroupStatus
  beginTime: string
  endTime: string
  category: KpiCategoryType
  office: KpiOfficeType
  attendanceKpi?: number
  examKpi?: number
  homeworkKpi?: number
  studyMonth?: number
  course: KpiCourseType
  learningType: string
  rating?: number
}

export type TeachersKpiParamsType = {
  keys: string[]
  page: number
  perPage: number
  value: string
  fromByStartedDate: string | null
  toByStartedDate: string | null
  fromByEndedDate?: string | null
  toByEndedDate?: string | null
  status?: string | GroupStatus
  officeIds: number[]
  categoryId: number
  courseId: number
  learningTypeId: number
  teacherId: number
  kpiDate: string | string[]
  ratingFrom: string | null
  ratingTo: string | null
}

export type TeachersKpiRequestParamsType = Partial<TeachersKpiParamsType>

export type TeachersKpiResponseType = {
  groups: TeachersKpiGroupsType[]
  total: number
}

export type SelectOptionType = {
  label: string
  value: number | null
}
