import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { PlusOutlined } from '@ant-design/icons'
import { Flex, Tag } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import type { FilterValue, SorterResult } from 'antd/es/table/interface'
import classNames from 'classnames/bind'
import { Button } from '@/ant'
import { IStatistics } from '@/api/gamification'
import { StoreTitle, Table as AntTable } from '@/components'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { SortStatistic } from '@/pages/Gamification'
import { formattedTitles, formattedTypes } from '@/pages/Groups'
import { getPaginationParams } from '@/pages/utils'
import { useStores } from '@/stores'
import { Orders } from '@/stores/gamification/types'
import { StudentStatuses } from '@/stores/students'
import { getFullName } from '@/utils'
import { ALL_ID } from '../../constants'
import { GroupsModal } from './GroupsModal'

import styles from './table.module.scss'

const cn = classNames.bind(styles)

export const DataTable = observer(() => {
  const { gamificationStore } = useStores()
  const [groups, setGroups] = useState(false)
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.gamificationUnits,
    option: PermissionOptions.Update,
  })
  const [loaderId, setLoaderId] = useState(0)

  const handleOpenActionModal = async (params: IStatistics) => {
    setLoaderId(params.id)

    await gamificationStore.getStudentAwardsLevel(params?.level)

    gamificationStore.setActionModal(true)
    setLoaderId(0)
    gamificationStore.setStudentStatistics(params)
  }

  const handleStudentGroupClick = (id: number) => {
    gamificationStore.setStudentGroupsModal(true)
    gamificationStore.getStudentGroups({
      data: {
        studentId: id,
        categoryId:
          gamificationStore.statisticTab !== ALL_ID ? parseInt(gamificationStore.statisticTab! as string, 10) : null,
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.StatisticPosition,
        align: 'center',
        fixed: 'left',
        width: '80px',
        render: (_: any, item: IStatistics) => <span>{item.position}</span>,
      },
      {
        title: mainDictionary.hhId,
        align: 'center',
        width: '100px',
        render: (item: IStatistics) => <span>{item?.hId}</span>,
      },
      {
        title: mainDictionary.fullName,
        align: 'center',
        fixed: 'left',
        render: (item: IStatistics) => {
          const route = ROUTES.gamificationStudentStatistics
            .replace(':id', String(item?.id))
            .replace(':categoryId', String(gamificationStore.statisticTab))

          return (
            <Flex align="center" vertical gap={10}>
              <Link to={route}>{getFullName<IStatistics>(item)}</Link>

              {gamificationStore.statisticsFilter?.groupId && item?.status === StudentStatuses.FINISHED && (
                <Tag color="red">{mainDictionary.finished}</Tag>
              )}
            </Flex>
          )
        },
      },
      {
        title: mainDictionary.course,
        align: 'center',
        render: (item: IStatistics) => (
          <ol>{item?.courses?.map((course) => <li key={course?.id}>{course?.name}</li>)}</ol>
        ),
      },
      {
        title: mainDictionary.group,
        align: 'center',
        render: (item: IStatistics) => (
          <Tag className={cn('statistics-table__groups')} onClick={handleStudentGroupClick.bind(null, item.id)}>
            {item.groupsCount}
          </Tag>
        ),
      },
      {
        title: mainDictionary.erpStatus,
        align: 'center',
        render: (item: IStatistics) => <span>{item.status}</span>,
        sorter: true,
      },
      {
        title: mainDictionary.level,
        align: 'center',
        render: (item: IStatistics) => <span>{item?.level}</span>,
        sorter: true,
      },
      {
        title: mainDictionary.xp,
        align: 'center',
        render: (item: IStatistics) => <span>{item?.xp}</span>,
        sorter: true,
        fixed: 'right',
        defaultSortOrder: 'descend',
      },
      {
        title: mainDictionary.silver,
        align: 'center',
        fixed: 'right',
        render: (item: IStatistics) => <span>{item.coin}</span>,
        sorter: true,
      },
      ...(isAvailableEdit && gamificationStore.statisticTab !== ALL_ID
        ? [
            {
              title: mainDictionary.actions,
              align: 'center',
              render: (item: IStatistics) => (
                <Button
                  icon={<PlusOutlined />}
                  loading={loaderId === item.id}
                  type="primary"
                  onClick={handleOpenActionModal.bind(null, item)}
                />
              ),
            },
          ]
        : []),
    ],
    [gamificationStore.statistics, loaderId],
  )

  const handleSizeChange = (page: number, perPage: number) => {
    gamificationStore.setStatisticsFilter({
      ...gamificationStore.statisticsFilter,
      perPage,
      page,
    })
  }

  const handlePaginationChange = (value: number) => {
    gamificationStore.setStatisticsTableLoading(true)

    const info = {
      ...gamificationStore.statisticsFilter,
      page: value,
    }

    gamificationStore.setStatisticsFilter(info)
  }

  const orderStatistics = (orderType: SortStatistic | string, orders: Orders) => {
    const info = {
      ...gamificationStore.statisticsFilter,
      categoryId:
        gamificationStore.statisticTab !== ALL_ID ? parseInt(gamificationStore.statisticTab! as string, 10) : null,
      sortBy: orderType,
      orderBy: orders,
    }

    gamificationStore.setStatisticsFilter(info)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IStatistics> | SorterResult<IStatistics>[],
  ) => {
    gamificationStore.setStatisticsTableLoading(true)
    const currentSorter = Array.isArray(sorter) ? sorter[0] : sorter
    const orders = formattedTypes[currentSorter.order!]

    // @ts-ignore
    orderStatistics(formattedTitles[currentSorter.column?.title], orders)
  }

  return (
    <>
      <AntTable<IStatistics>
        // @ts-ignore
        columns={columns}
        className={cn('statistics-table')}
        storeTitle={StoreTitle.StatisticsPerPage}
        onStoreChange={handleSizeChange}
        dataSource={gamificationStore.statistics?.statistics}
        bordered
        rowKey="id"
        loading={gamificationStore.statisticsTableLoading}
        onChange={handleTableChange}
        scroll={{ x: 1500 }}
        pagination={{
          ...getPaginationParams({
            total: gamificationStore.statistics?.total,
            onChange: handlePaginationChange,
            pageSize: gamificationStore.statisticsFilter?.perPage! || 10,
            notShowSizeChanger: false,
            current: gamificationStore?.statisticsFilter?.page || 1,
          }),
        }}
      />

      {groups && <GroupsModal setClose={setGroups} />}
    </>
  )
})
