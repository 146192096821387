import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { IBreadcrumb } from '@/stores/breadcrumb/types'

import styles from './breadcrumbs.module.scss'

const cn = classNames.bind(styles)

export const Item = ({ label, link, children }: IBreadcrumb) => (
  <li className={cn('breadcrumb-items__item')}>
    {link ? (
      <Link className={cn('breadcrumb-items__link', 'more__items')} to={link}>
        <span title={(children ? children.toString() : label) || ''} className={cn('menu__items-link-text')}>
          {children ? children : label && label?.length > 20 ? `${label?.slice(0, 15)}...` : label}
        </span>
      </Link>
    ) : (
      <span className={cn('breadcrumb-items__element')}>{children ? children : label}</span>
    )}
  </li>
)
