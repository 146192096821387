import { EnterTypes } from '@/api/staff'

export type KeyType = [string, string]

export enum RoleTypes {
  STAFF = 1,
  ACADEMIC = 2,
  STUDENT = 3,
}

export interface IGetFaceIdLogsRequest {
  page?: number
  perPage?: number
  key?: string
  roleId?: number
  roleType?: RoleTypes
  enterType?: EnterTypes
  date?: string
  officeIds?: number[]
  startDate?: string
  endDate?: string
  deviceId: number
}

export interface IChangeStudentStatusReq {
  id: number
  isActive: boolean
}
