import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import {
  Message,
  MyNotification,
  NotificationSingle,
  NotificationsSended,
  NotificationsTemplates,
  NotificationStudentPrivate,
  PublicStudentNotificationCreate,
  StaffAndStudentPublic,
  TemplateCreate,
} from './lazy'

export const notificationsRouters = [
  {
    path: ROUTES.notifications,
    children: [
      {
        path: ROUTES.notificationsSended, // TODO: Add permissions
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <NotificationsSended />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.notificationStudentPrivate,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <NotificationStudentPrivate />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.notificationsPublicStaffAndStudent,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <StaffAndStudentPublic />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.notificationPublicStudentsCreate,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <PublicStudentNotificationCreate />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.notificationsSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <NotificationSingle />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.templates,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <NotificationsTemplates />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.templateCreate,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <TemplateCreate />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.templateEdit,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <TemplateCreate />
          </AvailableComponent>
        ),
      },
    ],
  },
  {
    path: ROUTES.myNotification,
    children: [
      {
        path: ROUTES.myNotification,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <MyNotification />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.myNotificationSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settings}>
            <Message />
          </AvailableComponent>
        ),
      },
    ],
  },
]
