import { IStaffInfo } from '@/stores/auth/types'
import { IChangeLoginRequest, IChangePasswordValues } from '@/stores/profile'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { ProfileEndpoints } from './endpoints'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class ProfileApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  changeLogin = (data: IChangeLoginRequest): Promise<IResponse<IStaffInfo>> =>
    this.post(ProfileEndpoints.ProfileChangeLogin, data)

  changePassword = (data: IChangePasswordValues): Promise<IResponse<IStaffInfo>> =>
    this.post(ProfileEndpoints.ProfileChangePassword, data)
}

export const profileApi = new ProfileApi(config)
