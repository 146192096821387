import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Input, Modal, notification, Typography } from 'antd'
import classNames from 'classnames/bind'
import { IStudents, studentApi } from '@/api/students'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { ResetPasswordContent } from '@/pages/Users/Assistants/ResetPasswordContent'
import { studentStore } from '@/stores/students/student'
import { getFullName, regexPhoneNumber } from '@/utils'

import styles from '../student-sms.module.scss'

const cn = classNames.bind(styles)

interface FormValues {
  phone: string
}

export const StudentSmsSendModal = observer(() => {
  const [loading, setLoading] = useState(false)
  const [resetPassword, setResetPassword] = useState('')
  const [form] = Form.useForm()
  const {
    setStudentSmsSendModal,
    singleStudent,
    page,
    perPage,
    keys,
    value,
    orderBy,
    categoryId,
    statusId,
    systemStatus,
    studentGroup,
    getStudents,
    setStudentId,
  } = studentStore

  const handleModalClose = () => {
    setStudentSmsSendModal(false)
    setStudentId(null)
  }

  const handleSubmit = (values: FormValues) => {
    setLoading(true)
    if (!singleStudent) {
      return
    }
    studentApi
      .studentSmsSend({
        id: singleStudent?.id,
        login: singleStudent?.login,
        password: resetPassword,
        phone: values?.phone,
      })
      .then((res) => {
        if (res.success) {
          const studentParams = {
            page,
            perPage,
            keys,
            value,
            orderBy,
            categoryId,
            statusId,
            systemStatus,
            groupId: studentGroup!,
          }

          getStudents(studentParams).catch(addCatchNotification)

          notification.success({
            message: '',
            description: mainDictionary.smsSended,
          })
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        setLoading(false)
        handleModalClose()
      })
  }

  const handleModalOk = () => {
    form.submit()
  }

  const getData = () => {
    setLoading(true)

    if (!singleStudent) {
      return
    }

    studentApi
      .resetPassword({ id: singleStudent.id })
      .then((res) => {
        if (res.success) {
          setResetPassword(res?.data?.newPassword)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!singleStudent) {
      handleModalClose()

      return
    }

    getData()
    form.setFieldsValue({
      phone: singleStudent?.mobile || singleStudent?.phoneNumber,
    })
  }, [singleStudent])

  return (
    <Modal
      open={studentStore.studentSmsSendModal}
      onCancel={handleModalClose}
      confirmLoading={loading}
      onOk={handleModalOk}
      title={mainDictionary.smsSend}
    >
      {singleStudent && (
        <Typography.Text className={cn('sms-modal__title')} strong>
          {mainDictionary.toWhom}: {getFullName<IStudents>(singleStudent)}
        </Typography.Text>
      )}

      <Form
        layout="vertical"
        onFinish={handleSubmit}
        validateMessages={{
          required: mainDictionary.formRequiredMessage,
          whitespace: mainDictionary.formRequiredWhiteSpace,
        }}
        form={form}
      >
        <Form.Item
          label={mainDictionary.phoneNumber}
          name="phone"
          rules={[
            { required: true },
            {
              pattern: regexPhoneNumber,
              message: mainDictionary.phoneFormatCheckerError,
            },
          ]}
        >
          <Input placeholder={mainDictionary.phoneNumber} />
        </Form.Item>
      </Form>
      {singleStudent && (
        <Typography className={cn('sms-modal__title')}>
          {mainDictionary.login}:
          <Typography.Text className={cn('sms-modal__desc')} strong>
            {singleStudent.login ? singleStudent.login : '---'}
          </Typography.Text>
        </Typography>
      )}
      <Typography className={cn('sms-modal__title')}>
        {mainDictionary.password}:
        <Typography.Text className={cn('sms-modal__desc')} strong>
          <ResetPasswordContent password={resetPassword} />
        </Typography.Text>
      </Typography>
    </Modal>
  )
})
