import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { onMessage } from '@firebase/messaging'
import { Layout as AntdLayout } from 'antd'
import { MenuProps } from 'antd/es/menu/menu'
import classnamesBind from 'classnames/bind'
import { useBoolean } from 'usehooks-ts'
import { Icons } from '@/components'
import { ConfirmSynchModal } from '@/components/ConfirmSynchModal'
import { LoginModal } from '@/components/LoginModal'
import { NoInternet } from '@/components/NoInternet/NoInternet'
import { SynchModal } from '@/components/SychnModal'
import { addNotification, NotificationMessageType } from '@/modules/notifications'
import {
  permissionObjectTypes,
  PermissionOptions,
  useCheckPermission,
  ValueOfPermissionObjectTypes,
} from '@/modules/permission'
import { messaging } from '@/shared/helpers'
import { useOnLine } from '@/shared/hooks'
import { useStores } from '@/stores'
import { useDeviceMediaQuery } from '@/utils'
import { Content } from './Content'
import { Header } from './Header'
import { getMenu, IMenuItems, mainMenuLists, Menu, menuSkeletons } from './Menu'

import styles from './layout-protected.module.scss'

const cn = classnamesBind.bind(styles)

const getSelectedKeys = (pathname: string) => {
  const pathnameList = pathname.split('/')

  return [
    pathname,
    ...(pathnameList[1] ? [`/${pathnameList[1]}`] : []),
    ...(pathnameList[2] ? [`/${pathnameList[1]}/${pathnameList[2]}`] : []),
  ]
}

type MenuItem = Required<MenuProps>['items'][number]

export const LayoutProtected = observer(() => {
  const { value, toggle } = useBoolean(false)
  const { isMobile } = useDeviceMediaQuery()
  const siderWidth = value ? 80 : isMobile ? 0 : 250

  const [isAvailableToSee] = useCheckPermission({
    module: permissionObjectTypes.managementReports,
    option: PermissionOptions.Read,
  })
  const { permissionsStore, appStore } = useStores()
  const navigate = useNavigate()
  const mainMenuList = mainMenuLists(appStore.reportsCount || 0)
  const { pathname } = useLocation()
  const selectedKeys = getSelectedKeys(pathname)
  const { isOnline } = useOnLine()

  const menu = useMemo(() => {
    if (!permissionsStore.structuredPermissions) {
      return []
    }

    const menuItemsPermissionsKeys = Object.keys(
      permissionsStore.structuredPermissions,
    ) as ValueOfPermissionObjectTypes[]

    return getMenu(menuItemsPermissionsKeys, mainMenuList)
  }, [permissionsStore.permissions, appStore.reportsCount]) as MenuItem[]

  const menuItems = permissionsStore.permissions === null ? menuSkeletons : menu

  useEffect(() => {
    if (pathname === '/' && permissionsStore.permissions && menu && menu.length) {
      const { key, children } = menu[0] as IMenuItems
      const link = children && children[0] ? children[0].key : key

      navigate(link || '/')
    }
  }, [menu, permissionsStore.permissions, pathname])

  useEffect(() => {
    if (permissionsStore.permissions) {
      appStore.setMenuItems(menuItems)
      appStore.setSelectedKeys(selectedKeys)
    }
  }, [permissionsStore.permissions, selectedKeys])

  useEffect(() => {
    if (isAvailableToSee) {
      appStore.getReportsCount()
    }
  }, [isAvailableToSee])

  onMessage(messaging, (payload) => {
    addNotification({
      message: payload?.notification?.title || '',
      messageType: NotificationMessageType.Info,
    })
  })

  return (
    <>
      {!isOnline && <NoInternet />}
      <AntdLayout className={cn('layout')} hasSider>
        {!isMobile && (
          <AntdLayout.Sider
            trigger={null}
            collapsible
            collapsed={value}
            width={siderWidth}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: !isOnline ? 'sticky' : 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div className={cn('layout__logo', { 'layout__logo--center': value })}>
              <Icons.Logo width={40} />
              {!value && <span className={cn('layout__logo-text')}>{appStore.adminRole}</span>}
            </div>

            <div className={cn('layout__menu')}>
              <Menu />
            </div>
          </AntdLayout.Sider>
        )}
        {appStore.confirmSynchModal && <ConfirmSynchModal />}
        {appStore.synchModal && <SynchModal />}

        <AntdLayout className="site-layout" style={{ marginLeft: !isOnline ? 0 : siderWidth }}>
          <Header collapsed={value} onCollapsedClick={toggle} />

          <Content>
            <Outlet />
          </Content>
        </AntdLayout>
        {appStore.loginModal?.isVisible && <LoginModal />}
      </AntdLayout>
    </>
  )
})
