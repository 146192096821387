import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { getPerPages } from '@/shared'
import { SynchronizeAvailablePages, useStores } from '@/stores'
import { useBreadcrumbs, useCheckMyGroup, useSynchronization } from '@/utils/hooks'
import { AssistantsModal } from './AssistantsModal'
import { groupsBreadCrummb } from './constants'
import { Filter } from './Filter'
import { TeacherSchedule } from './MyGroups/TeacherSchedule'
import { GroupTable } from './Table'

export const Groups = observer(() => {
  const { groupsStore } = useStores()
  const perPageFromLocalStorage = getPerPages()

  useBreadcrumbs(groupsBreadCrummb)
  const isMyGroup = useCheckMyGroup()

  useSynchronization({ synchronizeType: SynchronizeAvailablePages.Groups })

  useEffect(() => {
    groupsStore.resetSingleGroup()
    groupsStore.setActiveDay(null)
    groupsStore.setDate(null)
    const info = {
      perPage: (isMyGroup ? perPageFromLocalStorage?.myGroupsPerPage : perPageFromLocalStorage?.groupsPerPage) || 10,
      ...groupsStore.filterOptions,
    }

    groupsStore.setFilterOption(info)

    return () => {
      groupsStore.reset()
    }
  }, [])

  return (
    <>
      <Filter />
      <GroupTable />
      {groupsStore.assistantsModal && <AssistantsModal />}
      {groupsStore.teacherSchedule && <TeacherSchedule />}
    </>
  )
})
