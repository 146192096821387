import { ROUTES } from '@/constants'
import { AvailableComponent } from '@/modules/AvailableComponent'
import { permissionObjectTypes } from '@/modules/permission'
import { Accessories, AccessoriesAdd, Accessory, Categories, MarketTransactions } from './lazy'

export const marketRouter = [
  {
    path: ROUTES.market,
    children: [
      {
        path: ROUTES.marketCategories,
        index: true,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <Categories />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketTransactions,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <MarketTransactions />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketAccessories,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <Accessories />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketAccessoriesAdd,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <AccessoriesAdd />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketAccessoriesEdit,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <AccessoriesAdd />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketAccessory,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <Accessory />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.marketAccessoriesRestore,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.gamificationActions}>
            <AccessoriesAdd />
          </AvailableComponent>
        ),
      },
    ],
  },
]
