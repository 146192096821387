import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useBoolean } from 'usehooks-ts'
import { IAction } from '@/api/gamification'
import { StoreTitle, Table as AntTable } from '@/components/Table'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from '@/modules/permission'
import { getPaginationParams } from '@/pages/utils'
import { getPerPages } from '@/shared'
import { useStores } from '@/stores'

export const ByAction = observer(() => {
  const navigate = useNavigate()
  const { gamificationStore } = useStores()
  const perPageFromLocalStorage = getPerPages()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.gamificationGroups,
    option: PermissionOptions.Update,
  })
  const [page, setPage] = useState<number>()
  const [perPage, setPerPage] = useState<number>()
  const { value: loading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(true)

  const handleClick = (id: number) => {
    navigate(ROUTES.gamificationConnectActionSingle.replace(':id', String(id)))
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.actionName,
        render: (item: IAction) => <span>{item.name}</span>,
      },
      {
        title: mainDictionary.groupsCount,
        render: (item: IAction) => <span>{item.groupsCount}</span>,
      },
      ...(isAvailableEdit
        ? [
            {
              title: mainDictionary.settings,
              render: (item: IAction) => (
                <Button icon={<EditOutlined />} type="primary" onClick={handleClick.bind(null, item.id)} />
              ),
            },
          ]
        : []),
    ],
    [],
  )

  useEffect(() => {
    gamificationStore.setSelectedActionIds([])
    gamificationStore.getActions({ page: page!, perPage: perPage! }).then(setLoadingTrue).finally(setLoadingFalse)
  }, [page, perPage])

  const handleChange = (page: number, pageSize: number) => {
    setPage(page)
    setPerPage(pageSize)
  }

  useEffect(() => {
    setPerPage(perPageFromLocalStorage?.byActionPage)
  }, [])

  return (
    <AntTable
      // @ts-ignore
      columns={columns}
      dataSource={gamificationStore.deeds?.actions}
      bordered
      rowKey={'id'}
      storeTitle={StoreTitle.ByActionPage}
      onStoreChange={handleChange}
      loading={loading}
      pagination={{
        ...getPaginationParams({
          total: gamificationStore.deeds?.total,
          notShowSizeChanger: false,
          onChange: handleChange,
          pageSize: perPage,
        }),
      }}
    />
  )
})
