import React, { useMemo } from 'react'
import { Modal, Table, Typography } from 'antd'
import classNames from 'classnames/bind'
import { ScheduleItem } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { fullTimeToFormattedDate, getFormattedTimeFromParams } from '@/utils'

import styles from './groups-modal.module.scss'

const cn = classNames.bind(styles)

type TProps = {
  setClose: React.Dispatch<React.SetStateAction<boolean>>
}

export const GroupsModal = ({ setClose }: TProps) => {
  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (_: ScheduleItem, item: ScheduleItem, index: number) => <span>{index + 1}</span>,
      },
      {
        title: mainDictionary.teacherName,
        render: (item: ScheduleItem) => <span>{`${item.staff?.firstName} ${item.staff?.lastName}`}</span>,
      },
      {
        title: mainDictionary.iRole,
        render: (item: ScheduleItem) => <span>{item.staff?.role?.name}</span>,
      },
      {
        title: mainDictionary.dLessonDays,
        render: (item: ScheduleItem) => <span>{item?.daysOfWeek?.join?.('-')}</span>,
      },
      {
        title: mainDictionary.dLessonTime,
        render: (item: ScheduleItem) => (
          <span>{`${getFormattedTimeFromParams(item.beginTime)} - ${getFormattedTimeFromParams(item.endTime)}`}</span>
        ),
      },
      {
        title: mainDictionary.startDate,
        render: (item: ScheduleItem) => <span>{fullTimeToFormattedDate(item?.startDate)}</span>,
      },
      {
        title: mainDictionary.endDate,
        render: (item: ScheduleItem) => <span>{fullTimeToFormattedDate(item.endDate)}</span>,
      },
      {
        title: mainDictionary.room,
        render: (item: ScheduleItem) => <span>{item?.classroomName || '-'}</span>,
      },
    ],
    [],
  )

  const handleClose = () => {
    setClose(false)
  }

  return (
    <Modal onCancel={handleClose} onOk={handleClose} open>
      <div className={cn('assistant-modal')}>
        <div>
          <Typography.Title level={5}>{mainDictionary.activeSchedules}</Typography.Title>
          {/* // ts-ignore */}
          <Table columns={columns} bordered pagination={false} scroll={{ x: 1200 }} dataSource={[]} />
        </div>
        <div>
          <Typography.Title level={5}>{mainDictionary.other}</Typography.Title>

          <Table
            columns={columns}
            bordered
            pagination={false}
            // @ts-ignore
            dataSource={[{}, {}]}
            // dataSource={groupsStore.groupTeachers?.othersScheduleItems!}
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
    </Modal>
  )
}
