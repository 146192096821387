import { TableProps, Tag, TagProps, Tooltip } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import {
  ExamStatus,
  HomeworkFilterStatus,
  IGroupStatus,
  IVideo,
  JournalAttendedStatus,
  NotComingReasons,
  StudentLessonStatistic,
  VideoStatusHistory,
} from '@/api/groups'
import { IOption, ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { VideoStatus } from '@/modules/kinescope'
import { SortStatistic } from '@/pages/Gamification/types'
import { VideoActions, VideoName, VideoStat } from '@/pages/Groups/GroupSingle/Videos/ColumnItems'
import { VideoUploadingActions } from '@/pages/Groups/GroupSingle/Videos/ColumnItems/VideoActions'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { Orders } from '@/stores/gamification/types'
import { formatDate, getFormattedFullDateFromParams } from '@/utils/date'
import { formatBytes } from '@/utils/formatBytes'
import { HomeworkChatsStatus, HomeworkStatistics } from './types'

import styles from '@/pages/Groups/GroupSingle/Videos/ColumnItems/VideoName/video-name.module.scss'

const cn = classNames.bind(styles)

export const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: 'user',
}

export const tableSizes = {
  x: 1500,
  y: 500,
}

export const imgFormat = 'image/jpeg'

export const groupStatus = [
  {
    value: null,
    label: mainDictionary.all,
  },
  {
    value: IGroupStatus.NotStarted,
    label: mainDictionary.notStarted,
  },
  {
    value: IGroupStatus.Active,
    label: mainDictionary.active,
  },
  {
    value: IGroupStatus.Ended,
    label: mainDictionary.ended,
  },
]

export const videoStatusText: Record<VideoStatus, string> = {
  [VideoStatus.Processing]: mainDictionary.convertedFileInKineskope,
  [VideoStatus.Done]: mainDictionary.convertedFileUpload,
  [VideoStatus.Error]: mainDictionary.error,
  [VideoStatus.Pause]: mainDictionary.pause,
  [VideoStatus.Uploading]: mainDictionary.uploading,
  [VideoStatus.Waiting]: mainDictionary.uploadWaiting,
}

export const tagColor: Record<VideoStatus, TagProps['color']> = {
  [VideoStatus.Processing]: 'blue',
  [VideoStatus.Done]: 'green',
  [VideoStatus.Error]: 'red',
  [VideoStatus.Pause]: 'yellow',
  [VideoStatus.Uploading]: 'blue',
  [VideoStatus.Waiting]: 'yellow',
}

export const getVideoColumns = (groupId: number): TableProps<IVideo>['columns'] => [
  {
    title: mainDictionary.videoName,
    fixed: 'left',
    width: 95,
    render: (item: IVideo) => <VideoName video={item} />,
  },
  {
    title: mainDictionary.lessonName,
    fixed: 'left',
    width: 95,
    render: (item: IVideo) => (
      <Tooltip placement="top" title={item?.lesson?.name}>
        <span className={cn('lesson-name')}>{item.lesson.name}</span>
      </Tooltip>
    ),
  },
  {
    title: mainDictionary.status,
    width: 32,
    align: 'center',
    render: (item: IVideo) => <VideoStat video={item} />,
  },
  {
    title: mainDictionary.lessonDate,
    align: 'center',
    width: 40,
    render: (item: IVideo) => <span>{formatDate(item.lesson.createdAt)}</span>,
  },
  {
    title: mainDictionary.size,
    align: 'center',
    width: 30,
    render: (item: IVideo) => <span>{formatBytes(item.size)}</span>,
  },
  {
    title: mainDictionary.addedDate,
    align: 'center',
    width: 40,
    render: (item: IVideo) => <span>{formatDate(item.createdAt)}</span>,
  },
  {
    title: mainDictionary.actions,
    fixed: 'right',
    width: 38,
    render: (item: IVideo) =>
      item.status === VideoStatus.Error ||
      item.status === VideoStatus.Pause ||
      item.status === VideoStatus.Uploading ? (
        <VideoUploadingActions video={item} groupId={groupId} />
      ) : (
        <VideoActions video={item} />
      ),
  },
]

export const videoStatusColumns: TableProps<VideoStatusHistory>['columns'] = [
  {
    title: mainDictionary.status,
    key: 'status',
    render: (value, record) => <Tag color={tagColor[record.status]}>{videoStatusText[record.status]}</Tag>,
  },
  {
    title: mainDictionary.message,
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: mainDictionary.date,
    key: 'message',
    render: (value, record) => <span className={cn('nowrap')}>{getFormattedFullDateFromParams(record.createdAt)}</span>,
  },
]

export const homeworkStatusTagColors: Record<number, TagProps['color']> = {
  [HomeworkChatsStatus.ACCEPTED]: 'green',
  [HomeworkChatsStatus.REJECTED]: 'red',
  [HomeworkChatsStatus.WAITING]: 'yellow',
  [HomeworkChatsStatus.NotDone]: 'yellow',
}

export const homeworkStatusTagTexts: Record<string, TagProps['color']> = {
  [HomeworkChatsStatus.ACCEPTED]: mainDictionary.approved,
  [HomeworkChatsStatus.REJECTED]: mainDictionary.rejects,
  [HomeworkChatsStatus.WAITING]: mainDictionary.wait,
  [HomeworkChatsStatus.NotDone]: mainDictionary.wait,
}

export const DidNotDoHomework = '4'

const newDate = new Date()

export const currentDate = formatDate(newDate, true)

export const currentFormattedDate = formatDate(newDate, true)

export const lessonTimeFormatter = (time: string) => time?.split(':').slice(0, 2).join(':')

export const groupsBreadCrummb: IBreadcrumb[] = [{ label: mainDictionary.groups }]

export const groupsSingleBreadCrummb: IBreadcrumb[] = [{ label: mainDictionary.groups, link: ROUTES.groups }]

export const MAX_SCORE = 100
export const UNIQUE_KEY = 'UNIQUE_KEY'
export const ERROR_NOTIFICATION_UNIQUE_KEY = 'ERROR_NOTIFICATION_UNIQUE_KEY'
export const dayWeeks = ['Ya', 'Du', 'Se', 'Ch', 'Pay', 'Ju', 'Sha']

export const formattedTypes = {
  ascend: Orders.Ascend,
  descend: Orders.Descend,
}

export const formattedTitles = {
  Level: SortStatistic.LEVEL,
  XP: SortStatistic.XP,
  Kumush: SortStatistic.COIN,
}

export const INVALID = 'invalid'

export const allOption = {
  label: mainDictionary.allOfThem,
  value: null,
}

export const homeworkStudentStatisticsFilterOption = [
  {
    value: null,
    label: mainDictionary.allOfThem,
  },
  {
    value: HomeworkChatsStatus.ACCEPTED,
    label: mainDictionary.acceptedHomework,
  },
  {
    value: HomeworkChatsStatus.REJECTED,
    label: mainDictionary.rejectedHomework,
  },
  {
    value: HomeworkChatsStatus.WAITING,
    label: mainDictionary.waiters,
  },
  {
    value: HomeworkChatsStatus.NotDone,
    label: mainDictionary.notDone,
  },
  {
    value: HomeworkChatsStatus.NotGiven,
    label: mainDictionary.byNotGiven,
  },
]

const pointerStyle = {
  cursor: 'pointer',
}

export const conditionalRowStylesHomeworkStudent = [
  {
    when: (row: StudentLessonStatistic) => row.homeworkStatus === HomeworkFilterStatus.Rejected,
    style: {
      backgroundColor: '#FEFF33',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: StudentLessonStatistic) => row.homeworkStatus === HomeworkFilterStatus.Waiting,
    style: {
      backgroundColor: '#465fdc',
      color: '#fff',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: StudentLessonStatistic) => row.homeworkStatus === HomeworkFilterStatus.Accepted,
    style: {
      backgroundColor: '#00FF00',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: StudentLessonStatistic) => row.homeworkStatus === HomeworkFilterStatus.Uncompleted,
    style: {
      backgroundColor: '#FF0000',
      color: '#fff',
      '&:hover': pointerStyle,
    },
  },
  {
    when: (row: StudentLessonStatistic) => row.homeworkStatus === HomeworkFilterStatus.NotGiven,
    style: {
      backgroundColor: '#fff',
      color: '#000',
      '&:hover': pointerStyle,
    },
  },
]

export const homeworkStatisticsFilterOption: IOption[] = [
  {
    value: HomeworkStatistics.AverageScore,
    label: <span className={`${styles.default} ${styles['normalize-option']} `}>{mainDictionary.byScore}</span>,
    className: 'option-st',
  },
  {
    value: HomeworkStatistics.RejectedHomeworkCount,
    className: 'option-st',
    label: <span className={'rejected-st normalize-option-st'}>{mainDictionary.byRejected}</span>,
  },
  {
    value: HomeworkStatistics.UncompletedHomeworkCount,
    className: 'option-st',
    label: <span className={'normalize-option-st uncompleted-st'}>{mainDictionary.byNotDone}</span>,
  },
  {
    label: <span className={'accepted-st normalize-option-st'}>{mainDictionary.byAccepted}</span>,
    value: HomeworkStatistics.AcceptedHomeworkCount,
    className: 'option-st',
  },
  {
    value: HomeworkStatistics.WaitingHomework,
    className: 'option-st',
    label: <span className={'normalize-option-st waiter-st'}>{mainDictionary.byWaiting}</span>,
  },
]

export const studentHomeworkStatisticsFilterOption: IOption[] = [
  {
    value: HomeworkChatsStatus.ACCEPTED,
    className: 'option-st',
    label: <span className={`${styles.accepted} ${styles['normalize-option']}`}>{mainDictionary.byAccepted}</span>,
  },
  {
    value: HomeworkChatsStatus.REJECTED,
    className: 'option-st',
    label: <span className={`rejected 'normalize-option`}>{mainDictionary.byRejected}</span>,
  },
  {
    value: HomeworkChatsStatus.WAITING,
    className: 'option-st',
    label: <span className={cn('normalize-option waiter')}>{mainDictionary.waitingForChecking}</span>,
  },
  {
    value: HomeworkChatsStatus.NotDone,
    className: 'option-st',
    label: <span className={cn('normalize-option uncompleted')}>{mainDictionary.byNotDone}</span>,
  },
  {
    value: HomeworkChatsStatus.NotGiven,
    label: mainDictionary.byNotGivenHw,
  },
]

export const studentSortOptions = [allOption, ...studentHomeworkStatisticsFilterOption]

export const calendarSettings = {
  lang: {
    locale: 'ru',
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'OK',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Select time',
    dateSelect: 'Select date',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century',
    shortWeekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  timePickerLocale: {
    placeholder: 'Select time',
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM',
}

export const isAcademic = 'my-groups'

export const PER_ATTENDANCE_GET_SECOND = 30000

export const VALID_EXAM_SCORE = /^([1-9]|[1-9][0-9]|100)$/
export const studentColumn = {
  name: mainDictionary.student,
}

type TStatusMap = { [key in JournalAttendedStatus]: string }

export const journalAttendanceTableColors: TStatusMap = {
  [JournalAttendedStatus.Absent]: 'absent',
  [JournalAttendedStatus.Ended]: 'ended',
  [JournalAttendedStatus.NoClass]: 'no-class',
  [JournalAttendedStatus.Late]: '',
  [JournalAttendedStatus.Attended]: '',
}

export const journalAttendanceTableContent = {
  [JournalAttendedStatus.Absent]: '-',
  [JournalAttendedStatus.Ended]: '*',
  [JournalAttendedStatus.Late]: '+',
  [JournalAttendedStatus.NoClass]: '!',
  [JournalAttendedStatus.Attended]: '+',
}

export const homeworkIconClasses = {
  [HomeworkFilterStatus.Accepted]: 'accepted-icon',
  [HomeworkFilterStatus.Rejected]: 'rejected-icon',
  [HomeworkFilterStatus.Uncompleted]: 'notDone-icon',
  [HomeworkFilterStatus.Waiting]: 'waiting-icon',
  [HomeworkFilterStatus.NotGiven]: 'not-given-icon',
  [HomeworkFilterStatus.Checking]: 'waiting-icon',
}

export const curriculumDate = (date: string) => dayjs(date).format('DD.MM')
export const disabledDate = (current: dayjs.Dayjs | null) => {
  if (!current) {
    return false
  }

  return current.isAfter(dayjs().endOf('month')) || current.isBefore(dayjs().subtract(4, 'days').startOf('day'))
}

export const formattedTime = (time: string): string => time.slice(0, 2)

export const formattedNumber = (index: number) => index.toString().padStart(2, '0')

export const checkingExamScore = /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?|0(?:\.[0-9]{1,2})?)$/

export const fillHour = (time: number) => {
  if (!time) {
    return
  }

  return time.toString().padStart(2, '0')
}

export const examStatuses = {
  [ExamStatus.InProgress]: mainDictionary.inProgress,
  [ExamStatus.Completed]: mainDictionary.completed,
}

export const reasonColors = {
  [NotComingReasons.OnRoad]: 'gold',
  [NotComingReasons.Reasonable]: 'success',
  [NotComingReasons.WithoutReason]: 'red',
}

export const reasonComments = {
  [NotComingReasons.OnRoad]: mainDictionary.onRoadComment,
  [NotComingReasons.Reasonable]: mainDictionary.reasonableComment,
  [NotComingReasons.WithoutReason]: mainDictionary.withoutReasonComment,
}

export const imageExtensions = ['jpeg', 'jpg', 'png', 'webp']
