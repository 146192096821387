import { BranchOffice, HhOffice } from '@/pages/Controls/BranchOffice/types'
import { GenderTypes } from '@/pages/Controls/types'
import { StaffTypes } from '@/pages/Profile/types'
import { GamificationMethod } from '@/stores/gamification'
import { IRole } from '../groups/types'
import { StaffWorkStatus } from '../users/types'
import { StudentSystemStatus } from '../controls'
import { Group } from '../study-department'

export interface IStaff {
  id: number
  photo: string
  firstName: string
  lastName: string
  rating: number
  phone: string
  genderType: GenderTypes
  workStatus: StaffWorkStatus
  isActive: boolean
  createdAt: Date
  hCreatedAt?: string
  login: string
  staffType: StaffTypes
  role: IRole
  userId: number
  daysOfWeek?: string[]
  beginTime?: string
  endTime?: string
}

interface IMethodCourse {
  id: number
  name: string
}

export interface IAction {
  [key: string]: any
  id: number
  name: string
  createdAt: Date
  levelsCount: number
  groupsCount: number
  method: number
  courses?: IMethodCourse[]
}

export interface ISelectId {
  id: number
}

export interface IGetActionResponse {
  total: number
  actions: IAction[]
}

interface IRange {
  range: number
  coin: number
  xp: number
  condition?: string
}

export interface IActionLevel {
  id: number
  level: number
  createdAt: Date
  awards: IRange[]
}

export interface IActionLevelResponse {
  actionLevels: IActionLevel[]
  total: number
}

export interface IMethod {
  [key: string]: any
  id: number
  name: string
  displayName: string
  createdAt: string
  actions?: IAction[]
}

export interface IGetMethodResponse {
  methods: IMethod[]
}
export interface ICategory {
  [key: string]: any
  id: number | string
  hName: string
  levelsCount: number
  createdAt: string
  name?: string
  displayName: string
}

export interface ILevel {
  id: number
  requiredXp: number
  _number: number
  categoryId: number
  createdAt: string
  previousXp?: number
  nextXp?: number
  xpProportion?: number
}

export interface IGetCategoryResponse {
  categories: ICategory[]
  total: number
}

export interface IGetLevelsByCategoryIdResponse {
  levels: ILevel[]
  total: number
}

export enum GroupStatus {
  NotStarted = 1,
  ACTIVE = 2,
  ENDED = 3,
}

export interface IGroup {
  id: number
  name: string
  hName?: string
  studentsCount?: number
  startedDate: Date
  endedDate: Date
  status: GroupStatus
  beginTime?: string
  endTime?: string
  category?: ICategory
  courseName?: string
  actionsCount?: number
  isStudying?: boolean
  controlActionName?: string
}

export interface IGetGroupActionsByGroupResponse {
  total: number
  groups: IGroup[]
}

export interface Assistant extends IStaff {
  canAttendance?: boolean
}

export interface IStaffGroupDetail {
  assistants: Assistant[]
  teacher: IStaff
}

export interface IGroupActionCategories {
  name: string
  displayName?: string
  id: number
}

export interface IGroupActionCategoriesResponse {
  categories: IGroupActionCategories[]
  total: number
}

export interface IGroupAction {
  id: number
  groupId: number
  action: IAction
  group: IGroup
  createdAt: Date
}

export interface IGroupInfo {
  id: number
  hName: string
  actionAttached: boolean
  groupAttached: boolean
  createdAt: Date
}

export interface IGetStaffGroupActionResponse {
  groupActions: IGroupAction[]
  total: number
}

export interface ICourse {
  name: string
  groups: IGroupInfo[]
}

export interface GetGroupsByActionRes {
  name: string
  courses: ICourse[]
}

export interface Course {
  id: number
  name: string
  createdAt: Date
}

export interface StudentStatus {
  id: number
  hName: string
  createdAt: Date
}

export interface IStatistics {
  [key: string]: any
  id: number
  hId: number
  photo: string
  firstName: string
  lastName: string
  status: string
  level: number
  coin: number
  xp: number
  groupsCount: number
  courses: Course[]
  position: number
  middleName: string
  isStudying: boolean
}

export interface IStatisticsResponse {
  statistics: IStatistics[]
  total: number
}

export interface IGetCoursesResponse {
  courses: Course[]
  total: number
}

export interface IStatus {
  id: number
  name: string
  createdAt: string
}

export interface IGetStatusesResponse {
  statuses: IStatus[]
  groups: IGroup[]
  offices: BranchOffice[]
}

export interface IStudentAward {
  id: number
  studentId?: number
  coin: number
  xp: number
  comment: string
  createdAt: Date
  additionalInfo?: any
  groupName: string
  groupId?: number
  lateness: 0
  lessonDate: string
}

export interface IGetStudentAwardResponse {
  studentAwards: IStudentAward[]
  total: number
}

export interface IGetStatisticsMethodsResponse {
  id: number
  name: string
  totalXp: number
  totalCoin: number
  method: GamificationMethod
}

export interface IGetStudentStatistics {
  totalCoin: number
  totalXp: number
  studentId: number
  coin: number
  xp: number
  level: number
  firstName: string
  lastName: string
  nextLevelXp?: number
}

export interface IStudentGroup {
  total: number
  groups: IGroup[]
}

export interface IGetSingleActionResponse {
  id: number
  name: string
  method: IMethod
  createdAt: string
}

interface StudentCategoriesOnly {
  id: number
  hName: string
  displayName: string
  createdAt: Date
}

export interface IGetStudentCategoriesOnly {
  categories: StudentCategoriesOnly[]
  total: number
}

export interface IGetActionsByMethodRes {
  method: GamificationMethod
  actions: Action[]
}
export interface Action {
  id: number
  name: string
  method?: GamificationMethod
  createdAt: string
}

export type TCourse = {
  id: number
  name: string
  createdAt: string
  isValid: boolean
}

export type LevelInfo = {
  id: number
  requiredXp: number
  xpProportion: number
}

export enum AttendedStatus {
  Attended = 1,
  DidNotParticipate = 2,
  Come = 3,
  Late = 4,
}

export type GroupAttendance = {
  studentId: number
  studentName: string
  status: AttendedStatus
  lateness: number
  studentGroupId: number
}

export type GroupAttendanceRes = {
  studentAttendances: GroupAttendance[]
  id: number
  groupId: number
  groupName: string
  attendedDate: string
  beginTime: string
  endTime: string
  staffName: string
  scheduleItemId: string
}

export type StudentAwardGroups = {
  id: number
  hGroupId: number
  hName: string
  office: HhOffice
  startedDate: string
  endedDate: string
  hasStudent: boolean
  createdAt: string
  isStudying: boolean
}

export type TheBestOnes = {
  hId: number
  firstName: string
  lastName: string
  nomination: string
  studentId: number
  officeName: string
  categoryName: string
  photo: string
  createdAt: string
  categoryId: number
  officeId: number
  id: number
}

export type GetTheBestOnesRes = {
  bestStudents: TheBestOnes[]
  total: number
}

export interface Category {
  createdAt: string
  hId: number
  hName: string
  id: number
  name?: string
  displayName?: string
}

export type GroupMembers = {
  categories: Category[]
  groups: Group[]
  offices: BranchOffice[]
  id: number
  hId: number
  photo: string
  firstName: string
  lastName: string
  xp: number
  status: StudentSystemStatus
  coin: number
  systemStatus: StudentSystemStatus
  isStudying: boolean
}

export type GetAllGroupMembersRes = {
  total: number
  bestStudents: GroupMembers[]
}
