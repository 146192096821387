import { makeAutoObservable } from 'mobx'
import { addCatchNotification } from '@/modules/notifications'
import { branchOfficeApi } from '@/pages/Controls/BranchOffice/shared/branch-office.api'
import { BranchOffice, HhOffice } from '@/pages/Controls/BranchOffice/types'

class BranchOfficeStore {
  branchOffices: BranchOffice[] | null = null
  hhOffices: HhOffice[] | null = null
  inActionBranchOffice: BranchOffice | null = null
  isOpenAddModal: boolean = false
  isOpenDeleteModal: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  getBranchOffices = () =>
    branchOfficeApi.getOffices().then((response) => {
      if (response.success) {
        this.setBranchOffices(response.data.offices)
      }
    })

  getOfficesHh = () =>
    branchOfficeApi
      .getOfficesHh()
      .then((response) => {
        if (response.success) {
          this.setHhOffices(response.data.hOffices)
        }
      })
      .catch(addCatchNotification)

  setBranchOffices = (branchOffices: BranchOffice[]) => {
    this.branchOffices = branchOffices
  }

  setHhOffices = (hhOffices: HhOffice[]) => {
    this.hhOffices = hhOffices
  }

  setOpenAddModalStatus = (status: boolean) => {
    this.isOpenAddModal = status
  }

  setOpenDeleteModalStatus = (status: boolean) => {
    this.isOpenDeleteModal = status
  }

  setInActionBranchOffice = (inActionBranchOffice: BranchOffice | null) => {
    this.inActionBranchOffice = inActionBranchOffice
  }

  reset = () => {
    this.branchOffices = null
    this.hhOffices = null
    this.inActionBranchOffice = null
    this.isOpenAddModal = false
    this.isOpenDeleteModal = false
  }
}

export const branchOfficeStore = new BranchOfficeStore()
