import { makeAutoObservable } from 'mobx'
import { api } from './api'
import { Permission, StructuredPermissions } from './types'
import { getStructuredPermissions, normalizedPermissions } from './utils'

export class Store {
  permissions: Permission[] | null = null
  structuredPermissions: StructuredPermissions | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getPermissions = () =>
    api.getMinePermissions().then((response) => {
      this.setPermissions(response.data)
      this.setStructuredPermissions(response.data)

      return response
    })

  setPermissions = (permissions: Permission[]) => {
    this.permissions = normalizedPermissions(permissions)
  }

  setStructuredPermissions = (permissions: Permission[]) => {
    this.structuredPermissions = getStructuredPermissions(permissions)
  }

  reset = () => {
    this.permissions = null
  }
}

export const store = new Store()
