import { Course, ICategory, StudentStatus } from '@/api/gamification/types'
import { VideoStatus } from '@/modules/kinescope'
import { BranchOffice, HhOffice } from '@/pages/Controls/BranchOffice/types'
import { GenderTypes, RoleTypes } from '@/pages/Controls/types'
import { ExamChatsStatus, HomeworkChatsStatus, HomeworkTypes } from '@/pages/Groups/types'
import { StaffInfo, UserTypes } from '@/pages/Profile/types'
import { StudentStatus as StudentStatuses } from '../settings/types'
import { Category, IStudents } from '../students/types'
import { ICourse } from '../study-department/types'

export interface IRole {
  id: number
  name: string
  author: StaffInfo
  type: RoleTypes
  createdAt: Date
}

export interface IStaff {
  id: number
  photo: string
  firstName: string
  lastName: string
  rating: number
  teacherWorkStatus: string
  isActive: boolean
  createdAt: string
  login: string
  accountStatus: IStaffAccountStatus
  staffType: IStaffTypes
  role?: IRole
  beginTime?: string
  endTime?: string
  daysOfWeek?: string[]
  hasPermission: boolean
  phone?: string
  offices: HhOffice[]
}
export enum IStaffAccountStatus {
  Active = 'Faol',
  InActive = 'Nofaol',
  HasNotAccount = "Profili yo'q",
}
export enum IStaffTypes {
  ADMIN = 1,
  TEACHER = 2,
  ASSISTANT = 3,
}
export enum IGroupStatus {
  NotStarted = 1,
  Active = 2,
  Ended = 3,
}

export interface IStaffGroupDetail {
  assistants: IStaff[]
  teacher: IStaff
}
export interface StaffOfGroup {
  id: number
  firstName: string
  lastName: string
  role: Role
  hasPermission?: boolean
}

export interface StaffGroupDetail {
  title: string
  staff: IStaff[]
}

export interface StaffGroupsDetail {
  activeScheduleItems: ScheduleItem[]
  othersScheduleItems: ScheduleItem[]
}

export interface Role {
  id: number
  name: string
  author: StaffInfo
  type: RoleTypes
  createdAt: Date
}

export interface ScheduleItem {
  id: number
  staff: StaffOfGroup
  daysOfWeek: string[]
  role: RoleTypes
  beginTime: string
  endTime: string
  classroomName: string
  startDate: string
  endDate: string
}

export interface IGroup {
  [key: string]: any
  id: number
  name: string
  staff?: IStaff[]
  studentsCount?: number
  startedDate: Date
  endedDate: Date
  status: IGroupStatus
  lessons?: ILesson
  beginTime?: string
  endTime?: string
  staffDetail?: StaffGroupDetail[]
  category?: ICategory
  activeScheduleItems: ScheduleItem[]
  course?: ICourse
  learningType?: string
  attendanceKpi?: number
  examKpi?: number
  homeworkKpi?: number
  office?: HhOffice
  othersScheduleItems: ScheduleItem[]
}

export interface IGroupsResponse {
  total: number
  groups: IGroup[]
  staffGroups: IGroup[]
}

export type Curriculum = {
  id: number
}

export interface ILesson {
  id: number
  name: string
  examId?: number
  createdAt: string
  curriculum?: Curriculum
  hasHomeworkDb?: boolean
  isMyLesson: boolean
}

export interface IVideo {
  [key: string]: any
  id: number
  lesson: ILesson
  size: number
  status: VideoStatus
  origName: string
  statusHistories: VideoStatusHistory[]
  url: string
  filename: string
  duration: number
  updatedAt: string
  createdAt: string
  hasLesson: boolean
  percentage?: number
  isUploading?: boolean
  currentStatus?: VideoStatus
  onStart?: () => void
  onAbort?: () => Promise<any>
}

export interface VideoStatusHistory {
  createdAt: string
  id: number
  message: string
  status: VideoStatus
}

export interface IVideosResponse {
  total: number
  videos: IVideo[]
}

export interface ILessonsResponse {
  total: number
  lessons: ILesson[]
}

export interface HomeworkAttachment {
  url: string
  origName: string
  size: number
  duration?: number
}

export interface Lesson {
  id: number
  name: string
  createdAt: Date
  curriculum: ICurriculum
}
export interface Homework {
  id: number
  [key: string]: any
  lesson: Lesson
  deadline: Date | string
  homeworkInfo: HomeworkInfo
  studentsCount: number
  sendedStudentsHomeworkCount?: number
  checkedStudentsHomeworkCount?: number
  createdAt: Date
  deadlineType?: HomeworkTypes
  acceptedHomeworkCount?: number
  waitingHomeworkCount?: number
  createdByType: HomeworkCreatedByTypes
}
export interface HomeworkInfo {
  desc: string
  attachments: HomeworkAttachment[]
  homeworkDbs?: HomeworkInfo[]
  id: number
}

export interface IGetHomeworkResponse {
  total: number
  homework: Homework[]
}

export interface IHomeworkChat {
  id: number
  sender: IHomeworkChatUser
  receiver: IHomeworkChatUser
  isEditable: boolean
  message: string
  status: HomeworkChatsStatus
  attachments: HomeworkAttachment[]
  createdAt: string
  lesson: ILesson
  updatedAt: string
  score?: number
  deadline?: string
  xp?: number
  coin?: number
  homeworkDbId?: number
  isExam?: boolean
  submissions?: IHomeworkChat[]
}
export interface IHomeworkChatUser {
  id: number
  firstName: string
  lastName: string
  studentId: number
  role?: IRole
  userType: UserTypes
  score?: number
  middleName: string
  staffId?: number
  roleName?: string
}
export interface IGetAllHomeworkChatsRes {
  homeworkChats: IHomeworkChat[]
  homeworkInfo: HomeworkInfo
  lesson: ILesson
}

export interface IHomeworkResponse {
  total: number
  homeworkChatsData: IGetAllHomeworkChatsRes
}

export interface IGetIncompleteStudentsResponse {
  students: IStudents[]
}

export enum AttendedStatus {
  Attended = 1,
  DidNotParticipate = 2,
  Come = 3,
  Late = 4,
}

export enum NotComingReasons {
  OnRoad = 'Kelayotgan',
  WithoutReason = 'Sababsiz',
  Reasonable = 'Sababli',
}

export interface StudentAttendant {
  id?: number
  student?: IStudents
  studentGroupId: number
  arrivedTime?: string
  status: AttendedStatus
  createdAt?: Date
  isStudying?: boolean
  silver?: number
  xp?: number
  time?: string
  isUpdated?: boolean
  comment?: NotComingReasons
  hasMissedHomework?: boolean
}
export interface IGetGroupStudentAttendancesResponse {
  studentAttendances: StudentAttendant[]
  lessonDaysOfMonth: string[]
  isDone: boolean
  scheduleTimeOver?: boolean
  lesson: Lesson
  isFirstVisiting: boolean
  isAttended: boolean
  isStaffAllowedForAttendance: boolean
  lessonWeekDays: string[]
  isAvailableForAttendance: boolean
}

export interface IGroupAttendanceResponse {
  groupStudentData: IGetGroupStudentAttendancesResponse
  total: number
}

export interface IStudentAttendanceResponse {
  success: boolean
}
export interface IStudentExam {
  id: number
  student: IStudents
  score: number
  status: IExamStatus
}
export interface IExam {
  id: number
  name: string
  createdAt: Date
  deadline: string
  examDate: string
  failedStudentsCount?: number
  announcedAt?: string
  announceable: boolean
  lessonDate?: string
  lessonId?: number
  lessonBeginTime: string
  status: ExamStatus
  desc?: string
  attachments?: HomeworkAttachment[]
  studyMonthId?: string
  studentsCount?: number
}
export interface IGetAllExamsRes {
  [key: string]: any
  exam: IExam
  passedStudents: IStudentExam[]
  failedStudents: IStudentExam[]
  absentStudents: IStudentExam[]
  studentsCount: number
  participantsCount: number
}
export enum IExamStatus {
  Failed = 1,
  Passed,
  Absent,
  FailedExam,
}

export interface IGetGroupExamsResponse {
  exams: IGetAllExamsRes[]
  total: number
}

export interface IGetGroupStudentsResponse {
  total: number
  students: IStudents[]
}

export interface StudentHomeworkStatistic {
  id: number
  firstName: string
  lastName: string
  averageScore: number
  acceptedHomeworkCount: number
  rejectedHomeworkCount: number
  uncompletedHomeworkCount: number
  totalHomeworkCount: number
  waitingHomeworkCount?: number
  isStudying?: boolean
  middleName?: string
}

export interface Group {
  id: number
  hGroupId: number
  hName: string
  hBranchId: number
  startedDate: Date
  endedDate: Date
  createdAt: Date
  category: Category
}

export interface IStudyMonths {
  month: number
  startDate: string
  endDate: string
}

export interface IGetHomeworkStatisticsResponse {
  students: StudentHomeworkStatistic[]
  total: number
  group: Group
  studyMonths: IStudyMonths[]
}

export enum StudentSystemStatus {
  ACTIVE = 1,
  BLOCKED,
}
export interface Student {
  id: number
  hId: number
  photo: string
  firstName: string
  lastName: string
  login: string
  phoneNumber: string
  genderType: GenderTypes
  status: StudentStatus
  createdAt: Date
  level?: number
  silver?: number
  xp?: number
  categories?: ICategory[]
  groups?: Group[]
  userId: number
  systemStatus: StudentSystemStatus
  middleName?: string
}

export interface StudentLessonStatistic {
  homeworkScore: number
  homeworkStatus: HomeworkFilterStatus
  lessonName: string
  lessonCreatedAt: Date
  homeworkId: number
  lessonId: number
  homeworkDeletedAt?: boolean
}

export interface GroupStudyMonth {
  month: number
  startDate: string
  endDate: string
}

export interface IGetHomeworkStatisticsByStudentRes {
  student: Student
  lessons: StudentLessonStatistic[]
  studyMonths: GroupStudyMonth[]
  total: number
}

export interface ICurriculum {
  id: number
  name: string
  position: number
  createdAt: string
  isUsed: boolean
}

export interface IGetGroupCurriculumsResponse {
  total: number
  curriculum: ICurriculum[]
}

export interface StudentOfGroup {
  id: number
  controlAction: StudentStatuses
  firstName: string
  lastName: string
  startedDate: Date
  endedDate: Date
  middleName: string
  hId: number
  offices: HhOffice[]
  status: string
  systemStatus: StudentSystemStatus
  createdAt: string
}

export interface IGetGroupInfoRes {
  id: number
  office: BranchOffice
  course: Course
  name: string
  category: Category
  studentsCount: number
  staff: StaffGroupDetail[]
  activeStudents: IStudents[]
  endedStudents: IStudents[]
  inactiveStudents: IStudents[]
  notStartedStudents: IStudents[]
}

export interface IHomeworkInfoCountRes {
  waiting: number
  rejected: number
  accepted: number
  uncompleted: number
}

export interface GetJournalListRes {
  days: JournalDay[]
  students: JournalStudent[]
  firstName?: string
  lastName?: string
  additionalStats: IAdditionalStatistics
  homework?: number
}

export interface IAdditionalStatistics {
  stats: AdditionalStats[]
  homeworkPercentage: number
  attendancePercentage: number
}

export interface JournalDay {
  day?: number
  month?: number
  date?: string
  beginTime?: string
  endTime?: string
  homeworkId?: number
  title?: string
  lessonId?: number
}
export interface JournalStudent {
  id?: number
  firstName: string
  lastName: string
  userId?: number
  middleName?: string
  stats?: JournalStats[]
  attendance?: GeneralStats
  homework?: GeneralStats
  homeworkAverage?: number
  attendanceAverage?: number
}
export interface GeneralStats {
  total: number
  value: number
  percentage: number
}

export enum JournalAttendedStatus {
  Attended = 1,
  Absent,
  NoClass,
  Late,
  Ended,
}

export enum HomeworkFilterStatus {
  Waiting = 1,
  Rejected,
  Accepted,
  Checking,
  Uncompleted,
  NotGiven,
}
export interface JournalStats {
  homeworkStatus: HomeworkFilterStatus
  attendedStatus: JournalAttendedStatus
  date: string
  attendance: GeneralStats
  homework: GeneralStats
  attendedCount?: number
  homeworkId?: number
  lessonId?: number
  acceptedCount?: number
  homeworkChatId?: number
  lateness?: number
}
export interface AdditionalStats {
  attendedStudentCount: number
  acceptedHomeworkCount: number
  attendance: GeneralStats
  homework: GeneralStats
}

export interface IGetStudyMonthRes {
  studyMonths: IStudyMonths[]
}
export interface GetStudentsByGroupRes {
  id: number
  hId: number
  photo: string
  firstName: string
  lastName: string
  middleName: string
  status: string
  systemStatus: StudentSystemStatus
  offices: HhOffice[]
  hasGroup: boolean
  createdAt: string
}

export interface IGetStudentsRes {
  students: GetStudentsByGroupRes[]
}

export interface IPrice {
  id: number
  name: string
  value: string
  units: string
}

export type TReasonOption = {
  id: string
  name: string
  color: string
  orderIndex: number
  label?: string
}

export type TNegativeOption = Omit<TReasonOption, 'color' | 'orderIndex'>

export interface IGetFormOptionsRes {
  reasonOptions: TReasonOption[]
  negativeFactorOptions: TNegativeOption[]
}

export interface IFinishGroupRes {
  data: boolean
  warning: string[]
}

export interface IStudyMonth {
  id: string
  name: string
}

export interface StudentExam {
  id: number
  firstName: string
  lastName: string
  middleName: string
  score: number
  failingReason: string
  status: IExamStatus
}
export interface Exam {
  id: number
  name: string
  examDate: string
  createdAt: Date
}
export interface ExamStudents {
  exam: Exam
  students: StudentExam[]
}

export interface IGetHomeworkFileUrlRes {
  filename: string
}

export type IExamFailureReason = {
  id: string
  name: string
  orderIndex: number
}

export const enum ExamStatus {
  InProgress = 1,
  Completed,
}

export type ExamResult = {
  announcedAt?: string
  studentId: number
  firstName: string
  lastName: string
  middleName: string
  status?: HomeworkChatsStatus
  score?: number
  xp?: number
  coin?: number
  deadline?: string
  chatCreatedAt?: string
  chatUpdatedAt?: string
  sender: IHomeworkChatUser
}

export interface ExamChatUser {
  firstName: string
  lastName: string
  role: IRole
  middleName?: string
  userType: UserTypes
  roleName?: string
}

export type ExamChatRes = {
  id: number
  isExam?: boolean
  sender: ExamChatUser
  message: string
  status: ExamChatsStatus
  attachments: HomeworkAttachment[]
  score?: number
  isEditable: boolean
  xp?: number
  updatedAt?: string
  coin?: number
  createdAt: string
  receiver?: IHomeworkChatUser
}

export type ExamParticipate = {
  id: number
  announceable: boolean
  firstName: string
  lastName: string
  studentId?: number
  middleName: string
  score: number
  failingReasonId?: string
  isNotDisabled?: boolean
  status?: IExamStatus
}

export const enum HomeworkCreatedByTypes {
  System = 1,
  Teacher,
}
export const enum HomeworkAssignmentTypes {
  Random = 1,
  Same,
}
export const enum HomeworkDbLessonStatuses {
  Completed = 1,
  NotCompleted,
}

export type TeacherAttendance = {
  id: number
  photo: string
  lessonName: string
  beginTime: string
  endTime: string
  staffId: number
  staffName: string
  createdAt: string
}

export type TeacherAttendanceRes = {
  attendances: TeacherAttendance[]
  total: number
}
