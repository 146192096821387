import { IBreadcrumb } from '@/stores/breadcrumb'

type ConfigMoreBreadcrumbItemsReturnType = {
  first?: IBreadcrumb
  last?: IBreadcrumb
  menuItems?: IBreadcrumb[]
  lastBefore?: IBreadcrumb
}

export const configMoreBreadcrumbItems = (breadcrumbItems: IBreadcrumb[]): ConfigMoreBreadcrumbItemsReturnType => {
  if (breadcrumbItems?.length < 3) {
    return {}
  }

  const [firstItem, ...more] = breadcrumbItems

  return {
    first: firstItem,
    last: more[more.length - 1],
    lastBefore: more[more.length - 2],
    menuItems: more.slice(0, -2),
  }
}
