import { AxiosError, isAxiosError } from 'axios'
import classNames from 'classnames/bind'
import { TError } from '@/api'

import styles from './ErrorMessage.module.scss'

const cn = classNames.bind(styles)

type Props = {
  error: Error | AxiosError<{ error: TError }>
}

export const ErrorMessage = ({ error }: Props) => {
  if (isAxiosError(error) && error?.response) {
    return (
      <div className={cn('message')}>
        <span className={cn('message__title')}>{error?.response?.data?.error?.errMsg || error?.message}</span>
        <span>
          <b>code:</b> <i>{error?.code}</i>
        </span>
        <span>
          <b>status:</b> <i>{error?.response?.status}</i>
        </span>
        {error?.response?.data?.error?.errId && (
          <span>
            <b>errId:</b> <i>{error?.response?.data?.error?.errId}</i>
          </span>
        )}
      </div>
    )
  }

  return (
    <div className={cn('message')}>
      <span>
        <b>name:</b> <i>{error?.name}</i>
      </span>
      <span>
        <b>message:</b> <i>{error?.message}</i>
      </span>
    </div>
  )
}
