import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Modal, Row, Typography } from 'antd'
import classNames from 'classnames/bind'
import { IStudents } from '@/api/students'
import mainDictionary from '@/dictionary'
import { studentStore } from '@/stores/students/student'
import { dateFormatter, getFullName } from '@/utils'

import styles from '../student-sms.module.scss'

const cn = classNames.bind(styles)

export const StudentSmsHistoryModal = observer(() => {
  const [loading, setLoading] = useState(false)
  const { studentSmsHistoryModal, setStudentSmsHistoryModal, singleStudent, getStudentSmsHistory, studentSmsHistory } =
    studentStore

  const handleModalClose = () => {
    setStudentSmsHistoryModal(false)
  }

  useEffect(() => {
    if (!singleStudent) {
      setStudentSmsHistoryModal(false)

      return
    }

    setLoading(true)
    getStudentSmsHistory(singleStudent?.id).finally(() => {
      setLoading(false)
    })
  }, [singleStudent])

  return (
    <Modal
      open={studentSmsHistoryModal}
      onCancel={handleModalClose}
      confirmLoading={loading}
      onOk={handleModalClose}
      title={mainDictionary.sentSms}
    >
      {singleStudent && (
        <Typography.Text className={cn('sms-modal__title')} strong>
          {mainDictionary.student}: {getFullName<IStudents>(singleStudent)}
        </Typography.Text>
      )}
      <Row gutter={24}>
        {studentSmsHistory && studentSmsHistory?.history?.length > 0 ? (
          studentSmsHistory?.history?.map((item) => (
            <Col className={cn('sms-modal__col')} span={24} key={item?.id}>
              <Card className={cn('sms-modal__card')}>
                <Typography.Text className={cn('sms-modal__card-title')}>
                  {mainDictionary.sentNumber}: {item?.phone}
                </Typography.Text>
                <Typography.Text className={cn('sms-modal__card-desc')}>{item?.text}</Typography.Text>
                <Typography className={cn('sms-modal__card-footer')}>
                  <Typography.Text strong>{dateFormatter(item?.createdAt)}</Typography.Text>
                  <Typography.Text strong>
                    {mainDictionary.sentAuthor}:{item?.initiator ? getFullName(item.initiator) : mainDictionary.sistem}
                  </Typography.Text>
                </Typography>
              </Card>
            </Col>
          ))
        ) : (
          <Col>{mainDictionary.smsNot}</Col>
        )}
      </Row>
    </Modal>
  )
})
