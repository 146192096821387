import { useState } from 'react'
import { observer } from 'mobx-react'
import { InboxOutlined } from '@ant-design/icons'
import { Button, Form, notification, Space, Upload, UploadProps } from 'antd'
import { UploadChangeParam } from 'antd/es/upload/interface'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { useCheckMyGroup } from '@/utils/hooks/useCheckMyGroup'
import { VIDEO_MIME_TYPES, VIDEO_MIME_TYPES_TO_ACCEPT } from '../../constants'
import { Items } from './Items'

type FormValues = {
  uploadFiles: UploadChangeParam
} & Record<
  string,
  {
    lessonId: string
    origName: string
  }
>

export type AddVideoProps = {
  endpoint: {
    creat: string
    update: string
  }
  pageLink?: string
  pageName?: string
  groupId: number
  onClose?: () => void
  onSuccess?: () => void
}

export const AddVideo = observer(({ groupId, endpoint, pageLink, pageName, onClose, onSuccess }: AddVideoProps) => {
  const { kinescopeStore } = useStores()
  const [form] = Form.useForm<FormValues>()
  const [isVisibleSubmitButton, setVisibleSubmitButton] = useState(false)
  const [inProcess, setInProcess] = useState(false)
  const isAcademic = useCheckMyGroup()

  const handleBeforeUpload: UploadProps['beforeUpload'] = (file) => {
    // TODO: этот фикс чисто для macos где не принимает .mkv
    if (!file.type && file.name.endsWith('.mkv')) {
      return false
    }

    if (!VIDEO_MIME_TYPES.includes(file.type)) {
      notification.error({
        message: mainDictionary.invalidVideoNotificationMessage,
        placement: 'topRight',
      })

      return Upload.LIST_IGNORE
    }

    return false
  }

  const handleUploadDraggerChange: UploadProps['onChange'] = (info) => {
    setVisibleSubmitButton(!!info?.fileList?.length)
  }

  const handleFormFinish = (formValues: FormValues) => {
    if (!formValues.uploadFiles.fileList.length) {
      return null
    }

    const { fileList } = formValues.uploadFiles

    setInProcess(true)

    Promise.allSettled(
      fileList.map(async (file) => {
        const formFileValues = formValues[file.uid]

        return kinescopeStore
          .getUploadLinks(endpoint.creat, {
            groupId,
            lessonId: parseInt(formFileValues.lessonId, 10),
            origName: formFileValues.origName,
            size: file.size!,
          })
          .then((res) => res.data)
      }),
    )
      .then((results) => {
        const uploadFilesIds = results
          .map((result, index) => {
            const uploadFile = formValues.uploadFiles.fileList[index]

            if (result.status !== 'fulfilled') {
              notification.error({
                message: mainDictionary.getNotUploadUrlFromKinescope(uploadFile.name),
                placement: 'topRight',
              })

              return null
            }

            const uid = uploadFile.uid
            const formFileValues = formValues[uid]

            const uploadFiled = {
              file: uploadFile.originFileObj as File,
              videoId: result.value.videoId,
              origName: formFileValues.origName,
              uploadUrl: result.value.uploadUrl,
              lessonId: parseInt(formFileValues.lessonId, 10),
              isVisible: true,
            }

            kinescopeStore.setUpload({
              chapterId: groupId,
              id: result.value.id,
              page: { link: pageLink, name: pageName },
              uploadFile: uploadFiled,
            })

            return result.value.id
          })
          .filter(Boolean)

        kinescopeStore.uploads[groupId]?.forEach(({ id, uploadFile }) => {
          if (uploadFilesIds.includes(id)) {
            kinescopeStore.runUploadFile({
              chapterId: groupId,
              id,
              uploadFile,
              updateApiEndpoint: endpoint.update,
              onSuccess,
              isAcademic,
            })
          }
        })

        form.resetFields()

        onClose?.()

        setInProcess(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const uploadItemRender: UploadProps['itemRender'] = (originNode, file, fileList, actions) => (
    <Items key={file.uid} file={file} onDelete={actions.remove} />
  )

  return (
    <Form layout="vertical" onFinish={handleFormFinish} form={form}>
      <Form.Item name="uploadFiles">
        <Upload.Dragger
          accept={VIDEO_MIME_TYPES_TO_ACCEPT}
          beforeUpload={handleBeforeUpload}
          disabled={inProcess}
          itemRender={uploadItemRender}
          maxCount={10}
          onChange={handleUploadDraggerChange}
          multiple
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{mainDictionary.uploadText}</p>
          <p className="ant-upload-hint">{mainDictionary.uploadHint}</p>
        </Upload.Dragger>
      </Form.Item>

      <Space style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
        {onClose && (
          <Button disabled={inProcess} onClick={onClose}>
            {mainDictionary.cancel}
          </Button>
        )}
        {isVisibleSubmitButton && (
          <Button type="primary" htmlType="submit" disabled={inProcess} loading={inProcess}>
            {mainDictionary.addFiles}
          </Button>
        )}
      </Space>
    </Form>
  )
})
