import { Button, Dropdown, DropdownProps } from 'antd'
import classnamesBind from 'classnames/bind'
import { DownloadIcon } from './Icon'

import styles from './notification.module.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  hasUploadingFiles: boolean
  dropdownMenu: DropdownProps['menu']
}

export const Notification = ({ dropdownMenu, hasUploadingFiles }: Props) => (
  <span className={cn('notification')}>
    <Dropdown menu={dropdownMenu} placement="bottomRight" disabled={!hasUploadingFiles}>
      <Button
        shape="circle"
        type="text"
        icon={<DownloadIcon />}
        className={cn('notification__button')}
        disabled={!hasUploadingFiles}
      />
    </Dropdown>
  </span>
)
