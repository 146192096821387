import { makeAutoObservable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { gamificationApi } from '@/api/gamification'
import {
  GetAllGroupMembersRes,
  GetGroupsByActionRes,
  GetTheBestOnesRes,
  GroupAttendanceRes,
  GroupMembers,
  IAction,
  IActionLevel,
  IActionLevelResponse,
  ICategory,
  IGetActionResponse,
  IGetActionsByMethodRes,
  IGetCategoryResponse,
  IGetCoursesResponse,
  IGetGroupActionsByGroupResponse,
  IGetLevelsByCategoryIdResponse,
  IGetStaffGroupActionResponse,
  IGetStatisticsMethodsResponse,
  IGetStatusesResponse,
  IGetStudentAwardResponse,
  IGetStudentCategoriesOnly,
  IGetStudentStatistics,
  IGroupAction,
  IGroupActionCategoriesResponse,
  ILevel,
  IMethod,
  IStatistics,
  IStatisticsResponse,
  IStudentAward,
  LevelInfo,
  StudentAwardGroups,
  TCourse,
  TheBestOnes,
} from '@/api/gamification/types'
import { IGroup, StaffGroupsDetail } from '@/api/groups'
import { addCatchNotification, addNotification } from '@/modules/notifications'
import { ALL_ID } from '@/pages/Gamification/constants'
import { gamificationMethodNames } from '@/pages/Gamification/GroupActions/constants'
import { SortStatistic } from '@/pages/Gamification/types'
import { YEAR_FORMAT, YEAR_VIEW_FORMAT } from '@/utils'
import { METHODS } from './constants'
import {
  AddNominationReq,
  EditNominationReq,
  GetAllGroupMembersReq,
  GetGroupAttendanceReq,
  GetStudentGroupTypes,
  GetTheBestOnesReq,
  IAddActionLevelRequest,
  IAddActionRequest,
  IAddLevelRequest,
  IAddParticipateLessonDeed,
  IAddStudentAwards,
  IDeleteInfo,
  IEditActionLevel,
  IEditDeedRequest,
  IEditMethodRequest,
  IGetActionParams,
  IGetCategoriesRequest,
  IGetCoursesParams,
  IGetGroupActionRequest,
  IGetGroupRequest,
  IGetStaffGroupActions,
  IGetStatistics,
  IGetStatusesParams,
  IGetStudentAwards,
  ISelectOptions,
  IUpdateActionGroups,
  IUpdateSelectIds,
  Orders,
} from './types'

class GamificationStore {
  byGroupInfoModal = false
  categories: IGetCategoryResponse | null = null
  studentCategories: IGetCategoryResponse | null = null
  categoryLevels: IGetLevelsByCategoryIdResponse | null = null
  levelSingleModal = false
  levelDeleteModal = false
  singleLevel: ILevel | null = null
  singleCategory: ICategory | null = null
  methodModal = false
  methods: IMethod[] | null = null
  singleMethod: IMethod | null = null
  deeds: IGetActionResponse | null = null
  actionLevels: IActionLevelResponse | null = null
  actionLevelModal = false
  deedsModal = false
  singleActionLevel: IActionLevel | null = null
  confirmActionModal = false
  singleAction: IAction | null = null
  groupActionsByGroups: IGetGroupActionsByGroupResponse | null = null
  groupStaff: StaffGroupsDetail | null = null
  groupActionFilter: IGetGroupActionRequest | null = null
  groupActionCategories: IGroupActionCategoriesResponse | null = null
  groupStaffActions: IGetStaffGroupActionResponse | null = null
  groupActionGroups: IGetStaffGroupActionResponse | null = null
  actionSelectOption: ISelectOptions[] | null = null
  existedActions: number[] | null = null
  actionDefaultIds: IGroupAction[] | null = null
  selectedIds: number[] = []
  actionConnectedGroups: GetGroupsByActionRes[] | null = null
  connectionTab = '1'
  selectedActionIds: number[] | null = []
  actionModal = false
  statisticTab: null | string | number | undefined = ALL_ID
  statistics: IStatisticsResponse | null = null
  statisticsTableLoading = false
  statisticsFilter: IGetStatistics | null = {
    page: 1,
    sortBy: SortStatistic.XP,
    orderBy: Orders.Descend,
  }

  courses: IGetCoursesResponse | null = null
  selectedStudent: GroupMembers | null = null
  selectedAwardedStudent: TheBestOnes | null = null
  categoriesModal = false
  statuses: IGetStatusesResponse | null = null
  studentStatistics: IStatistics | null = null
  studentStatisticsResults: IGetStudentAwardResponse | null = null
  studentStatistic: IGetStudentStatistics | null = null
  studentAwardLoader = false
  commentModal = false
  studentAward: IStudentAward | null = null
  groupActionByGroupTableLoading = false
  singleLevelTableLoading = false
  methodsTableLoading = false
  attendanceTableLoading = false
  studentGroups: IGroup[] | null = null
  studentGroupsModal = false
  deedsEditModal = false
  singleDeed: IAction | null = null
  statisticsMethods: IGetStatisticsMethodsResponse[] | null = null
  studentCategoriesOnly: IGetStudentCategoriesOnly | null = null
  passExamMakeModal = false
  makeHomeDeed = false
  cameDeedMakeModal = false
  methodActions: IGetActionsByMethodRes[] | null = null
  selectedLevel: ILevel | null = null
  courseMethods: TCourse[] | null = null
  levelInfo: LevelInfo | null = null
  groupAttendance: GroupAttendanceRes[] | null = null
  studentAwardGroups: StudentAwardGroups[] | null = null
  selectedStudentGroupId: undefined | null | number = null
  group: IGroup | null = null
  groupScheduleModal = false
  bestStudents: GetTheBestOnesRes | null = null
  bestStudentsLoading = false
  allStudentsLoading = false
  nominationModal = false
  allStudents: GetAllGroupMembersRes | null = null
  allStudentsFilter: GetAllGroupMembersReq = {
    page: 1,
    perPage: 10,
  }

  bestStudentsFilter: GetTheBestOnesReq = {
    page: 1,
    perPage: 10,
  }

  resetStatisticsFilter = () => {
    this.setStatisticsFilter({
      page: 1,
      perPage: this.statisticsFilter?.perPage,
    })
  }

  setSelectedAwardedStudent = (params: TheBestOnes | null) => {
    this.selectedAwardedStudent = params
  }

  setNominationModal = (params: boolean) => {
    this.nominationModal = params
  }

  setCategoriesModal = (params: boolean) => {
    this.categoriesModal = params
  }

  setSelectedStudent = (params: GroupMembers | null) => {
    this.selectedStudent = params
  }

  setAllStudentsFilter = (params: GetAllGroupMembersReq) => {
    this.allStudentsFilter = params
  }

  setAllStudentsLoading = (params: boolean) => {
    this.allStudentsLoading = params
  }

  setAllStudents = (params: GetAllGroupMembersRes | null) => {
    this.allStudents = params
  }

  setBestStudentsFilter = (params: GetTheBestOnesReq) => {
    this.bestStudentsFilter = params
  }

  setBestStudentsLoading = (params: boolean) => {
    this.bestStudentsLoading = params
  }

  setGroupScheduleModal = (params: boolean) => {
    this.groupScheduleModal = params
  }

  setBestStudents = (params: GetTheBestOnesRes | null) => {
    this.bestStudents = params
  }

  setGroup = (params: IGroup | null) => {
    this.group = params
  }

  getGroup = (id: number) =>
    gamificationApi
      .getGroup(id)
      .then((res) => {
        if (res.success) {
          this.setGroup(res.data)
        }
      })
      .catch(addNotification)

  setSelectedStudentGroupId = (params?: null | number) => {
    this.selectedStudentGroupId = params
  }

  addNomination = (params: AddNominationReq) => gamificationApi.addNomination(params)

  setStudentAwardLoader = (params: boolean) => {
    this.studentAwardLoader = params
  }

  setStudentAwardGroups = (params: StudentAwardGroups[] | null) => {
    this.studentAwardGroups = params
  }

  setGroupAttendance = (params: GroupAttendanceRes[] | null) => {
    this.groupAttendance = params
  }

  resetStudentGroups = () => {
    this.studentGroups = null
  }

  setSelectedLevel = (paramms: ILevel | null) => {
    this.selectedLevel = paramms
  }

  setCameDeedMakeModal = (params: boolean) => {
    this.cameDeedMakeModal = params
  }

  setLevelInfo = (params: LevelInfo | null) => {
    this.levelInfo = params
  }

  setMethodAction = (params: IGetActionsByMethodRes[] | null) => {
    this.methodActions = params
  }

  resetStudentCategories = () => {
    this.studentCategories = null
  }

  setStatuses = (statuses: IGetStatusesResponse | null) => {
    this.statuses = statuses
  }

  getGroupAction = () =>
    gamificationApi
      .getGroupActions()
      .then((res) => {
        if (res.success) {
          this.setMethodAction(res?.data)
        }
      })
      .catch(addCatchNotification)

  getAllStudents = (params: GetAllGroupMembersReq) =>
    gamificationApi
      .getAllStudents(params)
      .then((res) => {
        if (res.success) {
          this.setAllStudents(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setAllStudentsLoading(false))

  setMakeHomeDeed = (params: boolean) => {
    this.makeHomeDeed = params
  }

  setPassExamMakeModal = (params: boolean) => {
    this.passExamMakeModal = params
  }

  setAttendanceTableLoading = (params: boolean) => {
    this.attendanceTableLoading = params
  }

  setSingleDeed = (params: IAction | null) => {
    this.singleDeed = params
  }

  setDeedsEditModal = (params: boolean) => {
    this.deedsEditModal = params
  }

  setStudentGroupsModal = (params: boolean) => {
    this.studentGroupsModal = params
  }

  setMethodsTableLoading = (params: boolean) => {
    this.methodsTableLoading = params
  }

  setConnectionTab = (params: string) => {
    this.connectionTab = params
  }

  setSingleLevelTableLoading = (params: boolean) => {
    this.singleLevelTableLoading = params
  }

  setGroupActionByGroupTableLoading = (params: boolean) => {
    this.groupActionByGroupTableLoading = params
  }

  setStudentAward = (params: IStudentAward | null) => {
    this.studentAward = params
  }

  setCommentModal = (params: boolean) => {
    this.commentModal = params
  }

  setStudentStatistics = (params: IStatistics) => {
    this.studentStatistics = params
  }

  setStatisticsFilter = (params: IGetStatistics | null) => {
    this.statisticsFilter = params
  }

  setStatisticsTableLoading = (params: boolean) => {
    this.statisticsTableLoading = params
  }

  setStatisticsTab = (params?: string) => {
    this.statisticTab = params
  }

  setSelectedActionIds = (params: number[] | null) => {
    this.selectedActionIds = params
  }

  setActionLevelModal = (params: boolean) => {
    this.actionLevelModal = params
  }

  setSelectedIds = (params: number[]) => {
    this.selectedIds = params
  }

  setActionSelectOption = (params: ISelectOptions[]) => {
    this.actionSelectOption = params
  }

  setActionModal = (params: boolean) => {
    this.actionModal = params
  }

  setExistedActions = (params: number[]) => {
    this.existedActions = params

    this.setGroupActionSelect()
  }

  setGroupActionFilter = (params: IGetGroupActionRequest | null) => {
    this.groupActionFilter = params
  }

  setByGroupInfoModal = (params: boolean) => {
    this.byGroupInfoModal = params
  }

  setDeedsModal = (params: boolean) => {
    this.deedsModal = params
  }

  setActionLevel = (params: IActionLevel | null) => {
    this.singleActionLevel = params
  }

  setConfirmModal = (params: boolean) => {
    this.confirmActionModal = params
  }

  resetSingleAction = () => {
    this.singleAction = null
  }

  setMethodTable = (params: boolean) => {
    this.methodModal = params
  }

  setSingleMethod = (params: IMethod | null) => {
    this.singleMethod = params
  }

  setLevelSingleModal = (params: boolean) => {
    this.levelSingleModal = params
  }

  setSingleLevel = (params: ILevel | null) => {
    this.singleLevel = params
  }

  setLevelDeleteModal = (params: boolean) => {
    this.levelDeleteModal = params
  }

  constructor() {
    makeAutoObservable(this)
  }

  getMethods = () => {
    this.methods = METHODS
  }

  deleteBestStudent = (id: number) => gamificationApi.deleteBestStudent(id)

  addParticipateLessonDeed = (params: IAddParticipateLessonDeed) => gamificationApi.addParticipateLessonDeed(params)

  editMethod = (params: IEditMethodRequest) => gamificationApi.editMethod(params)

  currentDate = (isNormal?: boolean) => dayjs(new Date()).format(isNormal ? YEAR_VIEW_FORMAT : YEAR_FORMAT)

  currentFormattedDate = () => dayjs(new Date()).format(YEAR_VIEW_FORMAT)

  getCategories = (params?: IGetCategoriesRequest) =>
    gamificationApi
      .getCategories(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.categories = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)

  getStudentAwardGroups = (params: GetStudentGroupTypes) =>
    gamificationApi
      .getStudentAwardGroups(params)
      .then((res) => {
        if (res.success) {
          this.setStudentAwardGroups(res.data)
        }
      })
      .catch(addCatchNotification)

  getGroupAttendance = (params: GetGroupAttendanceReq) =>
    gamificationApi
      .getGroupAttendance(params)
      .then((res) => {
        if (res.success) {
          this.setGroupAttendance(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentCategories = (params: number) =>
    gamificationApi.getStudentCategories(params).then((res) => {
      if (res.success) {
        runInAction(() => {
          this.studentCategories = res.data
          if (!this.statisticTab) {
            if (!res?.data?.categories?.[0]?.id) return

            this.statisticTab = res?.data?.categories?.[0]?.id
          }
        })
      }
    })

  editNomination = (params: EditNominationReq) => gamificationApi.editNomination(params)

  getTheBestOnes = (params: GetTheBestOnesReq) =>
    gamificationApi
      .getTheBestOnes(params)
      .then((res) => {
        if (res.success) {
          this.setBestStudents(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setBestStudentsLoading(false))

  resetCategories = () => {
    this.categories = null
  }

  getLevelsCategoryById = () =>
    gamificationApi
      .getLevelsCategoryById()
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.categoryLevels = res.data
          })
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        runInAction(() => {
          this.singleLevelTableLoading = false
        })
      })

  getSingleLevel = (id: number) =>
    gamificationApi
      .getSingleLevel(id)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.singleLevel = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        runInAction(() => {
          this.singleLevelTableLoading = false
        })
      })

  getSingleCategory = () =>
    gamificationApi
      .getSingleCategory()
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.singleCategory = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)

  deleteLevel = (params: IDeleteInfo) => gamificationApi.deleteLevel(params)

  addLevel = (params: IAddLevelRequest) => gamificationApi.addLevel(params)

  editLevel = (params: IAddLevelRequest) => gamificationApi.editSingleLevel(params)

  addAction = (params: IAddActionRequest) => gamificationApi.addAction(params)

  setCourseMethods = (courseMethods: TCourse[] | null) => {
    this.courseMethods = courseMethods
  }

  getCoursesByMethod = (params: IMethod | number) =>
    gamificationApi
      .getCoursesByMethod(params)
      .then((res) => {
        if (res.success) {
          this.setCourseMethods(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  resetGroupStaff = () => {
    this.groupStaff = null
  }

  deleteGroupActions = (id: number) => {
    const filteredActions = this.actionDefaultIds?.filter((item) => item.action.id !== id)

    this.actionDefaultIds = filteredActions!

    const filterIds = filteredActions?.map((item) => item.id)

    this.selectedIds = filterIds!

    this.setGroupActionSelect()
  }

  resetGroupActions = () => {
    this.actionDefaultIds = []
    this.selectedIds = []
    this.existedActions = null
  }

  setGroupActionSelect = () => {
    const existedActions = this.actionDefaultIds?.map((item) => item.action.id)

    const disabled = this.methodActions?.map((item) => {
      const ids: IAction[] = []

      item.actions?.forEach((action) => {
        if (existedActions?.includes(action.id)) {
          // @ts-ignore
          ids.push(item.actions)
        }
      })

      return ids
    })

    //@ts-ignore
    const actionIds = disabled?.flat(Infinity).map((item) => item.id)

    this.existedActions = existedActions!

    this.actionSelectOption = this.methodActions?.map((item) => ({
      label: gamificationMethodNames[item?.method],
      options: item.actions?.map((action) => ({
        label: action.name,
        value: action?.id,
        disabled: actionIds?.includes(action.id),
      })),
    }))!
  }

  getActions = (params?: IGetActionParams) =>
    gamificationApi
      .getActions(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.deeds = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getActionLevels = (params: number) =>
    gamificationApi
      .getActionLevels(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.actionLevels = res.data
          })
        }
      })
      .catch(addCatchNotification)

  addActionLevels = (params: IAddActionLevelRequest) => gamificationApi.addActionLevel(params)

  editActionLevel = (params: IEditActionLevel) => gamificationApi.editActionLevel(params)

  editAwardActionLevel = (params: IAddActionLevelRequest) => gamificationApi.editAwardActionLevel(params)

  deleteActionLevel = (params: number) => gamificationApi.deleteActionLevel(params)

  getLevelXpProportionn = (levelId: number) =>
    gamificationApi
      .getLevelXpProportion(levelId)
      .then((res) => {
        if (res.success) {
          this.setSelectedLevel(res.data)
        }
      })
      .catch((err) => {
        console.error(err)
      })

  getSingleAction = (params: number) =>
    gamificationApi
      .getSingleAction(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.singleAction = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)

  getGroupActions = (params?: IGetGroupActionRequest) =>
    gamificationApi
      .getGroupActionByGroup(params)
      .then((res) => {
        runInAction(() => {
          this.groupActionByGroupTableLoading = true
        })

        return res
      })
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.groupActionsByGroups = res.data
          })
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        runInAction(() => {
          this.groupActionByGroupTableLoading = false
        })
      })

  getGroupActionDetail = (params: number) =>
    gamificationApi
      .getGroupActionDetail(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.groupStaff = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getGroupActionCategories = () =>
    gamificationApi
      .getGroupActionCategories()
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.groupActionCategories = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getStaffGroupActions = (params?: IGetStaffGroupActions) =>
    gamificationApi
      .getStaffGroupActions(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.groupStaffActions = res.data
            this.actionDefaultIds = res.data.groupActions
          })
        }

        return res
      })
      .catch(addCatchNotification)

  updateGroupActionByGroup = (params: IUpdateSelectIds) => gamificationApi.updateGroupActionByGroup(params)

  getActionGroups = (params: IGetStaffGroupActions) =>
    gamificationApi
      .getStaffGroupActions(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.groupActionGroups = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getGroupActionsByAction = (params: number) =>
    gamificationApi
      .getActionGroups(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.actionConnectedGroups = res.data
          })
        }
      })
      .catch(addCatchNotification)

  deleteActionGroup = (id: number) => {
    const filtered = this.groupActionGroups?.groupActions.filter((item) => item.group.id !== id)

    this.groupActionGroups!.groupActions = filtered!

    const updatedActions = this.actionConnectedGroups?.map((item) => ({
      name: item.name,
      courses: item.courses.map((courses) => ({
        name: courses.name,
        groups: courses.groups.map((group) => {
          if (group.id === id) {
            return {
              ...group,
              groupAttached: false,
              actionAttached: false,
            }
          }

          return group
        }),
      })),
    }))

    this.actionConnectedGroups = updatedActions!
  }

  updateActionGroups = (params: IUpdateActionGroups) => gamificationApi.updateActionGroups(params)

  getStatistics = (params: IGetStatistics) =>
    gamificationApi
      .getStatistics(params)
      .then((res) => {
        if (res.success) {
          this.statistics = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        runInAction(() => {
          this.statisticsTableLoading = false
        })
      })

  getCourses = (params?: IGetCoursesParams) =>
    gamificationApi
      .getCourses(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.courses = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getStudentAwardFilterObjects = (params?: IGetStatusesParams) =>
    gamificationApi
      .getStudentAwardFilterObjects(params)
      .then((res) => {
        if (res.success) {
          this.setStatuses(res.data)
        }
      })
      .catch(addCatchNotification)

  getStatuses = () =>
    gamificationApi
      .geStatuses()
      .then((res) => {
        if (res.success) {
          this.setStatuses(res.data)
        }
      })
      .catch(addCatchNotification)

  addStudentAward = (params: IAddStudentAwards) => gamificationApi.addStudentAwards(params)

  getStudentAwards = (params: IGetStudentAwards) =>
    gamificationApi
      .getStudentAwards(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.studentStatisticsResults = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getStudentStatistics = (params: IGetStudentAwards) =>
    gamificationApi
      .getStudentStatistics(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.studentStatistic = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)

  getStatisticsMethods = (params: IGetStudentAwards) =>
    gamificationApi
      .getStudentAwardsMethods(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.statisticsMethods = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getStudentGroups = (params: IGetGroupRequest) =>
    gamificationApi
      .getStudentGroups(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.studentGroups = res.data
          })
        }
      })
      .catch(addCatchNotification)

  getStudentAwardsLevel = (level: number) =>
    gamificationApi
      .getLevelNumber(level)
      .then((res) => {
        if (res.success) {
          this.setLevelInfo(res.data)

          return res
        }
      })
      .catch(addCatchNotification)

  getStudentCategoriesOnly = (studentId: { studentId: number }) =>
    gamificationApi
      .getStudentCategoriesOnly(studentId)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.studentCategoriesOnly = res.data
          })
        }
      })
      .catch(addCatchNotification)

  editDeed = (params: IEditDeedRequest) => gamificationApi.editDeed(params)

  reset = () => {
    this.connectionTab = '1'
    this.deeds = null
    this.methods = null
    this.categories = null
    this.groupStaff = null
    this.statistics = null
    this.singleLevel = null
    this.statisticTab = null
    this.singleMethod = null
    this.singleAction = null
    this.actionLevels = null
    this.categoryLevels = null
    this.existedActions = null
    this.singleCategory = null
    this.actionDefaultIds = null
    this.singleActionLevel = null
    this.studentCategories = null
    this.groupStaffActions = null
    this.groupActionFilter = null
    this.groupActionGroups = null
    this.actionSelectOption = null
    this.groupActionsByGroups = null
    this.groupActionCategories = null
    this.actionConnectedGroups = null
    this.deedsModal = false
    this.methodModal = false
    this.actionModal = false
    this.levelDeleteModal = false
    this.actionLevelModal = false
    this.levelSingleModal = false
    this.byGroupInfoModal = false
    this.confirmActionModal = false
    this.statisticsTableLoading = false
    this.selectedIds = []
    this.selectedActionIds = []
    this.statisticsFilter = {
      perPage: 10,
      page: 1,
    }
    this.studentStatisticsResults = null
  }
}

export const gamificationStore = new GamificationStore()
