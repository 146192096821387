import { observer } from 'mobx-react'
import { useStores } from '@/stores'
import { Filter } from './Filter'
import { InfoModal } from './InfoModal'
import { ByGroupTable } from './Table/Table'

export const ByGroup = observer(() => {
  const { gamificationStore } = useStores()

  return (
    <>
      <Filter />
      <ByGroupTable />

      {gamificationStore.byGroupInfoModal && <InfoModal />}
    </>
  )
})
