import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { IBreadcrumb } from '@/stores/breadcrumb/types'
import { NestedList } from './NestedList'

import styles from './menu.module.scss'

type Props = {
  items: IBreadcrumb[]
}

export const Menu = ({ items }: Props) => {
  const [firstItem, ...lastItems] = items

  return (
    <ul className={styles['menu__block-list']}>
      <li className={styles['menu-link']}>
        {firstItem?.link ? (
          <Link className={styles['menu__items-link']} to={firstItem?.link}>
            <span className={styles['menu__items-link-text-item']}>{firstItem?.label}</span>
          </Link>
        ) : (
          firstItem?.label
        )}
      </li>
      <NestedList items={lastItems} />
    </ul>
  )
}
