/* eslint-disable max-len */
export const ROUTES = {
  home: '/',
  signIn: '/signin',
  groups: '/groups',
  edit: '/edit',
  result: '/result',
  exam: '/exam',
  announce: '/announce',
  myGroups: '/my-groups',
  myGroupsSingle: '/my-groups/:id/staff/:staffId/schedule/:scheduleId',
  groupSingle: '/groups/:id/staff/:staffId/schedule/:scheduleId',
  addExam: '/groups/:id/staff/:staffId/schedule/:scheduleId/add',
  myGroupAddExam: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/add',
  editExam: '/groups/:id/staff/:staffId/schedule/:scheduleId/exam/:examId/edit',
  myGroupEditExam: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/exam/:examId/edit',
  examResult: '/groups/:id/staff/:staffId/schedule/:scheduleId/exam/:resultId/result',
  myExamResult: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/exam/:resultId/result',
  examSingle: '/groups/:id/staff/:staffId/schedule/:scheduleId/exam/:id',
  myGroupExamSingle: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/exam/:id',
  examView: '/groups/:id/staff/:staffId/schedule/:scheduleId/exam/:id/chat/:chatId/exam/:senderId',
  myExamView: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/exam/:id/chat/:chatId/exam/:senderId',
  examAnnounce: '/groups/:id/staff/:staffId/schedule/:scheduleId/exam/:examId/announce',
  myExamAnnounce: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/exam/:examId/announce',
  addExamMyGroups: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/add',
  myHomeworkStatistics: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/homework/statistics',
  homeworkStatistics: '/groups/:id/staff/:staffId/schedule/:scheduleId/homework/statistics',
  homeworkStatisticsSingle:
    '/groups/:id/staff/:staffId/schedule/:scheduleId/homework/statistics/:statisticId/start/:startDateId/end/:endDate',
  myHomeworkStatisticsSingle:
    '/my-groups/:id/staff/:staffId/schedule/:scheduleId/homework/statistics/:statisticId/start/:startDateId/end/:endDate',
  editAttendance: '/groups/:id/staff/:staffId/attendance/schedule/:scheduleId/edit/:date',
  homeworkAdd: '/groups/:id/staff/:staffId/schedule/:scheduleId/homework/add',
  myHomeworkAdd: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/homework/add',
  homeworkEdit: '/groups/:id/staff/:staffId/schedule/:scheduleId/homework/:homeworkId/edit',
  myHomeworkEdit: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/homework/:homeworkId/edit',
  homeworkSingle: '/groups/:id/staff/:staffId/schedule/:scheduleId/lesson/:lessonId/theme/:homeworkId',
  myHomeworkSingle: '/my-groups/:id/staff/:staffId/schedule/:scheduleId/lesson/:lessonId/theme/:homeworkId',
  homeworkSingleView:
    '/groups/:id/staff/:staffId/schedule/:scheduleId/lesson/:lessonId/theme/:homeworkId/chat/:chatId/home/:senderId',
  myHomeworkSingleView:
    '/my-groups/:id/staff/:staffId/schedule/:scheduleId/lesson/:lessonId/theme/:homeworkId/chat/:chatId/home/:senderId',

  audits: '/audits',

  management: '/management',
  managementAutoCall: '/auto-call',
  managementAutoCallHistory: '/auto-call/history/:id',

  controls: '/control',
  controlRoles: '/control',
  singleRole: '/control/roles/:id',
  controlRolesAdd: '/control/roles/add',
  controlUsers: '/control/:userId/users',
  controlUsersAdd: '/control/users/add',
  controlUserEdit: '/control/:id/users/:userId/edit',
  editRole: '/control/roles/edit/:id',
  controlBranchOffice: '/branch-office',
  teachers: '/teachers',
  teacherSingle: '/teachers/:id',
  students: '/students',
  singleStudent: '/students/:studentId',
  singleStudentResult: '/students/:id/result',
  studentAdd: '/students/add',
  contracts: '/contracts',
  contractsHistory: '/contracts/history/:id',

  gamification: '/gamification',
  gamificationStatistics: '/gamification/statistics',
  gamificationLevels: '/gamification/levels',
  gamificationLevelsSingle: '/gamification/levels/:id',
  gamificationActions: '/gamification/actions',
  gamificationActionSingle: '/gamification/actions/:id',
  gamificationHomeworkExamSingle: '/gamification/actions/homework-exam/:id',
  gamificationHomeworkHomeworkSingleAdd: '/gamification/actions/homework-exam/:id/homework-add',
  gamificationHomeworkHomeworkSingle: '/gamification/actions/homework-exam/:id/homework/:levelId/edit',
  gamificationCameSingle: '/gamification/actions/came/:id',
  gamificationActionByPassExamSingle: '/gamification/actions/:id/passExam',
  gamificationConnect: '/gamification/connect',
  gamificationConnectGroupSingle: '/gamification/connect/:id',
  gamificationConnectActionSingle: '/gamification/connect/action/:id',
  gamificationStudent: '/gamification/:categoryId/student/:id',
  gamificationStudentStatistics: '/gamification/statistics/:id',
  gamificationStudentStatisticsMore: '/gamification/statistics/:id/:methodId',
  gamificationTheBestOnes: '/gamification/the-best-ones',
  gamificationTheBestOnesAdd: '/gamification/the-best-ones/add',

  usersAcademic: '/users/academic',
  usersAcademicSingle: '/users/academic/:id',
  usersAcademicGroups: '/users/academic/:id/group',
  teacherAttendance: '/users/academic/:id/:groupId/attendances',

  assistants: '/assistants',
  assistantSingle: '/assistants/:id',
  assistantAttendance: '/assistants/:id/:groupId/attendances',

  courses: 'courses',
  coursesCategories: '/courses/categories',
  coursesSubCategories: '/courses/sub-categories',
  coursesSubCategoriesView: '/courses/sub-category/:id/list',
  archivedCoursesSubCategoriesView: '/courses/sub-category/archived/:id/list',
  coursesList: '/courses/list',
  coursesListAdd: '/courses/list/add',
  coursesListEdit: '/courses/list/:id/edit',
  coursesVideos: '/courses/videos',
  coursesCategorySubCategories: '/courses/category/:id/sub-categories',
  coursesSubCategoriesCreate: '/courses/sub-categories/create',
  coursesSubCategoriesEdit: '/courses/sub-category/:id/edit',
  faceId: '/faceId',
  faceIdScreens: '/face-id/screens',

  chapters: '/courses/list/:id/chapters',
  chapterAdd: '/courses/list/:id/chapter/add',
  chapterEdit: '/courses/list/chapter/:id/edit',
  faceIdDevices: '/face-id/devices',

  market: '/market',
  marketTransactions: '/market/transactions',
  marketCategories: '/market/categories',
  marketAccessories: '/market/accessories',
  marketAccessoriesAdd: '/market/accessories/add',
  marketAccessoriesEdit: '/market/accessories/:id/edit',
  marketAccessoriesRestore: '/market/accessories/:id/restore',
  marketAccessory: '/market/accessories/:id',

  lessons: '/courses/chapters/:id/lessons',
  videos: '/courses/videos',

  blogs: 'blogs',
  blogsPosts: '/blogs/posts',
  blogsSettings: '/blogs/categories',
  blogCategoriesSingle: '/blogs/categories/:categoryId',
  blogsComments: '/blogs/comments',
  blogsFaq: '/blogs/faq',
  blogsUnArchiveAdd: '/blogs/unarchive/add',
  blogsUnArchiveAddByCategory: '/blogs/unarchive/add/:categoryId',
  blogsArchiveAdd: '/blogs/archive/add',
  blogsArchiveAddByCategory: '/blogs/archive/add/:categoryId',
  blogUnArchiveEdit: '/blogs/archive/:blogId/edit',

  blogArchive: '/blogs/archive',
  blogUnArchive: '/blogs/unarchive',

  users: 'users',
  usersStaff: '/users/staff',
  usersSingleStaff: '/users/staff/:id',
  studentsAssistants: '/users/students/assistants',
  studentsTeachers: '/users/students/staffs',
  studentsAuthors: '/users/students/authors',
  authors: '/users/authors',
  authorAdd: '/users/authors/add',
  authorEdit: '/users/authors/:authorId/edit',

  studyDepartment: '/study-department',
  studyDepartmentHomeworks: '/study-department/homeworks',
  studyDepartmentTeacherStatistics: '/study-department/teacher-statistics',
  studyDepartmentHome: '/study-department/homeworks/:id',
  studyDepartmentSingleStudent: '/study-department/homeworks/:id/student/:studentId/start/:startDateId/end/:endDate',
  studyDepartmentHomePage: '/study-department/homeworks/:id/lesson/:lessonId/theme/:homeworkId',
  studyDepartmentHomePageHomeworkSingle:
    '/study-department/homeworks/:id/lesson/:lessonId/theme/:homeworkId/chat/:chatId/home/:senderId',
  studyDepartmentCourses: '/study-department/courses',
  studyDepartmentCoursesSingle: '/study-department/courses/:id',
  studyDepartmentCoursesSingleDragDrop: '/study-department/courses/:id/drag-drop',
  studyDepartmentCoursesSingleSwitchHomework: '/study-department/courses/:id/switch/:homeworkId',
  studyDepartmentHomeworkChat:
    '/study-department/group/:groupId/user/:userId/homeworkChat/:homeworkId/lesson/:lessonId/student/:studentId',
  studyDepartmentTeachersKPI: '/study-department/teachers-kpi',
  studyDepartmentSource: '/study-department/source',
  studyDepartmentHomeworkDataBase: '/study-department/source/homework',
  studyDepartmentHomeworkDataBaseDraft: '/study-department/source/draft',
  studyDepartmentHomeworkDataBaseDraftLessons: '/study-department/source/draft/:id/lessons',
  studyDepartmentHomeworkDataBaseDraftLessonsHomework: '/study-department/source/draft/:id/lessons/:lessonId/homework',
  studyDepartmentHomeworkDataBaseChapters: '/study-department/source/homework/:courseId/chapters',
  studyDepartmentHomeworkDataBaseChaptersHomework:
    '/study-department/source/homework/:courseId/chapters/:chapterId/homework',
  studyDepartmentHomeworkDataBaseChaptersHomeworkAdd:
    '/study-department/source/homework/:courseId/chapters/:chapterId/homework/add',
  studyDepartmentHomeworkDataBaseChaptersHomeworkEdit:
    '/study-department/source/homework/:courseId/chapters/:chapterId/homework/:homeworkId/edit',

  teacherEdit: '/users/teacher/:id/edit',
  teacherAdd: '/users/teacher/add',
  promo: '/promo',
  promoSingle: '/promo/:promoId',
  promoArchiveSingle: '/promo/:promoId/archived',
  discount: '/discount',
  discountSingle: '/discount/:discountId',
  discountArchiveSingle: '/discount/:discountId/archived',

  reports: '/reports',
  reportSingle: '/reports/:id',

  publicResult: '/exam-result/:examId',

  settings: '/settings',
  settingsPublicOffering: '/settings/public-offering',
  settingsPublicOfferingUpdate: '/settings/public-offering/update',
  settingsStudentStatuses: '/settings/students',
  settingsStudentStatusesEdit: '/settings/students/:id/edit',
  settingsMobileApp: '/settings/mobile-app',

  notifications: '/notifications',
  notificationsSended: '/notifications/sended',
  notificationsSingle: '/notifications/more/:id',
  notificationsPublicStaffAndStudent: '/notifications/public-staff-and-student/create',
  templates: '/notifications/templates',
  templateEdit: '/notifications/templates/:id/edit',
  templateCreate: '/notifications/templates/create',
  notificationPublicStudentsCreate: '/notifications/public-students/create',
  notificationStudentPrivate: '/notifications/student-private/create',

  myNotification: '/my-notification',
  myNotificationSingle: '/my-notification/:id',

  competitions: '/events',
  addCompetitions: '/events/add',
  editCompetitions: '/events/:id/edit',
  singleCompetition: '/events/:id',
  masterClass: '/master-class',
  addMasterClass: '/master-class/add',
  editMasterClass: '/master-class/:id/edit',
  singleMasterClass: '/master-class/:id',
  masterClassParticipants: '/master-class/:id/participants',

  profile: '/profile',
} as const

/*
 * T extend {name, age}
 * {name, age, asd, asdasd,,}
 * */
