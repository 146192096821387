import { IStaffInfo } from '@/stores/auth/types'
import {
  IControlValues,
  ICreateUserRequest,
  IEditDeviceRequest,
  IEditRequest,
  IGetDevices,
  IGetDeviceScreenLogs,
  IGetLastScreenInDeviceReq,
  IGetRolesParams,
  IGetRoleUsers,
  IMakeDevice,
  IOpenDoor,
  IUserChangeStatusRequest,
  IUserEditRequest,
} from '@/stores/controls/type'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { ControlsEndpoints } from './endpoints'
import {
  GetLastScreenInDeviceRes,
  ICreateRoleResponse,
  ICreateUserResponse,
  IDevice,
  IGetDeviceResponse,
  IGetFaceScreens,
  IGetPermissionResponse,
  IPermissionResponse,
  IResetPasswordResponse,
  IRolesResponse,
  IRoleUsersResponse,
  ISingleRoleResponse,
  PermissionNames,
} from './types'

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class ControlsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getPermissions = (): Promise<IResponse<IPermissionResponse>> =>
    this.get(`${ControlsEndpoints.Roles}${ControlsEndpoints.PermissionObjectsAll}`)

  createRole = (values: IControlValues): Promise<IResponse<ICreateRoleResponse>> =>
    this.post(ControlsEndpoints.Roles, values)

  getRoles = (params?: IGetRolesParams): Promise<IResponse<IRolesResponse>> =>
    this.get(`${ControlsEndpoints.Roles}${ControlsEndpoints.All}`, { params })

  deleteRole = (id: number): Promise<IResponse<IPermissionResponse>> => this.delete(`${ControlsEndpoints.Roles}/${id}`)

  getSingleRole = (id: number): Promise<IResponse<ISingleRoleResponse>> => this.get(`${ControlsEndpoints.Roles}/${id}`)

  editRole = (values: IEditRequest): Promise<IResponse<ICreateRoleResponse>> =>
    this.put(`${ControlsEndpoints.Roles}/${values.id}`, values.data)

  getRoleUsers = (params: IGetRoleUsers): Promise<IResponse<IRoleUsersResponse>> => {
    const { roleId, ...data } = params

    return this.get(`${ControlsEndpoints.Users}/${ControlsEndpoints.ByRole}/${roleId}`, { params: data })
  }

  createUser = (params: ICreateUserRequest): Promise<IResponse<ICreateUserResponse>> =>
    this.post(`${ControlsEndpoints.UsersCreate}`, params)

  resetPassword = (params: number): Promise<IResponse<IResetPasswordResponse>> =>
    this.patch(`${ControlsEndpoints.Users}/${params}/${ControlsEndpoints.ResetPassword}`)

  getSingleUser = (params: string): Promise<IResponse<IStaffInfo>> => this.get(`${ControlsEndpoints.Users}/${params}`)

  editUser = (params: IUserEditRequest | IUserChangeStatusRequest): Promise<IResponse<IResetPasswordResponse>> =>
    this.put(`${ControlsEndpoints.Users}/${params.type}/${params.id}/${ControlsEndpoints.Update}`, params.data)

  getSinglePermission = (params: number): Promise<IResponse<IGetPermissionResponse[]>> =>
    this.get(`${ControlsEndpoints.Roles}/${params}${ControlsEndpoints.Detail}`)

  getDevices = (params?: IGetDevices): Promise<IResponse<IGetDeviceResponse>> =>
    this.get(ControlsEndpoints.Devices, { params })

  addDevice = (params: IMakeDevice): Promise<IResponse<IDevice>> => this.post(ControlsEndpoints.Devices, params)

  deleteDevice = (id: number): Promise<IResponse<IDevice>> => this.delete(`${ControlsEndpoints.Devices}/${id}`)

  getFaceScreens = (params: IGetDeviceScreenLogs): Promise<IResponse<IGetFaceScreens>> =>
    this.get(`${ControlsEndpoints.FaceIdScreens}`, { params })

  editDevice = (params: IEditDeviceRequest): Promise<IResponse<IDevice>> =>
    this.put(`${ControlsEndpoints.Devices}/${params.id}`, params.data)

  getSystemUserImage = (params: number | null): Promise<Blob> =>
    this.get(`${ControlsEndpoints.FaceControl}${ControlsEndpoints.GetLogImage}`, { params })

  getLastScreenInDevice = (params: IGetLastScreenInDeviceReq): Promise<IResponse<GetLastScreenInDeviceRes[]>> =>
    this.get(`${ControlsEndpoints.FaceControlLastScreenInDevice}`, { params })

  openDoor = (params: IOpenDoor): Promise<IResponse<IDevice>> =>
    this.post(`${ControlsEndpoints.FaceControl}${ControlsEndpoints.OpenDoor}`, params)

  getAuditPermissions = (): Promise<IResponse<PermissionNames>> => this.get(`${Endpoints.AuditsPermissionNames}`)
}

export const controlsApi = new ControlsApi(config)
