import { MouseEvent, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Button, message, TableProps, Tag, Typography } from 'antd'
import { IGroup } from '@/api/groups'
import { StoreTitle, Table } from '@/components/Table'
import { fixedKpi, groupStatus, groupStatusColor, ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { getPaginationParams } from '@/pages/utils'
import { getPerPages } from '@/shared'
import { useStores } from '@/stores'
import { getRowIndex } from '@/utils'
import { formatDate, getPreviousDate } from '@/utils/date'

interface ITableProps {
  isMyGroup?: boolean
}

export const GroupTable = observer(({ isMyGroup }: ITableProps) => {
  const navigate = useNavigate()
  const { groupsStore, appStore } = useStores()
  const perPageFromLocalStorage = getPerPages()

  const handleTablePaginationChange = (value: number, perPage: number) => {
    const previousDate = getPreviousDate()

    const defaultValue = groupsStore?.myGroupFilter?.kpiDate || previousDate

    const info = {
      ...groupsStore.filterOptions,
      page: value,
      perPage,
    }

    const myGroupInfo = {
      ...groupsStore.myGroupFilter,
      page: value,
      id: appStore.profile?.id,
      status: groupsStore.myGroupStatus,
      kpiDate: groupsStore.myGroupFilter?.kpiDate || defaultValue,
      perPage,
    }

    groupsStore.setFilterOption(info)
    if (isMyGroup) {
      groupsStore.getAcademicGroups(myGroupInfo)

      return
    }
    groupsStore.getGroups(info)
  }

  const handleOpenModal = (item: IGroup) => {
    const schedules = {
      activeScheduleItems: item?.activeScheduleItems,
      othersScheduleItems: item?.othersScheduleItems,
    }

    groupsStore.setGroupTeachers(schedules)
    groupsStore.setAssistantsModal(true)
  }

  const handleGroupNameClick = (item: IGroup, e: MouseEvent) => {
    e.preventDefault()

    const schedules = {
      activeScheduleItems: item?.activeScheduleItems,
      othersScheduleItems: item?.othersScheduleItems,
    }

    if (item.activeScheduleItems?.length > 1 || item?.othersScheduleItems?.length > 0) {
      groupsStore.setStaffGroup(item)
      groupsStore.setTeacherSchedule(true)
      groupsStore.setGroupTeachers(schedules)

      return
    }

    const allSchedules = [...item.activeScheduleItems, ...item.othersScheduleItems]

    if (isMyGroup && allSchedules.length > 0) {
      const myGroup = ROUTES.myGroupsSingle
        .replace(':id', String(item.id))
        .replace(':staffId', String(appStore.profile?.id))

      if (e.ctrlKey || e.metaKey) {
        window.open(`${location.origin}${myGroup}`)

        return
      }

      navigate(myGroup)

      if ((item.activeScheduleItems?.length < 2 || item.othersScheduleItems?.length < 2) && allSchedules?.length > 0) {
        const myGroupsSingle = ROUTES.myGroupsSingle
          .replace(':id', String(item.id))
          .replace(':staffId', String(allSchedules?.[0]?.staff?.id))
          .replace(':scheduleId', String(allSchedules?.[0]?.id))

        navigate(myGroupsSingle, { replace: true })
      } else {
        groupsStore.setStaffGroup(item)
        groupsStore.setTeacherSchedule(true)
        groupsStore.setGroupTeachers(schedules)
      }

      return
    }

    if (allSchedules?.length > 0) {
      const groupSingle = ROUTES.groupSingle
        .replace(':id', String(item.id))
        .replace(':staffId', String(allSchedules?.[0]?.staff?.id))
        .replace(':scheduleId', String(allSchedules?.[0]?.id))

      if (e.ctrlKey || e.metaKey) {
        window.open(`${location.origin}${groupSingle}`)

        return
      }
      navigate(groupSingle)
    } else {
      message.warning(mainDictionary.teacherPermission)
    }
  }

  const columns: TableProps<IGroup>['columns'] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (_: any, item: IGroup, index: number) => {
          const perPageOption =
            (isMyGroup ? perPageFromLocalStorage?.myGroupsPerPage : perPageFromLocalStorage?.groupsPerPage) || 10

          return <span>{getRowIndex(groupsStore.filterOptions?.page!, perPageOption, index)}</span>
        },
        fixed: 'left',
        width: 90,
      },
      {
        title: mainDictionary.groupName,
        render: (item: IGroup) => {
          const route = isMyGroup
            ? ROUTES.myGroupsSingle.replace(':id', String(item.id))
            : ROUTES.groupSingle.replace(':id', String(item.id))

          return (
            <Link to={route} onClick={handleGroupNameClick.bind(null, item)}>
              {item?.name}
            </Link>
          )
        },
        fixed: 'left',
      },
      {
        title: mainDictionary.status,
        render: (item: IGroup) => <Tag color={groupStatusColor[item.status]}>{groupStatus[item.status]}</Tag>,
      },
      ...(!isMyGroup
        ? [
            {
              title: mainDictionary.category,
              render: (item: IGroup) => <Typography.Text>{item?.category?.displayName || '-'}</Typography.Text>,
            },
            {
              title: mainDictionary.course,
              render: (item: IGroup) => <Typography.Text>{item?.course?.name || '-'}</Typography.Text>,
            },
            {
              title: mainDictionary.studyType,
              render: (item: IGroup) => <Typography.Text>{item.learningType}</Typography.Text>,
            },
          ]
        : [
            {
              title: mainDictionary.attendanceTitle,
              render: (item: IGroup) => <>{fixedKpi(item?.attendanceKpi)} %</>,
            },
            {
              title: mainDictionary.homework,
              render: (item: IGroup) => <>{fixedKpi(item?.homeworkKpi)} %</>,
            },
            {
              title: mainDictionary.exam,
              render: (item: IGroup) => <>{fixedKpi(item?.examKpi)} %</>,
            },
          ]),
      {
        title: mainDictionary.branch,
        render: (item: IGroup) => <Typography.Text>{item?.office?.name || '-'}</Typography.Text>,
      },
      {
        title: mainDictionary.lessonStartDate,
        render: (item: IGroup) => <span>{formatDate(item.startedDate) || '-'}</span>,
      },
      {
        title: mainDictionary.lessonEndDate,
        render: (item: IGroup) => <span>{formatDate(item.endedDate)}</span>,
      },
      {
        title: mainDictionary.info,
        align: 'center',
        render: (item: IGroup) => (
          <Button type="primary" icon={<EllipsisOutlined />} onClick={handleOpenModal.bind(null, item)} />
        ),
        fixed: 'right',
      },
    ],
    [groupsStore.groups?.groups, groupsStore.academicGroups?.groups],
  )

  const handleShowSizeChange = (page: number, perPage: number) => {
    groupsStore.setFilterOption({
      ...groupsStore.filterOptions,
      perPage,
      page,
    })
  }

  const handleMyGroupShowSizeChange = (page: number, perPage: number) => {
    groupsStore.setMyGroupFilter({
      ...groupsStore.filterOptions,
      perPage,
      page,
    })
  }

  return (
    <Table<IGroup>
      columns={columns}
      rowKey="id"
      scroll={{ x: 1500 }}
      dataSource={isMyGroup ? groupsStore.academicGroups?.staffGroups : groupsStore.groups?.groups}
      onStoreChange={isMyGroup ? handleMyGroupShowSizeChange : handleShowSizeChange}
      storeTitle={isMyGroup ? StoreTitle.MyGroupPerPage : StoreTitle.GroupsPerPage}
      loading={groupsStore.groupsTableLoading}
      pagination={{
        current: (isMyGroup ? groupsStore.myGroupFilter?.page : groupsStore.filterOptions?.page) || 1,
        total: isMyGroup ? groupsStore.academicGroups?.total! : groupsStore.groups?.total!,
        onChange: handleTablePaginationChange,
        ...getPaginationParams({
          total: isMyGroup ? groupsStore.academicGroups?.total! : groupsStore.groups?.total!,
          notShowSizeChanger: false,
        }),
        showSizeChanger: true,
        pageSize: isMyGroup ? perPageFromLocalStorage?.myGroupsPerPage : perPageFromLocalStorage?.groupsPerPage!,
      }}
      bordered
    />
  )
})
