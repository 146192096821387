export enum StudyDepartmentEndpoints {
  HomeworkStatistics = 'homework-statistics',
  ByChecking = '/by-checking',
  GroupActions = '/groups-actions',
  ByStudent = '/by-student',
  Groups = '/groups',
  Student = '/students',
  FilterObjects = '/filter-objects',
  Courses = '/courses',
  ActiveDisciplines = '/actual-disciplines',
  Detail = '/detail',
  StudyMonth = '/study-month',
  Curriculum = '/curriculum',
  List = '/list',
  Roles = '/roles',
  Positions = '/positions',
  HomeworkDbLessons = '/homework-db/lessons',
  HomeworkDb = '/homework-db',
  SetLessons = '/set-lessons',
  Lessons = '/lessons',
  Move = '/move',
  StudentsNotDoingHomework = '/homework-statistics/students-not-doing-homework',
  Academic = '/academic',
  Statistics = '/statistics',
}
