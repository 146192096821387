import { observer } from 'mobx-react'
import { ToolOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import { staffApi } from '@/api/staff/staff'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { ResetPasswordContent } from '@/pages/Users/Teachers/ResetPassword'
import { StaffStatusProps } from '../../types'

export const StaffResetPassword = observer(({ staff }: StaffStatusProps) => {
  const resetPassword = async () => {
    try {
      const {
        data: { password },
      } = await staffApi.resetPassword({
        id: staff.id,
      })

      Modal.info({
        content: <ResetPasswordContent password={password} />,
        footer: null,
        closable: true,
      })
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    }
  }

  const handleResetPasswordClick = () => {
    Modal.confirm({
      title: mainDictionary.resetPasswordConfirm,
      okText: mainDictionary.okText,
      cancelText: mainDictionary.cancelText,
      onOk: (close) => {
        resetPassword()
        close()
      },
    })
  }

  return (
    <div>
      <Tooltip placement="bottom" title={mainDictionary.resetPassword}>
        <Button type="primary" icon={<ToolOutlined />} onClick={handleResetPasswordClick} />
      </Tooltip>
    </div>
  )
})
