export const statisticsDictionary = {
  programming: 'Dasturlash',
  design: 'Dizayn',
  marketing: 'Marketing',
  nameOrId: 'Ism yoki ID',
  nameOrHHId: 'Ism yoki HH ID',
  image: 'Rasm',
  sureName: 'Familiya',
  amount: 'Miqdori',
  collectedResultsMonitoring: 'Yig`ilgan natijalar monitoringi',
  iAllXp: 'Jami XP -',
  participateToLesson: 'Darsga ishtirok bo`yicha ',
  iAllSilver: 'Jami Kumush - ',
  doHomework: 'Uyga vazifalar bo`yicha ',
  givenByAdministration: 'Administratsiya tomonidan berilgan ',
  byParticipateExam: 'Imtihonda qatnashish bo’yicha',
  byPassExam: 'Imtihondan o’tish bo’yicha',
  byHomework: 'Uyga vazifa bo`yicha',
  homeworkGivenDate: 'Uyga vazifa berilgan sana',
  positionRating: 'Reyting o’rni',
  StatisticPosition: 'O`rni',
  haveNotCategory: 'Bu o`quvchida category yo`q',
  datePicker: 'Vaqt bo`yicha',
  lResults: 'natijalar',
  came: 'Keldi',
  dontCame: 'Kelmadi',
  noStatus: 'Status yo`q',
  waiting: 'Kutayotganlar',
  rejected: 'Qaytarilganlar',
  rejects: 'Qaytarilgan',
  accepted: 'Qabul qilinganlar',
  late: 'Kech kelgan',
}

export const statisticsMethodsDictionary: Record<string, string> = {
  participateLesson: statisticsDictionary.participateToLesson,
  doHomework: statisticsDictionary.byHomework,
  participateExam: statisticsDictionary.byParticipateExam,
  passExam: statisticsDictionary.byPassExam,
  byAdmin: statisticsDictionary.givenByAdministration,
}

export const statisticsMoreDictionary = [
  `${statisticsDictionary.participateToLesson} ${statisticsDictionary.lResults}`,
  `${statisticsDictionary.byHomework} ${statisticsDictionary.lResults}`,
  `${statisticsDictionary.byParticipateExam} ${statisticsDictionary.lResults}`,
  `${statisticsDictionary.byPassExam} ${statisticsDictionary.lResults}`,
  `${statisticsDictionary.givenByAdministration} ${statisticsDictionary.lResults}`,
]

export const StudentHomeworkStatus: string[] = [
  statisticsDictionary.noStatus,
  statisticsDictionary.waiting,
  statisticsDictionary.rejected,
  statisticsDictionary.accepted,
]

export const StudentParticipateLessonStatus: string[] = [
  statisticsDictionary.noStatus,
  statisticsDictionary.came,
  statisticsDictionary.dontCame,
  '',
  statisticsDictionary.late,
]
