import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { breadcrumbStore } from '@/stores/breadcrumb'
import { IBreadcrumb } from '@/stores/breadcrumb/types'

export function useBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
  const location = useLocation()

  useEffect(() => {
    breadcrumbStore.addBreadcrumbs(breadcrumbs)

    return () => {
      if (!location.pathname.includes('statistics')) {
        breadcrumbStore.clearBreadcrumbs()
      }
    }
  }, [])
}
