import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, DatePicker, Form, Input, Select } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { useDebounceValue } from 'usehooks-ts'
import { IGroupStatus } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { branchOfficeStore } from '@/pages/Controls/BranchOffice/shared/branch-office.store'
import { getPerPages } from '@/shared'
import { useStores } from '@/stores'
import { ISelectOption } from '@/stores/study-department/types'
import { formatDate } from '@/utils/date'
import { allOption, groupStatus } from '../constants'

import styles from './filter.module.scss'

const cn = classNames.bind(styles)

export const Filter = observer(() => {
  const { groupsStore, studyDepartment } = useStores()
  const { filterOptions, setSelectedOption } = groupsStore
  const [debouncedValue, setDebounceValue] = useDebounceValue('', 1000)
  const [form] = Form.useForm()
  const perPageFromLocalStorage = getPerPages()

  const handleGroupNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDebounceValue(e.currentTarget.value)

    const info = {
      ...filterOptions,
      value: e.currentTarget.value,
      page: 1,
    }

    groupsStore.setFilterOption(info)
  }

  const handleChangeStatus = (value: string) => {
    const info = {
      ...filterOptions,
      status: value,
      page: 1,
    }

    setSelectedOption(value)

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleStartLessonChange = (value: any) => {
    if (!value) {
      const info = {
        ...filterOptions,
        fromByStartedDate: undefined,
        toByStartedDate: undefined,
        page: 1,
      }

      groupsStore.getGroups(info)
      groupsStore.setFilterOption(info)

      return
    }

    const from = formatDate(value?.[0], true)
    const to = formatDate(value?.[1], true)

    const info = {
      ...filterOptions,
      fromByStartedDate: from,
      toByStartedDate: to,
      page: 1,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleOfficeChange = (officeIds: number[]) => {
    const info = {
      ...filterOptions,
      officeIds,
      page: 1,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const offices = useMemo(() => {
    const branches = branchOfficeStore.branchOffices?.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    if (!branches?.length) {
      return [allOption]
    }

    return [allOption, ...branches]
  }, [branchOfficeStore.branchOffices])

  const handleEndLessonChange = (value: any) => {
    if (!value) {
      const info = {
        ...filterOptions,
        fromByEndedDate: undefined,
        toByEndedDate: undefined,
        page: 1,
      }

      groupsStore.getGroups(info)
      groupsStore.setFilterOption(info)

      return
    }

    const from = formatDate(value?.[0], true)
    const to = formatDate(value?.[1], true)

    const info = {
      ...filterOptions,
      fromByEndedDate: from,
      toByEndedDate: to,
      page: 1,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleClear = () => {
    setDebounceValue('')

    const info = {
      page: 1,
      status: IGroupStatus.Active,
      keys: ['hName'],
      perPage: perPageFromLocalStorage?.groupsPerPage || 10,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
    form.resetFields()
    form.setFieldValue('status', IGroupStatus.Active)
  }

  useEffect(() => {
    if (groupsStore.isSynchronized) {
      handleClear()
    }
  }, [groupsStore.isSynchronized])

  useEffect(() => {
    const info = {
      ...filterOptions,
      value: filterOptions?.value || debouncedValue,
      status: filterOptions?.status ? filterOptions?.status : String(IGroupStatus.Active),
    }

    if (filterOptions?.status === null) {
      info.status = ''
    }

    groupsStore.setGroupsTableLoading(true)
    groupsStore.getGroups(info)
  }, [debouncedValue])

  useEffect(() => {
    if (Object.keys(filterOptions).length) {
      setDebounceValue(filterOptions.value!)
      setSelectedOption(
        filterOptions.status ? filterOptions.status : filterOptions?.status === null ? null : IGroupStatus.Active,
      )
      form.setFieldsValue({
        groupName: filterOptions.value,
        startDate: filterOptions.fromByStartedDate
          ? [dayjs(filterOptions.fromByStartedDate), dayjs(filterOptions.toByStartedDate)]
          : undefined,
        endDate: filterOptions?.fromByEndedDate
          ? [dayjs(filterOptions.fromByEndedDate), dayjs(filterOptions.toByEndedDate)]
          : undefined,
        officeId: filterOptions?.officeIds,
        category: filterOptions?.categoryId,
        course: filterOptions?.courseId,
        teacher: filterOptions?.teacherId,
        type: filterOptions?.learningTypeId,
        status: filterOptions?.status,
      })
    }
  }, [])

  useEffect(() => {
    if (groupStatus.length > 1) {
      form.setFieldsValue({
        status: filterOptions.status
          ? filterOptions.status
          : filterOptions?.status === null
            ? null
            : IGroupStatus.Active,
      })
    }
  }, [groupStatus])

  useEffect(() => {
    studyDepartment.getFilterObjects()
  }, [])

  useEffect(() => {
    branchOfficeStore.getBranchOffices().catch(addCatchNotification)
  }, [])

  const categories = useMemo(
    () =>
      studyDepartment.categories?.map((item) => ({
        label: item.label,
        value: item?.value,
      })),
    [studyDepartment.categories],
  )

  const handleChangeCategory = (categoryId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      categoryId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleChangeCourse = (courseId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      courseId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleChangeTeacher = (teacherId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      teacherId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleChangeLearningType = (learningTypeId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      learningTypeId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleFilter = (input: string, option?: ISelectOption) =>
    (option?.label ?? '').toLowerCase().includes(input?.toLowerCase() ?? '')

  const handleFilterSort = (optionA: ISelectOption, optionB: ISelectOption) =>
    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())

  return (
    <Form disabled={groupsStore.groupsTableLoading} layout="vertical" className={cn('filter')} form={form}>
      <Form.Item label={mainDictionary.groupName} name="groupName">
        <Input onChange={handleGroupNameChange} />
      </Form.Item>

      <Form.Item label={mainDictionary.status} name="status">
        <Select className={cn('filter__select-status')} onChange={handleChangeStatus} options={groupStatus} />
      </Form.Item>

      <Col span={3}>
        <Form.Item name="category" label={mainDictionary.category}>
          <Select options={categories} defaultValue={allOption?.value} onChange={handleChangeCategory} />
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item name="course" label={mainDictionary.course}>
          <Select
            options={studyDepartment.courses!}
            onChange={handleChangeCourse}
            placeholder={mainDictionary.choose}
            filterOption={handleFilter}
            showSearch
            filterSort={handleFilterSort}
          />
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item name="teacher" label={mainDictionary.academic}>
          <Select
            options={studyDepartment.teachers!}
            placeholder={mainDictionary.choose}
            filterOption={handleFilter}
            showSearch
            filterSort={handleFilterSort}
            onChange={handleChangeTeacher}
          />
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item name="type" label={mainDictionary.typeOfStudy}>
          <Select
            options={studyDepartment.learningTypes!}
            defaultValue={allOption.value}
            onChange={handleChangeLearningType}
          />
        </Form.Item>
      </Col>

      <Form.Item name="startDate" label={mainDictionary.lessonStartDate}>
        <DatePicker.RangePicker onChange={handleStartLessonChange} />
      </Form.Item>

      <Form.Item name="endDate" label={mainDictionary.lessonEndDate}>
        <DatePicker.RangePicker onChange={handleEndLessonChange} />
      </Form.Item>

      {offices?.length > 2 && (
        <Form.Item name="officeId" label={mainDictionary.branches}>
          <Select
            defaultValue={allOption.value}
            className={cn('filter__select-status')}
            options={offices}
            placeholder={mainDictionary.chooseBranch}
            onChange={handleOfficeChange}
          />
        </Form.Item>
      )}

      <Button htmlType="button" onClick={handleClear}>
        {mainDictionary.clear}
      </Button>
    </Form>
  )
})
