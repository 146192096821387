import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { Flex, Modal, Table, Typography } from 'antd'
import classNames from 'classnames/bind'
import { ScheduleItem } from '@/api/groups'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { fullTimeToFormattedDate, getFormattedTimeFromParams } from '@/utils/date'

import styles from './assistant-modal.module.scss'
const cn = classNames.bind(styles)

export const AssistantsModal = observer(() => {
  const { groupsStore } = useStores()

  const handleClose = () => {
    groupsStore.setAssistantsModal(false)
    groupsStore.setAssistants(null)
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (_: ScheduleItem, item: ScheduleItem, index: number) => <span>{index + 1}</span>,
      },
      {
        title: mainDictionary.teacherName,
        render: (item: ScheduleItem) => <span>{`${item.staff?.firstName} ${item.staff?.lastName}`}</span>,
      },
      {
        title: mainDictionary.iRole,
        render: (item: ScheduleItem) => <span>{item.staff?.role?.name}</span>,
      },
      {
        title: mainDictionary.dLessonDays,
        render: (item: ScheduleItem) => <span>{item?.daysOfWeek.join('-')}</span>,
      },
      {
        title: mainDictionary.dLessonTime,
        render: (item: ScheduleItem) => (
          <span>{`${getFormattedTimeFromParams(item.beginTime)} - ${getFormattedTimeFromParams(item.endTime)}`}</span>
        ),
      },
      {
        title: mainDictionary.schedulePeriod,
        render: (item: ScheduleItem) => (
          <span>{`${fullTimeToFormattedDate(item?.startDate)} - ${fullTimeToFormattedDate(item.endDate)}`}</span>
        ),
      },
      {
        title: mainDictionary.room,
        render: (item: ScheduleItem) => <span>{item?.classroomName || '-'}</span>,
      },
    ],
    [],
  )

  return (
    <Modal
      open={groupsStore.assistantsModal}
      title={mainDictionary.info}
      onCancel={handleClose}
      onOk={handleClose}
      width={1200}
    >
      <Flex justify="center">
        <Typography.Title level={5}>{mainDictionary.schedule}</Typography.Title>
      </Flex>
      <div className={cn('assistant-modal')}>
        <div>
          <Typography.Title level={5}>{mainDictionary.activeSchedules}</Typography.Title>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={groupsStore.groupTeachers?.activeScheduleItems!}
          />
        </div>
        <div className={cn('assistant-modal__inactive')}>
          <Typography.Title level={5}>{mainDictionary.otherSchedules}</Typography.Title>

          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={groupsStore.groupTeachers?.othersScheduleItems!}
          />
        </div>
      </div>
    </Modal>
  )
})
