import { kinescopeStore } from '@/modules/kinescope/store'
import { permissionsStore } from '@/modules/permission'
import { academicStore } from './academic'
import { appStore } from './app'
import { assistantsStore } from './assistants'
import { auditStore } from './audit'
import { authStore } from './auth'
import { breadcrumbStore } from './breadcrumb'
import { controlsStore } from './controls'
import { eventsStore } from './events'
import { faceIdStore } from './faceId'
import { gamificationStore } from './gamification'
import { groupsStore } from './groups'
import { marketStore } from './market'
import { notificationStore } from './notifications'
import { profileStore } from './profile'
import { reportsStore } from './reports'
import { settingsStore } from './settings'
import { staffStore } from './staff'
import { studentStore } from './students/student'
import { studyDepartment } from './study-department'
import { teachersStore } from './teachers'
import { teachersKpiStore } from './teachers-kpi'
import { usersStore } from './users'

export const stores = {
  kinescopeStore,
  academicStore,
  appStore,
  assistantsStore,
  auditStore,
  gamificationStore,
  faceIdStore,
  authStore,
  breadcrumbStore,
  controlsStore,
  groupsStore,
  permissionsStore,
  profileStore,
  staffStore,
  studentStore,
  teachersStore,
  usersStore,
  studyDepartment,
  reportsStore,
  settingsStore,
  marketStore,
  teachersKpiStore,
  notificationStore,
  eventsStore,
}

export const resetStores = () => {
  academicStore.reset()
  appStore.reset()
  auditStore.reset()
  authStore.reset()
  breadcrumbStore.reset()
  controlsStore.reset()
  gamificationStore.reset()
  groupsStore.reset()
  kinescopeStore.reset()
  permissionsStore.reset()
  profileStore.reset()
  staffStore.reset()
  studentStore.reset()
  usersStore.reset()
  settingsStore.reset()
  teachersKpiStore.reset()
}
